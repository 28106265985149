import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import ToaBarChart from 'src/components/BarChart'
import InfoTooltip from 'src/components/InfoTooltip'
import Modal from 'src/components/Modal'
import Button from 'src/components/input/Button'
import NumberGrid, { Colors, States } from 'src/components/tables/NumberGrid.tsx'
import ArrayHelper from 'src/utils/arrayHelpers.ts'
import { H2, Label } from 'src/pages/Labor/CrewPlanner/MainView.tsx'
import CustomTooltip from 'src/components/charts/helpers/CustomTooltip.tsx'
import moment from 'moment'

type Props = {
  jobsAvailable: number[],
  plannedJobs: number[],
  setPlannedJobs: (plannedJobs: number[]) => void
}

const CHART_HEIGHT = 84;

/**
  * Displays jobs available by week and planned jobs by week.
  * Shows a number grid and a bar chart. The bar chart is colored
  * when planned jobs exceed available jobs.
  *
  * Planned jobs are editable (setPlannedJobs is called when a cell is edited)
  */
export default function AvailableWork({ jobsAvailable, plannedJobs, setPlannedJobs }: Props) {

  const [showJobsAvailableModal, setShowJobsAvailableModal] = useState<boolean>(false)

  let tooltips = {
    plannedJobs: "Your forecast for how many jobs you plan to do for each week."
  }

  let rows = [
    {
      name: "Available",
      values: jobsAvailable,
      isEditable: false,
      state: States.DEFAULT,
    },
    {
      name: "Planned",
      values: plannedJobs,
      isEditable: true,
      state: States.DEFAULT,
      border: Colors.GREEN,
    },
  ]

  let validPlanned = plannedJobs.map((p, i) => Math.min(p, jobsAvailable[i]));

  let dataKeys = ["available", "planned", "validPlanned"]
  let weeks = ArrayHelper.generateNDatesFormatted(jobsAvailable.length, "weeks", moment().utc().startOf("week").toDate(), "M/D", { useUTC: true });
  let data = ArrayHelper.mergeArrays([...dataKeys, "week"], jobsAvailable, plannedJobs, validPlanned, weeks)

  const tooltipFields = [
    { field: "available", label: "Available" },
    { field: "planned", label: "Planned" },
  ]

  /**
   * Expands job available explanation modal
   */
  function handleJobsAvailableExpand() {
    setShowJobsAvailableModal(true)
  }

  /**
    * Handles changes to the planned jobs grid
    * 
    */
  function handlePlannedChange(row: number, column: number, value: number) {
    let newPlannedJobs = [...plannedJobs]
    newPlannedJobs[column] = value
    setPlannedJobs(newPlannedJobs)
  }

  return (
    <>

      {/* Chart */}
      <div className="flex gap-2 -mt-10">

        {/* Spacing */}
        <Label></Label>

        <ToaBarChart
          height={CHART_HEIGHT}
          data={data}
          dataKeys={dataKeys}
          layering="bullet"
          colors={["fill-gray-300", "fill-secondary-orange", "fill-gray-600"]}
          labelLists={[false, false, false]}
          xAxisSettings={{
            hide: true
          }}
          yAxisSettings={{
            hide: true
          }}
          customTooltip={<CustomTooltip
            fields={tooltipFields}
            headerField={{ field: "week", label: "Week" }}
          />}
        />

      </div>

      {/* Table */}
      <div className="flex items-stretch gap-2">
        <Label>
          <H2>
            <p>Jobs Available</p>
            {/* TODO: implement jobs available explanation */}
            {/* <button onClick={handleJobsAvailableExpand} className="ml-auto"><ArrowsPointingOutIcon className="h-5 w-5 text-primary-green hover:text-primary-green-600" /></button> */}
          </H2>
          <H2>
            <p>Planned Jobs</p>
            <InfoTooltip text={tooltips.plannedJobs} />
          </H2>
        </Label>

        <NumberGrid rows={rows} onBlur={handlePlannedChange} />
      </div>


      {/* Modals */}
      <Modal open={showJobsAvailableModal} setOpen={setShowJobsAvailableModal}>
        {/* TODO: implement */}
        Some super awesome explanation of how jobs available is calculated
      </Modal>
    </>
  )
}
