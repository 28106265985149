import { UserPlusIcon } from 'lucide-react';
import React, { useState } from 'react';
import Button from 'src/components/input/Button';
import Dropdown from 'src/components/input/Dropdown';
import Input from 'src/components/input/Input';
import Modal from 'src/components/Modal';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import useLocations from 'src/hooks/data/locations/useLocations.ts';
import { Order } from 'src/hooks/data/orders/useOrders.ts';
import useUsers from 'src/hooks/data/users/useUsers.ts';
import UserManager from 'src/tools/UserManager';
import { addressToString, getDeliveryDateString, MinorBadge } from '../OrderRowItem.tsx';
import useTrucks from 'src/hooks/data/trucks/useTrucks.ts';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/Spinner';

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}


/**
 * Modal with a truck selection dropdown and note input.
 * Handles talking to server on form submission.
 */
export default function AssignTruckModal({
  order, open, setOpen
}: Props) {
  order = order ?? {
    name: "ORDER NAME",
    soNumber: 'SO NUMBER',
    contact: {
      name: 'CONTACT NAME',
      phone: 'CONTACT PHONE',
      email: "CONTACT EMAIL",
    }
  } as Order

  const [truckId, setTruckId] = useState<string>()
  const [note, setNote] = useState<string>("")

  const [loading, setLoading] = useState<boolean>(false)

  const pickticketFilePath = order.files.pickTickets?.[0]?.filePath; // pick_tickets/<company ID>/<order ID>/<file name>
  const pickticketFileName = pickticketFilePath ? pickticketFilePath.split("/").pop() : null;
  const pickticketLinkArr = useS3URLs(pickticketFilePath ? [pickticketFilePath] : []);
  const pickticketLink = pickticketLinkArr?.length ? pickticketLinkArr[0].getObjectSignedUrl : null;

  const trucks = useTrucks();

  const deliveryString = getDeliveryDateString(order)
  const addressString = addressToString(order.orderAddress)

  const locations = useLocations(); // TODO: optimize to only get the one for this
  const connections = useConnections(); // TODO: optimize to only get the one for this order

  const location = order.locationId ? locations?.find(m => m._id === order.locationId) : { name: "No Location" }
  const connection = connections?.find(c => c.id === order.installerId)

  /**
   * Submits the form
   */
  async function handleSubmit(e) {
    e.preventDefault() // Don't refresh

    setLoading(true);

    try {
      var res = await UserManager.makeAuthenticatedRequest(`/api/orders/assign-truck`, "POST", {
        orderId: order._id,
        truckId: truckId,
        note: note
      })
    } catch (err) {
      console.error(err);
    }

    // TODO: success message
    setOpen(false); // Close modal
    setLoading(false);
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
    >
      <form onSubmit={handleSubmit}>
        {/* Header */}
        <div className="pb-4">
          <div className="flex items-center">
            <div>
              <Title>Hand off to Delivery Team</Title>
              <Subtitle>Add truck to trigger shipping process</Subtitle>
            </div>
            <div className="ml-auto text-sm font-medium">
              Sale Order #: <span className='font-semibold text-gray-600'>{order.soNumber ?? "--"}</span>
            </div>
          </div>
        </div>

        <Divider />

        {/* Order Details */}
        <Group>
          <div className="flex justify-between">
            {/* Order Name */}
            <div>
              <div className="text-sm font-normal text-gray-600">
                Order {/* TODO: TOA ID */}
              </div>
              <div className="text-sm font-medium">
                {order.name}
              </div>
            </div>
            {/* Main Metadata */}
            <div>
              <div className="flex gap-2">
                <MinorBadge>
                  {
                    location ? location.name
                      : <div className="animate-pulse">Location</div>
                  }
                </MinorBadge>
                <MinorBadge>
                  {
                    connection ? connection.name
                      : <div className="animate-pulse">Installer</div>
                  }
                </MinorBadge>
              </div>
              <div>{deliveryString}</div>
              <div>{addressString}</div>
            </div>
            {/* Contact */}
            <div className="space-y-1">
              <div className="text-sm font-medium">Contact Info</div>
              <div className="text-sm font-normal text-gray-500">
                <div>{order.contact?.name}</div>
                <div>{order.contact?.phone}</div>
                <div>{order.contact?.email}</div>
              </div>
            </div>
          </div>
        </Group>

        <Divider />

        {/* Truck Selection */}
        <Group>
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex items-center gap-3">
              <div><UserPlusIcon className="w-6 h-6 text-gray-700" /></div>
              <div className="pr-4 text-sm font-bold">Truck</div>
              {
                trucks
                  ? (trucks.length > 0
                    ? (
                      <Dropdown
                        wide
                        justifyLeft
                        placeholder="Select a truck"
                        options={[trucks.map(truck => ({
                          label: truck.name,
                          value: truck._id
                        }))]}
                        selectedValue={truckId}
                        onSelected={(option) =>
                          setTruckId(option.value)
                        }
                      />
                    )
                    : (
                      <Link
                        className="px-2 py-1 border rounded-md bg-primary-rose-100 border-primary-rose-200 text-primary-rose"
                        to="/app/trucks/manage"
                      >
                        No trucks, add a truck on the Trucks page.
                      </Link>
                    )
                  )
                  : (
                    <div className="w-40 h-8 bg-gray-100 rounded-md animate-pulse"></div>
                  )
              }
            </div>
            <div className="text-sm text-gray-600">
              <span className="font-semibold">Pick Ticket:</span>
              {" "}
              <span>{pickticketFileName ?? "No Pick Ticket"}</span>
              {" "}
              {pickticketFileName && <a
                className={
                  pickticketLink ?
                    "text-primary-green cursor-pointer" :
                    "animate-pulse"
                }
                href={"TEST"}
              >
                View
              </a>}
            </div>
            <div className="flex w-full gap-3 pt-4 px-7">
              <div className='text-sm font-semibold text-gray-500'>Notes:</div>
              <div className="grow">
                <Input
                  type="textarea"
                  placeholder='Enter any notes here...'
                  value={note}
                  onChange={value => setNote(value)}
                />
              </div>
            </div>
          </div>
        </Group>

        {/* Actions */}
        <Footer>
          <div className="flex items-center justify-end gap-2">
            {
              loading ? <div className="flex items-center pr-7">
                <Spinner size={20} />
              </div> :
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!truckId}
                >
                  Assign Truck
                </Button>
            }
            <Button
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Footer>
      </form>
    </Modal >
  )
}

/**
  * Simple styling for form group
  */
function Group({ children }) {
  return <div className="py-4">{children}</div>
}

/**
  * Simple styling for form title
  */
function Title({ children }) {
  return <div className="text-base font-semibold">{children}</div>
}

/**
  * Simple styling for form subtitle
  */
function Subtitle({ children }) {
  return <div className="text-sm font-normal text-gray-500">{children}</div>
}

/**
  * Simple styling for form divider
  */
function Divider({ }) {

  return <div className="-mx-4 border-t sm:-mx-6" />
}

/**
  * Simple styling for form footer
  */
function Footer({ children }) {
  return (
    <div
      className="px-4 py-2 mt-4 -m-4 rounded-b-lg bg-gray-50 sm:-m-6 sm:mt-2 sm:px-6 sm:py-3"
    >
      {children}
    </div>
  )
}
