import { ArrowTopRightOnSquareIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from 'react'
import ForecastInput from "../Forecast/ForecastInput";
import NumberGrid from '../tables/NumberGrid.tsx';
import ActualVsForecastChart from '../ActualVsForecastChart';
import ToaBarChart from '../BarChart.js';
import classNames from "src/tools/classNames.js";
import Modal from "../Modal";
import calculateForecastError from "src/tools/Forecast/calculateForecastError.ts";
import { Link } from "react-router-dom";
import { WeekMap, weekMapToArray } from "src/hooks/data/forecast/useJobAvailabilityBreakdown.ts";
import moment from "moment";

// define row props
type RowProps = {
  futureData: any;
  historicalData: any;
  marketId: string;
  marketName: string;
  onSalesForecastChange: (
    marketId: string,
    colIndex: number,
    value: number
  ) => void;

  installsAvailable: {
    excludingSales: number;
    includingSales: number;
  }[];
  plannedJobs: {
    forecast: WeekMap;
    historical: number[];
  }
}

// TODO: comment
export default function Row({
  futureData,
  historicalData,
  marketId,
  marketName,
  onSalesForecastChange,

  installsAvailable,
  plannedJobs
}: RowProps) {

  const [forecastAccuracy, setForecastAccuracy] = useState(0);

  const [rowExpanded, setRowExpanded] = useState(marketId === "total");

  const [installsAvailableModalOpen, setInstallsAvailableModalOpen] = useState(false);

  const barChartTestData = {
    height: "100%",
    // minHeight: 170,
    data: futureData,
    dataKeys: ["readyToInstall", "forecast"],
    colors: ["fill-gray-300", "fill-gray-500"],
    labelLists: [false, true],

    xAxisSettings: {
      // stroke: "#6b7280",
      // axisLine: false,
      // tickLine: false,
      // dataKey: "week",
      hide: true,
    },
    yAxisSettings: {
      hide: true,
    },

    layering: "bullet",
    hideTooltip: true,
  };

  const forecastVsActualRows = [
    {
      state: "default",
      values: historicalData?.map((data) => data.forecast),
      border: "border-primary-green border-2",
      isEditable: false,
    },
    {
      state: "default",
      values: historicalData?.map((data) => data.actual),
      isEditable: false,
    },
    {
      state: "default",
      values: plannedJobs?.historical ?? Array(6).fill(null),
      isEditable: false,
    },
  ];

  const forecastAvailableToInstall = [
    {
      state: "default",
      values: futureData?.map((item) => item.forecast),
      border: "border-primary-green border-2",
      isEditable: marketId !== "total",
    },
    {
      state: "default",
      values: installsAvailable?.map((item) => item?.includingSales || 0) ?? Array(6).fill(null),
      isEditable: false,
    },
    {
      state: "default",
      values: plannedJobs ? weekMapToArray(plannedJobs.forecast, 16, moment().utc().startOf("week").toDate()) : Array(6).fill(null),
      isEditable: false,
    },
  ];

  const Skeleton = ({ width = "100%", height = "100%" }: { width?: number | string, height?: number | string }) => <div className="bg-gray-300 rounded-lg animate-pulse" style={{ height, width }} />

  useEffect(() => {
    calcForecastError();
  }, [historicalData]);

  function calcForecastError() {
    let error = calculateForecastError(historicalData ?? []);
    error = Math.round(error * 100);

    setForecastAccuracy(error);
  }

  function handleExpand() {
    setRowExpanded(!rowExpanded);
  }

  function handleExpandInstallsAvailable() {
    setInstallsAvailableModalOpen(!installsAvailableModalOpen);
  }

  // historical sales forecast vs actual number grid
  var historicalForecastActualGrid = (
    <div
      className={classNames(
        'flex flex-col items-end justify-end h-full',
        !rowExpanded ? 'pb-4' : ''
      )}
    >
      <div className="w-[300px]">
        {/* week labels, use 6 columns */}
        <div className="grid grid-cols-6">
          {historicalData?.map((item, i) => (
            <div className="col-span-1 row-span-1 py-1 text-xs font-normal leading-4 text-center text-gray-500 align-middle">
              {item.week}
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-row items-center justify-end w-[410px]',
          rowExpanded ? 'h-full' : ''
        )}
      >
        <div className='flex flex-col items-end justify-around h-full pr-2 grow'>
          <div className='text-sm font-medium leading-5 text-right align-middle'>Sales Forecast</div>
          <div className='text-sm font-medium leading-5 text-right align-middle'>Actual Sales</div>
          <div className='text-sm font-medium leading-5 text-right align-middle'>Planned Jobs</div>
        </div>
        <div className='w-[300px]'>
          {historicalData &&
            <NumberGrid
              rows={forecastVsActualRows}
              numColumns={6}
            />
          }
        </div>
      </div>

    </div>
  )

  // future sales forecast vs available to install number grid
  var forecastAvailableToInstallGrid = (
    <div
      className={classNames(
        'flex flex-col items-end justify-end h-full',
        !rowExpanded ? 'pb-4' : ''
      )}
    >
      <div className="w-[800px]">
        {/* week labels, use 16 columns */}
        <div className="grid grid-cols-[repeat(16,minmax(0,1fr))]">
          {futureData?.map((item, i) => (
            <div className="col-span-1 row-span-1 py-1 text-xs font-normal leading-4 text-center text-gray-500 align-middle">
              {item.week}
            </div>
          ))}

        </div>
      </div>
      <div className='flex flex-row items-end justify-end w-[950px]'>
        <div className='flex flex-col items-end justify-around h-full pr-2 grow'>
          <div className='py-1 text-sm font-medium leading-5 text-right align-middle'>Sales Forecast</div>
          <div className="flex flex-row items-center justify-start gap-1">
            <div className='text-sm font-medium leading-5 text-right align-middle'>Installs Available</div>
            {/* // TODO: uncomment when implemented */}
            {/* <button */}
            {/*   className="my-1" */}
            {/*   onClick={handleExpandInstallsAvailable} */}
            {/* > */}
            {/*   <ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" /> */}
            {/* </button> */}
          </div>
          <div className='text-sm font-medium leading-5 text-right align-middle'>Planned Jobs
            {marketId !== "total" && <Link to={"../labor/crew-planner?market=" + marketId} className="pl-3">
              <ArrowTopRightOnSquareIcon className="w-5 h-5 text-primary-green hover:text-primary-green-600 inline" />
            </Link>}
          </div>
        </div>
        <div className='w-[800px]'>
          <NumberGrid
            rows={forecastAvailableToInstall}
            numColumns={16}
            onChange={(rowIndex, colIndex, value) => {
              onSalesForecastChange(marketId, colIndex, parseInt(value));
            }}
          />
        </div>
      </div>
    </div>
  )

  var installsAvailableModal = (
    <Modal
      open={installsAvailableModalOpen}
      setOpen={handleExpandInstallsAvailable}
      wide
    >
      Placeholder
    </Modal>
  )

  if (rowExpanded) {
    return (
      <>
        {installsAvailableModal}
        <div className='p-4 mx-1 border-t border-b border-gray-300 bg-gray-50 w-[1592px]'>
          {/* // TODO: improve height? */}
          {/* <div className="grid grid-cols-13 grid-rows-3 gap-1 w-[1560px] h-80"> */}
          <div className="grid grid-cols-13 grid-rows-3 gap-1 w-[1560px] h-[360px]">

            <div className="col-start-1 col-end-3 row-start-1 row-end-3" >
              <div className="flex flex-row items-center justify-start w-40 gap-3">
                {marketId != "total" &&
                  <button
                    className="my-1"
                    onClick={handleExpand}
                  >
                    <ArrowsPointingInIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" />
                  </button>
                }
                <div className='my-2 overflow-hidden text-base font-semibold leading-6 text-center align-middle text-nowrap text-ellipsis'>
                  {marketId === "total" ? "Total" : marketName}
                </div>
              </div>
            </div>

            <div className="col-start-3 col-end-6 row-start-1 row-end-3" >
              <div className='flex flex-row justify-end h-full mx-4'>
                {/* make the line chart only partial width so it lines up with the center of the cells below it */}
                <div className='flex flex-row w-[270px] justify-center items-center'>
                  <ActualVsForecastChart
                    data={historicalData}
                    height={180}
                  />
                </div>
              </div>
            </div>

            <div className="col-start-6 col-end-13 row-start-1 row-end-3" >
              {/* <Skeleton /> */}
              <div className='flex flex-row justify-end mt-1 h-52'>
                <div className='w-[800px] h-full'>
                  <ToaBarChart
                    {...barChartTestData}
                  />
                </div>
              </div>
            </div>

            <div className="flex col-start-1 col-end-2 row-start-3 row-end-4" >
              <div className="flex flex-col items-center justify-end w-40">
                <div className='flex flex-col items-center self-end justify-center w-32 bg-white border border-gray-300 rounded h-14'>
                  <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Forecast Error</div>
                  <div className='text-base font-semibold leading-6 text-center align-middle'>{
                    forecastAccuracy === 0
                      ? "0%"
                      : forecastAccuracy > 0
                        ? "+" + forecastAccuracy + "%"
                        : forecastAccuracy + "%"
                  }</div>
                </div>
              </div>
            </div>

            <div className="col-start-2 col-end-6 row-start-3 row-end-4">
              {historicalForecastActualGrid}
            </div>

            <div className="col-start-6 col-end-13 row-start-3 row-end-4" >
              {forecastAvailableToInstallGrid}
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        {installsAvailableModal}
        <div className='px-4 mx-1 border-t border-gray-400 w-[1592px]'>
          {/* // TODO: improve height? */}
          {/* <div className="grid grid-cols-13 grid-rows-1 gap-1 w-[1560px] h-32"> */}
          <div className="grid grid-cols-13 grid-rows-1 gap-1 w-[1560px] h-[170px]">

            <div className="col-start-1 col-end-2 row-start-1 row-end-2" >
              <div className='flex flex-col items-center justify-end w-40 h-full gap-1.5 pb-4'>
                <div className="flex flex-row items-center justify-start w-full gap-3">
                  <button
                    className="my-1"
                    onClick={handleExpand}
                  >
                    <ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" />
                  </button>
                  <div className='overflow-hidden text-base font-semibold leading-6 text-left align-middle text-nowrap text-ellipsis'>
                    {marketId === "total" ? "Total" : marketName}
                  </div>
                </div>

                <div className='flex flex-col items-center self-end justify-center w-32 bg-white border border-gray-300 rounded h-14'>
                  <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Forecast Error</div>
                  <div className='text-base font-semibold leading-6 text-center align-middle'>{
                    forecastAccuracy === 0
                      ? "0%"
                      : forecastAccuracy > 0
                        ? "+" + forecastAccuracy + "%"
                        : forecastAccuracy + "%"
                  }</div>
                </div>
              </div>
            </div>

            <div className="col-start-2 col-end-6 row-start-1 row-end-2">
              {historicalForecastActualGrid}
            </div>

            <div className="col-start-6 col-end-13 row-start-1 row-end-2" >
              {forecastAvailableToInstallGrid}
            </div>

          </div>
        </div>
      </>
    )
  }

}
