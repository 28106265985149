import React, { useEffect, useState } from 'react';
import ToaBarChart from '../BarChart';
import classNames from 'src/tools/classNames';
import CustomTooltip, { Field as TooltipFields } from './helpers/CustomTooltip.tsx';

/**
  * Data type for the MinMaxBarChart props
  */
type Data = {
  value: number,
  label: number | string,
  [key: string]: any,
}

/**
 * Wrapper of the CustomTooltip Fields type.
  * Requires field to be in the data object.
 */
export type Field = TooltipFields & {
  field: keyof Data,
}

/**
  * Data type passed to the ToaBarChart component.
  * Same as Data type but adds invalidValue and validValue fields.
  * These fields are used to color the bars differently depending on the min and max values.
  */
type MinMaxData = {
  label: number | string,
  value: number,
  validValue?: number,
  invalidValue?: number,
  [key: string]: any,
}

type Props = {
  data: Data[],
  tooltipFields?: Field[],
  min: number,
  max: number
  height?: number,
  showInputs?: boolean,
  onMinChange?: (v: number) => void,
  onMaxChange?: (v: number) => void,
}

/**
  * A bar chart that allows the user to set a min and max value.
  * The min and max are displayed in an area with inputs on the left.
  * Values outside of the min and max range are displayed in a different color.
  * This is a wrapper of the ToaBarChart component.
  */
export default function MinMaxBarChart<F extends string[]>({
  data,
  tooltipFields = [],
  min,
  max,
  height = 150,
  showInputs = false,
  onMinChange = (v: number) => { },
  onMaxChange = (v: number) => { },
}: Props): JSX.Element {

  const dataWithMinMax = formatData(data, min, max);

  const minMaxArea = {
    y1: min,
    y2: max,
    fill: "",
    stroke: "",
    strokeWidth: 2,
    strokeDasharray: "4 4",
    className: "fill-primary-green/50 stroke-primary-green",
  }

  const chartHeight = height - 52; // X axis height
  const maxValue = Math.max(...data.map(d => d.value));
  let maxInputTop = chartHeight * (1 - (max / Math.max(max, maxValue)));
  let minInputTop = chartHeight * (1 - (min / Math.max(max, maxValue)));
  minInputTop = Math.max(minInputTop, maxInputTop + 10);
  maxInputTop = Math.min(maxInputTop, minInputTop - 20);

  const topOffset = 20;

  return <div className="relative">
    <ToaBarChart
      data={dataWithMinMax}
      dataKeys={["value", "invalidValue", "validValue"]}
      colors={[null, "fill-primary-rose", "fill-gray-500"]}
      labelLists={[true, false, false]}
      height={height}
      yAxisSettings={{ hide: true, domain: [0, Math.max(max, maxValue)] }}
      xAxisSettings={{
        tickLine: false,
        axisLine: false,
        dataKey: "label",
      }}
      areas={[minMaxArea]}
      barSize={20}
      layering="layered"
      margin={{
        // Hide left and right edge of area
        left: - 2, right: -2,
        top: 20,
      }}

      customTooltip={<CustomTooltip
        fields={
          tooltipFields.length ?
            tooltipFields : [
              { field: "value", label: "Value" },
            ]}
        headerField={{ field: "label", label: "Label" }}

      />}
    />


    {/* Inputs */}
    {showInputs && <>
      <div className={classNames("absolute left-0 -translate-x-full -translate-y-1/2")} style={{ top: maxInputTop + topOffset }}>
        <TinyInput
          position={LabelPos.TOP}
          label="max"
          value={max}
          onChange={onMaxChange}
        />
      </div>
      <div className={classNames("absolute left-0 -translate-x-full -translate-y-1/2")} style={{ top: minInputTop + topOffset }}>
        <TinyInput
          position={LabelPos.BOTTOM}
          label="min"
          value={min}
          onChange={onMinChange}
        />
      </div>
    </>}
  </div>
}


/**
  * Formats the data to include invalid and valid fields depending on the min and max.
  * This makes it so the chart can display values outside of the min and max range in a different color.
  */
function formatData(data: Data[], min: number, max: number): MinMaxData[] {
  return data.map(d => {

    if (d.value < min) {
      var invalidValue = d.value;
    } else if (d.value > max) {
      var validValue = max;
      var invalidValue = d.value;
    } else {
      var validValue = d.value;
    }

    return {
      label: d.label,
      value: d.value,
      invalidValue,
      validValue,
      ...d
    }
  });
}

/**
* Used for positiong the "min" and "max" labels.
* Min uses the bottom position and max uses the top position.
*/
enum LabelPos {
  TOP, BOTTOM
}

type TinyInputProps = {
  value: number;
  position?: LabelPos;
  label?: string;
  onChange?: (v: number) => void;
}


/**
  * Helper component for the MinMaxBarChart.
  * A small input that allows the user to change the min and max values of the chart.
  * Is positioned to the left at the top or bottom of the min/max range
  */
function TinyInput({ value: value_prop, position = LabelPos.TOP, label = "", onChange = () => { } }: TinyInputProps) {

  const [value, setValue] = useState(value_prop);

  // Sync on prop change
  useEffect(() => {
    setValue(value_prop);
  }, [value_prop]);

  return (
    <div className="relative focus-within:z-10">
      {label && (<label
        className={classNames(
          "absolute inset-x-0",
          position === LabelPos.TOP ? "bottom-full" : "top-full",
          "text-xs font-normal leading-4 text-gray-400 text-center",
        )}
      >{label}</label>)}
      <input
        type="number"
        min={0}
        step={0.1}
        className={classNames(
          "hide-arrows",
          "text-center text-xs font-normal leading-4",
          "border-0 ring-primary-green ring-1 ring-inset focus:ring-2 focus:ring-primary-green",
          "invalid:ring-red-500 focus:invalid:ring-red-500",
          "w-8",
          "px-0.5 py-0.5"
        )}
        value={value}

        placeholder="0"
        onKeyDown={e => { if (e.key === "Enter") e.currentTarget.blur() }}
        onBlur={() => { onChange(value); setValue(value_prop) }}
        onChange={e => {
          let value = +e.target.value;

          setValue(value);
        }}
      />
    </div>
  )
}
