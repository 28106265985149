import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import { ClockIcon, HomeIcon, PlusIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/react/24/outline'
import { BanIcon, FilePlus2Icon, HandshakeIcon, PackageIcon, PackageOpenIcon, StickyNoteIcon, WorkflowIcon } from 'lucide-react'
import { PlusCircleIcon } from "@heroicons/react/24/solid";

// --- Mimic Backend Types --- //

export type Address = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: number;
}

export type Timestamp = {
  type: TimestampTypes;
  stamp: Date;
  typeInfo: TimestampTypeInfo;
  addedBy: string; // ObjectId
}

// Based on timestamp type
// TODO: improve typing
export type TimestampTypeInfo = {
  oldStatus: OrderStatus;
  newStatus: OrderStatus;
} | {
  filePath: string;
}

export type File = {
  filePath: string;
  uploadedAt: Date;
  uploadedBy: string; // ObjectId
}

export type RequstedDelivery = {
  deliveryDate: Date; // Ignore time, use date
  deliveryWindow: {
    start: Date; // Ignore date, use time
    end: Date; // Ignore date, use time
  };
  relativeToInstall?: {
    // Number of "unit"s to subtract
    amount: Number;
    // Unit to use for subtracting from installation date
    unit: "day" | "week" | "month";
  }
}

export type Note = {
  note: string;
  addedBy: string; // ObjectId
  addedAt: Date;
}

export type Quote = {
  value?: number;
  agreements: {
    deliveryDate: Agreement;
    material: Agreement;
    amount: Agreement;
  }
  description: string;
  file?: File;
}

export type Agreement = {
  installer?: AgreementStatus;
  distributor?: AgreementStatus;
  info?: any;
}

export type Order = {
  _id: string; // ObjectId
  name: string;
  jobId: string; // ObjectId

  marketId: string; // ObjectId
  locaitonId: string; // ObjectId

  installerId: string; // ObjectId
  distributorId: string; // ObjectId

  primaryContactId: string; // ObjectId
  installerOnSiteLeadId?: string; // ObjectId
  sharedWithIds: string[]; // ObjectId[]

  contact: {
    name: string;
    phone: string;
    email: string;
  },

  poNumber?: string;
  soNumber?: string;

  quoteRequired: boolean;
  quote: Quote;

  installationDate?: Date;
  requestedDelivery: RequstedDelivery;
  orderAddress: Address;

  files: {
    billOfMaterials: File[];
    additionalFiles: File[];
    pickTickets: File[];
  };
  notes: Note[];

  timestamps: Timestamp[];
  status: OrderStatus;

  createdAt: Date;
  updatedAt: Date;
}

// ---------------------------- //

// --- Mimic Backend Enums --- //

/**
 * Possible timestamp types for an order's timestamp.
 * Maps to the string type in the database.
 */
export enum TimestampTypes {
  STATUS_CHANGED = "STATUS_CHANGED",
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_UPDATED = "ORDER_UPDATED",
  ORDER_DELETED = "ORDER_DELETED",
  NOTE_ADDED = "NOTE_ADDED",
  NOTE_CHANGED = "NOTE_CHANGED",
  NOTE_DELETED = "NOTE_DELETED",
  ATTACHMENT_ADDED = "ATTACHMENT_ADDED",
  ATTACHMENT_DELETED = "ATTACHMENT_DELETED",
  BILL_OF_MATERIALS_UPLOADED = "BILL_OF_MATERIALS_UPLOADED",
  BILL_OF_MATERIALS_DELETED = "BILL_OF_MATERIALS_DELETED",
  AGREEMENT_CHANGED = "AGREEMENT_CHANGED",
}

/**
 * Possible statuses for an order.
 * Maps to the string status in the database.
 */
export enum OrderStatus {
  WAITING_FOR_QUOTE = "WAITING_FOR_QUOTE",
  WAITING_FOR_QUOTE_APPROVAL = "WAITING_FOR_QUOTE_APPROVAL",
  WAITING_FOR_QUOTE_ADJUSTMENT = "WAITING_FOR_QUOTE_ADJUSTMENT",
  CONFIRMED = "CONFIRMED",
  READY_TO_PACKAGE = "READY_TO_PACKAGE", // Pick ticket made?
  PACKAGING = "PACKAGING",
  PACKAGED = "PACKAGED",
  READY_TO_DELIVER = "READY_TO_DELIVER",
  IN_TRANSIT = "IN_TRANSIT",
  DELIVERED = "DELIVERED",
  CANCELED = "CANCELED",
}

export enum AgreementStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

// --------------------------- //

// --- Constants --- //

/**
  * Map of order statuses to labels.
  */
export const OrderStatusLabels = {
  [OrderStatus.WAITING_FOR_QUOTE]: "Waiting for Quote",
  [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: "Waiting for Approval",
  [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: "Waiting for Adjustments",
  [OrderStatus.CONFIRMED]: "Confirmed",
  [OrderStatus.READY_TO_PACKAGE]: "Ready to Package",
  [OrderStatus.PACKAGING]: "Packaging",
  [OrderStatus.PACKAGED]: "Packaged",
  [OrderStatus.READY_TO_DELIVER]: "Ready to Deliver",
  [OrderStatus.IN_TRANSIT]: "In Transit",
  [OrderStatus.DELIVERED]: "Delivered",
  [OrderStatus.CANCELED]: "Order Canceled",
}

/**
  * Map of order statuses to icons.
  */
export const OrderStatusIcons = {
  // TODO: fix order
  [OrderStatus.WAITING_FOR_QUOTE]: ShoppingCartIcon,
  [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: ClockIcon,
  [OrderStatus.CONFIRMED]: HandshakeIcon,
  [OrderStatus.READY_TO_DELIVER]: ClockIcon,
  [OrderStatus.IN_TRANSIT]: TruckIcon,
  [OrderStatus.DELIVERED]: HomeIcon,
  [OrderStatus.CANCELED]: BanIcon,
  [OrderStatus.READY_TO_PACKAGE]: ClockIcon,
  [OrderStatus.PACKAGING]: PackageOpenIcon,
  [OrderStatus.PACKAGED]: PackageIcon,
}

/**
  * Map of timestamp types to icons.
  */
export const TimestampIcons = {
  [TimestampTypes.STATUS_CHANGED]: WorkflowIcon,
  [TimestampTypes.ORDER_CREATED]: PlusIcon,
  [TimestampTypes.BILL_OF_MATERIALS_UPLOADED]: FilePlus2Icon,
  [TimestampTypes.NOTE_ADDED]: StickyNoteIcon,
  [TimestampTypes.AGREEMENT_CHANGED]: HandshakeIcon,
  [TimestampTypes.ATTACHMENT_ADDED]: FilePlus2Icon,
}

/**
  * Map of timestamp types to labels.
  */
export const TimestampLabels = {
  [TimestampTypes.STATUS_CHANGED]: "Status Changed",
  [TimestampTypes.ORDER_CREATED]: "Order Created",
  [TimestampTypes.ORDER_UPDATED]: "Order Updated",
  [TimestampTypes.ORDER_DELETED]: "Order Deleted",
  [TimestampTypes.NOTE_ADDED]: "Note Added",
  [TimestampTypes.NOTE_CHANGED]: "Note Changed",
  [TimestampTypes.NOTE_DELETED]: "Note Deleted",
  [TimestampTypes.ATTACHMENT_ADDED]: "Attachment Added",
  [TimestampTypes.ATTACHMENT_DELETED]: "Attachment Deleted",
  [TimestampTypes.BILL_OF_MATERIALS_UPLOADED]: "Bill of Materials Uploaded",
  [TimestampTypes.BILL_OF_MATERIALS_DELETED]: "Bill of Materials Deleted",
}

/**
 * Sections for the status list.
 * Organizes statuses into groups.
 */
export const StatusSections: {
  name: string;
  majorStatuses: (OrderStatus | {
    minorGroupLabel: string;
    minorGroupIcon: any; // TODO: type?
    minorStatuses: OrderStatus[];
  })[]
}[] = [
    {
      name: "New Orders",
      majorStatuses: [
        {
          minorGroupLabel: "Submitted",
          minorGroupIcon: ShoppingCartIcon,
          minorStatuses: [
            OrderStatus.WAITING_FOR_QUOTE,
            OrderStatus.WAITING_FOR_QUOTE_APPROVAL,
            OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT,
          ]
        },
        OrderStatus.CONFIRMED,
      ]
    },
    {
      name: "Packaging",
      majorStatuses: [
        OrderStatus.READY_TO_PACKAGE,
        OrderStatus.PACKAGING,
        OrderStatus.PACKAGED,
      ]
    },
    {
      name: "Delivery",
      majorStatuses: [
        OrderStatus.READY_TO_DELIVER,
        OrderStatus.IN_TRANSIT,
        OrderStatus.DELIVERED,
      ]
    },
    {
      name: "Other",
      majorStatuses: [OrderStatus.CANCELED]
    }
  ]

// ----------------- //

export type Filters = {
  marketIds?: string[]; // ObjectId[]
  locationIds?: string[]; // ObjectId[]
  connectionIds?: string[]; // ObjectId[]
  search?: string;
  statuses?: OrderStatus[];
}

// TODO: IDEA: wrapper hook for this that manages pagination so it essentially "gets next page" when some function is called.
// could be nice to load on scroll
/**
  * Hook to get orders based on filters.
  * Also returns status counts for each status (not affected by filters).
  * Won't update if `filters` is `null` (requires at least an empty object `{}`).
  */
export default function useOrders(filters: Filters): [Order[], { [status: string]: number }, () => void] {

  const [orders, setOrders] = useState<Order[]>();

  // TODO: type this
  const [statusCounts, setStatusCounts] = useState<{ [status: string]: number }>();

  // Get orders on mount and when filters change
  useEffect(() => {
    if (!filters) return; // Require at least an empty filters object

    const abortController = new AbortController();

    // TODO: handle debounce? take param to toggle debounce?

    getOrders(abortController);
    return () => {
      // Abort last request
      abortController.abort();
    }
  }, [filters]);

  /**
    * Gets orders from the backend based on filters.
    */
  async function getOrders(abortController?: AbortController) {

    // Add filters to URL
    // TODO: use URL object? needs user manager rework I think though.
    let url = "/api/orders/find?";

    // Apply filters
    if (filters.marketIds) url += `markets=${filters.marketIds.map(m => m + "").join(",")}&`; // +"" to get "null"
    if (filters.locationIds) url += `locations=${(filters.locationIds).join(",")}&`;
    url += `connections=${(filters.connectionIds ?? []).join(",")}&`;
    url += `search=${filters.search}&`;

    // Make request for getting count of each status
    UserManager.makeAuthenticatedRequest(url, "GET", null, {
      signal: abortController?.signal ?? undefined,
    }).then(res => {
      if (res.data?.status === "ok") {
        setStatusCounts(res.data.statusCounts);
      }
    }).catch(err => {
      console.error(err);
      // TODO: toast message?
    });

    // Add status filter
    if (filters.statuses) {
      url += `statuses=${(filters.statuses ?? []).join(",")}`;
    }


    // Make request for getting orders
    UserManager.makeAuthenticatedRequest(url, "GET", null, {
      signal: abortController?.signal ?? undefined,
    }).then(res => {
      if (res.data?.status === "ok") {
        let orders = res.data.orders;
        setOrders(orders);
      }
    }).catch(err => {
      console.error(err);
      // TODO: toast message?
    });


  }


  return [orders, statusCounts ?? {}, getOrders];
}
