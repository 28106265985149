import { createContext } from "react";
import { ForecastType } from "src/pages/Forecast/history/ForecastHistoryPage";
import { MarketType } from "../ForecastHistoryContext";

export type ForecastSnapshotViewContextType = {
  forecast?: ForecastType;
  market?: MarketType;
  stages?: StageType[];
  submitter?: any; // TODO: type
  materialGroups?: any[]; // TODO: type
  materials?: any[]; // TODO: type
};

export type StageType = {
  _id: string;
  name: string;
  order: number;
};


const ForecastSnapshotViewContext = createContext<ForecastSnapshotViewContextType>({
  forecast: undefined,
  market: undefined,
  stages: undefined,
  // TODO: material
});

export default ForecastSnapshotViewContext;