import Button from "../components/input/Button";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logos/lightMode/logo.svg";

class Home extends React.Component {
  render() {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-full">
          {/* Logo */}
          <div>
            <img src={logo} alt="Toa Logo" className="h-12" />
          </div>

          {/* Welcome */}
          <div className="pt-3 text-3xl font-bold">Welcome to Toa</div>

          {/* Learn more */}
          <div className="max-w-lg px-5 pt-3 font-semibold text-justify text-m">
            Toa is a platform to connect the clean energy players. It provides{" "}
            <span className="text-primary-rose">Reliable Forecasts</span>,{" "}
            <span className="text-secondary-blue">Actionable Alerts</span>, and{" "}
            <span className="text-primary-green">Key Communication</span>.
            Learn more <a className="underline text-primary-green" href="https://toa.energy">here</a>.
          </div>

          {/* Log in / Sign up */}
          <div>
            <Link to="/login/login">
              <Button variant="primary" className="mt-3">
                Log in
              </Button>
            </Link>
            {/* <Link to="/login/register"> */}
            {/*   <Button variant="primary" className="mt-3 ml-3"> */}
            {/*     Sign up */}
            {/*   </Button> */}
            {/* </Link> */}
          </div>
        </div>
      </>
    );
  }
}

export default Home;
