import moment, { DurationInputArg2 } from "moment";

/**
 * ArrayHelper provides utility functions for arrays.
 * This is useful for testing and generating data.
 * Has functions for merging arrays, generating random values, generating dates, etc.
 */
export default class ArrayHelper {
  /**
   * Takes an array of fields and as many arrays as fields.
   * Throws an error if the number of fields doesn't match the number of arrays.
   * Merges the arrays into an array of objects where each object represents
   * the same index of all arrays.
   *
   * For example:
   * ```typescript
   * let fields = ["a","b","c"];
   * let a = [0,1,2];
   * let b = ["d","e","f"];
   * let c = [3,4,5];
   * let result = ArrayHelper.mergeArrays(fields, a, b, c);
   * console.log(result);
   * // Output: 
   * [
   *  {a:0,b:"d",c:3},
   *  {a:1,b:"e",c:4},
   *  {a:2,b:"f",c:5},
   * ]
   * ```
   */
  static mergeArrays<T extends string[], U extends any[][]>(fields: T, ...arrays: U): { [K in T[number]]: U[number][number] }[] {

    // Must have a field per array
    if (fields.length !== arrays.length) {
      throw new Error('The number of fields must match the number of arrays.');
    }

    // Get max length
    let maxLength = Math.max(...arrays.map(a => a.length));

    let result = [];

    // Go through each index
    for (let i = 0; i < maxLength; i++) {
      let obj: any = {};

      // Add each array's value at the index to the resulting object
      for (let j = 0; j < arrays.length; j++) {
        let arr = arrays[j];

        if (i >= arr.length) continue;

        let field = fields[j];

        let value = arr[i];

        obj[field] = value;
      }

      result.push(obj);
    }

    return result;
  }

  /**
   * Generates n random values in range [min, max)
   * Default min = 0, max = 100
   */
  static generateNRand(n: number, options?: { min?: number, max?: number }): number[] {
    let { min = 0, max = 100 } = options || {};
    let range = max - min;
    return Array.from({ length: n }, () => Math.floor(Math.random() * range) + min);
  }

  /**
   * Generates an array of length n with values from 0 to n-1.
   * E.g. generateNCounting(3) => [0,1,2]
   */
  static generateNCounting(n: number): number[] {
    return Array.from({ length: n }, (_, i) => i);
  }

  /**
    * Generates n dates starting from a given date.
    * Default unit is days.
    * Default start is today.
    * For example:
    * ```typescript
    * let dates = Array.generateNDates(3, "week", new Date("2021-01-01"));
    * console.log(dates.map(d => d.format("YYYY-MM-DD")));
    * // Output:
    * // ["2021-01-01", "2021-01-08", "2021-01-15"]
    * ```
    */
  static generateNDates(n: number, unit: moment.unitOfTime.DurationConstructor = "days", from: Date = new Date()): Date[] {
    return Array.from({ length: n }, (_, i) => moment(from).add(i, unit).toDate());
  }

  /**
   * Generates n dates starting from a given date and formats them.
   * The opts parameter can be used to specify whether to use UTC time.
   * UTC is useful to ignore timezone offsets and focus on the day (esp. when time is midnight UTC).
   * 
   * Example:
   * ```typescript
   * let units = "days";
   * let startDate = new Date("2021-01-01");
   * let format = "M/D/YYYY";
   * generateNDatesFormatted(3, units, startDate, format); // ["1/1/2021", "1/2/2021", "1/3/2021"]
   * ```
   */
  static generateNDatesFormatted(n: number,
    unit: moment.unitOfTime.DurationConstructor = "days",
    from: Date = new Date(),
    format: string = "YYYY-MM-DD",
    opts = { useUTC: false },
  ): string[] {
    return Array.from({ length: n }, (_, i) => {
      let d = moment(from).add(i, unit);

      if (opts.useUTC) d.utc();

      return d.format(format);
    });
  }

  /**
   * Sets the array's length to the given value.
   * If the array is too long, it will be sliced/truncated.
   * If the array is too long, it will backfill with the default value.
   *
   * Examples:
   * ```typescript
   * setArrayLength([1,2,3], 2, 0); // [1,2]
   * setArrayLength([1,2,3], 4, 0); // [1,2,3,0]
   * ```
   */
  static setArrayLength<T>(arr: T[], length: number, defaultValue: T): T[] {
    // If too long, just slice it
    if (arr.length > length) {
      return arr.slice(0, length);
    }

    // Create array of missing length with default
    let diff = length - arr.length;
    let backfill = Array.from({ length: diff }, () => defaultValue);

    // Return old plus backfill
    return arr.concat(backfill);
  }
}
