import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import classNames from 'src/tools/classNames';

type Props = {
  blocks?: Block[];
  numSlots: number;
  color: Colors;
  disabled?: boolean;
  height?: number;
  onChange?: (blocks: Block[]) => void;
}

export enum Colors {
  PURPLE,
}

const COLOR_MAP = {
  [Colors.PURPLE]: {
    bg: "bg-purple-400",
    hover: "hover:bg-purple-300",
    darkHover: "hover:bg-purple-600/50",
  },
}

export type Block = {
  start: number;
  end: number;
}

/**
 * Component for selecting ranges of slots in a grid.
 * Each slot is toggled with a click.
 * Slots are grouped into blocks with a start and end slot (inclusive).
 * These can be passed in without grouping them as this component handles the grouping.
 * E.g. [{start: 0, end: 0}, {start: 1, end: 1}] is the same as [{start: 0, end: 1}]
 */
export default function BlockRanges({ blocks: blocks_prop, numSlots, color, disabled, height = 20, onChange }: Props) {

  const [blocks, setBlocks] = useState<Block[]>(condenseBlocks(blocks_prop ?? []));

  // Sync prop blocks with state blocks
  useEffect(() => {
    if (blocks_prop) {
      // TODO: validation
      let newBlocks = condenseBlocks(blocks_prop);
      if (newBlocks.length !== blocks.length) {
        setBlocks(newBlocks);
      }
    }
  }, [blocks_prop])

  /**
    * Adds a block at the given index.
    */
  function addBlock(index: number) {
    // Get slot number
    let slotsBefore = 0;
    for (let i = 0; i < index; i++) {
      if (blocks[i] === null) {
        slotsBefore++;
      } else {
        slotsBefore += blocks[i].end - blocks[i].start + 1;
      }
    }

    // Handle where to place it based on neighboring blocks
    // Set slot to slots before (e.g. if 0 slots before, use 0. if one empty and one 2 width, use 3 b/c 0 index)
    let newBlocks = [...blocks];
    newBlocks[index] = {
      start: slotsBefore,
      end: slotsBefore,
    }
    newBlocks = condenseBlocks(newBlocks);
    setBlocks(newBlocks);
    onChange?.(newBlocks);
  }

  /**
    * Removes the slot at the given index.
    * Handles splitting blocks.
    */
  function removeSlot(index: number) {
    // Find block containing slot
    let blockIndex = blocks.findIndex(block =>
      // Exists
      block !== null &&
      // Slot in block
      block.start <= index &&
      block.end >= index
    );
    let oldBlock = blocks[blockIndex];

    // If no block found, return
    if (blockIndex === -1) return;

    // Resulting left block
    let leftBlock: Block | null = null;
    if (index > oldBlock.start) {
      leftBlock = {
        start: oldBlock.start,
        end: index - 1,
      }
    }

    // Resulting right block
    let rightBlock: Block | null = null;
    if (index < oldBlock.end) {
      rightBlock = {
        start: index + 1,
        end: oldBlock.end,
      }
    }

    // Replace old block with [left, null, right] 
    // Don't add left/right if null
    // 
    // This handles the 4 cases:
    // - 1 slot block: remove entirely
    // - removing far left slot: [(start,end)] -> [null, (start+1,end)]
    // - removing far right slot: [(start,end)] -> [(start,end-1), null]
    // - removing middle slot: [(start,end)] -> [(start,index-1), null, (index+1,end)]
    let newBlocks = [...blocks];

    // Remove old block
    newBlocks[blockIndex] = null;

    // Right first so it doesn't mess up left index

    // Add right block if exists
    if (rightBlock) {
      newBlocks.splice(blockIndex + 1, 0, rightBlock);
    }

    // Add left block if exists
    if (leftBlock) {
      newBlocks.splice(blockIndex, 0, leftBlock);
    }

    newBlocks = condenseBlocks(newBlocks);
    setBlocks(newBlocks);
    onChange?.(newBlocks);
  }

  /**
    * Merges adjacent blocks.
    */
  function condenseBlocks(blocks: Block[]) {
    // If any adjacent  blocks, merge them
    let prev = blocks;
    let newBlocks = [];

    // Merge any adjacent blocks
    for (let i = 0; i < prev.length; i++) {
      if (prev[i] === null) {
        newBlocks.push(null);
        continue;
      }

      // Aim to get start of first and end of last of adjacent blocks
      let startSlot = prev[i].start;
      let endSlot = prev[i].end;

      // If the next block is adjacent, merge it
      while (i < prev.length - 1 && prev[i + 1] !== null) {
        endSlot = prev[i + 1].end;

        i++;
      }

      // Add merged block
      newBlocks.push({
        start: startSlot,
        end: endSlot,
      })
    }

    return newBlocks;
  }

  return (
    <div
      className={`grid w-full grid-cols-2 relative`}
      style={{
        height,
        gridTemplateColumns: "repeat(" + numSlots + ", 1fr)",
      }}>

      {
        blocks.map((block, i) => {
          if (!block) return <EmptySlot key={i} color={color} disabled={disabled} addBlock={() => addBlock(i)} />

          let leftConstraint = 0;
          let rightConstraint = numSlots - 1;

          // Look left until before a block
          for (let j = i - 1; j >= 0; j--) {
            if (blocks[j] === null) {
              leftConstraint = j;
            } else {
              break;
            }
          }

          // Look right until before a block
          for (let j = i + 1; j < blocks.length; j++) {
            if (blocks[j] === null) {
              rightConstraint = j;
            } else {
              break;
            }
          }

          let slotConstraints = {
            left: leftConstraint,
            right: rightConstraint,
          }

          return <Block
            key={i}
            color={color}
            block={block}
            slotConstraints={slotConstraints}
            disabled={disabled}
            removeSlot={removeSlot}
          />
        })
      }
    </div >
  )
}

type EmptySlotProps = {
  color: Colors;
  addBlock: () => void;
  disabled?: boolean;
}

/**
  * Empty slot that can be clicked to add a block.
  */
function EmptySlot({ color, addBlock: addSlot, disabled }: EmptySlotProps) {

  return (
    <div
      data-disabled={disabled}
      className={classNames(
        COLOR_MAP[color].hover,
        "rounded",
        "cursor-pointer",
        "data-[disabled=true]:invisible",
      )}
      onClick={() => addSlot()}
    >
    </div>)
}

type BlockProps = {
  block: Block;
  color: Colors;
  slotConstraints: { left: number, right: number };
  disabled: boolean;
  removeSlot: (slot: number) => void;
}

/**
  * Block of slots.
  * Can be clicked to remove slots.
  */
function Block({ block, color, disabled, removeSlot }: BlockProps) {
  let blockRange = block.end - block.start + 1;

  return (
    <motion.div
      className={classNames(
        COLOR_MAP[color].bg,
        "rounded",
        "col-span-2",
        "grid",
        "data-[disabled=true]:bg-gray-300"
      )}

      data-disabled={disabled}

      style={{
        gridColumn: "span " + blockRange + " / span " + blockRange,
        gridTemplateColumns: "repeat(" + blockRange + ", 1fr)",
      }}

      // drag="x"
      dragMomentum={false}
      dragSnapToOrigin
    >

      {!disabled && Array.from({ length: blockRange }, (_, i) => {
        return (
          <div
            className={classNames(
              i === 0 ? "rounded-l" : "",
              i === blockRange - 1 ? "rounded-r" : "",
              "cursor-pointer",
              COLOR_MAP[color].darkHover,
            )}
            onClick={() => removeSlot(i + block.start)}
          />
        )
      })}
    </motion.div>)
}
