import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
} from "recharts";

export default function ActualVsForecastChart({
  data = [],
  actualDataKey = "actual",
  forecastDataKey = "forecast",
  height = 218, // 218 roughly makes the chart the same height as the bucket timeline chart
}) {
  data = data.map((d, i) => ({
    ...d,
    diff: d[actualDataKey] - d[forecastDataKey],
  }));
  return (
    <div className="relative w-full" style={{ height: height }}>
      <div className="absolute inset-0">
        <ResponsiveContainer height={height} width={"99%"}>
          <LineChart data={data}>
            <Tooltip />
            <Line dataKey={actualDataKey} stroke="#4B5563" strokeWidth={2} />
            <Line dataKey={forecastDataKey} stroke="#1CB7BE" strokeWidth={2} />
            <XAxis
              dataKey="diff"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: "12px", fill: "#111827" }}
              domain={[0, data.length - 1]}
              tickMargin={13}
            />
            <YAxis axisLine={false} tickLine={false} tick={false} width={5} />
            {/* Label for each line */}
            {
              data?.length &&
              <>
                <ReferenceLine
                  y={data[0][actualDataKey]}
                  style={{ opacity: 0 }}
                  label={{
                    value: "Actual",
                    position: "insideTopLeft",
                    fontSize: "12px",
                  }}
                />
                <ReferenceLine
                  y={data[0][forecastDataKey]}
                  style={{ opacity: 0 }}
                  label={{
                    value: `Forecast`, // TODO: implement lookback for data. - ${data.length} Weeks Prior`,
                    position: "insideBottomLeft",
                    fontSize: "12px",
                  }}
                />
              </>}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
