import React, { useEffect, useState } from 'react'
import TopBar from '../nav/TopBar'
import { Outlet, useLocation } from 'react-router-dom'
import SubNav from '../nav/SubNav'

type Props = {}

/**
 * Layout for the Order page.
 * Handles tabs to switch between Job list and Order list
 */
export default function OrderLayout({ }: Props) {
  const [tabs, setTabs] = useState([
    {
      name: "Orders",
      href: "list",
      current: true
    },
  ])

  const location = useLocation();

  // --- Use Effects --- //

  // Sync tabs with URL
  useEffect(() => {
    // Get the current tab from the URL
    const currentTabHref = location.pathname.split("/").pop();

    // Get name by href
    let currentTabName = tabs.find(tab => tab.href === currentTabHref)?.name;

    // Set the current tab
    setTabs(oldTabs => {
      let newTabs = oldTabs.map(tab => {
        if (tab.name === currentTabName) {
          tab.current = true
        } else {
          tab.current = false
        }
        return tab
      });
      return newTabs;
    });
  }, [location]);

  // --- Handlerse --- //

  /**
   * Sets selected tab based on name
   */
  function handleTabSelected(name: string, href: string): void {
    setTabs(oldTabs => {
      let newTabs = oldTabs.map(tab => {
        if (tab.name === name) {
          tab.current = true
        } else {
          tab.current = false
        }
        return tab
      });
      return newTabs;
    });

  }

  return (
    <div className="grow flex flex-col">
      {/* Top Bar */}
      <TopBar>Orders</TopBar>

      <SubNav
        tabs={tabs}
        onSelected={handleTabSelected}
      />

      <Outlet />
    </div>
  )
}
