import React, { useState } from 'react';
import Button from 'src/components/input/Button';
import FileUpload from 'src/components/input/FileUpload.tsx';
import Input from 'src/components/input/Input';
import Modal, { ModalFooter } from 'src/components/Modal';
import Spinner from 'src/components/Spinner';
import SwitchCase from 'src/components/utils/SwitchCase.tsx';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import { AgreementStatus, Order, TimestampTypes } from 'src/hooks/data/orders/useOrders.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import S3 from 'src/tools/S3/s3.ts';
import UserManager from 'src/tools/UserManager';
import SimpleAdditionalAttachments from '../common/AdditionalAttachsDsply.tsx';
import AgreementInputRows from '../common/AgreementInputRows.tsx';
import FileDisplay from '../common/FileDisplay.tsx';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import OrderTimeline from '../OrderTimeline.tsx';

// TODO: parent prop type for all order modals?
type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * Component for approving/reviewing an order.
 * Delivery date, material, and amount can be adjusted or confirmed.
 * Only the distributor can adjust the amount.
 * A new BOM/Quote can be uploaded.
 * Displays a timeline of changes.
 */
export default function ApproveOrderModal({
  order: order_prop, open, setOpen
}: Props) {


  // --- States --- //

  const [order, setOrder] = useState<Order>(structuredClone(order_prop));
  const [additionalNote, setAdditonalNote] = useState<string>("");
  const [materialNote, setMaterialNote] = useState<string>("");

  const [newBOM, setNewBOM] = useState<File | null>(null);
  const [newQuote, setNewQuote] = useState<File | null>(null);

  const [requestBOM, setRequestBOM] = useState<boolean>(false);
  const [requestQuote, setRequestQuote] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // --- Hooks --- //

  const user = useCurrentUser();
  const companyType = user?.company?.type;

  // --- Consts --- //

  // BOM
  const bomFilePath = order.files.billOfMaterials.at(-1)?.filePath;
  const bomName = bomFilePath ? bomFilePath.split("/").pop() : null;
  const bomLinkArr = useS3URLs(bomFilePath ? [bomFilePath] : []);
  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;

  // Quote
  const quoteFilePath = order.quote?.file?.filePath;
  const quoteName = quoteFilePath ? quoteFilePath.split("/").pop() : null;
  const quoteLinkArr = useS3URLs(quoteFilePath ? [quoteFilePath] : []);
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;

  // Activity timeline filters
  const timelineTimestamps = [
    TimestampTypes.AGREEMENT_CHANGED,
    TimestampTypes.NOTE_ADDED,
  ]
  const excludeNoteTypeFilter = [
    "material", // Material note is displayed in the agreement timestamp so no need to show it twice
  ]

  // Submit conditions
  const canSubmit = [
    order.quote?.agreements.deliveryDate[companyType] != AgreementStatus.PENDING,
    order.quote?.agreements.material[companyType] != AgreementStatus.PENDING,
    order.quote?.agreements.amount[companyType] != AgreementStatus.PENDING,
  ].every(Boolean);

  /**
   * Handles uploading the file to S3 as a Quote.
   * Returns the S3 filepath. 
   * Returns null if it fails.
   */
  async function uploadQuote(file: File): Promise<string> {
    // TODO: improve file path? can do later
    let quoteFilePath = `quotes/${order.installerId}/${order.marketId}/${order._id}/${file.name}`;

    await S3.upload(file, quoteFilePath);

    return quoteFilePath;
  }

  /**
   * Handles uploading the file to S3 as a BOM.
   * Returns the S3 filepath. 
   * Returns null if it fails.
   */
  async function uploadBOM(file: File): Promise<string> {
    // TODO: improve file path? can do later
    let bomFilePath = `bom/${order.installerId}/${order.marketId}/${order._id}/${file.name}`

    await S3.upload(file, bomFilePath);

    return bomFilePath;
  }

  /**
   * Submit order agreement changes.
   * Called from form and prevents event default.
   */
  async function handleSubmit(event) {
    event.preventDefault(); // Don't refresh page
    setLoading(true); // Start spinner


    // Upload BOM/quote and get resulting S3 filepath
    let newFile = companyType == "installer" ? newBOM : newQuote;
    if (newFile) {
      var newFilePath = companyType == "installer" ? await uploadBOM(newFile) : await uploadQuote(newFile);
    }

    // TODO: show error message
    if (!newFilePath && newFile) return;

    let body = {
      orderId: order._id,

      deliveryDateAgreement: order.quote.agreements.deliveryDate[companyType],
      deliveryDate: order.quote.agreements.deliveryDate[companyType] === AgreementStatus.REJECTED ? order.requestedDelivery : undefined,

      materialAgreement: order.quote.agreements.material[companyType],
      materialNote: order.quote.agreements.material[companyType] === AgreementStatus.REJECTED ? materialNote : undefined,

      amountAgreement: order.quote.agreements.amount[companyType],
      amount: companyType === "distributor" && order.quote.agreements.amount[companyType] === AgreementStatus.REJECTED ? order.quote.value : undefined,

      additionalNote: additionalNote,

      newFilePath: newFilePath,

      // TODO: requestBOM, requestQuote
    }

    try {
      var res = await UserManager.makeAuthenticatedRequest(`/api/orders/approve-order`, "POST", body)
    } catch (err) {
      // TODO: error message
      console.error(err);
    }

    setOpen(false); // Close modal
    setLoading(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} wide>
      <form onSubmit={handleSubmit}>
        <div className="flex divide-x max-h-[80vh]">

          {/* Order */}
          <div className="flex flex-col pr-6 overflow-y-scroll divide-y">
            <OrderModalHeader
              title="Approve Order"
              subtitle="Approve or adjust parts of the order"
              order={order}
            />

            {/* Existing Files */}
            <div className="grid grid-cols-2 gap-2 py-3">
              {!newBOM && <FileDisplay
                filename={bomName ?? "--"}
                link={bomLink}
                label="BOM"
              />}
              <SwitchCase test={companyType}>
                <div data-case="installer" className={newBOM ? "col-span-2" : ""}>
                  <FileUpload
                    uploadText="Upload New BOM"
                    uploadedText="New BOM"
                    buttonVariant="gray"
                    onFileChange={setNewBOM}
                  />
                </div>
                <div data-case="distributor">
                  {/* TODO: implement */}
                  {/* <Input */}
                  {/*   type="checkbox" */}
                  {/*   label="Request BOM" */}
                  {/*   name="requestBOM" */}
                  {/*   value={requestBOM} */}
                  {/*   onChange={setRequestBOM} */}
                  {/* /> */}
                </div>
              </SwitchCase>
            </div>
            <div className="grid grid-cols-2 gap-2 py-3">
              {!newQuote && <FileDisplay
                filename={quoteName ?? "--"}
                link={quoteLink}
                label="Quote"
              />}
              <SwitchCase test={companyType}>
                <div data-case="distributor" className={newQuote ? "col-span-2" : ""}>
                  <FileUpload
                    uploadText="Upload New Quote"
                    uploadedText="New Quote"
                    buttonVariant="gray"
                    onFileChange={setNewQuote}
                  />
                </div>
                <div data-case="installer" className="flex items-center justify-center">
                  {/* TODO: implement */}
                  {/* <Input */}
                  {/*   type="checkbox" */}
                  {/*   label="Request Quote" */}
                  {/*   name="requestQuote" */}
                  {/*   value={requestQuote} */}
                  {/*   onChange={setRequestQuote} */}
                  {/* /> */}
                </div>
              </SwitchCase>
            </div>

            {/* Additional Attachments */}
            <SimpleAdditionalAttachments
              order={order}
            />

            {/* Agreement Changes */}
            <AgreementInputRows
              order={order}
              originalOrder={order_prop}
              onChange={setOrder}
              materialNote={materialNote}
              setMaterialNote={setMaterialNote}
            />

            {/* Add Note */}
            < div className="py-2">
              <Input
                label="Add Additional Note:"
                placeholder='Add an additional note here...'
                type="textarea"
                value={additionalNote}
                onChange={setAdditonalNote}
              />
            </div>
          </div>

          {/* Activity */}
          <div className="flex flex-col px-6">
            <div className="pb-3 text-sm font-medium">Adjustment Timeline</div>
            <div className="overflow-y-scroll grow">
              {/* TODO: original order state? agreement timestamp is changing */}
              <OrderTimeline
                orders={[order]}
                timestampFilter={timelineTimestamps}
                hideOrderLink
                excludeNoteTypeFilter={excludeNoteTypeFilter}
              />
            </div>
          </div>

        </div>

        {/* Footer */}
        <ModalFooter>
          <div className="flex justify-end gap-2">
            {
              !loading
                ? <Button
                  variant="primary"
                  disabled={!canSubmit}
                  type="submit"
                >
                  Submit
                </Button>
                : <div className="flex items-center pr-7">
                  <Spinner size={20} />
                </div>
            }
            <Button
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  )
}

