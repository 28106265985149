import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext"
import UserManager from "src/tools/UserManager"

export type WeekMap = {
  [week: string]: number
}
export type MarketWeekMap = {
  [marketId: string]: WeekMap
}
export type AvailabilityBreakdown = {
  jobsByweek: MarketWeekMap, // TOA estimated installs from active pipeline ignoring conversion rate
  estimatedInstallsByWeek: MarketWeekMap, // TOA estimated installs from active pipeline accounting for conversion rate

  salesByWeek: MarketWeekMap, // User forecasted sales
  estimatedInstallsFromSales: MarketWeekMap, // Estimated installs from sales accounting for conversion rate

  installsByWeek: MarketWeekMap, // User forecasted jobs
  availableJobs: {
    [marketId: string]: {
      excludingSales: number,
      includingSales: number,
    }[]
  },

  historicalActualInstalls: MarketWeekMap, // Last 6 weeks of actual installs
  historicalForecastInstalls: {
    [marketId: string]: number[]
  }, // Last 6 weeks of actual installs

  marketCycleConvData: {
    [marketId: string]: {
      [stageId: string]: {
        _id: string, // Market ID

        conversionRate: number,
        jobCount: number,
        activeJobs: number,
        canceledJobs: number,
        completedJobs: number,

        avgCycleTime: number,
        maxCycleTime: number,
        minCycleTime: number,
        stdevCycleTime: number,
      }
    }
  }
}

/**
  * Hook to get job availability breakdwon by market.
  */
export default function useJobAvailabilityBreakdown(marketIds: string[]): AvailabilityBreakdown {
  const [jobAvailability, setJobAvailability] = useState<AvailabilityBreakdown>();

  // `useMemo` stabelizes the `marketIds` array so that the `useEffect` doesn't run on every render if the array is the same.
  // `join` is used to check IDs by value instead of reference just to be extra safe.
  // Without this, if this hook is passed something like `[someID]` from a parent component, it will rerun on every render
  // as it is making a new array every time.
  const stableIds = useMemo(() => marketIds, [marketIds.join(',')]);

  // Fetch the data from the server when marketIds changes
  useEffect(() => {
    if (!stableIds || !stableIds.length) return;

    const url = `/api/forecast/get-availability-breakdown?markets=${stableIds.join(',')}`;
    UserManager.makeAuthenticatedRequest(url, "GET")
      .then((res) => {
        if (res.data.status === 'ok') {
          let availabilityBreakdown = res.data.availabilityBreakdown;

          setJobAvailability(availabilityBreakdown);
        }
      }).catch((err) => {
        // TODO: handle error. maybe a toast? we might want to build a whole toast system though to push any notifs to
        console.error(err);
      });

  }, [stableIds]);

  return jobAvailability;
}

/**
 * Turns a week map into an array of numbers.
 * Looks at `numWeeks` weeks from `startWeek` and fills
 * in the array with the values from the weekMap for each
 * week in that range.
 *
 * Inverse of `arrayToWeekMap`
 */
export function weekMapToArray(weekMap: WeekMap, numWeeks: number, startWeek: Date): number[] {
  let output = Array(numWeeks).fill(0);

  let startWeek_moment = moment(startWeek).utc().startOf('week');
  for (let i = 0; i < numWeeks; i++) {
    let thatWeek = startWeek_moment.clone().add(i, 'weeks');
    let thatWeekStr = thatWeek.toISOString();

    let value = weekMap?.[thatWeekStr] || 0;

    output[i] = value;
  }

  return output;
}

/**
 * Takes an array and a start week and turns it into a week map.
 * Assumes each element in the array corresponds to a week starting
 * with the `startWeek` date.
 *
 * Inverse of `weekMapToArray`
 */
export function arrayToWeekMap(data: number[], startWeek: Date): WeekMap {
  let output: WeekMap = {}
  let week = moment(startWeek).utc().startOf("week");
  for (let i = 0; i < data.length; i++) {
    let weekStr = week.toISOString();
    week.add(1, "weeks");

    output[weekStr] = data[i];
  }
  return output
}
