import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useLocations, { LocationType } from "./useLocations.ts";


/**
 *  A hook that returns the locations of the user's distributor.
 *  Also returns a state for selecting locations.
 *
 *  Before locations are loaded, `locations` and `selectedLocations` are null.
 *
 *  If `defaultAll` is true, all locations are selected by default.
 *  Otherwise, once locations are loaded, `selectedLocations` is set to none (i.e. [])
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [locations, selectedLocations, setSelectedLocation] = useMultiLocationSelect);
 *  if (!locations) return <div>Loading...</div>;
 *  return <ul>{locations.map(location => <li>{location.name}</li>)}</ul>;
 *  ```
 *
 *  Other hooks: 
 *  - `useLocations` - If you don't need to select locations, use `useLocations`
 */
export default function useMultiLocationSelect(defaultAll?: boolean): readonly [readonly LocationType[] | null, LocationType[] | null, Dispatch<SetStateAction<LocationType[]>>] {
  const locations = useLocations();
  const [selectedLocations, setSelectedLocations] = useState<LocationType[]>();

  // Set selected locations on load
  useEffect(() => {
    if (locations && !selectedLocations) {
      setSelectedLocations(defaultAll ? [...locations] : []); // [...locations] to clone as it's readonly
    }
  }, [locations]);

  return [locations, selectedLocations, setSelectedLocations] as const;
}
