import React, { Component, useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import BucketTimelineDiagram from "../../components/BucketTimelineDiagram";
import Dropdown from "../../components/input/Dropdown";
import Card from "../../components/Card";
import Button from "../../components/input/Button";
import {
  ArrowsPointingOutIcon,
  BellIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  ListBulletIcon,
  QueueListIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import BulletChart from "../../components/charts/BulletChart";
import $, { get } from "jquery";
import ActualVsForecastChart from "../../components/ActualVsForecastChart";
import ForecastInput from "../../components/Forecast/ForecastInput";
import moment, { max } from "moment";
import UserManager from "../../tools/UserManager";
import SubNav from "../../components/nav/SubNav";
import StepsPanel from "../../components/nav/StepsPanels";

import Forecast from "./classes/Forecast";

import pluralize from "pluralize";
import BulletChartAndInput from "../../components/Forecast/BulletChartAndInput";
import { ForecastSnapshotViewContextType } from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function ThreeInfoCard({ titles, values }) {
  if (titles.length !== values.length || titles.length !== 3) {
    console.error("ThreeInfoCard: titles and values must be of length 3");
    return null;
  }

  return (
    <Card className="!p-0 overflow-none">
      <div className="grid justify-center grid-cols-3 text-sm font-medium leading-5 text-center">
        {titles.map((title, i) => (
          <div className="p-3 border-b" key={i}>
            {title}
          </div>
        ))}

        <div className="p-3 text-xl font-semibold leading-7 ">{values[0]}</div>
        <div className="p-3 text-xl font-semibold leading-7 border-x">
          {values[1]}
        </div>
        <div className="p-3 text-xl font-semibold leading-7 ">{values[2]}</div>
      </div>
    </Card>
  );
}

export default function JobForecastPage({ isStatic = false }) {
  const contextValue = useOutletContext();

  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = contextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
    } = contextValue;
  }

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [keyConsiderations, setKeyConsiderations] = React.useState([
    {
      icon: (
        <ExclamationTriangleIcon className="w-5 h-5 stroke-2 text-primary-rose" />
      ),
      text: "Critical gap in pipeline on 5/1",
    },
    {
      icon: <BellIcon className="w-5 h-5 text-gray-400 stroke-2" />,
      text: "Multiple gaps 1 month out",
    },
    {
      icon: <BellIcon className="w-5 h-5 text-gray-400 stroke-2" />,
      text: "Multiple gaps 3 months out",
    },
    {
      icon: <UserPlusIcon className="w-5 h-5 text-gray-400 stroke-2" />,
      text: "2 New crews 4 months out",
    },
  ]);
  const [goalComparisonData, setGoalComparisonData] = React.useState();
  const [actualVsForecastData, setActualVsForecastData] = React.useState([
    { actual: 0, forecast: 10 },
    { actual: 7, forecast: 10 },
    { actual: 6, forecast: 10 },
    { actual: 8, forecast: 10 },
    { actual: 4, forecast: 10 },
    { actual: 0, forecast: 12 },
    { actual: 7, forecast: 11 },
    { actual: 9, forecast: 11 },
  ]);

  // Reverse stages so that installation is on the left
  // This is so that it lines up with the timeline as jobs in later
  // stages are installed sooner
  let stageStats = forecastData ? [...forecastData.stages].reverse() : [];

  if (isStatic) {
    stageStats = staticForecastData ? [...staticForecastData.stageStats].reverse() : [];
  }

  useEffect(() => {
    // TODO: replace with actual data
    var tempGoalCompData = [];
    for (var i = 0; i < 8; i++) {
      tempGoalCompData.push({
        label: moment().add(i, "weeks").startOf("week").format("M/D"),
        actual: Math.random() * 10,
        goal: Math.random() * 10,
      });
    }
    setGoalComparisonData(tempGoalCompData);
  }, [rawForecastData]);

  function formatStages(stages) {
    let maxCount = Math.max(...stages.map((stage) => stage.activeInStage));

    return stages.map((stage) => {
      return {
        title: stage.name,
        label: pluralize("Job", stage.activeInStage, true),
        selectedLabel: pluralize(
          "Day",
          Math.round(stage.avgTimeToInstall),
          true
        ),
        height: (stage.activeInStage / maxCount) * 100,
        fromWeek: stage.fromWeek,
        toWeek: stage.toWeek,
        activeInStage: stage.activeInStage,
        avgTimeToInstall: stage.avgTimeToInstall,
      };
    });
  }

  return (
    <>
      {/* ----- Left ----- */}
      {/* // TODO: implement historicals */}
      {false && (
        <div className="flex flex-col gap-5">
          {/* Basic Info */}
          <div className="flex flex-col gap-2.5">
            {/* Historical Indicator */}
            <div className="flex gap-2.5 items-center">
              <div className="ml-auto text-sm font-medium text-primary-rose ">
                Last 8 Weeks
              </div>
              <ChevronLeftIcon className="w-4 h-4 stroke-[3] stroke-primary-rose" />
            </div>

            {/* Info */}
            <ThreeInfoCard
              titles={["Cycle Time", "Conversion", "Completed Jobs"]}
              values={["52 days", "84%", "328"]}
            />
          </div>

          {/* Historical Forecast Comparison */}
          <div className="flex flex-col gap-5 py-5 border-gray-300 basis-1/3 border-y">
            {/* Section Title */}
            <div className="flex items-center">
              {/* Text */}
              <div>
                <div className="text-sm font-medium leading-5 text-gray-500">
                  Historical: Actual vs Forecast
                </div>
                <div className="text-xl font-semibold leading-7">
                  -36 Jobs | 20% Under
                </div>
              </div>

              {/* Expand button */}
              <div className="ml-auto">
                <ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 cursor-pointer stroke-2 hover:text-primary-rose" />
              </div>
            </div>

            {/* Chart */}
            <div>
              <ActualVsForecastChart data={actualVsForecastData} />
            </div>
          </div>

          {/* Historical Goal Comparison */}
          <div className="flex flex-col">
            {/* Section Title */}
            <div className="flex items-center">
              {/* Text */}
              <div>
                <div className="text-sm font-medium leading-5 text-gray-500">
                  Historical: Actual vs Goal
                </div>
                <div className="text-xl font-semibold leading-7">
                  40 Jobs (avg / wk)
                </div>
              </div>

              {/* Expand button */}
              <div className="ml-auto">
                <ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 cursor-pointer stroke-2 hover:text-primary-rose" />
              </div>
            </div>

            {/* Bullet Chart */}
            <div className="">
              <BulletChart
                data={goalComparisonData}
                frontValueDataKey="actual"
                tickValueDataKey="goal"
                tickStyle={{
                  baseStyle: { strokeWidth: 4 },
                  aboveTickStyle: { stroke: "#1CB7BE" },
                  belowTickStyle: { stroke: "#D4246A" },
                }}
              />
            </div>

            {/* Historical Inputs */}
            <div className="flex flex-col">
              <ForecastInput />
            </div>
          </div>
        </div>
      )}

      {/* ----- Right ----- */}
      {/* // TODO: reimplement border when historicals are in <div className="flex flex-col gap-5 pl-6 border-l border-gray-300 grow"> */}
      <div className="flex flex-col items-center gap-5 grow">
        {/* Metrics */}
        {/* // TODO: implement metrics */}
        {false && (
          <div className="flex flex-col gap-2.5">
            {/* Pipeline Indicator */}
            <div className="flex gap-2.5 items-center">
              <ChevronRightIcon className="w-4 h-4 stroke-[3] stroke-primary-rose" />
              <div className="text-sm font-medium text-primary-rose ">
                Total Pipeline
              </div>
            </div>

            {/* Info */}
            <div className="flex gap-8">
              <ThreeInfoCard
                titles={["Active Jobs", "Avg Next 8 Weeks", "Coverage"]}
                values={[658, 50, "13 Wks"]}
              />

              {/* Key Considerations */}
              <div className="flex flex-col gap-2.5">
                {/* Header */}
                <div className="text-base font-semibold leading-6">
                  Key Considerations
                </div>

                {/* List */}
                <div className="px-2.5 grid grid-rows-2 grid-flow-col gap-x-10 gap-y-1.5 overflow-x-auto">
                  {keyConsiderations.map((item, i) => (
                    <div
                      key={i}
                      className="flex gap-2.5 items-center text-sm font-medium"
                    >
                      {item.icon}
                      {item.text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Diagram */}
        <div
          id="bucket-timeline-parent"
          // className="py-5 border-gray-300 basis-1/3 border-y" // TODO: reimplement border when metrics are in
          className="basis-1/3 ml-[18px]"
        >
          <BucketTimelineDiagram
            values={formatStages(stageStats)}
            weekGap={23}
            selectedIndex={selectedIndex}
            onSelect={(i) => setSelectedIndex(i === selectedIndex ? -1 : i)}
          />
        </div>

        {/* Forecast */}
        <div
          className="flex flex-col ml-[18px]"
          style={{ width: $("#bucket-timeline-parent svg").width() }}
        >
          <BulletChartAndInput
            stageStats={stageStats}
            selectedIndex={selectedIndex}
            fixedWidth

            isStatic={isStatic}
          />
        </div>
      </div>
    </>
  );
}
