/**
 * Helper class for formatting addresses to strings.
 * Could expand to more address behaviors.
 */
export default class AddressHelper {

  /**
  * Convert an address object to a string.
  * If `oneLine` is true, the address will be formatted as a single line.
  * Otherwise, new lines will be used to separate the address fields.
  *
  * TODO: test
  */
  static toString(address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: number;
  }, oneLine: boolean = false): string {
    if (!address) return '';

    let addressStr = address.line1.trim();
    if (address.line2?.trim()) {
      addressStr += `\n${address.line2.trim()}`;
    }
    addressStr += `\n${address.city.trim()}`                                      // City
    addressStr += `${(address.city.trim() && address.state.trim()) ? ", " : ""}`  // City/state separator
    addressStr += `${address.state.trim()}`                                       // State
    addressStr += ` ${address.postalCode}`;

    addressStr = addressStr.trim();

    // Remove new lines if `oneLine` is true
    if (oneLine) {
      addressStr = addressStr.replace(/\n/g, ', ');
    }

    return addressStr;
  }
}
