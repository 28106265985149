import React from 'react'
import moment from 'moment'
import classNames from 'src/tools/classNames';

export type Field = {
  field: string,
  label: string,
  round?: boolean,
  highlight?: boolean,
  units?: string
  asDate?: boolean // Whether or not to use date formatter
  hideNulls?: boolean
};

type Props = {
  active?: boolean,
  payload?: any,
  label?: string,
  fields: Field[],
  headerField: Field,
  dateFormatter?: (d: moment.MomentInput) => string
}

/**
 *
 * Custom tooltip for the charts in the grid view.
 * Takes an array of fields to display in the tooltip.
 * Each field object should have a label, field, and optionally a highlight, round, and units.
 *
 * label: label to display for the field
 * field: field to get the value from the data object
 * highlight: whether to highlight the value
 * round: whether to round the value
 * units: units to display after the value
 * asDate: whether to use the date formatter
 * hideNulls: whether to hide the field if the value is null
 *
 */
export default function CustomTooltip({
  active,
  payload,
  label,
  fields = [],
  headerField,
  dateFormatter = (d: moment.MomentInput) =>
    moment.utc(d).format("MMM YYYY"),
}: Props): JSX.Element {
  if (active && payload && payload.length) {
    let headerValue = headerField && payload[0].payload[headerField.field];

    return (
      <div className="flex flex-col gap-2 p-4 text-sm bg-white border border-gray-300 rounded-md dark:bg-gray-700">
        {/* Date */}
        <div className="text-sm font-medium">{formatValue(headerValue, headerField, dateFormatter)}</div>

        {/* Table */}
        <table className="table table-fixed">
          <tbody>
            {fields.map((f, i) => {
              var value = payload[0].payload[f.field];

              if (value == null && f.hideNulls) return null;

              return (
                <tr key={i}>
                  <td className="text-gray-500 dark:text-gray-300">
                    {f.label}:
                  </td>
                  <td
                    className={classNames(
                      "text-right whitespace-nowrap",
                    )}
                  >
                    {formatValue(value, f, dateFormatter)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

/**
  * Formats the value based on the field configuration.
  * Handles rounding and applying the date formatter.
  */
function formatValue(value: any, field: Field, dateFormatter: (d: moment.MomentInput) => string): JSX.Element {
  if (!field) return null;

  let formattedValue = value;

  if (field.round) {
    formattedValue = Math.round(value);
  }

  if (field.asDate) {
    formattedValue = dateFormatter(value);
  }

  return <span className={classNames(field.highlight ? "text-primary-green" : "")}>{formattedValue}{field.units}</span>
}
