import React, { useState, useEffect, Fragment, ReactNode, useMemo } from 'react'
import Input from './Input'
import moment from 'moment';
import Dropdown from './Dropdown';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import classNames from 'src/tools/classNames';
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import UserManager from 'src/tools/UserManager';
import Spinner from '../Spinner';
import { on } from 'events';

type TimeRange = {
  // TODO: implement
  start?: string,
  end?: string,
}

type Props = {
  startTime?: string,
  endTime?: string,

  onChange?: (timeRange: TimeRange) => void,

  label?: string,
  justifyRight?: boolean,
}

/**
  * Input component for selecting a time range, start and end time
  * Handles fetching and creating time ranges that tie to the user.
  * Time ranges have a start and end time, both in the format `HH:mm`.
  *
  * Props:
  * - `startTime`: Start value time in the format `HH:mm` (Internal state will sync with this prop)
  * - `endTime`: End value time in the format `HH:mm` (Internal state will sync with this prop)
  * - `onChange`: Callback function to call when the time range changes
  * - `label`: Label for the input
  * - `justifyRight`: If true, the dropdown will be right-aligned
  */
export default function TimeRangeInput({
  startTime: startTime_prop,
  endTime: endTime_prop,
  onChange,
  label,
  justifyRight = false,
}: Props) {
  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange>();
  const [timeRanges, setTimeRanges] = useState<TimeRange[]>();
  const [customOpen, setCustomOpen] = useState(false);

  // Sync to props
  useEffect(() => {
    if (startTime_prop && endTime_prop) {
      setSelectedTimeRange({
        start: startTime_prop,
        end: endTime_prop,
      })
    }
  }, [startTime_prop, endTime_prop])

  // Get time ranges on mount
  useEffect(() => {
    fetchTimeRanges();
  }, []);

  /**
   * Toggle the dropdown
   */
  function buttonClicked() {
    setCustomOpen(prev => !prev);
  }

  /**
   * Fetch the user's time ranges from the server
   */
  function fetchTimeRanges() {
    UserManager.makeAuthenticatedRequest(`/api/user-settings/time-range`, "GET")
      .then((res) => {
        if (res.data.status === "ok") {
          setTimeRanges(res.data.timeRanges)
        } else {
          setTimeRanges([])
        }
      }).catch((err) => {
        setTimeRanges([])
        console.error(err)
      });
  }

  /**
   * Add a time range.
   * Optimistically updates the state and sends a request to the server.
   * Reverts if the request fails.
   */
  function createTimeRange(timeRange) {
    let oldTimeRanges = timeRanges;
    let newTimeRanges = [...oldTimeRanges, timeRange]
    setTimeRanges(newTimeRanges)
    // Send request to create time range
    UserManager.makeAuthenticatedRequest(`/api/user-settings/time-range`, "POST", timeRange)
      .then(() => {
        // Success
      }).catch((err) => {
        // Revert if failed
        setTimeRanges(oldTimeRanges)
      });
  }

  /**
   * Deletes a time range.
   * Optimistically updates the state and sends a request to the server.
   * Reverts if the request fails.
   */
  function deleteTimeRange(timeRange: TimeRange) {
    let oldTimeRanges = timeRanges;
    let newTimeRanges = oldTimeRanges.filter(tr => tr !== timeRange)
    setTimeRanges(newTimeRanges)
    // Send request to delete time range
    UserManager.makeAuthenticatedRequest("/api/user-settings/time-range", "DELETE",
      {
        index: oldTimeRanges.indexOf(timeRange)
      }
    ).then((res) => {
      // Success
    }).catch((err) => {
      // Revert if failed
      setTimeRanges(oldTimeRanges)
    });
  }

  return <div>
    {label && (
      <label
        className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
      >
        {label}
      </label>
    )}
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <MenuButton
              onClick={buttonClicked}
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              {selectedTimeRange ? formatTimeRange(selectedTimeRange) : "Select Time Range"}
              <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-gray-400" />
            </MenuButton>
          </div>
          {customOpen && (
            <MenuItems
              static
              className={classNames(
                "absolute z-10 mt-2 min-w-56 rounded-md bg-white shadow-lg ring-1",
                "ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform",
                "data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in",

                justifyRight ? "right-0 origin-top-right" : "left-0 origin-top-left"
              )}
            >
              <div className="py-2">
                <NewTimeRangeItem
                  onCreate={createTimeRange}
                />
                {
                  timeRanges?.map((timeRange, index) =>
                    <Item
                      key={JSON.stringify(timeRange)}
                      onClick={() => {
                        setSelectedTimeRange(timeRange)
                        onChange?.(timeRange)
                        setCustomOpen(false)
                      }}
                    >
                      {formatTimeRange(timeRange)}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteTimeRange(timeRanges[index]);
                        }}
                        type="button"
                      >
                        <TrashIcon
                          className="w-5 h-5 hover:text-gray-600"
                        />
                      </button>
                    </Item>
                  ) ?? <div className="py-3">
                    <Spinner size={20} />
                  </div>
                }
              </div>
            </MenuItems>)
          }
        </>
      )
      }
    </Menu>
  </div>
}


type ItemProps = {
  children: ReactNode,
  hover?: boolean,
  onClick?: () => void,
}

/**
 * Sub-component used in the TimeRangeInput component's dropdown.
 * Handles styling for menu items.
 *
 * Props:
 * - `children`: The content of the item
 * - `hover`: If true, the item will have a hover effect (default: true)
 * - `onClick`: Callback function to call when the item is clicked
 */
function Item({
  children,
  hover = true,
  onClick,
}: ItemProps) {
  return <MenuItem>
    {({ active, close }) => (
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          "px-4 py-2 text-sm text-gray-500",
          hover ? "hover:bg-gray-100 cursor-pointer" : "",
          "flex items-center justify-between w-full",
        )}
      >
        {children}
      </button>
    )}
  </MenuItem>
}

/**
 * Sub-component used in the TimeRangeInput component's dropdown.
 * Item/MenuItem for creating a new time range.
 * Houses everything for creating a new time range (selection dropdowns; buttons)
 *
 * Props:
 * - `onCreate`: Callback function to call when the time range is created
 */
function NewTimeRangeItem({ onCreate }: { onCreate: (timeRange: TimeRange) => void }) {

  const [newTimeRange, setNewTimeRange] = useState<TimeRange>();

  // Time options for dropdowns
  // 30-minute intervals from 12:00 AM to 11:30 PM
  const options = useMemo(() => {

    let arr = [];
    let time = moment().startOf('day');

    while (time.isBefore(moment().endOf('day'))) {
      arr.push({
        label: time.format('h:mm A'),
        value: time.format('HH:mm')
      })
      time.add(30, 'minutes')
    }

    return arr;

  }, [])

  /**
   * Start creating a new time range.
   * Sets the state to a blank time range.
   */
  function createTimeRange() {
    setNewTimeRange({})
  }

  /**
   * Cancels the creation of a new time range.
   * Clears the state.
   */
  function cancel() {
    setNewTimeRange(undefined)
  }

  /**
   * Confirms the creation of a time range.
   * Calls `onCreate` to notify parent.
   * Clears the state so another time range
   * can be created.
   */
  function confirm() {
    onCreate(newTimeRange)
    setNewTimeRange(undefined)
  }

  /**
   * Updates the start of the time range.
   * If start is after end, set end to start.
   * This enforces end after start.
   */
  function setStart(option) {
    let value = option.value;

    let result = { ...newTimeRange }

    if (value > newTimeRange.end) {
      result.end = value;
    }

    result.start = value;

    setNewTimeRange(result)
  }

  /**
   * Updates the end of the time range.
   * If end is before start, set start to end.
   * This enforces end after start.
   */
  function setEnd(option) {
    let value = option.value;

    let result = { ...newTimeRange }

    if (value < newTimeRange.start) {
      result.start = value;
    }

    result.end = value;

    setNewTimeRange(result)
  }

  return newTimeRange ?
    <Item
      hover={false}
    >
      <div className="flex flex-row items-center w-full gap-2" >
        <Dropdown
          placeholder="Earliest"
          selectedValue={newTimeRange.start}
          options={[options]}
          onSelected={setStart}
        />
        {` - `}
        <Dropdown
          placeholder="Latest"
          selectedValue={newTimeRange.end}
          options={[options]}
          onSelected={setEnd}
        />
        <button
          type="button"
          className="text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-400"
          onClick={confirm}
          data-disabled={!newTimeRange.start || !newTimeRange.end}
          disabled={!newTimeRange.start || !newTimeRange.end}
        >
          <CheckIcon className="w-5 h-5" />
        </button>
        <button
          type="button"
          className="text-primary-rose hover:text-primary-rose-900"
          onClick={cancel}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
    </Item>
    :
    <Item
      onClick={createTimeRange}
    >
      <div className="w-full flex items-center justify-between">
        <p>Create New Time Range</p>
        <PlusIcon className='w-5 h-5' />
      </div>
    </Item>

}

/**
 * Formats a time range for display ("h:mm A - h:mm A")
 */
function formatTimeRange(timeRange: TimeRange): string {
  let start = moment(timeRange.start, 'HH:mm').format('h:mm A')
  let end = moment(timeRange.end, 'HH:mm').format('h:mm A')
  return `${start} - ${end}`
}

/**
 * Input component for selecting a time range, start and end time
 * 
 * @param onChange - function to call when the time range changes
 * @param label - label for the input
 * @param enforceStartBeforeEnd - if true, enforce that the start time is before or equal to the end time
 */
function Deprecated_TimeRangeInput({
  startTime: startTimeProp,
  endTime: endTimeProp,
  onChange,
  label,
  enforceStartBeforeEnd = false,
}) {
  const [startTime, setStartTime] = useState(startTimeProp || "")
  const [endTime, setEndTime] = useState(endTimeProp || "")

  useEffect(() => {
    setStartTime(startTimeProp)
  }, [startTimeProp]);
  useEffect(() => {
    setEndTime(endTimeProp)
  }, [endTimeProp]);

  return (
    <div>
      {label && (
        <label
          className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
        >
          {label}
        </label>
      )}
      <div className='flex flex-row items-center w-full gap-2'>
        <div className='w-full'>
          <Input
            type="time"
            value={startTime}
            onBlur={(value) => {
              if (enforceStartBeforeEnd && endTime) {
                if (value <= endTime) {
                  setStartTime(value)
                  onChange({
                    startTime: value,
                    endTime
                  })
                } else {
                  setStartTime(endTime)
                  onChange({
                    startTime: endTime,
                    endTime
                  })
                }
              } else {
                setStartTime(value)
                onChange({
                  startTime: value,
                  endTime
                })
              }

            }}
          />
        </div>
        <div>
          -
        </div>
        <div className='w-full'>
          <Input
            type="time"
            value={endTime}
            onBlur={(value) => {
              if (enforceStartBeforeEnd && startTime) {
                if (value >= startTime) {
                  setEndTime(value)
                  onChange({
                    startTime,
                    endTime: value
                  })
                } else {
                  setEndTime(startTime)
                  onChange({
                    startTime,
                    endTime: startTime
                  })
                }
              } else {
                setEndTime(value)
                onChange({
                  startTime,
                  endTime: value
                })
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
