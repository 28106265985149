import React, { useEffect } from "react"
import classNames from "src/tools/classNames"

type WeekInputProps = {
  value: number,
  defaultValue?: number,
  disabled?: boolean
  onChange?: (value: number) => void
}

/**
 * Input for a number of jobs per week. Displays "/ wk" after the input.
 */
export default function WeekInput({ value: value_prop, defaultValue, disabled, onChange }: WeekInputProps): JSX.Element {
  const [value, setValue] = React.useState<number>(value_prop)

  useEffect(() => {
    setValue(value_prop)
  }, [value_prop])

  return <div className="relative rounded-md">
    <input
      type="number"
      name="perWeek"
      placeholder={defaultValue ? `${defaultValue}` : "-"}
      step={0.1}
      min={0}
      value={value}
      onChange={e => {
        let value = +e.target.value;
        if (isNaN(value)) value = 0;
        setValue(value || null);
      }}
      onBlur={(e) => {
        let value = +e.target.value;
        if (isNaN(value)) value = 0;
        onChange?.(value)
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.currentTarget.blur()

          let value = +e.currentTarget.value;
          if (isNaN(value)) value = 0;
          onChange?.(value)
        }
      }}
      className={
        classNames(

          // Positioning with "/ wk"
          "hide-arrows block w-20 min-w-20 rounded-md py-1 pl-1.5 pr-10",

          // Text
          "text-center text-xs font-normal",

          // Border
          !disabled ? "border-0 ring-purple-500 invalid:!ring-red-500 ring-inset ring-1" : "border-0",

          // Ring (focus)
          "focus:ring-purple-500 focus:ring-2",

          //  Placeholder
          "placeholder:text-gray-400"
        )}
      disabled={disabled}
    />
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1.5">
      <span className="text-gray-500 sm:text-sm"> / wk</span>
    </div>
  </div>
}
