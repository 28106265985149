import { ClockIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import pluralize from 'pluralize'
import React, { useEffect, useState } from 'react'
import Row, { MarketData } from 'src/components/Labor/MarketForecasts/Row.tsx'
import Button from 'src/components/input/Button'
import MultiSelectListbox from 'src/components/input/MultiSelectListbox.tsx'
import Switch, { Variant } from 'src/components/input/Switch.tsx'
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext'
import { StageType } from 'src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext'
import useJobAvailabilityBreakdown, { AvailabilityBreakdown, WeekMap, weekMapToArray } from 'src/hooks/data/forecast/useJobAvailabilityBreakdown.ts'
import useMultiMarketSelect from 'src/hooks/data/markets/useMultiMarketSelect.ts'
import useStages from 'src/hooks/data/stages/useStages.ts'
import { add } from "mathjs";
import generateAvailableAndRemainder from 'src/tools/Forecast/GenerateAvailableAndRemainder.ts'
import { Link } from 'react-router-dom'

const NUM_FORECAST_WEEKS = 16;
const NUM_HISTORICAL_WEEKS = 6;

type Props = {}

/**
 * Displays the market forecasts page.
 * This is the Job Forecasts tab within the Labor page (`/app/labor/market-forecasts`).
 * Shows a list of markets and their planned jobs and available jobs.
 * Planned jobs are editable. Historical values are also displayed.
 * Also displays a total row.
 */
export default function MarketForecasts({ }: Props) {
  const [markets, selectedMarkets, setSelectedMarkets] = useMultiMarketSelect(true);
  const stages = useStages();
  const [showTotal, setShowTotal] = useState<boolean>(true);
  const marketAvailabilityBreakdown = useJobAvailabilityBreakdown(markets?.map(m => m._id) ?? []);

  // Pull planned jobs from availability breakdown as it is
  // a state here as the user changes it
  const [marketsPlannedJobs, setMarketsPlannedJobs] = useState<{ [market: string]: number[] }>({});

  // Sync market planned jobs on market availability breakdown change
  useEffect(() => {
    // Go through markets and set state

    let initialPlannedJobs = marketAvailabilityBreakdown?.installsByWeek;

    let resultingPlannedJobs: { [market: string]: number[] } = {};

    // Iterate over markets
    for (let marketId in initialPlannedJobs) {
      // Get market's planned jobs
      let marketPlannedJobs = initialPlannedJobs[marketId];
      // Set market's planned jobs as array
      resultingPlannedJobs[marketId] = weekMapToArray(marketPlannedJobs, NUM_FORECAST_WEEKS, moment().utc().startOf('week').toDate());
    }

    // Set state
    setMarketsPlannedJobs(resultingPlannedJobs);
  }, [marketAvailabilityBreakdown])


  // TODO: handle CSS better. constant widths are bad but CSS is a nightmare
  return (
    <div>

      {/* Actions / Filters */}
      <div className="mx-4 my-4 flex gap-2 items-center">
        <MultiSelectListbox
          options={markets?.map(m => ({ value: m._id, label: m.name })) ?? []}
          selectedOptionsValues={selectedMarkets?.map(m => m._id) ?? []}
          itemType="Market"
          onChange={(values) => {
            setSelectedMarkets(markets.filter(m => values.includes(m._id)))
          }}
        />

        <Switch
          label="Show Total"
          variant={Variant.BOXY}
          checked={showTotal}
          setChecked={setShowTotal}
        />


        {/* Divider */}
        <div className="ml-auto" />

        <Link to="/app/forecast/history">
          <Button variant="secondary" className="!py-2 !px-2.5">
            <ClockIcon className="h-5 w-5 stroke-[3px] text-primary-green" />
          </Button>
        </Link>
      </div>

      {/* Body */}
      <div className="flex flex-col flex-1 space-y-2 overflow-auto">
        <div className="3xl:flex 3xl:flex-col 3xl:justify-center 3xl:items-center">
          {/* Total */}
          {showTotal &&
            <div className="w-[1592px] border-b border-gray-400 mx-2 bg-gray-50">
              <Row
                isExpanded
                isEditable={false}
                preventExpandCollapse
                {...getTotalMarketRepresentation(
                  selectedMarkets,
                  marketAvailabilityBreakdown,
                  stages,
                  marketsPlannedJobs,
                )} // Market and market data
              />
            </div>
          }

          {/* Header */}
          <div className='px-4 my-6 text-xl font-semibold leading-7 text-left align-middle w-[1592px]'>
            Job Forecast by Market
          </div>

          {/* Rows */}
          {selectedMarkets && selectedMarkets.length
            ?
            <div className="w-[1592px] flex flex-col border-b mx-2 border-gray-400">
              {selectedMarkets.map((market) => (
                <Row
                  key={market._id}
                  market={market}
                  marketData={stages && marketAvailabilityBreakdown ? extractMarketData(market, marketAvailabilityBreakdown, stages) : null}
                  onPlannedJobsChange={(plannedJobs) => {
                    setMarketsPlannedJobs({
                      ...marketsPlannedJobs,
                      [market._id]: plannedJobs
                    })
                  }}
                />
              ))}
            </div>
            :
            (<div className="text-center text-gray-500 pt-6">No Markets Selected</div>)
          }
        </div>
      </div>

    </div>
  )
}

/**
  * Extracts market data from the availability breakdown.
  * Makes it easy to pass a specific market's data to the row component.
  */
function extractMarketData(market: MarketType, availabilityBreakdown: AvailabilityBreakdown, stages: StageType[]): MarketData {
  let marketId = market._id.toString();
  let startStage = stages.length && stages[1];
  let stageId = startStage._id.toString();

  // --- Stats --- //
  let conversionRate = availabilityBreakdown.marketCycleConvData[marketId]?.[stageId]?.conversionRate;
  let cycleTime = availabilityBreakdown.marketCycleConvData[marketId]?.[stageId]?.avgCycleTime;

  // --- Forecast --- //
  let plannedJobsMap = availabilityBreakdown.installsByWeek[marketId];
  let plannedJobs = weekMapToArray(plannedJobsMap, NUM_FORECAST_WEEKS, moment().utc().startOf('week').toDate());

  let installsAvailable = Array(NUM_FORECAST_WEEKS).fill(0);
  let totalEstimatedInstalls = Array(NUM_FORECAST_WEEKS).fill(0);
  if (availabilityBreakdown && market) {
    let estimatedFromPipeline = weekMapToArray(availabilityBreakdown.estimatedInstallsByWeek[market._id] ?? {}, NUM_FORECAST_WEEKS, moment().utc().startOf('week').toDate());
    let estimatedFromSales = weekMapToArray(availabilityBreakdown.estimatedInstallsFromSales[market._id] ?? {}, NUM_FORECAST_WEEKS, moment().utc().startOf('week').toDate());
    totalEstimatedInstalls = estimatedFromPipeline.map((v, i) => v + estimatedFromSales[i]);
    installsAvailable = generateAvailableAndRemainder(plannedJobs, totalEstimatedInstalls).available;
  }

  // --- Historical --- //
  let h_actualInstallsMap = availabilityBreakdown.historicalActualInstalls[marketId];
  let h_actualInstalls = weekMapToArray(h_actualInstallsMap, NUM_HISTORICAL_WEEKS, moment().utc().startOf('week').subtract(6, 'weeks').toDate());

  let h_forecastInstalls = availabilityBreakdown.historicalForecastInstalls[marketId];

  let result: MarketData = {
    stats: {
      conversionRate,
      cycleTime,
    },
    forecast: {
      plannedJobs,
      installsAvailable,
      estimatedJobs: totalEstimatedInstalls,
    },
    historical: {
      actual: h_actualInstalls,
      forecast: h_forecastInstalls,
    }
  }

  return result
}

/**
  * Calculates the market data to represent the total of all selected markets.
  * Sums actual installs, forecasted/planned jobs, and available jobs.
  */
function getTotalMarketRepresentation(
  selectedMarkets: MarketType[],
  availabilityBreakdown: AvailabilityBreakdown,
  stages: StageType[],
  plannedJobs: { [market: string]: number[] } = {},
): {
  market: MarketType;
  marketData: MarketData;
} {
  if ([selectedMarkets, availabilityBreakdown, stages].some(v => !v)) // Skip if no data
    return { market: { _id: "total", name: "Total" }, marketData: null };

  let startStage = stages.length && stages[1]; // Skip 0 stage as stage 1 means it has a stage 0 timestamp
  let sId = startStage._id.toString();

  let selectedMarketIds = new Set(selectedMarkets.map(m => m._id.toString()));

  // TODO: error handle array sizes on aggregation functions. check how `add` works?

  /**
    * Sums the values of a MarketWeekMap field for all selected markets.
    * For example, sums estimated installs for all selected markets.
    *
    *  Pulls from availability breakdown.
    *
    * `numWeeks` takes the number of weeks to pull from the WeekMaps.
    * `goBackWeeks` flag determines if the weeks are historical or forecast.
    * For example, if `goBackWeeks` is true, it will get the N weeks right before
    * the current week (historical). If false, it will get the N weeks including
    * and after the current week (forecast).
    */
  function aggregateMarketWeekMap(field: keyof AvailabilityBreakdown, numWeeks: number, goBackWeeks: boolean = false): number[] {
    // Get start date
    let startDate = moment().utc().startOf('week').toDate();
    if (goBackWeeks) startDate = moment(startDate).subtract(numWeeks, 'weeks').toDate(); // If go back, weeks are all historical (i.e. past 6 weeks)

    // Iterate over markets
    return Object.entries(availabilityBreakdown[field])
      .reduce((acc, [_marketId, weekMap]) => { // Reduce to a single array
        if (!selectedMarketIds.has(_marketId)) return acc; // Skip if not selected
        let marketDataPoints = weekMapToArray(weekMap, numWeeks, startDate); // Extract market's data for field
        return add(acc, marketDataPoints) // Add element-wise
      }, Array(numWeeks).fill(0)) // Init with 0s
  }

  /**
    * Sums the values of a market->array field for all selected markets.
    * `numWeeks` is used to determine the size of the array to sum.
    *
    *  Pulls from availability breakdown.
    */
  function aggregateMarketArrays(field: keyof AvailabilityBreakdown, numWeeks: number): number[] {
    // Iterate over markets
    return Object.entries(availabilityBreakdown[field])
      .reduce((acc, [_marketId, weekArray]) => {
        if (!selectedMarketIds.has(_marketId)) return acc; // Skip if not selected
        if (!weekArray || weekArray.length === 0) return acc; // Skip if no data

        // Ensure same length for addition
        let maxLength = Math.max(acc.length, weekArray.length);
        let a = [...acc, ...Array(maxLength - acc.length).fill(0)];
        let b = [...weekArray, ...Array(maxLength - weekArray.length).fill(0)];
        return add(a, b);
      }, Array(numWeeks).fill(0))
  }

  /**
    *  Similar to `aggregateMarketArrays`, but for available jobs specifically.
    *  Gets total available jobs including preditcted installs from sales.
    *
    *  Pulls from availability breakdown.
    */
  function aggregateAvailableJobs(): number[] {

    // Iterate over markets
    return Object.entries(availabilityBreakdown.availableJobs)
      .reduce((acc, [_marketId, weekArray]) => {
        if (!selectedMarketIds.has(_marketId)) return acc; // Skip if not selected
        // Add available jobs for each week
        return add(acc, weekArray.map(w => w.includingSales))
      }, Array(NUM_FORECAST_WEEKS).fill(0))
  }

  /**
    * Similar to `aggregateMarketArrays`, but for planned jobs specifically.
    * This doesn't pull from availability breakdown as planned jobs are editable.
    */
  function aggregatePlannedJobs(): number[] {
    return Object.entries(plannedJobs)
      .reduce((acc, [_marketId, market_plannedJobs]) => {
        if (!selectedMarketIds.has(_marketId)) return acc; // Skip if not selected
        return add(acc, market_plannedJobs)
      }, Array(NUM_FORECAST_WEEKS).fill(0))
  }

  // --- Total Market --- //
  let combinedMarket: MarketType = {
    _id: "total", name: `Total - ${pluralize("Market", selectedMarkets?.length || 0, true)}`
  }

  let addEstimatedJobs = add(
    aggregateMarketWeekMap("estimatedInstallsByWeek", NUM_FORECAST_WEEKS),
    aggregateMarketWeekMap("estimatedInstallsFromSales", NUM_FORECAST_WEEKS),
  )

  // --- Total Market Data --- //
  // For each field, aggregate data from all markets
  let combinedMarketData: MarketData = {
    stats: {
      conversionRate: availabilityBreakdown.marketCycleConvData.combined?.[sId]?.conversionRate,
      cycleTime: availabilityBreakdown.marketCycleConvData.combined?.[sId]?.avgCycleTime,
    },
    forecast: {
      plannedJobs: aggregatePlannedJobs(),
      installsAvailable: aggregateAvailableJobs(),
      estimatedJobs: addEstimatedJobs
    },
    historical: {
      actual: aggregateMarketWeekMap("historicalActualInstalls", NUM_HISTORICAL_WEEKS, true),
      forecast: aggregateMarketArrays("historicalForecastInstalls", NUM_HISTORICAL_WEEKS),
    }
  }

  return {
    market: combinedMarket,
    marketData: combinedMarketData
  }
}
