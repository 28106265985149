import moment from 'moment';
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom';
import { Order, OrderStatusLabels, Timestamp, TimestampIcons, TimestampLabels, TimestampTypes, AgreementStatus } from 'src/hooks/data/orders/useOrders.ts'
import useUsersByIds, { UserMap } from 'src/hooks/data/users/useUsersByIds.ts';
import classNames from 'src/tools/classNames';
import StringHelper from 'src/utils/stringHelper.ts';
import { AgreementBubble, getAgreementStatus, AgreementTypes } from './OrderAgreementIcons.tsx';

type Props = {
  orders: Order[],
  timestampFilter?: TimestampTypes[],
  hideOrderLink?: boolean,
  excludeNoteTypeFilter?: string[] // TODO: make enum for note types
}

/**
 * Displays a timeline of orders and their timestamps.
 * If `timestampFilter` is provided, only timestamps of those types will be displayed
 * If `hideOrderLink` is true, the order link with it's name will be hidden
 *
 * Used heavily in order list page to show all order activity
 */
export default function OrderTimeline({
  orders,
  timestampFilter,
  excludeNoteTypeFilter,
  hideOrderLink = false
}: Props) {

  const users = useUsersByIds(orders?.map(order => order.timestamps.map(timestamp => timestamp.addedBy)).flat());

  // Flatten timestamps
  let timestamps: { timestamp: Timestamp, order: Order }[] = orders
    .reduce<{ timestamp: Timestamp, order: Order }[]>((acc, order) => {
      return acc.concat(order.timestamps.map(timestamp => ({ timestamp, order })))
    }, [])
    .filter(({ timestamp }) => !timestampFilter || timestampFilter.includes(timestamp.type));

  // Sort by recent
  // TODO: figure out why stamp isn't a date yet
  timestamps.sort((a, b) => new Date(b.timestamp.stamp).getTime() - new Date(a.timestamp.stamp).getTime());

  return <div className="relative grid grid-cols-[auto,1fr] gap-3">
    {timestamps.map((timestamp, i) =>
      <TimelineItem
        key={i}
        {...timestamp}
        users={users}
        hideOrderLink={hideOrderLink}
        isLast={i === timestamps.length - 1}
        excludeNoteTypeFilter={excludeNoteTypeFilter}
      />)}
  </div>
}

type TimelineItemProps = {
  timestamp: Timestamp;
  order: Order;
  users: UserMap;
  hideOrderLink?: boolean;
  isLast?: boolean;
  excludeNoteTypeFilter?: string[];
}

/**
 * One item in the timeline. This is a single timestamp on an order
 * Shows the user who made the timestamp, the action they took, and the time it happened
 * Common timestamps have thorough descriptions (e.g. agreement changed shows all changes).
 * Many timestamps just show the timestamp type value for the action
 */
function TimelineItem({
  timestamp,
  order,
  users,
  hideOrderLink = false,
  isLast = false,
  excludeNoteTypeFilter = ["material"],
}: TimelineItemProps): JSX.Element {
  let stamp = moment(timestamp.stamp)
  // TODO: finish implementing

  // Get user name but skeleton load with random length
  // TODO: can probably improve. randomizes each render which doesn't look great
  let userName: ReactNode = <span className="animate-pulse rounded-full bg-gray-300 w-10 h-4 text-white/0">{Array(Math.ceil(Math.random() * 4) + 5).fill("a")}</span>
  if (users) {
    const userObj = users[timestamp.addedBy];
    userName = userObj ? `${userObj.firstName} ${userObj.lastName.charAt(0)}.` : <span className="italic" > Missing User</span>;
  }

  let description: ReactNode = <></>;
  switch (timestamp.type) {

    case TimestampTypes.ORDER_CREATED:
      description = "created order";
      break;

    case TimestampTypes.STATUS_CHANGED:
      // TODO: dynamic typing?
      const oldStatus = timestamp.typeInfo.oldStatus;
      const newStatus = timestamp.typeInfo.newStatus;
      // TODO: handle missing statuses or no label for status
      const oldLbl = OrderStatusLabels[oldStatus];
      const newLbl = OrderStatusLabels[newStatus];
      description = oldStatus ? <>
        changed status from <Highlight>{oldLbl ?? oldStatus}</Highlight> to <Highlight>{newLbl}</Highlight>
      </> : <>
        changed status to <Highlight>{newLbl}</Highlight>
      </>
      break;

    case TimestampTypes.BILL_OF_MATERIALS_UPLOADED:
      description = "uploaded Bill of Materials"
      break;

    case TimestampTypes.NOTE_ADDED:
      var note = order.notes.find(note => note._id === timestamp.typeInfo?.noteId);

      if (excludeNoteTypeFilter && excludeNoteTypeFilter.includes(note?.type)) {
        return <></>;
      }
      description = note?.note ? `added note "${note?.note}"` : "added a note";
      break;

    case TimestampTypes.AGREEMENT_CHANGED:
      var note = order.notes.find(note => note._id === timestamp.typeInfo?.materialChange);
      var newAgreements = timestamp.typeInfo?.new;
      var oldAgreements = timestamp.typeInfo?.old;

      var deliveryDateStatusChanged = newAgreements?.deliveryDate?.installer != oldAgreements?.deliveryDate?.installer
        || newAgreements?.deliveryDate?.distributor != oldAgreements?.deliveryDate?.distributor;
      var materialStatusChanged = newAgreements?.material?.installer != oldAgreements?.material?.installer
        || newAgreements?.material?.distributor != oldAgreements?.material?.distributor;
      var amountStatusChanged = newAgreements?.amount?.installer != oldAgreements?.amount?.installer
        || newAgreements?.amount?.distributor != oldAgreements?.amount?.distributor;

      var newDeliveryDateStatus = getAgreementStatus(newAgreements?.deliveryDate);
      var newMaterialStatus = getAgreementStatus(newAgreements?.material);
      var newAmountStatus = getAgreementStatus(newAgreements?.amount);
      var oldDeliveryDateStatus = getAgreementStatus(oldAgreements?.deliveryDate);
      var oldMaterialStatus = getAgreementStatus(oldAgreements?.material);
      var oldAmountStatus = getAgreementStatus(oldAgreements?.amount);

      // Create string to display amount changes
      // If no change just show the amount
      // If change show "old -> new"
      // Formats for currency
      let amountString = timestamp.typeInfo?.amountChange?.old ? formatCurrency(timestamp.typeInfo?.amountChange?.old) : "--"
      if (timestamp.typeInfo?.amountChange?.new && timestamp.typeInfo?.amountChange?.old !== timestamp.typeInfo?.amountChange?.new) {
        amountString += " -> " + formatCurrency(timestamp.typeInfo?.amountChange?.new)
      }

      // TODO: timezones?
      let dateString = ""
      let dateFormat = "ddd M/D"
      if (timestamp.typeInfo?.deliveryDateChange) {
        let oldDate = timestamp.typeInfo?.deliveryDateChange?.old;
        let newDate = timestamp.typeInfo?.deliveryDateChange?.new;
        // Old value
        if (oldDate) {
          dateString += moment(oldDate.deliveryDate).utc().format(dateFormat)
          dateString += " @ " + moment(oldDate.deliveryWindow.start).format("h:mma") + " - " + moment(oldDate.deliveryWindow.end).format("h:mma")
        } else {
          dateString += "--"
        }
        // New value
        if (newDate) {
          dateString += " -> " + (newDate.deliveryDate ? moment(newDate.deliveryDate).utc().format(dateFormat) : "--")
          dateString += " @ " + moment(newDate.deliveryWindow.start).format("h:mma") + " - " + moment(newDate.deliveryWindow.end).format("h:mma")
        }
      } else {
        dateString += newDeliveryDateStatus === AgreementStatus.ACCEPTED ? "Accepted" : "Pending"
      }


      description = <>
        Agreement changed
        <div className="grid grid-cols-[auto,1fr] items-center gap-1">
          {
            deliveryDateStatusChanged &&
            <>
              <AgreementBubble
                status={getAgreementStatus(newAgreements.deliveryDate)}
                icon={AgreementTypes.DELIVERY_DATE}
              />
              {dateString}
            </>
          }
          {
            materialStatusChanged &&
            <>
              <AgreementBubble
                status={getAgreementStatus(newAgreements.material)}
                icon={AgreementTypes.MATERIAL}
              />
              {
                timestamp.typeInfo?.materialChange ?
                  <div>
                    {
                      note ? note.note : "--"
                    }
                  </div>
                  : <div>
                    {newMaterialStatus === AgreementStatus.ACCEPTED ? "Accepted" : "Pending"}
                  </div>
              }
            </>
          }
          {
            amountStatusChanged &&
            <>
              <AgreementBubble
                status={getAgreementStatus(newAgreements.amount)}
                icon={AgreementTypes.AMOUNT}
              />
              <div>
                {amountString}
              </div>
            </>
          }
        </div>
      </>
      break;
    // TODO: more cases
    default:
      description = TimestampLabels[timestamp.type] ?? "- " + StringHelper.toPhraseCase(timestamp.type);
      break;
  }

  // TODO: dark mode
  return <div className="text-gray-500 grid grid-cols-subgrid col-span-full group">
    {/* Icon */}
    <Circle
      timestamp={timestamp}
      isLast={isLast}
    />

    {/* Information */}
    <div className='text-sm'>
      <p>
        <span className="font-medium text-gray-900 dark:text-white">{userName}</span>
        {" "}
        {description}
      </p>
      <p className="">
        {!hideOrderLink &&
          <>
            <Link
              className="text-primary-green hover:text-primary-green-600"
              to={`../details/${order._id}`}
            >
              {order.name ?? <span className="italic">No Name</span>}
            </Link>
            {" - "}
          </>
        }
        <span className="">
          <span className='group-hover:hidden'>{stamp.fromNow()}</span>
          <span className='hidden group-hover:inline'>{stamp.format("M/D/Y h:mma")}</span>
        </span>
      </p>
    </div>
  </div>
}

type CircleProps = {
  timestamp: Timestamp;
  isLast?: boolean;
}

// TODO: comment
function Circle({ timestamp, isLast = false }: CircleProps) {
  if (!timestamp.type) return null;
  const Icon = TimestampIcons[timestamp.type];

  // TODO: dark mode
  // TODO: outer circle stretches. Should fit not fill
  // TODO: improve h/w classes
  return <div className="flex flex-col items-center">
    <div className="rounded-full bg-gray-300 min-w-[30px] min-h-[30px] flex items-center justify-center">
      {/* TODO: default icon? */}
      {Icon ? <Icon className="h-5 w-5 stroke-2" /> : ""}
    </div>
    {!isLast && <div className="w-0.5 grow bg-gray-300 relative top-1.5"></div>}
  </div>
}

// TODO: comment
function Highlight({ children }: { children?: ReactNode }): JSX.Element {
  return <span className="font-semibold">{children}</span>
}

/**
  * Formats a number for currency display
  * Example: 1000 -> $1,000.00
  */
function formatCurrency(value: number): string {
  return Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)
}
