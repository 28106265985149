import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import Card from 'src/components/Card'
import AvailableWork from 'src/components/Labor/CrewPlanner/AvailableWork.tsx';
import PlannedCapacity from 'src/components/Labor/CrewPlanner/PlannedCapacity.tsx';
import WorkCapacityRatioChart from 'src/components/Labor/CrewPlanner/WorkCapacityRatioChart.tsx';
import Dropdown from 'src/components/input/Dropdown';
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext';
import useJobAvailabilityBreakdown, { AvailabilityBreakdown, weekMapToArray } from 'src/hooks/data/forecast/useJobAvailabilityBreakdown.ts';
import useMarketSelect from 'src/hooks/data/markets/useMarketSelect.ts';
import generateAvailableAndRemainder from 'src/tools/Forecast/GenerateAvailableAndRemainder.ts';
import UserManager from 'src/tools/UserManager';
import classNames from 'src/tools/classNames';
import MathHelper from 'src/utils/mathHelper.ts';

// TODO: handle dark mode

type Props = {}

export enum Modes {
  WHAT_IF = "whatIf",
  CREW_CAPACITY = "crews"
}

const NUM_WEEKS = 16;

export type Crew = {
  _id?: string,
  name: string,
  plannedWeeks: number[], // Array of which weeks are planned for (e.g. [0,4,5,6] means weeks 0, 4, 5, 6 are planned)
  weekCapacity: number,
  weekCapacityOverride?: number,
  isHypothetical?: boolean,
}

/**
 * The main view for the `Labor>Crew Planner` page.
 * Shows, by market, a breakdown / input of the `Work / Capacity Ratio`.
 * This consists of the work/capacity ratio chart, available work, and planned capacity.
 */
export default function CrewPlannerMainView({ }: Props) {

  const [searchParams, setSearchParams] = useSearchParams()
  const marketSearchParam = searchParams.get("market");

  const [markets, selectedMarket, setSelectedMarket] = useMarketSelect(marketSearchParam || undefined)
  const availabilityBreakdown = useJobAvailabilityBreakdown(selectedMarket ? [selectedMarket._id] : []);

  const [desiredRatioRange, setDesiredRatioRange] = useState<{ min: number, max: number }>({ min: 0, max: 1 });
  const [jobsSentToSubs, setJobsSentToSubs] = useState<number[]>(Array(NUM_WEEKS).fill(0))
  const [plannedJobs, setPlannedJobs] = useState<number[]>(Array(NUM_WEEKS).fill(0));
  const [mode, setMode] = useState<Modes>(Modes.CREW_CAPACITY)
  const [crews, setCrews] = useState<Crew[]>([]);
  const [fillerCrewIndex, setFillerCrewIndex] = useState<number>(1); // Used for adding filler crews. Incremented each time a filler crew is added.
  const [whatIfCrews, setWhatIfCrews] = useState<number[]>(Array(NUM_WEEKS).fill(0))
  const [whatIfCrewPerWeek, setWhatIfCrewPerWeek] = useState<number>(4);

  const saveAbortControllerRef = useRef<AbortController>(new AbortController());

  // Sync URL with state
  useEffect(() => {
    if (selectedMarket) {
      setSearchParams({ market: selectedMarket._id });
    }
  }, [selectedMarket]);

  // Sync planned jobs when availability breakdown changes
  useEffect(() => {
    if (!availabilityBreakdown) return;

    let weekMap = availabilityBreakdown.installsByWeek[selectedMarket?._id];
    if (!weekMap) return;

    let newPlannedJobs = weekMapToArray(weekMap, NUM_WEEKS, moment().utc().startOf('week').toDate());
    setPlannedJobs(newPlannedJobs)
  }, [availabilityBreakdown]);

  // Get capacity data on market selected
  useEffect(() => {
    if (!selectedMarket) return;

    UserManager.makeAuthenticatedRequest(`/api/forecast/crew-planner?market=${selectedMarket._id}`)
      .then((res) => {
        if (res.data.status === "ok") {
          if (res.data.capacityInformation) {
            let info = res.data.capacityInformation;

            // Set states
            setDesiredRatioRange(info.desiredRatioRange);
            setJobsSentToSubs(info.jobsSentToSubs);
            setMode(info.capacityType);
            setWhatIfCrews(info.whatIfCrews);
            setWhatIfCrewPerWeek(info.whatIfCrewPerWeek);
            setCrews(info.crewCapacities.map((c: {
              crew: string | null,
              name: string,
              weekCapacity: number,
              weekCapacityOverride: number,
              plannedWeeks: number[],
            }, i: number): Crew => ({
              _id: c.crew ? c.crew : (i + 1).toString(),
              name: c.crew ?? `Crew ${i + 1}`,
              plannedWeeks: c.plannedWeeks,
              weekCapacity: c.weekCapacity,
              weekCapacityOverride: c.weekCapacityOverride,
              isHypothetical: !c.crew
            })));
          }
        }
      }).catch((err) => {
        // Note that 404 means there is no data for the selected market
        // so stick with state defaults and save will set them initially
        console.error(err);
      });
  }, [selectedMarket?._id]);


  // --- Calculate What If Capacity --- //
  let whatIfCapacity = whatIfCrews.map((c) => c * whatIfCrewPerWeek);

  // --- Calculate Crew Capacity --- //
  let crewCapacity = calculateCrewCapacity(crews, jobsSentToSubs)


  // --- Job Capacity --- //
  // Either crew capacity or what if capacity based on mode
  let jobCapacity = mode === Modes.CREW_CAPACITY ? crewCapacity : whatIfCapacity;

  // --- Jobs Available --- //
  let jobsAvailable = Array(NUM_WEEKS).fill(0);
  if ([
    availabilityBreakdown,
    selectedMarket,
    availabilityBreakdown?.estimatedInstallsByWeek[selectedMarket._id],
    availabilityBreakdown?.estimatedInstallsFromSales[selectedMarket._id]].every(Boolean)
  ) {
    let estimatedFromPipeline = weekMapToArray(availabilityBreakdown.estimatedInstallsByWeek[selectedMarket._id], NUM_WEEKS, moment().utc().startOf('week').toDate());
    let estimatedFromSales = weekMapToArray(availabilityBreakdown.estimatedInstallsFromSales[selectedMarket._id], NUM_WEEKS, moment().utc().startOf('week').toDate());
    let totalEstimatedInstalls = estimatedFromPipeline.map((v, i) => v + estimatedFromSales[i]);
    jobsAvailable = generateAvailableAndRemainder(plannedJobs, totalEstimatedInstalls).available;
  }

  // --- Round Capacities (avoid stuff like 4.2*3=12.600000000000001) --- //
  jobCapacity = jobCapacity.map(c => MathHelper.round(c, 1));
  crewCapacity = crewCapacity.map(c => MathHelper.round(c, 1));
  whatIfCapacity = whatIfCapacity.map(c => MathHelper.round(c, 1));

  // --- Functions --- //

  /**
   * Adds a filler crew to the list of crews.
   */
  function handleCrewAdd() {
    let newCrews = [...crews, {
      _id: fillerCrewIndex.toString(),
      name: `Crew ${fillerCrewIndex}`,
      plannedWeeks: [],
      weekCapacity: 0,
      isHypothetical: true,
    }]
    setCrews(newCrews);
    setFillerCrewIndex(fillerCrewIndex + 1);

    // New job capacity based on crew change
    let jobCapacity = mode === Modes.CREW_CAPACITY ? calculateCrewCapacity(newCrews, jobsSentToSubs) : whatIfCapacity;
    saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, jobsSentToSubs, newCrews, whatIfCrews, whatIfCrewPerWeek);
  }

  /**
   * Deletes a crew from the list of crews.
   * Saves capacity information after deletion.
   */
  function handleCrewDelete(index: number) {
    let newCrews = [...crews];
    newCrews.splice(index, 1);
    setCrews(newCrews);

    // New job capacity based on crew change
    let jobCapacity = mode === Modes.CREW_CAPACITY ? calculateCrewCapacity(newCrews, jobsSentToSubs) : whatIfCapacity;
    saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, jobsSentToSubs, newCrews, whatIfCrews, whatIfCrewPerWeek);
  }

  /**
   * Handles a change to a crews planned weeks or week capacity.
   * Saves capacity information after change.
   */
  function handleCrewChange(index: number, plannedWeeks: number[], weekCapacity: number) {
    let newCrews = [...crews];
    newCrews[index].plannedWeeks = plannedWeeks;
    newCrews[index].weekCapacityOverride = weekCapacity || null;
    setCrews(newCrews);

    // New job capacity based on crew change
    let jobCapacity = mode === Modes.CREW_CAPACITY ? calculateCrewCapacity(newCrews, jobsSentToSubs) : whatIfCapacity;
    saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, jobsSentToSubs, newCrews, whatIfCrews, whatIfCrewPerWeek);
  }

  /**
   * Handles a change to the planned jobs.
   * Changes state and saves change to the server.
   */
  function handlePlannedJobsChange(newPlannedJobs: number[]) {
    setPlannedJobs(newPlannedJobs);

    let body = {
      marketDatas: [
        {
          market: selectedMarket?._id,
          plannedJobs: newPlannedJobs
        }
      ]
    }
    saveAbortControllerRef.current.abort(); // Abort previous if still running
    UserManager.makeAuthenticatedRequest(`/api/forecast/planned-jobs`, "POST", body, { signal: saveAbortControllerRef.current.signal })
      .then(res => {
        if (res.data.status === "ok") {
          setPlannedJobs(res.data.marketDatas[0].plannedJobs)
        }
      }).catch(err => {
        console.error(err);
      })
  }

  return (
    <div className="mx-auto max-w-[1150px]">
      {/* Actions */}
      <div className="flex flex-wrap gap-4 py-4">

        {/* Market Select */}
        <div className="grow sm:flex-initial">
          <Dropdown
            wide
            justifyLeft
            placeholder='Select Market'
            options={[
              markets?.map(m =>
                ({ label: m.name, value: m._id })) || []
            ]}
            selectedValue={selectedMarket?._id}
            onSelected={
              option => {
                setSelectedMarket(markets?.find(m => m._id === option.value))
              }
            }
          />
        </div>

        {/* TODO: figure out if we actually want a search */}

      </div>

      <Card>
        <div className="flex flex-col gap-4">
          <H1>Work / Capacity Ratio</H1>
          <WorkCapacityRatioChart
            jobsAvailable={jobsAvailable}
            jobCapacity={jobCapacity}
            desiredRatioRange={desiredRatioRange}
            onRangeChange={(r) => {
              setDesiredRatioRange(r);

              saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, r, mode, jobsSentToSubs, crews, whatIfCrews, whatIfCrewPerWeek);
            }}
          />
          <Divider />
          <H1>Available Work</H1>
          <AvailableWork
            jobsAvailable={jobsAvailable}
            plannedJobs={plannedJobs}

            setPlannedJobs={handlePlannedJobsChange}
          />
          <Divider />
          <H1>Planned Capacity</H1>
          <PlannedCapacity
            mode={mode}
            setMode={(newMode: Modes) => {
              setMode(newMode);

              const jobCapacity = newMode === Modes.CREW_CAPACITY ? crewCapacity : whatIfCapacity;
              saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, newMode, jobsSentToSubs, crews, whatIfCrews, whatIfCrewPerWeek);
            }}

            whatIfCrews={whatIfCrews}
            whatIfCapacity={whatIfCapacity}
            whatIfCrewPerWeek={whatIfCrewPerWeek}
            jobsSentToSubs={jobsSentToSubs}
            crewCapacity={crewCapacity}
            jobCapacity={jobCapacity}

            crews={crews}
            onCrewAdd={handleCrewAdd}
            onCrewDelete={handleCrewDelete}
            onCrewChange={handleCrewChange}

            setWhatIfCrews={(value: number[]) => {
              const jobCapacity = mode === Modes.CREW_CAPACITY ? crewCapacity : whatIfCrews.map((c) => c * whatIfCrewPerWeek);
              setWhatIfCrews(value);
              saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, jobsSentToSubs, crews, value, whatIfCrewPerWeek)
            }}
            setJobsSentToSubs={(newJobs: number[]) => {
              const jobCapacity = mode === Modes.CREW_CAPACITY ? calculateCrewCapacity(crews, newJobs) : whatIfCapacity;
              setJobsSentToSubs(newJobs);
              saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, newJobs, crews, whatIfCrews, whatIfCrewPerWeek)
            }}
            setWhatIfCrewPerWeek={(value: number) => {
              const jobCapacity = mode === Modes.CREW_CAPACITY ? crewCapacity : whatIfCrews.map((c) => c * whatIfCrewPerWeek);
              setWhatIfCrewPerWeek(value);
              saveCapacityInformation(selectedMarket, jobCapacity, jobsAvailable, desiredRatioRange, mode, jobsSentToSubs, crews, whatIfCrews, value)
            }} />
        </div>
      </Card>
    </div>
  )
}

type H1Props = {
  children?: React.ReactNode
};

/**
 * Header component. Handles styling for h1 tags.
 * Used for section headers such as "Work / Capacity Ratio"
 */
function H1({ children }: H1Props) {
  return <h1 className="text-lg font-semibold">{children}</h1>
}

/**
 * Simple border divider.
 */
function Divider() {
  return <div className="border-t border-gray-400" />
}



type HXProps = {
  children?: React.ReactNode,
  className?: string
}

/**
 * A header component for styling within this page.
 * Used in line titles such as "What-If Crews"
 */
export function H2({ children, className }: HXProps): JSX.Element {
  return <h2 className={classNames(
    "text-sm font-medium flex gap-2 items-center",
    className
  )}>{children}</h2>
}

/**
 * A header component for styling within this page.
 * Not actually used in the current implementation.
 */
export function H3({ children, className }: HXProps): JSX.Element {
  return <h3 className={classNames(
    "text-xs font-normal flex gap-2 items-center",
    className
  )}>{children}</h3>
}

/**
 * Label component to handle styling for data labels in this page.
 * Handles width and alignment of data labels.
 * For example, used in "What-If Crews" label to position the header
 * and per week text.
 */
export function Label({ children }: { children?: React.ReactNode }): JSX.Element {
  return <div className="flex-shrink-0 whitespace-nowrap w-52 flex flex-col justify-around">{children}</div>
}

/**
  * Saves capacity information to the server.
  * Called when any capacity information is changed.
  */
async function saveCapacityInformation(
  market: MarketType,
  jobCapacity: number[],
  jobsAvailable: number[],
  desiredRatioRange: { min: number, max: number },
  mode: Modes,
  jobsSentToSubs: number[],
  crews: Crew[],
  whatIfCrews: number[],
  whatIfCrewPerWeek: number,
) {
  if (!market) return;

  let capacityRatios = jobCapacity.map((c, i) => {
    if (c === 0) return 0;
    return jobsAvailable[i] / c;
  });
  let capacityInformation = {
    workCapacityRatio: capacityRatios,
    desiredRatioRange: desiredRatioRange,
    jobCapacity: jobCapacity,
    jobsAvailable: jobsAvailable,
    capacityType: mode,
    jobsSentToSubs: jobsSentToSubs,
    crewCapacities: crews.map(c => ({
      crew: c.isHypothetical ? null : c._id,
      weekCapacity: c.weekCapacity,
      weekCapacityOverride: c.weekCapacityOverride,
      plannedWeeks: c.plannedWeeks
    })),
    whatIfCrews: whatIfCrews,
    whatIfCrewPerWeek: whatIfCrewPerWeek
  }
  try {
    let res = await UserManager.makeAuthenticatedRequest(`/api/forecast/crew-planner`, "POST", { market: market._id, capacityInformation });
    // TODO: do something on save?
  } catch (err) {
    console.error(err);
  }
}

/**
  * Condenses crew data down to an array of crew capacities for each week.
  */
function calculateCrewCapacity(crews: Crew[], jobsSentToSubs: number[]) {
  let crewCapacity = Array(NUM_WEEKS).fill(0);
  // Add up planned weeks for each crew
  for (let crew of crews) {
    for (let week of crew.plannedWeeks) {
      crewCapacity[week] += crew.weekCapacityOverride ?? crew.weekCapacity;
    }
  }
  // Add jobs sent to subs
  for (let i = 0; i < jobsSentToSubs.length; i++) {
    crewCapacity[i] += jobsSentToSubs[i];
  }

  return crewCapacity;
}
