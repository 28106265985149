import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

/**
  * Displays an information icon with a tooltip that appears when the icon is hovered over.
  */
export default function InfoTooltip({ text = "" }) {
  // TODO: implement better width for small text string
  return (
    <div className="group relative">
      <InformationCircleIcon className="h-5 w-5 text-gray-500 stroke-2 group-hover:text-gray-400 cursor-help" />
      <div className="hidden group-hover:flex absolute w-64 origin-top left-1/2 -translate-x-1/2 text-white bg-black bg-opacity-70 font-normal text-sm p-3 rounded z-10">
        <div className="whitespace-break-spaces">{text}</div>
      </div>
    </div>
  );
}
