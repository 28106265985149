import React, { useState, useEffect } from 'react'
import Modal from '../components/Modal'
import Button from '../components/input/Button'
import RadioCards from '../components/input/RadioCards'
import { IdentificationIcon, BuildingOffice2Icon, UserGroupIcon, ChartBarSquareIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import StepsPanel from "../components/nav/StepsPanels";
import Connect from '../components/GetStarted/Connect'
import Locations from '../components/GetStarted/Locations'
import Roles from '../components/GetStarted/Roles'
import classNames from '../tools/classNames';
import UserManager from "../tools/UserManager";
import Spinner from '../components/Spinner';
import { useNavigate } from 'react-router-dom';
import OperationsSetup from '../components/GetStarted/OperationsSetup.tsx';
import { sendInvites } from '../tools/connectionManagement';

const nextStepsDistributor = [
    {
        label: 'Connect with Installers',
        description: 'Send invites to installers to start collaborating.',
        icon: <IdentificationIcon className="h-24 text-gray-400" />,
    },
    {
        label: 'Create Locations',
        description: 'Track demand forecasts and material orders by warehouse.',
        icon: <BuildingOffice2Icon className="h-24 text-gray-400" />

    },
    {
        label: 'Manage Roles',
        description: 'Add team members and assign roles and permissions.',
        icon: <UserGroupIcon className="h-24 text-gray-400" />
    },
]

const nextStepsInstaller = [
    {
        label: 'Connect with Distributors',
        description: 'Send invites to distributors to start collaborating.',
        icon: <IdentificationIcon className="h-24 text-gray-400" />,
    },
    {
        label: 'Operations Setup',
        description: 'Define your markets and stages to create forecasts.',
        icon: <Cog6ToothIcon className="h-24 text-gray-400" />

    },
    {
        label: 'Manage Roles',
        description: 'Add team members and assign roles and permissions.',
        icon: <UserGroupIcon className="h-24 text-gray-400" />
    },
]


/**
 * Modal that guides the user through the initial setup of their team, locations, and roles
 */
export default function GetStartedPage() {
    const [user, setUser] = useState({});
    const [company, setCompany] = useState({});

    const [step, setStep] = useState(0)

    const [nextStepButtonText, setNextStepButtonText] = useState('')
    const [nextStepButtonDisabled, setNextStepButtonDisabled] = useState(false)
    const [goToToaButtonDisabled, setGoToToaButtonDisabled] = useState(false)

    // state variables for the connect step
    const [connectionRequestCompanies, setConnectionRequestCompanies] = useState([])
    const [invites, setInvites] = useState([])
    const [sendingInvites, setSendingInvites] = useState(false) //TODO: use this to show a loading spinner on the send invites button

    const [error, setError] = useState("")

    const navigate = useNavigate();

    const [subNavTabs, setSubNavTabs] = useState([
        {
            id: "1",
            name: "Connect",
            status: "current",
            icon: <IdentificationIcon className="h-24 text-gray-400" />,
        },
        {
            id: "2",
            name: "Locations",
            status: "upcoming",
            icon: <BuildingOffice2Icon className="h-24 text-gray-400" />,
        },
        {
            id: "3",
            name: "Roles",
            status: "upcoming",
            icon: <UserGroupIcon className="h-24 text-gray-400" />,
        }
    ]);


    // on user change, update the subNavTabs to reflect the user's company type
    useEffect(() => {
        if (user.company?.type === 'installer') {
            let newSubNavTabs = subNavTabs.map((tab, index) => {
                if (index === 1) {
                    return {
                        ...tab,
                        name: 'Setup',
                        icon: <Cog6ToothIcon className="h-24 text-gray-400" />
                    }
                } else {
                    return tab
                }
            })
            setSubNavTabs(newSubNavTabs)
        }
    }, [user])


    // on page load, get the user and company. If the user is an installer, change the name of step 2 to Operations Setup and change the icon to a Cog6ToothIcon
    useEffect(() => {
        UserManager.makeAuthenticatedRequest("/api/user/info", "GET")
            .then((resUser) => {
                setUser(resUser.data.user);

                // Get company
                if (resUser.data.user.company) {
                    UserManager.makeAuthenticatedRequest(
                        "/api/company/findmy",
                        "GET"
                    ).then((res) => {
                        setCompany(res.data.company);
                    });

                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    /**
     * Update step and subNavTabs to move to the next step
     */
    function nextStep() {
        let currentStepIndex = subNavTabs.findIndex(tab => tab.status === 'current')
        if (currentStepIndex === subNavTabs.length - 1) {
            return
        }
        let newSteps = subNavTabs.map((tab, index) => {
            if (index === currentStepIndex) {
                return { ...tab, status: 'complete' }
            } else if (index === currentStepIndex + 1) {
                return { ...tab, status: 'current' }
            } else {
                return tab
            }
        })
        setStep(step + 1)
        setSubNavTabs(newSteps)
    }


    /**
     * @returns - relevant JSX.Element based on the current step
     */
    function getContent() {
        switch (step) {
            case 1:
                return <Connect
                    userCompanyType={user.company?.type}
                    setNextStepButtonText={setNextStepButtonText}
                    setNextStepButtonDisabled={setNextStepButtonDisabled}
                    setGoToToaButtonDisabled={setGoToToaButtonDisabled}
                    connectionRequestCompanies={connectionRequestCompanies}
                    setConnectionRequestCompanies={setConnectionRequestCompanies}
                    invites={invites}
                    setInvites={setInvites}
                />
            case 2:
                if (user.company.type === 'distributor') {
                    return <Locations
                        setNextStepButtonText={setNextStepButtonText}
                        setNextStepButtonDisabled={setNextStepButtonDisabled}
                        setGoToToaButtonDisabled={setGoToToaButtonDisabled}
                        setError={setError}
                    />
                } else if (user.company.type === 'installer') {
                    return <OperationsSetup
                        setNextStepButtonText={setNextStepButtonText}
                        setNextStepButtonDisabled={setNextStepButtonDisabled}
                        setGoToToaButtonDisabled={setGoToToaButtonDisabled}
                    />
                }
            case 3:
                return <Roles
                    setNextStepButtonText={setNextStepButtonText}
                    setNextStepButtonDisabled={setNextStepButtonDisabled}
                    setGoToToaButtonDisabled={setGoToToaButtonDisabled}
                    setError={setError}
                    company={company}
                />
            default:
                return null
        }
    }

    var nextButton = sendingInvites ? (
        <div style={{ position: "relative", minHeight: 40 }}>
            <Spinner />
        </div>
    ) : (
        <Button
            className='mx-1'
            variant='primary-green'
            onClick={() => {
                // currently only performing an action when the next step button is clicked on step 1
                // In step 1 the invites are all sent at once when the next button is clicked, 
                // this way the user is able to see the invites being sent before they are actually sent.
                // In step 2 and 3 (Locations and Roles) the action is performed when the user updates the table
                if (step === 1) {
                    setSendingInvites(true)
                    sendInvites(connectionRequestCompanies, invites, user)
                    setSendingInvites(false)
                } else if (step === 2) {

                } else if (step === 3) {

                }
                nextStep()
            }}
            disabled={nextStepButtonDisabled}
        >
            {nextStepButtonText}
        </Button>
    );

    if (step === 0) {
        return (
            <>
                <Modal
                    open={true}
                    setOpen={() => { }}
                    opacityClassNames='bg-gray-300 bg-opacity-75'
                    wide
                    transparent
                >
                    <div className='pb-6'>
                        <div className='my-2 px-2 py-2.5 align-middle text-center text-gray-900 text-4xl leading-10 font-semibold'>
                            Let's get you setup!
                        </div>
                    </div>
                    <div className='px-2 pb-3 my-2 text-xl font-medium leading-7 text-center text-gray-900 align-middle'>
                        Here is what we will walk you through
                    </div>
                    <RadioCards
                        cards={
                            user.company?.type === 'distributor'
                                ? nextStepsDistributor
                                : nextStepsInstaller
                        }
                        checkable={false}
                    />
                    <div className='my-4'>
                        <div className='my-2.5 py-6 flex justify-center'>
                            <Button type="submit"
                                onClick={() => {
                                    setStep(1)
                                }}
                                className='px-3 mx-2 rounded-md cursor-pointer grow max-w-64'
                                variant='primary-green'
                            >
                                Start
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        )
    } else {
        return (
            <div className=''>
                <Modal
                    open={true}
                    setOpen={() => { }}
                    opacityClassNames='bg-gray-300 bg-opacity-75'
                    wide
                >
                    <div className='w-[1200px] h-[84vh] flex flex-col min-h-[750px]'>
                        <div className='flex flex-col bg-white grow'>
                            <div className='lg:w-[760px] mx-auto'>
                                <StepsPanel steps={subNavTabs} />
                            </div>
                            <div className='flex flex-col items-center justify-start grow'>
                                {getContent()}
                            </div>
                        </div>
                        <div className='flex flex-row justify-end w-full pt-4'>
                            {/* error message justify left*/}
                            {error ? (
                                <div className='flex items-center justify-end mx-4 text-sm font-semibold leading-5 text-center text-red-600 align-middle grow'>
                                    {error}
                                </div>
                            ) : null}

                            {/* next button */}
                            {subNavTabs[step] ? (
                                nextButton
                            ) : null}

                            {/* go to toa button */}
                            <Button
                                className='mx-1'
                                variant={subNavTabs[step] ? 'secondary' : 'primary-green'}
                                disabled={goToToaButtonDisabled}
                                onClick={() => {
                                    navigate("/app")
                                }}
                            >
                                Go to TOA
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}


