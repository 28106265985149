import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import { Order } from "./useOrders";

/**
 * Simple hook to get a single order by ID
 * Returns null while order is loading
 */
export default function useOrder(id: string): Order | null {
  const [order, setOrder] = useState<Order | null>(null);
  useEffect(() => {
    UserManager.makeAuthenticatedRequest(`/api/orders/get?orderId=${id}`)
      .then(res => {
        if (res.data?.status === "ok") {
          setOrder(res.data.order);
        }
      }).catch(err => {
        console.error(err);
      });
  }, [id]);

  return order;
}
