import UserManager from "./UserManager"

/*
* Helper function to structure row data for the InputTable
*/
export function structureRowData({ id, truckName, rowStatus, menuOptions }) {
    return {
        id: id,
        truckName: {
            value: truckName,
            className: ''
        },
        rowStatus: rowStatus,
        menuOptions: menuOptions
    }
}

// Table columns for the InputTable
export async function tableColumns() {
    const columns = [
        {
            Header: "Truck Name",
            accessor: "truckName",
            placeholder: "Truck Name",
            required: false,
            primary: true,
            columnWidth: 3,
            editable: false,
            editType: null,
            validate: (value) => { return true },
        },
    ]

    return columns
}

/**
 * Get existing trucks for the distributor
 */
export async function getTrucks() {
    const trucks = await UserManager.makeAuthenticatedRequest(
        '/api/trucks/list',
        'GET'
    )

    if (trucks.data.status !== "ok") {
        return null
    } else {
        return trucks.data.trucks
    }
}

/**
 * Sort trucks by name
 */
export async function sortTrucks(trucks) {
    return trucks.sort((a, b) => (a.truckName > b.truckName) ? 1 : -1)
}

/**
 * Add new truck to the distributor
 */
export async function addNewTruck(truckName) {
    const newTruck = await UserManager.makeAuthenticatedRequest(
        '/api/trucks/add',
        'POST',
        {
            truckName: truckName
        }
    )

    console.log("newTruck", newTruck)

    if (newTruck.data.status !== "ok") {
        return null
    } else {
        return newTruck.data.truck
    }
}

/**
 * Edit truck name
 */
export async function editTruck(data, setError) {
    const response = await UserManager.makeAuthenticatedRequest(
        '/api/trucks/edit',
        'POST',
        {
            truckId: data.id,
            truckName: data.truckName.value
        }
    )

    if (response.data.status === "ok") {
        return {
            id: response.data.truck._id,
            newValue: response.data.truck.name,
            column: 'truckName'
        }
    } else {
        setError("Error editing truck")
    }
}

/**
 * Delete truck
 */
export async function deleteTruck(data, setError, setTrucks) {
    const response = await UserManager.makeAuthenticatedRequest(
        '/api/trucks/delete',
        'DELETE',
        {
            truckId: data.id
        }
    )

    if (response.data.status === "ok") {
        setTrucks((prevTrucks) => {
            return prevTrucks.filter((truck) => truck.id !== data.id)
        })
    }
}