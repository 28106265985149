import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode;
  defaultCase?: React.ReactNode;
  test: any;
}

/**
 * Conditionally renders one of the children based on the test prop.
 * Cases are defined by the `data-case` attribute on the child elements.
 * If no default, returns null.
 *
 * Example: 
 * ```tsx
 * <SwitchCase test={1}>
 *   <div data-case={0}>Won't render</div>
 *   <div data-case={1}>Will render</div>
 *   <div data-case={2}>Won't render</div>
 * </SwitchCase>
 * ```
 *
 * Ref: https://stackoverflow.com/questions/46592833/how-to-use-switch-statement-inside-a-react-component
 */
export default function SwitchCase({ children, test, defaultCase = null }: Props): JSX.Element {
  let caseElem = defaultCase;

  // Find matching case
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.props["data-case"] === test) {
      caseElem = child;
    }
  });

  return caseElem;
}
