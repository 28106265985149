import { PaperClipIcon } from '@heroicons/react/24/outline';
import React from 'react';
import StringHelper from 'src/utils/stringHelper.ts';

type Props = {
  filename: string;
  label: string;
  link: string;
  truncateAt?: number;
}

/**
 * Component to display a file reference.
 * Displays a paper clip icon, file name, and file link
 */
export default function FileDisplay({
  filename,
  label,
  link,
  truncateAt = 16
}: Props) {
  return <div className="flex items-center gap-3">
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full">
        <PaperClipIcon className="w-5 h-5 text-white" />
      </div>
      <p className="text-sm font-semibold text-gray-600">
        {label}
      </p>
    </div>
    <div className="text-sm font-medium text-gray-500">
      {StringHelper.truncate(filename, truncateAt)}
      {" "}
      <a
        className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700"
        href={link}
        target="_blank"
      >
        View
      </a>
    </div>
  </div>

}
