import React, { useContext, useState } from "react";
import Card from "src/components/Card";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import JobPipelineContext from "src/contexts/pipeline/jobs/JobPipelineContext";

import pluralize from "pluralize";
import UserManager from "src/tools/UserManager";
import { useEffect } from "react";

import moment from "moment";
import MiniGrid from "../../../tables/MiniGrid.tsx";
import Row, { MarketStatType } from "./RowsView/Row.tsx";
import { groupWeeksByMonths } from "src/tools/Forecast/WeekToMonth.ts";

type Props = {
  compact?: boolean;
  // companyType options are "installer" or "distributor", default is "installer"
  companyType?: "installer" | "distributor";
  installerId?: string;
};

type OutletContextType = {
  selectedMarketIds: string[];
  setSelectedMarketId: (ids: string[]) => void;
  markets: MarketType[];
  stages: { _id: string; name: string, order: number }[];
  selectedStageIndex: number;
  search?: string;
  setViewState: (state: string) => void;
};

/**
 * Displays a row of markets with stats. This is used in the pipeline page.
 * Shows sold by month, installs by month (with forecast), cycle and conversion rate.
 * If compact, displays in a line rather than in charts.
 */
export default function RowsView({
  compact = false,
  companyType = "installer",
  installerId = null,
}: Props): JSX.Element {
  const outletContextValue = useContext<OutletContextType>(JobPipelineContext);

  let { selectedMarketIds, setSelectedMarketId, markets, stages, selectedStageIndex, search, setViewState } = outletContextValue;
  let selectedStageId = stages && stages[selectedStageIndex]?._id;

  const multiMarketStats = usePipelineMultipleMarketStats(companyType, installerId, selectedMarketIds, search, selectedStageId)

  // Get selected markets. Equivalent to not "combined"
  let selectedMarkets = markets?.filter((market) =>
    selectedMarketIds.includes(market._id)
  );
  // Sort by active pipeline count descending
  if (multiMarketStats) {
    selectedMarkets.sort((a, b) => {
      let aStats = multiMarketStats[a._id];
      let bStats = multiMarketStats[b._id];
      if (!aStats || !bStats) return 0;

      return bStats.activePipelineCount - aStats.activePipelineCount;
    })
  }

  // No Selection Message
  if (selectedMarketIds.length === 0) {
    return (
      <div className="flex flex-col gap-4 px-4">
        <div className="mt-40 text-center text-gray-500">No markets selected</div>
      </div>
    );
  }

  function goToMarket(marketId: string) {
    setSelectedMarketId([marketId]);
    setViewState("grid");
  }

  // TODO: dark mode
  return (
    <div className="flex flex-col gap-4 px-4">
      {/* Combined */}
      <Card>
        <div className="-mx-4">
          <Row
            market={{
              name: `Total - ${pluralize(
                "Market",
                selectedMarketIds.length,
                true
              )}`,
              _id: null,
            }}
            marketStats={multiMarketStats && multiMarketStats["combined"]}
            goToMarket={() => { }}
            companyType={companyType}
          />
        </div>
      </Card>

      <div className="border-t border-gray-400"></div>

      {/* Individual */}
      {
        compact ? (
          // Compact view
          <div className="rounded bg-gray-50">
            {/* Header */}
            <div className="grid grid-cols-10 text-sm font-semibold bg-white border-b border-gray-300 divide-x divide-gray-400 rounded-t">
              <div className="flex items-center col-span-2 px-3 py-2">Market Summary</div>
              <div className="flex items-center col-span-3 px-3 py-2">Sold Jobs</div>
              <div className="flex items-center col-span-3 px-3 py-2">Installs</div>
              <div className="flex items-center justify-center col-span-1 px-3 py-2 text-center">Forecast</div>
              <div className="flex items-center justify-center col-span-1 px-3 py-2 text-center">Pipeline Metrics</div>
            </div>

            {/* Months */}
            <div className="grid grid-cols-10 py-2 text-sm font-medium border-b border-gray-400 divide-x divide-gray-400 rounded-t">
              <div className="flex items-center col-span-2 px-3">Active Pipeline</div>
              <div className="col-span-3 px-2.5 flex items-center">
                <MonthsGrid count={12} start={moment().subtract(11, "months").toDate()} />
              </div>
              <div className="flex items-center col-span-3 pl-5">
                <MonthsGrid count={12} start={moment().subtract(11, "months").toDate()} />
              </div>
              <div className="flex items-center justify-center col-span-1 pr-3 text-center">
                <MonthsGrid count={4} start={moment().add(1, "months").toDate()} />
              </div>
              <div className="grid items-center grid-cols-2 col-span-1 px-3 text-center">
                <div>Conv</div>
                <div>Cycle</div>
              </div>
            </div>

            {/* Rows */}
            <div className="flex flex-col py-1">{selectedMarkets?.map((market) => (
              <Row
                key={market._id}
                compact
                market={market}
                marketStats={multiMarketStats && multiMarketStats[market._id]}
                goToMarket={goToMarket}
                companyType={companyType}
              />
            ))}</div>
          </div>
        ) : (
          // Full view
          selectedMarkets?.map((market) => (
            <Card>
              <div className="-mx-5">
                <Row
                  key={market._id}
                  market={market}
                  marketStats={multiMarketStats && multiMarketStats[market._id]}
                  goToMarket={goToMarket}
                  companyType={companyType}
                />
              </div>
            </Card>
          )))}
    </div>
  );
}


type MultiMarketStatsType = {
  [marketId: string]: MarketStatType;
};

/**
  * Given a list of market ids, gets stats for each market. Can be filtered by search and stage.
  * This gets installs by month, sold by month, forecast, cycle time, and conversion rate.
  */
function usePipelineMultipleMarketStats(
  companyType: string,
  installerId: string,
  selectedMarketIds: string[],
  search?: string,
  stage?: string
): MultiMarketStatsType {
  const [stats, setStats] = useState<MultiMarketStatsType>(null);

  // Make request to get stats
  useEffect(() => {
    setStats(null);
    let url = `/api/pipeline/${companyType}/multiple-market-stats`;
    url += `?markets=${selectedMarketIds.join(",")}`;
    if (companyType === "distributor") url += `&installerId=${installerId}`;
    if (search) url += `&search=${encodeURIComponent(search)}`;
    if (stage) url += `&stage=${encodeURIComponent(stage)}`;

    UserManager.makeAuthenticatedRequest(url, "GET")
      .then((res) => {
        if (res.data.status === "ok") {
          setStats(res.data.data);

          // Get forecast by month
          for (let marketId in res.data.data) {
            let marketStats = res.data.data[marketId];
            let forecast = marketStats.forecast;
            if (forecast) {
              forecast.toaForecastByMonth = groupWeeksByMonths(forecast.toaForecast)
              forecast.inputForecastByMonth = groupWeeksByMonths(forecast.inputForecast)
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setStats(null);
      });
  }, [selectedMarketIds, search, stage]);

  return stats;
}


/**
  * Displays a mini grid of months from the start date. Useful for headers.
  */
function MonthsGrid({ start, count }: { start: Date, count: number }) {
  let data = Array.from({ length: count }, (_, i) => moment(start).add(i, "months").toDate())

  return <MiniGrid data={data} />
}
