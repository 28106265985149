/**
  * String helper class. Contains methods for string manipulation.
  * For example, converting to `Phrase case`.
  */
export default class StringHelper {
  /**
   * Turns a string into `Phrase case` where the first letter of
   * the first word is capitalized but everything else is lowercase.
   * Helpful for cases where you want to display a string in a more
   * user-friendly way. For example, converting a database field
   * like "ORDER_CREATED" to "Order created".
   *
   * Reference: https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
   *
   * TODO: test and improve
   */
  static toPhraseCase(str: string): string {
    let result = str.toLowerCase(); // Lowercase everything
    result = result.replace(/(\w)[-_](\w)/g, "$1 $2"); // Replace - or _ with space if within word
    result = result.charAt(0).toUpperCase() + result.slice(1); // Uppercase first letter
    return result;
  }

  /**
   * Turns a string into lowercase. Replaces - and _ with spaces.
   * Helpful for things like "ORDER_CREATED" to "order created".
   */
  static toLowerCase(str: string): string {
    let result = str.toLowerCase(); // Lowercase everything
    result = result.replace(/(\w)[-_](\w)/g, "$1 $2"); // Replace - or _ with space if within word
    return result;
  }

  /**
    * Truncates a string to a certain length.
    * Adds an ellipsis if the string is longer than the length.
    */
  static truncate(str: string, length: number): string {
    if (str.length <= length) return str;
    return str.slice(0, length - 3) + "...";
  }
}
