/**
 * Given an array of forecasted values and acutal values, returns the error.
 * A perfect forecast will return 0. 
 *
 * Note that this returns the error value as is (i.e. not as a percent).
 * To display as a percent do something like: 
 * ```ts
 * console.log(Math.round(calculateForecastError(dataPoints) * 100) + '%');
 * ````
 */
export default function calculateForecastError(dataPoints: {
  actual: number;
  forecast: number;
}[]): number {
  // use avg((forecast - actual) / actual) 
  let sum = 0;
  let denominator = 0;
  dataPoints.forEach((data) => {
    denominator++;
    if (data.actual === 0) return;
    sum += (data.forecast - data.actual) / data.actual;
  });
  const avg = sum / denominator;

  return avg;
}

/**
 * Given an array of forecasted values and acutal values, returns the error of the actuals
 * compared to the forecast. This is the opposite of `calculateForecastError`.
 * Actuals that align with the forecast will return 0.
 *
 * Note that this returns the error value as is (i.e. not as a percent).
 * To display as a percent do something like: 
 * ```ts
 * console.log(Math.round(calculateForecastError(dataPoints) * 100) + '%');
 * ````
 */
export function calculateActualAccuracy(dataPoints: {
  actual: number;
  forecast: number;
}[]): number {
  return calculateForecastError(dataPoints.map(d =>
    ({ actual: d.forecast, forecast: d.actual })
  ));
}
