import { ArrowTopRightOnSquareIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/20/solid'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext';
import calculateForecastError, { calculateActualAccuracy } from 'src/tools/Forecast/calculateForecastError.ts';
import classNames from 'src/tools/classNames';
import MathHelper from 'src/utils/mathHelper';
import NumberGrid, { Colors as NumberGridColors, Row as NumberGridRow } from 'src/components/tables/NumberGrid.tsx';
import ArrayHelper from 'src/utils/arrayHelpers.ts';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ActualVsForecastChart from 'src/components/ActualVsForecastChart';
import ToaBarChart from 'src/components/BarChart';
import generateAvailableAndRemainder from 'src/tools/Forecast/GenerateAvailableAndRemainder.ts';
import UserManager from 'src/tools/UserManager';
import debounce from 'src/tools/debounce';

type Props = {
  market: MarketType;
  marketData: MarketData;

  isExpanded?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  preventExpandCollapse?: boolean;

  isEditable?: boolean;

  onPlannedJobsChange?: (plannedJobs: number[]) => void;
}

const sixWeeksAgo = moment().utc().startOf("week").subtract(6, "weeks").toDate();
const historicalWeekLabels: string[] = ArrayHelper.generateNDatesFormatted(6,
  "weeks",
  sixWeeksAgo,
  "M/D",
  { useUTC: true }
);
const forecastWeekLabels: string[] = ArrayHelper.generateNDatesFormatted(16,
  "weeks",
  moment().utc().startOf("week").toDate(),
  "M/D",
  { useUTC: true, } // Ignore timezone and just use date 
);

const CELL_WIDTH = 50;

type RowContextType = {
  market: MarketType | null;
  marketData: MarketData | null;

  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
  preventExpandCollapse: boolean

  historicalWeekLabels: string[];
  forecastWeekLabels: string[];

  isEditable?: boolean;

  installsAvailable: number[];
  plannedJobs: number[];
  setPlannedJobs: (plannedJobs: number[]) => void;
}
const RowContext = createContext<RowContextType>({
  market: null,
  marketData: null,

  isExpanded: false,
  setExpanded: () => { },
  preventExpandCollapse: false,

  historicalWeekLabels,
  forecastWeekLabels,

  isEditable: true,

  installsAvailable: Array(16).fill(0),
  plannedJobs: Array(16).fill(0),
  setPlannedJobs: () => { },

});

export type MarketData = {
  stats: {
    conversionRate: number,
    cycleTime: number,
  },
  forecast: {
    plannedJobs: number[],
    installsAvailable: number[],
    estimatedJobs: number[],
  },
  historical: {
    actual: number[],
    forecast: number[],
  },
}


/**
 * Displays a row for a market on the Market Forecasts page.
 * Can expand/collapse to show more less data (cycle time, conversion rate, and charts).
 * Has a grid for historical and forecast data.
 */
export default function Row({
  market,
  marketData,

  isExpanded: isExpanded_prop = false,
  onExpand = () => { },
  onCollapse = () => { },
  preventExpandCollapse = false,

  isEditable = true,

  onPlannedJobsChange = () => { },
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpanded_prop);
  const [plannedJobs, setPlannedJobs] = useState<number[]>(marketData?.forecast.plannedJobs);

  useEffect(() => {
    setPlannedJobs(marketData?.forecast.plannedJobs);
  }, [(marketData?.forecast.plannedJobs ?? []).join(",")]); // Compare planned jobs by value not reference // TODO: useMemo on marketDatas?

  // Skeleton while loading
  if (!market || !marketData || !marketData.forecast.estimatedJobs || !plannedJobs) {
    return <div className="py-2"><Skeleton width={1592} height={160} /></div>
  }

  const installsAvailable = generateAvailableAndRemainder(plannedJobs, marketData.forecast.estimatedJobs).available;

  const contextValue: RowContextType = {
    isExpanded,
    setExpanded: (v: boolean) => {
      if (v !== isExpanded) {
        if (v) {
          onExpand()
        } else {
          onCollapse()
        }
        setIsExpanded(v)
      }
    },
    preventExpandCollapse,

    market,

    historicalWeekLabels,
    forecastWeekLabels,

    isEditable,

    marketData,

    installsAvailable,
    plannedJobs,
    setPlannedJobs: (v: number[]) => {
      onPlannedJobsChange(v);
      setPlannedJobs(v);
    },
  }

  // TODO: handle CSS better. constant widths are not ideal but CSS is a nightmare
  return <RowContext.Provider value={contextValue}>
    <div className={
      classNames(
        "w-[1592px]",
        isExpanded ? "bg-gray-50" : "",
      )}>
      {isExpanded ? <ExpandedRow /> : <CollapsedRow />}
    </div>
  </RowContext.Provider>
}

/**
 * Displays the markets data in a collapsed state.
 * In this state, it displays grids for historical
 * and forecast data along with the forecast error.
 */
function CollapsedRow() {

  return <>
    <div className='px-4 border-t border-gray-400'>
      <div className="mb-4 mt-2">
        <div className="grid grid-rows-1 gap-1">
          {/* <div className="grid grid-cols-[repeat(13,minmax(0,1fr))] grid-rows-1 gap-1"> */}
          <RowHeader />
          <HistoricalGrid />
          <ForecastGrid />
        </div>
      </div>
    </div>
  </>
}

/**
 * Displays the markets data in a expanded state.
 * In this state, it displays everything the collapsed
 * state displays along with a few more things:
 *
 * Expanded Only:
 * - Conversion rate and cycle time
 * - Actual vs Forecast chart
 * - Planned jobs and installs available chart
 * Shared with Collapsed:
 * - Forecast error
 * - Historical data grid
 * - Forecast data grid
 */
function ExpandedRow() {

  const {
    marketData,
    installsAvailable
  } = useContext<RowContextType>(RowContext);

  const vsData = ArrayHelper.mergeArrays(
    ["actual", "forecast"],
    marketData?.historical.actual || [],
    marketData?.historical.forecast || [],
  );

  const forecastData = ArrayHelper.mergeArrays(
    ["plannedJobs", "installsAvailable"],
    marketData?.forecast.plannedJobs || [],
    installsAvailable || [],
  );
  const barChartData = {
    height: "100%",
    // minHeight: 170,
    data: forecastData,
    dataKeys: ["plannedJobs", "installsAvailable"],
    colors: ["fill-gray-300", "fill-gray-500"],
    labelLists: [false, true],

    xAxisSettings: {
      // stroke: "#6b7280",
      // axisLine: false,
      // tickLine: false,
      // dataKey: "week",
      hide: true,
    },
    yAxisSettings: {
      hide: true,
    },

    layering: "bullet",
    hideTooltip: true,
  };

  return <>
    <div className='px-4 border-t border-gray-400'>
      <div className="mb-4 mt-2">
        {/* <div className="grid grid-cols-[repeat(13,minmax(0,1fr))] grid-rows-3 gap-1"> */}
        <div className="grid grid-rows-3 gap-1">
          <RowHeader />
          <div className="row-span-2 col-span-4 col-start-3 flex justify-end">
            <div className="pr-5 mt-6"
              style={{
                width: CELL_WIDTH * historicalWeekLabels.length,
              }}
            >
              <ActualVsForecastChart
                data={vsData}
                height={180}
              />
            </div>
          </div>
          <div className="row-span-2 col-span-7 col-start-8 flex justify-end pr-6">
            <div
              style={{ width: CELL_WIDTH * forecastWeekLabels.length }}
              className="h-full"
            >
              <ToaBarChart
                {...barChartData}
              />
            </div>
          </div>
          <HistoricalGrid />
          <ForecastGrid />
        </div>
      </div>
    </div >
  </>
}

/**
 * Displays the market name and expand/collapse button.
 * Also shows the forecast error.
 * When expanded, also shows the conversion rate and cycle time.
 */
function RowHeader({ }) {

  const {
    market,
    isExpanded,
    setExpanded,
    preventExpandCollapse,
  } = useContext<RowContextType>(RowContext);

  const handleExpand = () => { setExpanded(true) }
  const handleCollapse = () => { setExpanded(false) }


  let expandCollapseBtn = <></>
  if (!preventExpandCollapse) {
    expandCollapseBtn = isExpanded ? (
      <button
        className="my-1"
        onClick={handleCollapse}
      >
        <ArrowsPointingInIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" />
      </button>
    )
      : (
        <button
          className="my-1"
          onClick={handleExpand}
        >
          <ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" />
        </button >)
  }


  return <div className={classNames(
    "col-span-2 col-start-1 col-end-2 w-44",
    isExpanded ? "row-span-3" : "row-span-1",
    "flex flex-col"
  )}>
    <div className="flex gap-3 items-center pt-4">
      {expandCollapseBtn}
      {
        <h1 className="text-base font-semibold leading-6">{market?.name}</h1>
      }
    </div>
    {
      isExpanded &&
      <>
        <div className="grow"></div>
        <ConvCycleStat />
        <div className="pb-6"> </div>
      </>
    }
    <ForecastError />
  </div >
}

/**
 * Displays a grid of historical data.
 * Shows actual jobs/installs and planned jobs.
 */
function HistoricalGrid({ }) {

  const { historicalWeekLabels, marketData } = useContext<RowContextType>(RowContext);

  const historicalActual = marketData?.historical.actual || [];


  const numColumns = 6;
  const rows: NumberGridRow[] = [
    {
      values: marketData?.historical.actual || [],
      border: NumberGridColors.GREEN + " border-2",
    },
    {
      values: marketData?.historical.forecast || [],
    }
  ];

  return <div
    className={classNames(
      "col-span-4 col-start-3 col-end-7",
      "flex gap-2"
    )}>

    <div className="text-sm leading-5 font-medium whitespace-nowrap flex flex-col justify-around self-end h-[80px] text-right pl-6">
      <p>Actual Jobs</p>
      <p>Planned Jobs</p>
    </div>
    <div className="flex flex-col">
      <div className='flex justify-around items-center'>
        {historicalWeekLabels.map((label, i) =>
          <div
            key={i}
            className='text-xs font-normal leading-4 text-center text-gray-500 align-middle basis-[calc(100%/6)] py-1'
          >
            {label}
          </div>
        )}
      </div>
      <div
        style={{ width: CELL_WIDTH * numColumns }}
      >
        <NumberGrid
          rows={rows}
          numColumns={numColumns}
        />
      </div>
    </div>
  </div>
}

/**
 * Displays a grid of data for the forecast.
 * Shows planned jobs and installs available.
 */
function ForecastGrid({ }) {
  const { plannedJobs, setPlannedJobs, forecastWeekLabels, isEditable, market, marketData, installsAvailable } = useContext<RowContextType>(RowContext);

  const numColumns = 16;

  const rows: NumberGridRow[] = [
    {
      values: plannedJobs,
      border: NumberGridColors.GREEN + " border-2",
      isEditable: isEditable
    },
    {
      values: installsAvailable || [],
    }
  ];

  const linkTo = `../crew-planner?market=${market._id}`

  return <div
    className={classNames(
      "col-span-7 col-start-8",
      "flex gap-2"
    )}>
    <div className="text-sm leading-5 font-medium whitespace-nowrap flex flex-col justify-around self-end h-[80px] text-right pl-6">
      <p>
        Planned Jobs
        {
          isEditable && <Link to={linkTo} className="pl-3">
            <ArrowTopRightOnSquareIcon className="w-5 h-5 text-primary-green hover:text-primary-green-600 inline" />
          </Link>
        }
      </p>
      <p>Installs Available</p>
    </div>
    <div className="flex flex-col">
      <div className='flex justify-around items-center'>
        {forecastWeekLabels.map((label, i) =>
          <div
            key={i}
            className='text-xs font-normal leading-4 text-center text-gray-500 align-middle basis-[calc(100%/16)] py-1'
          >
            {label}
          </div>
        )}
      </div>
      <div
        style={{ width: CELL_WIDTH * numColumns }}
      >
        <NumberGrid
          rows={rows}
          numColumns={numColumns}
          onChange={
            (row, col, value) => {
              let newPlannedJobs = [...plannedJobs];
              newPlannedJobs[col] = value;
              setPlannedJobs(newPlannedJobs);
              debounceSavePlannedJobs(newPlannedJobs, market);
            }
          }
        />
      </div>
    </div>
  </div>
}

/**
 * Displays the forecast error for the market.
 */
function ForecastError({ }) {

  const { marketData } = useContext<RowContextType>(RowContext);

  // TODO: figure out if we want to keep forecast error vs sales accuracy.
  // I want to do forecast error but Shane thinks sales accuracy is better
  // since it might be easier for some people to understand
  // Consider swapping back to `calculateForecastError`
  const error = marketData ? calculateActualAccuracy(
    ArrayHelper.mergeArrays(
      ["actual", "forecast"],
      marketData.historical.actual,
      marketData.historical.forecast ?? Array(marketData.historical.actual.length).fill(0), // TODO: why is this null sometimes? set to 6 instead of `actual` length?
    ) as { actual: number, forecast: number }[],
  ) : null;
  let errorSign = "";
  if (error && error > 0) errorSign = "+";
  const errorStr = `${errorSign}${error ? Math.round(error * 100) : "--"}%`;

  return <div className='flex flex-col items-center self-end justify-center w-32 bg-white border border-gray-300 rounded py-2'>
    {/* <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Forecast Error</div> */}
    <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Actual Accuracy</div>
    <div className='text-base font-semibold leading-6 text-center align-middle'>
      {errorStr}
    </div>
  </div>
}

/**
  * Displays the conversion rate and cycle time for the market
  */
function ConvCycleStat({ }) {

  const { marketData } = useContext<RowContextType>(RowContext);

  const conv = marketData?.stats.conversionRate;
  const cycle = marketData?.stats.cycleTime;

  return <div className='flex flex-col items-center self-end justify-center w-32 bg-white border border-gray-300 rounded py-2'>
    <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Conv</div>
    <div className='text-base font-semibold leading-6 text-center align-middle'>
      {conv ? `${Math.round(conv * 100)}%` : "--"}
    </div>
    <div className='text-xs font-semibold leading-4 text-center text-gray-400 align-middle'>Cycle</div>
    <div className='text-base font-semibold leading-6 text-center align-middle'>
      {cycle ? `${Math.round(cycle)}d` : "--"}
    </div>
  </div>
}
/**
  * Displays a skeleton loading indicator.
  */
const Skeleton = ({ width = "100%", height = "100%" }: {
  width?: number | string, height?: number | string
}) => <div className="bg-gray-300 rounded-lg animate-pulse" style={{ height, width }} />


/**
  * Saves the given list of planned jobs to the given market in the DB.
  */
function savePlannedJobs(newPlannedJobs: number[], market: MarketType) {
  UserManager.makeAuthenticatedRequest('/api/forecast/planned-jobs', "POST", {
    marketDatas: [
      {
        market: market._id,
        plannedJobs: newPlannedJobs
      }
    ]
  }).then((res) => {
    // Planned jobs save!
    // Maybe do something here? Notify user or reset state
    // State reset might be good if it fails
  }).catch((err) => {
    console.error(err)
  });
}
const debounceSavePlannedJobs = debounce(savePlannedJobs, 500)
