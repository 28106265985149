import { useEffect, useState } from "react";
import { User } from "./useUsersByIds";
import UserManager from "src/tools/UserManager";

/**
 * Returns the current user info.
 * Helpful for determining the current user's permissions.
 * For example, you can get the user's company type to
 * determine which views to show.
 *
 * Returns null if loading.
 * Returns false if not logged in.
 */
export default function useCurrentUser() {
  const [user, setUser] = useState<User | null | false>(null);

  // Get user info on load
  useEffect(() => {
    UserManager.getUser()
      .then((userWrapper: { loggedIn: boolean, userData: User }) => {
        setUser(userWrapper.loggedIn ? userWrapper.userData : false);
      }).catch(err => {
        console.error(err);
      });
  }, []);

  return user;
}
