import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Button from "../Button";

type EditableProps = {
  states: {
    base: React.ReactNode;
    editing: React.ReactNode;
  };
  onSave: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

/**
  * Simple component for handling editable fields.
  * Handles the editing and save/cancel buttons.
  * Does not handle any input, this just displays the states.
  *
  * If editing, displays `states.editing`.
  * Otherwise, displays `states.base`.
  *
  * `disabled` will hide the edit button and only show `states.base`.
  * This seems like it defeats the purpose of the component,
  * which it kind of does, but it is useful for when you want to
  * conditionally edit something. For example, on `OrderDetailsPage`,
  * only an Installer can edit the PO number.
  */
export default function Editable({ states, onSave, onCancel, disabled = false }: EditableProps) {
  const [editing, setEditing] = useState<boolean>(false);

  const state = editing && !disabled ? states.editing : states.base;

  return <div
    className="flex gap-1 items-center"
  >
    <div>
      {state}
    </div>

    {
      editing ?
        (
          <>
            <Button
              className="ml-auto"
              variant="primary"
              onClick={() => {
                onSave?.()
                setEditing(false)
              }}
            >
              Save
            </Button>
            <button
              type="button"
              onClick={() => {
                onCancel?.()
                setEditing(false)
              }}
            >
              <XMarkIcon
                className="w-5 h-5 text-primary-rose hover:text-primary-rose-800 cursor-pointer"
              />
            </button>
          </>
        )
        : (
          !disabled &&
          <button
            className="ml-auto"
            type="button"
            onClick={() => setEditing(true)}
          >
            <PencilSquareIcon
              className="w-5 h-5 text-gray-500 hover:text-gray-700 cursor-pointer"
            />
          </button>
        )
    }

  </div>

}
