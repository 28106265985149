import { Popover } from '@headlessui/react'
import moment from 'moment'
import React from 'react'
import Card from 'src/components/Card'
import { Order } from 'src/hooks/data/orders/useOrders'
import { AgreementBubble, AgreementTypes, getAgreementStatus } from '../OrderAgreementIcons.tsx'
import { OrderStatusPopover } from './SimpleOrderStatuses.tsx'

type Props = {
  orders: Order[]
}

/**
 * Displays the orders' installation dates.
 * If one order, just displays the date.
 * If more, displays "Multiple Orders" with a popover of the installation dates.
 */
export default function SimpleOrderInstallDates({ orders }: Props) {
  if (!orders.length) return "--"

  return (
    orders.length > 1 ?
      <OrderStatusPopover orders={orders} />
      : orders[0].installationDate ? moment(orders[0].installationDate).format("M/D/Y") : "--"
  )
}
