import { CurrencyDollarIcon, TableCellsIcon } from '@heroicons/react/24/outline'
import { CalendarIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { Agreement, AgreementStatus, Order } from 'src/hooks/data/orders/useOrders.ts'
import classNames from 'src/tools/classNames'

type Props = {
  order: Order;
  small?: boolean;
}

/**
 * Icons for showing an orders agreement status breakdown.
 * Shows delivery date, material, and amount agreement statuses.
 *
 * `small` hides icons and instead is just small dots.
 */
export default function OrderAgreementIcons({ order, small = false }: Props) {
  const deliveryDateStatus = getAgreementStatus(order.quote?.agreements?.deliveryDate);
  const materialStatus = getAgreementStatus(order.quote?.agreements?.material);
  const amountStatus = getAgreementStatus(order.quote?.agreements?.amount);

  return !small ? (
    <div className="flex gap-3">
      <AgreementBubble status={deliveryDateStatus} icon={AgreementTypes.DELIVERY_DATE} />
      <AgreementBubble status={materialStatus} icon={AgreementTypes.MATERIAL} />
      <AgreementBubble status={amountStatus} icon={AgreementTypes.AMOUNT} />
    </div>
  ) : (
    <div className="flex gap-2">
      <AgreementBubble status={deliveryDateStatus} small icon={AgreementTypes.DELIVERY_DATE} />
      <AgreementBubble status={materialStatus} small icon={AgreementTypes.MATERIAL} />
      <AgreementBubble status={amountStatus} small icon={AgreementTypes.AMOUNT} />
    </div>
  )
}

export enum AgreementTypes {
  DELIVERY_DATE,
  MATERIAL,
  AMOUNT,
}

type BubbleProps = {
  status: AgreementStatus | null;
  icon: AgreementTypes;
  small?: boolean;
}

/**
 * The individual bubble for an agreement status.
 * If not `small`, shows the icon and color.
 * If `small`, just shows the color and is smaller.
 */
export function AgreementBubble({ status, icon, small = false }: BubbleProps) {

  switch (status) {
    case AgreementStatus.ACCEPTED:
      var color = "bg-primary-green-100 text-primary-green";
      break;
    case AgreementStatus.REJECTED:
      var color = "bg-primary-rose-200 text-primary-rose";
      break;
    case AgreementStatus.PENDING:
      var color = "bg-orange-100 text-secondary-orange";
      break;
    default:
      var color = "bg-gray-100 text-gray-600";
      break;
  }

  let iconMap = {
    [AgreementTypes.DELIVERY_DATE]: CalendarIcon,
    [AgreementTypes.MATERIAL]: TableCellsIcon,
    [AgreementTypes.AMOUNT]: CurrencyDollarIcon,
  }

  let IconElem = iconMap[icon];
  // TODO: improve? remove dupe code from `small`?
  // maybe larger on agreement rows than order list rows?

  return !small ? <div className={classNames(
    // Color
    color,

    // Shape
    'rounded-full h-[27px] w-[27px]',

    // Layout
    'items-center justify-center flex',
  )}>
    <IconElem className="h-4 w-4 stroke-2" />
  </div>
    : <div className={classNames(
      // Color
      color,
      "!bg-current", // Set bg to text color

      // Shape
      'rounded-full h-3 w-3',

      // Layout
      'items-center justify-center flex',
    )}>
    </div>
}

/**
  * Condenses the two parties' agreement statuses into a single status.
  * If both parties have accepted, the agreement is accepted.
  * If either party has rejected, the agreement is rejected.
  * If either party has no status yet, the agreement is null.
  * Otherwise, the agreement is pending. (E.g. one party has accepted, the other is pending)
  */
export function getAgreementStatus(agreement: Agreement): AgreementStatus | null {
  // If no agreement, no status
  if (!agreement) return null;

  // If either null, null // TODO: confirm
  if (agreement.distributor === null || agreement.installer === null)
    return null;

  // If both accpeted, accepted
  if (agreement.distributor === AgreementStatus.ACCEPTED && agreement.installer === AgreementStatus.ACCEPTED)
    return AgreementStatus.ACCEPTED;

  // If either rejected, rejected
  if (agreement.distributor === AgreementStatus.REJECTED || agreement.installer === AgreementStatus.REJECTED)
    return AgreementStatus.REJECTED;

  // Otherwise, pending
  return AgreementStatus.PENDING;
}
