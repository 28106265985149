import React, { Fragment, ReactNode, useState } from 'react'
import { Order } from 'src/hooks/data/orders/useOrders'
import OrderRowItem from './OrderRowItem.tsx'
import moment from 'moment'
import classNames from 'src/tools/classNames.js'
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext.tsx'
import { LocationType } from 'src/hooks/data/locations/useLocations.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'
import AddQuoteModal from './modals/AddQuoteModal.tsx'
import ApproveOrderModal from './modals/ApproveOrderModal.tsx'
import AddPickTicketModal from './modals/AddPickTicketModal.tsx'
import AddPickTicketAssignTruckModal from './modals/AddPickTicketAssignTruckModal.tsx'
import CancelOrderModal from './modals/CancelOrderModal.tsx'
import AssignTruckModal from './modals/AssignTruckModal.tsx'
import RescheduleOrderModal from './modals/RescheduleOrderModal.tsx'
import ReviewDateModal from './modals/ReviewDateModal.tsx'
import AddNoteModal from './modals/AddNoteModal.tsx'

type Props = {
  orders: Order[];
  selectedOrderId: string;
  markets: MarketType[];
  locations: LocationType[];
  connections: { name: string; id: string }[];
  view: 'rows' | 'list';
  onOrderClick?: (orderId: string) => void;
  refreshOrders?: () => void;
}

/**
 * Actions that an order can take. For now, just adding/approving quote.
 * This structure should be nice down the road if each status has unique actions.
 * Could adjust to match status values, but for now, just keeping it simple.
 */
export enum OrderAction {
  ADD_QUOTE,
  APPROVE_ORDER,
  ADD_PICK_TICKET,
  ADD_PICK_TICKET_AND_ASSIGN_TRUCK,
  CANCEL_ORDER,
  ASSIGN_TRUCK,
  RESCHEDULE_ORDER,
  REVIEW_DATE,
  ADD_NOTE,
}

/**
 * This component displays the list of given orders. It has a row and list view mode.
 * Row mode has more data and larger items. List mode is more compact.
 * Row mode is also grouped by delivery date.
 *
 * The order actions and its related enums and maps make it easy to define new actions
 * based on user company type and order status. The row item can send an OrderAction
 * which is used to select the function to call from `OrderActionFunctions`. These actions
 * open modals mapped in `OrderActionModals`. Where the actions are applied is defined
 * in `OrderRowItem`.
 */
export default function OrderList({
  orders,
  selectedOrderId,
  markets,
  locations,
  connections,
  view,
  onOrderClick = () => { },
  refreshOrders = () => { },
}: Props) {
  const user = useCurrentUser();
  const isInstaller = user?.company?.type === "installer";
  const isDistributor = user?.company?.type === "distributor";

  const [showModal, setShowModal] = useState<null | OrderAction>(null);
  const [orderForAction, setOrderForAction] = useState<Order | null>(null);

  // TODO: type fix
  /**
   * Functions for the order actions. For now, just adding/approving quote.
   * Most actions will likely just be to open an action modal and note the order to act on.
   */
  const OrderActionFunctions: Map<OrderAction, (o: Order) => void> = new Map([
    [
      OrderAction.ADD_QUOTE,
      (o: Order) => {
        setShowModal(OrderAction.ADD_QUOTE);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.APPROVE_ORDER,
      (o: Order) => {
        setShowModal(OrderAction.APPROVE_ORDER);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.ADD_PICK_TICKET,
      (o: Order) => {
        setShowModal(OrderAction.ADD_PICK_TICKET);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK,
      (o: Order) => {
        setShowModal(OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.CANCEL_ORDER,
      (o: Order) => {
        setShowModal(OrderAction.CANCEL_ORDER);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.ASSIGN_TRUCK,
      (o: Order) => {
        setShowModal(OrderAction.ASSIGN_TRUCK);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.RESCHEDULE_ORDER,
      (o: Order) => {
        setShowModal(OrderAction.RESCHEDULE_ORDER);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.REVIEW_DATE,
      (o: Order) => {
        setShowModal(OrderAction.REVIEW_DATE);
        setOrderForAction(o);
      }
    ],
    [
      OrderAction.ADD_NOTE,
      (o: Order) => {
        setShowModal(OrderAction.ADD_NOTE);
        setOrderForAction(o);
      }
    ],
  ])

  /**
   * Modals that an order action can use. For now, just adding/approving quote.
   * This structure should be nice down the road if each status has unique actions.
   * Could adjust to match status values, but for now, just keeping it simple.
   * Aligns with OrderActions for now. Not necessary but could be helpful if one is used
   * accidentially instead of the other.
   */
  const OrderActionModals: Map<OrderAction, (props: any) => JSX.Element> = new Map([
    // TODO: type of map
    [
      OrderAction.ADD_QUOTE,
      AddQuoteModal
    ],
    [
      OrderAction.APPROVE_ORDER,
      ApproveOrderModal
    ],
    [
      OrderAction.ADD_PICK_TICKET,
      AddPickTicketModal
    ],
    [
      OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK,
      AddPickTicketAssignTruckModal
    ],
    [
      OrderAction.CANCEL_ORDER,
      CancelOrderModal
    ],
    [
      OrderAction.ASSIGN_TRUCK,
      AssignTruckModal
    ],
    [
      OrderAction.RESCHEDULE_ORDER,
      RescheduleOrderModal
    ],
    [
      OrderAction.REVIEW_DATE,
      ReviewDateModal
    ],
    [
      OrderAction.ADD_NOTE,
      AddNoteModal
    ]
  ])

  /**
   * Does the specified order action on the specified order.
   * E.g. `OrderAction.ADD_QUOTE` will open the AddQuoteModal.
   */
  function handleOrderAction(action: OrderAction, order: Order) {
    // TODO: handle null function
    OrderActionFunctions.get(action)(order);
  }

  const ActiveModal = showModal != null ? OrderActionModals.get(showModal) : null;

  const groupings = groupOrders(orders);
  // TODO: improve view? or reduce duplicate code?

  // No orders, show "no orders" message
  if ((locations || markets) && orders && orders.length === 0) {
    return <div className="flex items-center justify-center">
      <p className="pt-20 text-sm text-gray-500">No Orders Found</p>
    </div>
  }

  // ----------------- //
  // --- ROWS VIEW --- //
  // ----------------- //
  let mainContent = view === "rows" ? <div
    className={classNames(
      // Grid layout: Name/address/status, badges/dates, BOM/Quote/amount labels, BOM/Quote/amount values, PO#/SO# (>=2xl) labels, PO#/SO# values (>=2xl), actions
      "grid 2xl:grid-cols-[repeat(2,1fr),repeat(5,auto)] grid-cols-[repeat(2,1fr),repeat(3,auto)] gap-x-3",
    )}
  >
    {/* Groups */}
    {
      (locations != null || markets != null) &&
      groupings.map(grouping => {
        if (grouping.orders.length === 0) return; // Skip if no orders
        return (
          <Fragment key={grouping.label}
          >
            {/* Group Header */}
            <h2 className="py-3 pl-5 text-sm font-semibold col-span-full">
              {grouping.label !== "Past Due" ? `Deliver ${grouping.label}` : grouping.label}
            </h2>
            {/* Group Items */}
            {
              grouping.orders.map(order => <OrderRowItem
                key={order._id}
                order={order}
                market={markets?.find(m => m._id === order.marketId)}
                location={locations?.find(l => l._id === order.locationId)}
                connection={connections.find(c => c.id === (isInstaller ? order.distributorId : order.installerId))}
                view={view}
                isSelected={order._id === selectedOrderId}
                onClick={() => onOrderClick(order._id)}
                onAction={(action) => {
                  // TODO: handle null function
                  OrderActionFunctions.get(action)(order);
                }}
              />)
            }
          </Fragment>
        )
      })
    }
  </div>

    // ----------------- //
    // --- LIST VIEW --- //
    // ----------------- //
    // : <div className="grid grid-cols-[repeat(8,auto)] gap-x-2 divide-y divide-gray-300">
    : <div className="grid grid-cols-[repeat(5,auto)] gap-x-2 divide-y divide-gray-300">

      {/* Header Row */}
      <div className={classNames(
        // Grid
        "p-2 grid grid-cols-subgrid col-span-full",

        // Styling
        "font-semibold"
      )}>
        <div>Name</div>
        <div>Tags</div>
        {/* <div>Status</div> */}
        {/* <div>Market</div> */}
        {/* <div>Distributor</div> */} {/* TODO: dynamic by user company */}
        <div>Delivery</div>
        <div>Agreement</div>
        <div></div>
      </div>

      {/* List */}
      {
        (locations != null || markets != null) &&
        groupings.map(grouping => {
          if (grouping.orders.length === 0) return; // Skip if no orders
          return (
            <Fragment key={grouping.label}
            >
              {/* Group Items */}
              {grouping.orders.map(order => <OrderRowItem
                key={order._id}
                order={order}
                market={markets?.find(m => m._id === order.marketId)}
                location={locations?.find(l => l._id === order.locationId)}
                connection={connections.find(c => c.id === (isInstaller ? order.distributorId : order.installerId))}
                view={view}
                isSelected={order._id === selectedOrderId}
                onClick={() => onOrderClick(order._id)}
                onAction={(action) => handleOrderAction(action, order)}
              />)}
            </Fragment>
          )
        })
      }
    </div>

  // ----------------- //
  // --- RENDERING --- //
  // ----------------- //

  return <>
    {mainContent}

    {/* Modals */}
    {
      orderForAction && ActiveModal &&
      <ActiveModal
        order={orderForAction}
        open
        setOpen={(open: boolean) => {
          if (!open) {
            setShowModal(null)
            refreshOrders();
          }
        }}
        onAction={(action) => handleOrderAction(action, orderForAction)}
      />
    }
  </>
}

type Group = {
  label: string,
  orders: Order[]
}

// TODO: comment
function groupOrders(orders: Order[]): Group[] {

  const dayMapKeyFormat = 'YYYY-MM-DD';
  let dayMap: { [dayStr: string]: Order[] } = {}
  const pastDueOrders: Order[] = [];

  // Categorize orders
  const today = moment().utc();
  for (let order of orders) {
    //  TODO: handle null date
    let orderDate = moment(order.requestedDelivery.deliveryDate).utc();
    let category = moment(orderDate).format(dayMapKeyFormat);

    // If past due, add to past due list and skip day grouping
    if (today.isAfter(orderDate) && !today.isSame(orderDate, 'day')) {
      pastDueOrders.push(order);
      continue;
    }

    // If not in map, add
    if (!dayMap[category]) {
      dayMap[category] = [];
    }

    // Add to group
    dayMap[category].push(order);
  }

  // Get sorted day keys
  const sortedDayKeys = Object.keys(dayMap).sort((a, b) => {
    return moment(a, dayMapKeyFormat).diff(moment(b, dayMapKeyFormat));
  });

  // Build array of sorted groups
  const groupings: Group[] = sortedDayKeys.map(key => {
    const date = moment(key, dayMapKeyFormat);
    let dayLabel: string;

    // Check today
    if (today.isSame(date, 'day')) {
      dayLabel = "Today";
    }
    // Check tomorrow
    else if (today.clone().add(1, "day").isSame(date, 'day')) {
      dayLabel = "Tomorrow";
    }
    // Check past due
    else if (today.isAfter(date)) {
      dayLabel = "Past Due";
    }
    // Other
    else {
      dayLabel = date.format('dddd M/D/Y');
    }

    return {
      label: dayLabel,
      orders: dayMap[key]
    }
  });

  // If any past due, add past due group at front
  if (pastDueOrders.length > 0) {
    groupings.unshift({
      label: "Past Due",
      orders: pastDueOrders
    });
  }
  return groupings;
}
