import { Popover } from '@headlessui/react'
import moment from 'moment'
import React from 'react'
import Card from 'src/components/Card'
import { Order } from 'src/hooks/data/orders/useOrders'
import { AgreementBubble, AgreementTypes, getAgreementStatus } from '../OrderAgreementIcons.tsx'

type Props = {
  orders: Order[]
}

/**
 * Displays the orders' agreement statuses.
 * If one order, just displays bubbles for the statuses.
 * If more, displays "Multiple Orders" with a popover of all the orders' bubbles
 */
export default function SimpleOrderStatuses({ orders }: Props) {
  if (!orders.length) return "--"

  return (
    orders.length > 1 ?
      <OrderStatusPopover orders={orders} />
      : <Bubbles order={orders[0]} />
  )
}

/**
  * Displays a bubble for the delivery date, material, and amount agreements respectively.
  */
function Bubbles({ order }: { order: Order }) {
  return <div className="flex items-center gap-2">
    <AgreementBubble small status={getAgreementStatus(order.quote?.agreements.deliveryDate)} icon={AgreementTypes.DELIVERY_DATE} />
    <AgreementBubble small status={getAgreementStatus(order.quote?.agreements.material)} icon={AgreementTypes.MATERIAL} />
    <AgreementBubble small status={getAgreementStatus(order.quote?.agreements.amount)} icon={AgreementTypes.AMOUNT} />
  </div>
}

/**
 * Simple popover with order names, installation dates, and agreement statuses
 */
export function OrderStatusPopover({ orders }: { orders: Order[] }) {
  return <Popover className="relative">
    <Popover.Button className="hover:text-primary-green cursor-pointer focus-visible:outline-none">Multiple Orders</Popover.Button>

    <Popover.Panel className="absolute z-10 left-1/2 top-6 -translate-x-1/2">
      <div className="bg-white border border-gray-300 rounded-lg p-3">
        <div className="grid grid-cols-3 w-max gap-x-2 divide-y">
          <div className="col-span-full grid grid-cols-subgrid font-semibold text-gray-900 pb-2">
            <div>Order Name</div>
            <div>Install Date</div>
            <div>Current Status</div>
          </div>
          {orders.map((order, i) => (
            <div className="col-span-full grid grid-cols-subgrid py-2">
              <div>{order.name}</div>
              <div>{order.installationDate ? moment(order.installationDate).format("M/D/Y") : "--"}</div>
              <Bubbles order={order} />
            </div>
          ))}
        </div>
      </div>
    </Popover.Panel>
  </Popover>
}
