import UserManager from './UserManager';

/**
 * Validate a zip code
 * @param {string} zip - the zip code to validate
 * @param {function} setError - function to set an error message
 * 
 * @returns - true if the zip code is valid, false otherwise
 */
export function validateZip(zip, setError = () => { }) {
    const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    // validate zip
    if (!zipRegex.test(zip)) {
        setError('Zip code must be in the format XXXXX or XXXXX-XXXX')
        return false
    }
    return true
}

export const stateOptions = [
    { label: 'AL', value: 'AL' },
    { label: 'AK', value: 'AK' },
    { label: 'AZ', value: 'AZ' },
    { label: 'AR', value: 'AR' },
    { label: 'CA', value: 'CA' },
    { label: 'CO', value: 'CO' },
    { label: 'CT', value: 'CT' },
    { label: 'DE', value: 'DE' },
    { label: 'FL', value: 'FL' },
    { label: 'GA', value: 'GA' },
    { label: 'HI', value: 'HI' },
    { label: 'ID', value: 'ID' },
    { label: 'IL', value: 'IL' },
    { label: 'IN', value: 'IN' },
    { label: 'IA', value: 'IA' },
    { label: 'KS', value: 'KS' },
    { label: 'KY', value: 'KY' },
    { label: 'LA', value: 'LA' },
    { label: 'ME', value: 'ME' },
    { label: 'MD', value: 'MD' },
    { label: 'MA', value: 'MA' },
    { label: 'MI', value: 'MI' },
    { label: 'MN', value: 'MN' },
    { label: 'MS', value: 'MS' },
    { label: 'MO', value: 'MO' },
    { label: 'MT', value: 'MT' },
    { label: 'NE', value: 'NE' },
    { label: 'NV', value: 'NV' },
    { label: 'NH', value: 'NH' },
    { label: 'NJ', value: 'NJ' },
    { label: 'NM', value: 'NM' },
    { label: 'NY', value: 'NY' },
    { label: 'NC', value: 'NC' },
    { label: 'ND', value: 'ND' },
    { label: 'OH', value: 'OH' },
    { label: 'OK', value: 'OK' },
    { label: 'OR', value: 'OR' },
    { label: 'PA', value: 'PA' },
    { label: 'RI', value: 'RI' },
    { label: 'SC', value: 'SC' },
    { label: 'SD', value: 'SD' },
    { label: 'TN', value: 'TN' },
    { label: 'TX', value: 'TX' },
    { label: 'UT', value: 'UT' },
    { label: 'VT', value: 'VT' },
    { label: 'VA', value: 'VA' },
    { label: 'WA', value: 'WA' },
    { label: 'WV', value: 'WV' },
    { label: 'WI', value: 'WI' },
    { label: 'WY', value: 'WY' },
]

/**
 * Get the columns for the location table
 * @param {function} setError - function to set an error message
 * 
 * @returns - an array of columns for the location table
 */
export function getTableColumns(setError = () => { }) {
    return [
        {
            Header: "Name",
            accessor: "name",
            placeholder: "Warehouse Name",
            required: true,
            primary: true,
            columnWidth: 5,
            editable: true,
            editType: 'input',
            unique: true,
            validate: (value) => { return true },
            // TODO add ratios here to make columns different widths, manually implement table-fixed
        },
        {
            Header: "Address",
            accessor: "address",
            placeholder: "Street Address",
            required: true,
            primary: true,
            columnWidth: 5,
            editable: true,
            editType: 'input',
            validate: (value) => { return true },
        },
        {
            Header: "Address Line 2",
            accessor: "address2",
            placeholder: "Suite, Floor, etc.",
            required: false,
            primary: true,
            columnWidth: 5,
            editable: true,
            editType: 'input',
            validate: (value) => { return true },
        },
        {
            Header: "City",
            accessor: "city",
            placeholder: "City",
            required: true,
            primary: true,
            columnWidth: 4,
            editable: true,
            editType: 'input',
            validate: (value) => { return true },
        },
        {
            Header: "State",
            accessor: "state",
            placeholder: "State",
            required: true,
            primary: true,
            columnWidth: 3,
            editable: true,
            editType: 'dropdown',
            options: [stateOptions],
            validate: (value) => { return true },
        },
        {
            Header: "Zip",
            accessor: "zip",
            placeholder: "Zip Code",
            required: true,
            primary: true,
            columnWidth: 3,
            editable: true,
            editType: 'input',
            validate: (value) => { return validateZip(value, setError) },
        },
    ]
}

/**
 * Sort locations by name.value, with new and editing locations at the end of the list
 * @param {array} locations - an array of locations
 * 
 * @returns - the locations sorted by name.value, with new and editing locations at the end of the list
 */
export function sortLocations(locations) {
    // Check if locations is an array
    if (!Array.isArray(locations)) {
        return locations;
    }
    // sort locations by name.value, if a location has a rowStatus of 'new' or 'editing' it will be at the end of the list
    return locations.sort((a, b) => {
        if (a.rowStatus === 'new' || a.rowStatus === 'editing') {
            return 1;
        }
        if (b.rowStatus === 'new' || b.rowStatus === 'editing') {
            return -1;
        }
        return a.name.value.localeCompare(b.name.value);
    });
}

/**
 * Get the locations for the current users company
 * @param {function} setError - function to set an error message
 * 
 * @returns - the list of locations for the current users company
 */
export function getLocations(setError = () => { }) {
    return UserManager.makeAuthenticatedRequest("/api/location/distributor/list", "GET")
        .then((response) => {
            if (response.data.status === "ok") {
                setError("");
                return response.data.locations;
            }
            else if (response.data.status === "error") {
                console.log("Error: ", response.data.error);
                setError("Error getting locations.");
                return null;
            }
        });
}

/**
 * Delete a location
 * @param {object} data - the location data to delete
 * @param {function} setError - function to set an error message
 * @param {function} setLocations - function to set the locations
 */
export function deleteLocation(data, setError = () => { }, setLocations = () => { }) {
    return UserManager.makeAuthenticatedRequest("/api/location/delete", "POST", {
        id: data.id
    }).then((response) => {
        if (response.data.status === "ok") {
            setError("");
            // remove the location from the table
            setLocations((prevLocations) => {
                return sortLocations(prevLocations.filter((prevLocation) => prevLocation.id !== data.id))
            });
            return true;
        }
        else if (response.data.status === "error") {
            console.log("Error: ", response.data.error);
            setError("Error deleting location.");
            return null;
        }
    });
}

/**
 * Update a location
 * @param {object} data - the location data to update
 * @param {function} setError - function to set an error message
 */
export async function updateLocation(data, setError = () => { }) {
    const response = await UserManager.makeAuthenticatedRequest("/api/location/update", "POST", {
        id: data.id,
        name: data.name.value,
        address: {
            line1: data.address.value,
            line2: data.address2.value,
            city: data.city.value,
            state: data.state.value,
            postalCode: data.zip.value
        }
    })

    if (response.data.status === "ok") {
        setError("");
        return {
            id: response.data.location._id
        };
    }
    else if (response.data.status === "error") {
        console.log("Error: ", response.data.error);
        setError("Error updating location.");
        return null;
    }
}

/**
 * Add a location
 * @param {object} data - the location data to add
 * @param {function} setError - function to set an error message
 */
export async function addLocation(data, setError = () => { }) {
    const response = await UserManager.makeAuthenticatedRequest("/api/location/create", "POST", {
        name: data.name.value,
        address: {
            line1: data.address.value,
            line2: data.address2.value,
            city: data.city.value,
            state: data.state.value,
            postalCode: data.zip.value
        }
    })
    if (response.data.status === "ok") {
        setError("");
        return {
            id: response.data.location._id
        };
    }
    else if (response.data.status === "error") {
        console.log("Error: ", response.data.error);
        setError("Error adding location.");
        return null;
    }
}