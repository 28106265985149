import axios from 'axios';

export default class UserManager {

  static user = null;
  static initialized = false;

  loggedIn = false;
  userData = null;

  /**
   * Returns the user of a static instance of the UserManager
   * @returns UserManager
   */
  static async getUser(initialize = false) {
    if (UserManager.user == null) {
      UserManager.user = new UserManager();
    }
    if (!this.initialized || initialize) {
      await UserManager.user.init();
      this.initialized = true;
    }

    return UserManager.user;
  }

  /**
   * Initializes the UserManager. This checks whether the user is logged in.
   * If the user is logged in, the user object is set.
   */
  async init() {

    var infoRes = await this.makeAuthenticatedRequest('/api/user/info', 'GET');

    // If here, user is logged in
    this.userData = infoRes.data.user;
    localStorage.setItem("user", JSON.stringify(this.userData));
    this.loggedIn = true;
    localStorage.setItem("loggedIn", true);
  }

  /**
   * Makes a request to refresh the user's token.
   * If unsuccessful, the user is logged out if given paramter is true (default).
   */
  async refreshToken(logoutOnFail = true) {
    try {
      var refreshRes = await axios.get('/api/user/token', {
        withCredentials: true
      });
    } catch (err) {
      if (logoutOnFail) {
        this.logout();
      }
    }
  }

  /**
   * Sends a request to the server to login with the given credentials.
   * @param email - email,
   * @param password - password
   * @returns The return value is a boolean.
   */
  static async login(email, password) {
    var res = await axios.post('/api/user/login', {
      email: email,
      password: password
    }, {
      withCredentials: true
    });

    var data = res.data;

    if (data.status === "ok") {
      UserManager.user = new UserManager();
      UserManager.user.loggedIn = true;
      UserManager.user.userData = data.user;

      localStorage.setItem("loggedIn", true);
      localStorage.setItem("user", JSON.stringify(UserManager.user.userData));
      return true;
    } else {
      return false;
    }
  }

  /**
   * Logs the user out and redirects to the login page.
   * As this is a static method, it uses the UserManager instance.
   * @param {boolean} [redirect=true] Whether to redirect to the login page.
   */
  static logout(redirect = true) {
    if (this.user != null && this.initialized) {
      this.user.logout(redirect);
    }
  }

  /**
   * Make an authenticated request to the server using the current user's credentials.
   * @param url - The url to make the request to.
   * @param method - The HTTP method to use.
   * @param [payload=null] - The data to be sent to the server.
   * @returns The return value is a promise.
   */
  static async makeAuthenticatedRequest(url, method = "GET", payload = null, opts = {}) {
    var user = await UserManager.getUser();
    return await user.makeAuthenticatedRequest(url, method, payload, opts);
  }

  /**
   * If the user is logged in, make a request to the server. If the request fails, refresh the token
   * and try again. If the request fails again, redirect to the login page.
   * 
   * @param url - The url to make the request to
   * @param method - "GET" or "POST"
   * @param payload - The payload to send with the request
   * @returns a promise.
   */
  async makeAuthenticatedRequest(url, method, payload = null, opts = {}) {

    // Check if logged in
    var loggedIn = localStorage.getItem("loggedIn");
    if (!loggedIn) return;

    // Make request to ensure logged in
    var failed = false;
    try {
      var res = await axios({
        url: url,
        method: method,
        data: payload,
        withCredentials: true
      });
      return res;
    } catch (err) {
      failed = true;
    }

    // If failed, try refreshing token
    if (failed) {

      // Refresh token. If failed, logout
      await this.refreshToken(false);

      // If successful, try again. If failed with 401 or 403, redirect to login if in app. Else return error.
      try {
        res = await axios({
          url: url,
          method: method,
          data: payload,
          withCredentials: true,
          ...opts
        });
        return res;
      } catch (err) {
        console.error(err);
        if (err.response.status === 401) {
          if (window.location.pathname.startsWith("/app")) {
            window.location.pathname = "/login/login";
          }
        }
        return err;
      }
    }

    // If here, user is logged in
    this.loggedIn = true;
    localStorage.setItem("loggedIn", true);
  }

  /**
   * Logs the user out and redirects to the login page.
   * @param {boolean} [redirect=true] Whether to redirect to the login page.
   */
  logout(redirect = true) {
    localStorage.setItem("loggedIn", false);
    localStorage.removeItem("user");
    this.loggedIn = false;
    this.userData = null;

    // Make request to logout
    axios.delete('/api/user/logout', {
      withCredentials: true
    })
      .then(res => {
        if (redirect && window.location.pathname !== "/login/login") {
          console.log('redirecting to login');
          window.location.pathname = "/login/login";
        }
      })
      .catch(err => {
        this.refreshToken(false)
          .then(() => {
            axios.delete('/api/user/logout', {
              withCredentials: true
            })
              .finally(() => {
                if (redirect && window.location.pathname !== "/login/login") {
                  console.log('redirecting to login');
                  window.location.pathname = "/login/login";
                }
              }).catch(err => {
                console.log('failed to logout');
                console.log(err);
              });
          })
          .catch(err => {
            console.log('failed to refresh token');
            console.log(err);
          });
      });
  }
}
