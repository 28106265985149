import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react'
import Modal, { ModalFooter } from 'src/components/Modal';
import { AgreementStatus, Order } from 'src/hooks/data/orders/useOrders.ts';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useLocations, { LocationType } from 'src/hooks/data/locations/useLocations.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import S3 from 'src/tools/S3/s3.ts';
import UserManager from 'src/tools/UserManager';
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import Dropdown from 'src/components/input/Dropdown';
import {
    WarehouseIcon,
    Trash2Icon
} from 'lucide-react';
import {
    PaperClipIcon,
    CheckCircleIcon
} from '@heroicons/react/20/solid';
import Button from 'src/components/input/Button';
import Input from 'src/components/input/Input';
import Spinner from 'src/components/Spinner';
import classNames from 'src/tools/classNames';
import StatusDisplay from '../common/StatusDisplay.tsx';
import SwitchCase from 'src/components/utils/SwitchCase.tsx';
import { HomeIcon, TruckIcon } from '@heroicons/react/24/outline';
import { UserPlusIcon } from 'lucide-react';
import OrderNotes from '../common/OrderNotes.tsx';
import AddressHelper from 'src/utils/addressHelper.ts';
import { addressToString, getDeliveryDateString, MinorBadge } from '../OrderRowItem.tsx';
import StringHelper from 'src/utils/stringHelper.ts';
import useTrucks from 'src/hooks/data/trucks/useTrucks.ts';
import { Link } from 'react-router-dom';

type Props = {
    order: Order;
    open: boolean;
    setOpen: (open: boolean) => void;
}

/**
 * Add pick ticket and assign truck modal
 * Prompts the user to select a location and truck, upload a pick ticket, and add notes
 * moves the order to READY_TO_DELIVER status upon submission
 */
export default function AddPickTicketAssignTruckModal({
    order: order_prop,
    open,
    setOpen: setOpen_prop
}: Props) {

    // ------------------------------------ //
    // --- States, Hooks, Memos, & Refs --- //
    // ------------------------------------ //

    const currentUser = useCurrentUser();
    const companyType = currentUser?.company?.type;

    const [loading, setLoading] = useState<boolean>(false);

    const [order, setOrder] = useState(structuredClone(order_prop));

    const connections = useConnections();
    let connectionId = null;
    switch (companyType) {
        case "installer":
            connectionId = order.distributorId;
            break;
        case "distributor":
            connectionId = order.installerId;
            break;
    }
    const connectionName = connections
        ?.find(c => c.id === connectionId)
        ?.name;

    const [markets, setMarkets] = useState<MarketType[] | null>(null);
    const [marketName, setMarketName] = useState<string | null>(null);

    const locations = useLocations();
    const locationName = locations?.find(l => l._id === order.locationId)?.name;

    const trucks = useTrucks();
    const [truckId, setTruckId] = useState<string>()

    const locationOptions = locations ? [locations.map(location => {
        return {
            label: location.name,
            value: location._id
        }
    })] : [];

    // const [locationOptions, setLocationOptions] = useState<{ label: string, value: string }[][] | null>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationType | null>(null);

    const [quoteFileName, setQuoteFileName] = useState<string | null>(null);

    const files = useS3URLs(
        order?.quote?.file?.filePath ? [order.quote.file.filePath] : []
    );
    const quoteLink = files.length ? files[0].getObjectSignedUrl : null;

    const hiddenPickTicketFileInput = useRef<HTMLInputElement>(null);
    const [pickTicketFile, setPickTicketFile] = useState<File | null>(null);

    const [note, setNote] = useState<string | null>(null);

    const deliveryString = getDeliveryDateString(order)
    const installString = order.installationDate ? moment(order.installationDate).utc().format('ddd M/D') : null

    // ------------------ //
    // --- Use Effect --- //
    // ------------------ //

    // Set markets and market name when connections are loaded
    useEffect(() => {
        const installerIds = connections?.map(c => c.id).join(',');

        if (installerIds) {
            UserManager.makeAuthenticatedRequest(
                `/api/markets/distributor/find?installerIds=${installerIds}`,
                'GET',
            )
                .then((response) => {
                    setMarkets(response.data.markets.map((market) => {
                        return {
                            id: market._id,
                            installerId: market.installer,
                            name: market.name
                        }
                    }))

                    setMarketName(markets?.find(m => m.installerId === order.installerId)?.name)

                })
                .catch((error) => {
                    console.error(error)
                })
        }

    }, [connections])

    // Set quote file name when order is loaded
    useEffect(() => {
        setQuoteFileName(
            order?.quote?.file?.filePath
                ? order.quote.file.filePath.split('/').pop()
                : null
        );
    }, [order]);

    // Set order when prop changes
    useEffect(() => {
        setOrder(structuredClone(order_prop));
    }, [order_prop]);

    /**
     * Submit the form to add the pick ticket and assign the truck, only update the status from the assign truck endpoint
     */
    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        // Upload pick ticket file to S3
        const pickTicketFileName = `pick_tickets/${currentUser.company.id}/${order._id}/${pickTicketFile.name}`;
        const pickTicketUploadSuccess = await S3.upload(pickTicketFile, pickTicketFileName);

        if (!pickTicketUploadSuccess) {
            console.error("Failed to upload pick ticket file to S3");
            setLoading(false);
            return;
        }

        // TODO: consider creating an endpoint that adds the pick ticket and assigns the truck in one request

        try {
            var response = await UserManager.makeAuthenticatedRequest(
                `/api/orders/add-pick-ticket`,
                'POST',
                {
                    orderId: order._id,
                    pickTicketPath: pickTicketFileName,
                    locationId: selectedLocation,
                    updateStatus: false
                }
            )
        } catch (error) {
            console.error("Failed to add pick ticket to order", error);
        }

        try {
            var res = await UserManager.makeAuthenticatedRequest(`/api/orders/assign-truck`, "POST", {
                orderId: order._id,
                truckId: truckId,
                note: note
            })
        } catch (err) {
            console.error(err);
        }

        setOpen_prop(false);
        setLoading(false);
    }

    /**
     * Clicks the hidden pick ticket file input. This opens the file select dialog.
     */
    function handlePickTicketFileClick() {
        hiddenPickTicketFileInput.current.click();
    }

    /**
     * Grabs the file when a file is selected.
     */
    function handlePickTicketFileChange(event) {
        if (event.target.files?.length) {
            setPickTicketFile(event.target.files[0]);
        }
    }

    /**
     * Removes the pick ticket file from the order.
     */
    function handlePickTicketFileRemove() {
        setPickTicketFile(null);
        if (hiddenPickTicketFileInput.current) {
            hiddenPickTicketFileInput.current.value = null;
        }
    }

    /**
      * Close the modal
      */
    function close() {
        setOpen_prop(false);
        setLoading(false);
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen_prop}
            wide
        >
            <form
                onSubmit={handleSubmit}
                className="flex flex-col"
            >
                <div className="divide-y">
                    {/* Header */}
                    <Section noTopPadding>
                        <div
                            className='pb-4'
                        >
                            <div
                                className='text-base font-semibold text-left align-middle'
                            >
                                Hand off <span className='text-[#1CB7BE]'>{order.name}</span> to Delivery Team
                            </div>
                            <div
                                className='text-sm font-normal text-left text-gray-500 align-middle'
                            >
                                Add location and pick ticket to trigger delivery process
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="font-semibold">{order.name}</div>
                            <div>
                                <StatusDisplay
                                    order={order}
                                    companyType={companyType}
                                />
                            </div>
                            <div className="grid grid-cols-[auto,1fr] gap-2 ml-auto pl-20">
                                <div className="text-sm font-medium">PO #</div>
                                <div className="text-sm font-semibold text-gray-600">{order.poNumber ?? "--"}</div>
                                <div className="text-sm font-medium">SO #</div>
                                <div className="text-sm font-semibold text-gray-600">{order.soNumber ?? "--"}</div>
                            </div>
                        </div>
                    </Section>

                    {/* Metadata */}
                    <Section>
                        <div className="flex items-center justify-between gap-2">
                            <div className="text-sm font-normal text-gray-500">
                                <div className="flex gap-2">
                                    {/* Market or Location Badge */}
                                    <SwitchCase test={companyType}>
                                        {/* Market */}
                                        <div data-case="installer" className="contents">
                                            <MinorBadge>
                                                {markets ? (marketName ?? <span className="italic">No Market</span>) : <Loading />}
                                            </MinorBadge>
                                        </div>
                                        {/* Location */}
                                        <div data-case="distributor" className="contents">
                                            <MinorBadge>
                                                {locations ? (locationName ?? <span className="italic">No location</span>) : <Loading />}
                                            </MinorBadge>
                                        </div>
                                    </SwitchCase>
                                    <MinorBadge>
                                        {connections ? (connectionName ?? <span className="italic">No Connection</span>) : <Loading />}
                                    </MinorBadge>
                                </div>
                                <div className="grid grid-cols-[auto,auto,1fr] items-center gap-x-2">
                                    <div>
                                        <TruckIcon className="w-5 h-5 text-gray-600" />
                                    </div>
                                    <div>Delivery:</div>
                                    <div>{deliveryString}</div>
                                    <div>
                                        <HomeIcon className="w-5 h-5 text-gray-600" />
                                    </div>
                                    <div>Install:</div>
                                    <div>{installString ?? "--"}</div>
                                </div>
                                <div className="text-gray-600">{AddressHelper.toString(order.orderAddress, true)}</div>
                            </div>
                            <div>
                                <h3 className="text-sm font-medium">Contact Info</h3>
                                <div className="text-sm font-normal text-gray-500">
                                    <p>{order.contact?.name || <span className="italic">No name</span>}</p>
                                    <p>{order.contact?.phone || <span className="italic">No phone</span>}</p>
                                    <p>{order.contact?.email || <span className="italic">No email</span>}</p>
                                </div>
                            </div>
                        </div>
                    </Section>

                    <Section>
                        <div
                            className='flex flex-row items-center justify-start gap-2'
                        >
                            <div className="flex items-center gap-2">
                                <div className="flex items-center justify-center">
                                    <CheckCircleIcon className="w-7 h-7 text-primary-green" />
                                </div>
                                <p className="text-sm font-semibold text-gray-600">Approved Quote</p>
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                                {quoteFileName && StringHelper.truncate(quoteFileName, 20)}
                                {" "}
                                <a
                                    className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700"
                                    onClick={() => window.open(quoteLink)}
                                >
                                    View
                                </a>
                            </div>
                        </div>
                    </Section>

                    <Section>
                        <div
                            className='flex flex-col mx-28'
                        >
                            <div
                                className='flex flex-row items-center justify-between w-full gap-4 py-4'
                            >
                                <div
                                    className='flex flex-row items-center justify-center gap-2'
                                >
                                    <WarehouseIcon
                                        size={24}
                                        color='#374151'
                                    />
                                    <div
                                        className='text-sm font-medium'
                                    >
                                        Location
                                    </div>
                                </div>
                                <div
                                    className='w-52'
                                >
                                    <Dropdown
                                        wide
                                        options={locationOptions}
                                        selectedValue={selectedLocation}
                                        onSelected={(location) => {
                                            setSelectedLocation(location?.value);
                                        }}
                                        placeholder='Select a Location'
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row items-center justify-between w-full gap-4">
                                <div
                                    className='flex flex-row items-center justify-center gap-2'
                                >
                                    <div><UserPlusIcon className="w-6 h-6 text-gray-700" /></div>
                                    <div className="text-sm font-medium">Truck</div>
                                </div>
                                <div
                                    className='w-52'
                                >
                                    {
                                        trucks
                                            ? (trucks.length > 0
                                                ? (
                                                    <Dropdown
                                                        wide
                                                        justifyLeft
                                                        placeholder="Select a truck"
                                                        options={[trucks.map(truck => ({
                                                            label: truck.name,
                                                            value: truck._id
                                                        }))]}
                                                        selectedValue={truckId}
                                                        onSelected={(option) =>
                                                            setTruckId(option.value)
                                                        }
                                                    />
                                                )
                                                : (
                                                    <Link
                                                        className="px-2 py-1 border rounded-md bg-primary-rose-100 border-primary-rose-200 text-primary-rose"
                                                        to="/app/trucks/manage"
                                                    >
                                                        No trucks, add a truck on the Trucks page.
                                                    </Link>
                                                )
                                            )
                                            : (
                                                <div className="w-40 h-8 bg-gray-100 rounded-md animate-pulse"></div>
                                            )
                                    }
                                </div>
                            </div>
                        </div>

                        {pickTicketFile
                            ? (
                                <div
                                    className='flex flex-row items-center justify-between w-full gap-4 px-24 py-5'
                                >
                                    <div
                                        className='flex flex-row items-center justify-center gap-2 align-middle'
                                    >
                                        <CheckCircleIcon
                                            height={28}
                                            width={28}
                                            color='#1cb7be'
                                        />
                                        <div
                                            className='text-sm text-gray-500 font-semibod'
                                        >
                                            Pick Ticket
                                        </div>
                                    </div>
                                    <div
                                        className='flex flex-row items-center justify-center gap-2 align-middle cursor-pointer'
                                        // view pick ticket
                                        onClick={() => {
                                            // Open pick ticket in new tab 
                                            window.open(URL.createObjectURL(pickTicketFile), '_blank');
                                        }}
                                    >
                                        <div
                                            className='overflow-hidden text-sm font-normal text-gray-600 align-middle max-w-52 text-ellipsis text-nowrap'
                                        >
                                            {pickTicketFile.name}
                                        </div>
                                        <div
                                            className='text-sm font-semibold text-primary-green'
                                        >
                                            View
                                        </div>
                                    </div>
                                    <div
                                        className='cursor-pointer'
                                        onClick={handlePickTicketFileRemove}
                                    >
                                        <Trash2Icon
                                            height={20}
                                            width={20}
                                            color='#d4246a'
                                        />
                                    </div>
                                </div>
                            )
                            : (
                                <div
                                    className='flex items-center justify-center w-full py-4'
                                >
                                    <input
                                        type="file"
                                        onChange={handlePickTicketFileChange}
                                        ref={hiddenPickTicketFileInput}
                                        className="hidden"
                                    />
                                    <Button
                                        onClick={() => {
                                            handlePickTicketFileClick();
                                        }}
                                        variant='primary-green'
                                        icon={<PaperClipIcon height={20} width={20} color='#ffffff' />}
                                    >
                                        Upload Pick Ticket
                                    </Button>
                                </div>
                            )}

                        <div
                            className='flex flex-row items-start justify-center w-full gap-4 px-6 py-4'
                        >
                            <div
                                className='text-sm font-medium text-gray-500'
                            >
                                Notes:
                            </div>
                            <div
                                className='w-full'
                            >
                                <Input
                                    type='textarea'
                                    placeholder='Add notes...'
                                    value={note}
                                    onChange={(value) => {
                                        setNote(value);
                                    }}
                                    className='resize-none'
                                />
                            </div>
                        </div>
                    </Section>
                </div>
                {/* Footer */}
                <ModalFooter>
                    <div className="flex items-center justify-end gap-2">
                        {
                            !loading ? <Button
                                variant="primary"
                                type="submit"
                                disabled={!selectedLocation || !truckId || !pickTicketFile}
                            >
                                Confirm
                            </Button>
                                : <div className="flex items-center pr-7">
                                    <Spinner size={20} />
                                </div>
                        }
                        <Button
                            variant="secondary"
                            onClick={close}
                        >
                            Cancel
                        </Button>
                    </div>
                </ModalFooter>
            </form >
        </Modal >
    )
}

type SectionProps = {
    children?: React.ReactNode
    noTopPadding?: boolean
}

/**
  * Styles a section of the modal
  */
function Section({ children, noTopPadding = false }: SectionProps) {
    return <div
        className={classNames(
            noTopPadding ? "pb-4" : "py-4",
        )}
    >
        {children}
    </div>
}

/**
* Super simple loading component.
* Pulses "Loading"
* TODO: move to a shared with OrderModalHeader
*/
function Loading({ }) {
    return <span className="animate-pulse">Loading</span>
}