import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

export type Truck = {
    _id: string;
    name: string;
  }

/**
 * Use this hook to fetch all trucks within the distributor.
 */
export default function useTrucks() {
  const [trucks, setTrucks] = useState<Truck[]>();

  useEffect(() => {
    const abortController = new AbortController();

    UserManager.makeAuthenticatedRequest("/api/trucks/list", "GET", null, { signal: abortController.signal })
      .then(res => {
        if (res.data.status === "ok") {
          setTrucks(res.data.trucks);
        }
      }).catch(err => {
        console.error(err);
      });
    return () => {
      abortController.abort();
    }
  }, []);

  return trucks;
}
