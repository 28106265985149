import React, { useState, useEffect } from 'react'
import Card from '../../components/Card'
import Button from '../../components/input/Button'
import SearchBar from '../../components/SearchBar'
import FilterSort from '../../components/FilterSort'
import InputTable from '../../components/input/InputTable'
import EmailInput from '../../components/input/EmailInput'
import Dropdown from '../../components/input/Dropdown'
import UserManager from '../../tools/UserManager'
import Modal from '../../components/Modal'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
    tableColumns,
    structureRowData,
    sortTrucks,
    addNewTruck,
    getTrucks,
    deleteTruck,
    editTruck
} from '../../tools/truckManagement'
import { getLocations } from '../../tools/locationManagement'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'
import Input from 'src/components/input/Input'

const roleMap = {
    'admin': 'Admin',
    'member': 'Member',
    'operations': 'Operations',
    'warehouse': 'Warehouse',
    'driver': 'Driver',
    'install-crew': 'Install Crew'
}

const columns = [
    {
        Header: "Truck Name",
        accessor: "truckName",
        placeholder: "Truck Name",
        required: false,
        primary: true,
        columnWidth: 3,
        editable: true,
        editType: "input",
        validate: (value) => { return true },
    },
]

/**
 * @returns - a component that allows the user to invite coworkers to the company and manage their roles
 */
export default function ManageTrucksPage() {

    // get current logged in user
    const currentUser = useCurrentUser()

    const [trucks, setTrucks] = useState([])

    const [truckName, setTruckName] = useState("")

    const [users, setUsers] = useState([])

    // error message
    const [error, setError] = useState("");

    const [showAddTrucksModal, setShowAddTrucksModal] = useState(false)

    // get trucks
    useEffect(() => {
        getTrucks().then(trucks => {
            if (trucks) {
                sortTrucks(trucks).then(sortedTrucks => {
                    setTrucks(
                        sortedTrucks.map(truck => {
                            return structureRowData({
                                id: truck._id,
                                truckName: truck.name,
                                rowStatus: 'readonly',
                                menuOptions: [
                                    { label: 'Delete', value: 'deleteTruck', onSelected: (item) => deleteTruck(item, setError, setTrucks) },
                                    { label: 'Edit', value: 'edit' }
                                ]
                            })
                        })
                    )
                })
            }
        })
    }, [])

    async function handleSaveRow(row) {
        const result = await editTruck(row, setError)

        return {
            id: result.id,
            resultList: [
                { column: result.column, value: result.newValue },
            ]
        }

    }

    async function handleSubmitTruck() {
        const truck = await addNewTruck(truckName)

        var newTrucks = [...trucks]
        newTrucks.push(structureRowData({
            id: truck._id,
            truckName: truck.name,
            rowStatus: 'readonly',
            menuOptions: [
                { label: 'Delete', value: 'deleteTruck', onSelected: (item) => deleteTruck(item, setError, setTrucks) },
                { label: 'Edit', value: 'edit' }
            ]
        }))

        // sort newTrucks by truckName
        newTrucks = newTrucks.sort((a, b) => (a.truckName.value > b.truckName.value) ? 1 : -1)
        setTrucks(newTrucks)
    }

    return (
        <div>
            <Modal open={showAddTrucksModal} setOpen={setShowAddTrucksModal}>
                <div className='flex flex-col items-center justify-between w-full'>
                    <div className='self-end'>
                        <XMarkIcon
                            className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-300'
                            onClick={() => setShowAddTrucksModal(false)}
                        />
                    </div>
                    <div className='flex flex-col items-center justify-start w-full gap-6'>
                        <div className='flex flex-col items-start self-start'>
                            <div className='text-base font-semibold leading-6 text-left align-middle'>
                                Add your delivery trucks
                            </div>
                        </div>

                        <div
                            className='self-start w-1/2'
                        >
                            <Input
                                label='Truck Name'
                                placeholder='Enter truck name'
                                value={truckName}
                                onChange={(value) => {
                                    setTruckName(value)
                                }}
                            />
                        </div>

                    </div>
                    <div className='flex flex-row justify-end w-full gap-2 mt-4'>
                        <Button
                            variant='primary'
                            onClick={() => {
                                handleSubmitTruck()
                                setTruckName("")
                            }}
                        >
                            Add Truck
                        </Button>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                setTruckName("")
                                setShowAddTrucksModal(false)
                            }}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className='flex flex-col gap-2 mt-4'>
                <Card title="Manage Team">
                    <div className='flex flex-col'>
                        <div>
                            <InputTable
                                tableData={trucks}
                                setTableData={setTrucks}
                                columns={columns}
                                addButtonText={'Add Truck'}
                                outerAddButton={currentUser?.company?.role === 'admin'}
                                innerAddButton={currentUser?.company?.role === 'admin'}
                                addButtonClicked={() => {
                                    setError("")
                                    setShowAddTrucksModal(true)
                                }}
                                addButtons={false}
                                height={'calc(100vh - 195px)'}
                                editColumnWidth={3}
                                editableRows={currentUser?.company?.role === 'admin'}
                                ellipsisDropdown={currentUser?.company?.role === 'admin'}
                                useOnBlur={false}
                                onSaveRow={handleSaveRow}
                                onCancelRow={() => setError("")}
                                onRowLoseFocus={() => setError("")}
                                error={error}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}
