import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { Dispatch, SetStateAction, useState } from 'react'
import InfoTooltip from 'src/components/InfoTooltip'
import IconToggle, { IconType } from 'src/components/input/IconToggle.tsx'
import BlockRanges, { Block, Colors as BlockColors } from 'src/components/input/BlockRanges.tsx'
import NumberGrid, { Colors as NumberGridColors, States } from 'src/components/tables/NumberGrid.tsx'
import { Crew, H2, H3, Label, Modes } from 'src/pages/Labor/CrewPlanner/MainView.tsx'
import classNames from 'src/tools/classNames'
import { MinusCircleIcon } from '@heroicons/react/20/solid'
import e from 'express'
import WeekInput from './WeekInput.tsx'

type Props = {

  whatIfCrews: number[],
  whatIfCapacity: number[],
  whatIfCrewPerWeek: number,
  jobsSentToSubs: number[],
  crewCapacity: number[],
  jobCapacity: number[],

  mode: Modes,
  setMode: (mode: Modes) => void

  crews: Crew[],
  onCrewAdd: () => void,
  onCrewDelete: (index: number) => void,
  onCrewChange: (index: number, plannedWeeks: number[], weekCapacity: number) => void,

  setWhatIfCrews: Dispatch<SetStateAction<number[]>>
  setJobsSentToSubs: Dispatch<SetStateAction<number[]>>
  setWhatIfCrewPerWeek: Dispatch<SetStateAction<number>>
}

/**
 * Represents and displays the planned capacity section of the `Labor>Crew Planner` page.
 * Contains the following sections:
 * - Job Capacity
 *   - Week by week calculated job capacity display
 * - What-If
 *   - Week by week crew count input (and jobs/wk input)
 *   - Week by week what-if job Capacity display (jobs/wk * crew count)
 * - Crew Capcity
 *   - Week by week crew capacity display
 *   - Crew list (with add/delete functionality)
 *     - jobs/wk input
 *     - Week selection input (block range)
 */
export default function PlannedCapacity({

  whatIfCrews,
  whatIfCapacity,
  whatIfCrewPerWeek,
  jobsSentToSubs,
  crewCapacity,
  jobCapacity,

  mode,
  setMode,

  crews,
  onCrewAdd,
  onCrewDelete,
  onCrewChange,

  setWhatIfCrews,
  setJobsSentToSubs,
  setWhatIfCrewPerWeek,
}: Props) {

  const [crewWeeksEditorOpen, setCrewWeeksEditorOpen] = useState<boolean>(true);

  const tooltips = {
    jobCapacity: "The estimated number of jobs that could be done if installs are ready. This is based on crews and how many jobs a week they can do."
  };

  let rows = [
    {
      name: "Jobs Capacity",
      values: jobCapacity,
      isEditable: false,
      state: States.DEFAULT,
    },
    {
      name: "What-If Crews",
      values: whatIfCrews,
      isEditable: true,
      state: mode === Modes.WHAT_IF ? States.DEFAULT : States.FADED,
      border: NumberGridColors.PURPLE,
    },
    {
      name: "What-If Job Capacity",
      values: whatIfCapacity,
      isEditable: false,
      state: mode === Modes.WHAT_IF ? States.DEFAULT : States.FADED,
    },
    {
      name: "Crew Capacity (Jobs)",
      values: crewCapacity,
      isEditable: false,
      state: mode === Modes.CREW_CAPACITY ? States.DEFAULT : States.FADED,
    },
    {
      name: "Jobs Sent to Subs",
      values: jobsSentToSubs,
      isEditable: true,
      state: mode === Modes.CREW_CAPACITY ? States.DEFAULT : States.FADED,
      border: NumberGridColors.PURPLE,
    },
  ];

  let modeToggleOptions: IconType[] = [{
    id: Modes.WHAT_IF,
    name: "What-If",
    content: {
      on: <div className="text-primary-green text-sm">What-If</div>,
      off: <div className="text-gray-400 text-sm">What-If</div>,
    }
  }, {
    id: Modes.CREW_CAPACITY,
    name: "Actual",
    content: {
      on: <div className="text-primary-green text-sm">Actual</div>,
      off: <div className="text-gray-400 text-sm">Actual</div>,
    }
  }];


  /**
   * Toggles the crew weeks editor open and closed.
   * When closed, you can still see crew capacity.
   * This just toggles the editor.
   */
  function handleCrewWeeksEditorToggle() {
    setCrewWeeksEditorOpen(!crewWeeksEditorOpen);
  }

  /**
   * Propogates the crew change to the parent component.
   */
  function handleCrewChange(index: number, plannedWeeks: number[], weekCapacity: number) {
    onCrewChange(index, plannedWeeks, weekCapacity)
  }

  /**
   * Handles the blur event from the what if crews grid input.
   *
   * Only row 0 should be editable. The column represents the week.
   */
  function handleWhatIfBlur(row: number, col: number, value: number) {
    if (row !== 0) return; // Only want row 0 (what if crews)

    let newValues = [...whatIfCrews];
    newValues[col] = value;
    setWhatIfCrews(newValues);
  }

  /** 
   * Handles the blur event from the jobs sent to subs grid input.
   *
   * Only row 0 should be editable. The column represents the week.
   */
  function handleJobsSentBlur(row: number, col: number, value: number) {
    if (row !== 0) return; // Only want row 0 (what if crews)

    let newValues = [...jobsSentToSubs];
    newValues[col] = value;
    setJobsSentToSubs(newValues);
  }

  return (
    <div className="relative">
      <div className="absolute right-0 -top-2 -translate-y-full">
        <IconToggle
          selectedIconId={mode}
          icons={modeToggleOptions}
          onChange={(iconId) => setMode(iconId as Modes)}
        />
      </div>

      <div className="flex flex-col">
        {/* Job Capacity */}
        <div className="flex items-stretch gap-2 mb-2">
          <Label>
            <H2><p>Job Capacity</p><InfoTooltip text={tooltips.jobCapacity} /></H2>
          </Label>
          <div className="col-span-3">
            <NumberGrid rows={rows.slice(0, 1)} />
          </div>
        </div>

        {/* What If */}
        <div className="flex items-stretch gap-2 border-t border-gray-300">
          <Label>
            <H2 className="flex items-center justify-between gap-2">
              What-If Crews
              <WeekInput disabled={mode !== Modes.WHAT_IF} value={whatIfCrewPerWeek} onChange={setWhatIfCrewPerWeek} />
            </H2>
            <H2>What-If Job Capacity</H2>
          </Label>
          <div className="col-span-3">
            <NumberGrid
              rows={rows.slice(1, 3)}
              onBlur={handleWhatIfBlur}
            />
          </div>
        </div>

        {/* Crew Capacity */}
        <div className="flex items-stretch gap-2 border-t border-gray-300">
          <Label>
            <H2>
              <p>Crew Capacity (Jobs)</p>
              <div className="ml-auto flex items-center gap-1">

                {/* Crew Add Button */}
                {crewWeeksEditorOpen && (<button
                  disabled={mode !== Modes.CREW_CAPACITY}
                  onClick={onCrewAdd}
                  className={classNames(mode === Modes.CREW_CAPACITY ? "text-purple-400 hover:text-purple-500" : "text-gray-300")}
                >
                  <PlusCircleIcon className="h-6 w-6" />
                </button>)}

                {/* Crew Editor Toggle */}
                <button onClick={handleCrewWeeksEditorToggle} className="text-primary-green hover:text-primary-green-600">
                  {crewWeeksEditorOpen ? (<ChevronUpIcon className="w-4 h-4 stroke-2" />) : (<ChevronDownIcon className="w-4 h-4 stroke-2" />)}
                </button>

              </div>
            </H2>
          </Label>
          <div className="col-span-3">
            <NumberGrid rows={rows.slice(3, 4)} />
          </div>
        </div>

        {/* Crew Weeks Editor */}
        {crewWeeksEditorOpen && (<div className="flex flex-col py-1">
          {crews?.map((c, i) => <CrewRow
            key={c._id}
            crew={c}
            disabled={mode !== Modes.CREW_CAPACITY}
            onChange={(plannedWeeks, weekCapacity) => handleCrewChange(i, plannedWeeks, weekCapacity)}
            onDelete={() => onCrewDelete(i)}
          />)}

          {crews.length === 0 && <div className="flex items-center gap-2 py-1">
            <Label><div className="text-gray-400 text-center grow text-sm py-0.5">No Crews</div></Label>
          </div>
          }
        </div>)}

        {/* Jobs Sent to Subs */}
        <div className="flex items-stretch gap-2">
          <Label>
            <H2>Jobs Sent to Subs</H2>
          </Label>
          <div className="col-span-3">
            <NumberGrid
              onBlur={handleJobsSentBlur}
              rows={rows.slice(4)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

type CrewRowProps = {
  crew: Crew;
  disabled: boolean;
  onDelete?: () => void;
  onChange?: (plannedWeeks: number[], weekCapacity: number) => void;
}

/**
 * A component displaying a crew row in the planned capacity table.
 * It has a /wk input and a block range input to allow the user to
 * define which weeks and how many jobs per week a crew will do.
 */
function CrewRow({ crew, disabled, onDelete, onChange }: CrewRowProps): JSX.Element {

  // TODO: get from parent?
  let blocks = Array(16).fill(null);
  for (let week of crew.plannedWeeks) {
    blocks[week] = {
      start: week,
      end: week,
    }
  }

  /**
   * Handles the change in the block range input.
   * Converts from blocks to slots and calls the parent onChange function.
   */
  function handleCrewRowChange(blocks: Block[]) {
    // Go through blocks and grab slots that are in blocks
    let plannedWeeks = blocks.reduce((acc, block) => {
      if (block) {
        for (let i = block.start; i <= block.end; i++) {
          acc.push(i);
        }
      }
      return acc;
    }, []);

    onChange(plannedWeeks, crew.weekCapacityOverride)
  }

  return <div className="flex items-center gap-2 even:bg-gray-100 py-1 group">
    <Label>
      <div className="flex items-center gap-2">
        {/* <H3 className="grow truncate">{crew.name}</H3> */}
        <h3 className="pl-2 text-xs font-normal grow truncate" title={crew.name}>{crew.name}</h3>
        {
          crew.isHypothetical ?
            (<button
              className={classNames(
                "invisible",
                !disabled ? "group-hover:visible" : ""
              )}
              onClick={onDelete}>
              <MinusCircleIcon className="h-5 w-5 text-red-500" />
            </button>)
            :
            <div className="w-5" />
        }
        <WeekInput
          value={crew.weekCapacityOverride}
          defaultValue={crew.weekCapacity}
          disabled={disabled}
          onChange={(value) => {
            onChange(crew.plannedWeeks, value);
          }}
        />
      </div>
    </Label>
    <BlockRanges
      numSlots={16}
      color={BlockColors.PURPLE}
      disabled={disabled}
      blocks={blocks}
      onChange={handleCrewRowChange}
    />
  </div>
}

