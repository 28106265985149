import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";

export type Connection = {
  name: string;
  id: string;
  status: string
}

/**
 * Returns the active connections of the user's company.
 * For example, gets connected distributors if the user
 * is within an installer.
 *
 * Before connections are fetched, returns null.
 */
export default function useConnections() {
  const [connections, setConnections] = useState<Connection[]>();

  // Get connections on load
  useEffect(() => {
    UserManager.makeAuthenticatedRequest("/api/company/connections-list")
      .then((response) => {
        if (response.data.status === "ok") {
          setConnections(response.data.connections.filter((c) => c.status === "active"));
        } else {
          console.error("Failed to get connections", response.data);
        }
      }).catch((error) => {
        console.error(error);
      });
  }, []);

  return connections;
}
