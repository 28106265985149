import React, { useEffect, useState } from 'react'
import classNames from 'src/tools/classNames';

type Props = {
  title?: string;
  description?: string;
  name: string;
  options: {
    id: string;
    label: string;
    disabled?: boolean;
  }[];
  selectedId: string;
  onChange?: (id: string) => void;
}

/**
 * Simple radio group input component.
 * Base: Tailwind UI's Radio Group > Simple inline list
 */
export default function RadioGroup({ title, description, options, selectedId, name, onChange }: Props) {
  // TODO: dark mode
  return (
    <fieldset>
      {title && <legend className="text-sm font-semibold leading-6 text-gray-900">{title}</legend>}
      {description && <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>}
      {/* <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0"> */}
      <div className="sm:flex sm:items-center sm:space-x-5 sm:space-y-0">
        {options.map((option) => (
          <div key={option.id} className="flex items-center">
            <input
              disabled={option.disabled}
              checked={option.id === selectedId}
              id={option.id}
              name={name}
              type="radio"
              className="h-4 w-4 border-gray-300 text-primary-green focus:ring-primary-green"
              onChange={() => {
                onChange?.(option.id)
              }}
            />
            <label htmlFor={option.id} className={classNames(
              "ml-3 block text-sm font-medium leading-6",
              option.disabled ? "text-gray-500" : ""
            )}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  )
}
