import { useEffect, useState } from "react";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

/**
 *  A hook that returns the markets of the user's installer.
 *
 *  Before markets are loaded, `markets` is null.
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [markets] = useMarkets();
 *  if (!markets) return <div>Loading...</div>;
 *  return <ul>{markets.map(market => <li>{market.name}</li>)}</ul>;
 *  ```
 *
 *  Other hooks: 
 *  - `useMarketSelect` - If you're looking for one market selected at a time, use `useMarketSelect`
 *  - `useMultiMarketSelect` - If you're looking for more than one market selected at a time, use `useMultiMarketSelect`
 */
export default function useMarkets(includeNullId: boolean = false): readonly MarketType[] | null {
  const [markets, setMarkets] = useState<MarketType[]>();
  const user = useCurrentUser();

  // Get markets on load
  useEffect(() => {
    if (!user || user.company?.type !== "installer") return; // Ensure installer user

    UserManager.makeAuthenticatedRequest("/api/markets/installer/find").then(
      res => {
        if (res.data.status === "ok") {
          const markets = res.data.markets;
          if (includeNullId) markets.unshift({ _id: null, name: "No Market" });
          setMarkets(markets);
        }
      }
    ).catch(err => {
      console.error(err);
    })
  }, [user]);

  return markets;
}
