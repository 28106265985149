import React, { useState } from 'react'
import Button from "../input/Button";
import Input from "../input/Input";
import EmailInput from '../input/EmailInput';
import Spinner from "../../components/Spinner";
import axios from "axios";
import UserManager from "../../tools/UserManager";

/**
 * Step three of the setup process, where the user invites teammates by email
 * 
 * @param {Object} companyData - object containing company data (companyName, companyType)
 * @param {Object} inviteEmails - object containing up to 3 invite emails
 * @param {Function} setInviteEmails - function to update invite emails
 * @param {Function} setBulkInviteEmails - function to update bulk invite emails
 * @param {boolean} useBulkInvite - boolean to determine if bulk invite is being used
 * @param {Function} setUseBulkInvite - function to update useBulkInvite
 * @param {Function} nextStep - function to move to the next step in the setup process
 * @param {Function} prevStep - function to move to the previous step in the setup process
 * @returns {JSX.Element} - step three of the setup process
 */
export default function StepThree(
    companyData,
    inviteEmails,
    setInviteEmails,
    useBulkInvite,
    setUseBulkInvite,
    nextStep,
    prevStep,
    error,
    setError,
    loading,
    setLoading,
    navigate,
    invitingCompanyId
) {

    /**
      * Validate email address using regex
      * there are many regexes for email validation, this one is simple and should work for most cases
      *
      * @param {string} email - email address to validate
      * @returns {boolean} - true if email is valid, false otherwise
      */
    function validateEmail(email) {
        // source for email regex: https://www.abstractapi.com/guides/email-validation-regex-javascript
        var re = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
        return re.test(email);
    }


    /**
     * Create the company using the company data from step 2
     * and submit the invite emails if they exist
     */
    async function submit() {
        setLoading(true);

        try {
            const response = await UserManager.makeAuthenticatedRequest("/api/company/create-company", "POST", {
                name: companyData.companyName,
                companyType: companyData.companyType,
                invitingCompanyId: invitingCompanyId,
            })
            if (response.data.status === "ok") {
                setError("");
            }
            else if (response.data.status === "error") {
                console.error("Error: ", response.data.error);
                setError("Error creating company");
            }
        } catch (error) {
            console.error("Error creating company: ", error);
            setError("Error creating company");
            return;
        }

        let allInviteEmails = inviteEmails.filter(email => email);

        for (let i = 0; i < allInviteEmails.length; i++) {
            // Make API call to send invites
            try {
                const response = await UserManager.makeAuthenticatedRequest("/api/invite/create", "POST", {
                    email: allInviteEmails[i],
                })
    
                if (response.data.status === "ok") {
                    setError("");
                }
                else if (response.data.status === "error") {
                    console.error("Error: ", response.data.error);
                    setError("Error sending invite");
                }
            } catch (error) {
                console.error("Error sending invite: ", error);
                setError("Error sending invite");
            }
        }

        setLoading(false);
        navigate("/app/get-started")
    }


    // submit button, or loading spinner if loading
    var submitButton = loading ? (
        <div style={{ position: "relative", minHeight: 40 }}>
            <Spinner />
        </div>
    ) : (
        <Button type="submit"
            onClick={() => {
                submit()
            }}
            className='px-3 mx-2 rounded-md cursor-pointer w-72'
            variant='primary-green'
        >
            {/* only show "Take me to TOA if there exists an email in inviteEmails that is not an empty string.
            inviteEmails can be an arbitrary length */}
            {(inviteEmails.length > 0 && inviteEmails.some(email => email))
                ? 'Invite and take me to TOA'
                : 'Take me to TOA'}
        </Button>
    );


    /**
     * Email input field for inviting teammates
     * either 3 individual email inputs or a bulk email input
     */
    function emailInputField() {
        if (useBulkInvite) {
            return (
                <div className='mx-2.5 py-1.5 w-full'>
                    <EmailInput
                        placeholder="Email addresses separated by commas"
                        className='text-sm font-normal leading-5 text-gray-400'
                        emails={inviteEmails}
                        setEmails={setInviteEmails}
                    />
                </div>
            );
        }
        else {
            return (
                <>
                    <div className='mx-2.5 py-1.5 w-full'>
                        <Input
                            placeholder="Email address"
                            type="text"
                            onChange={(value) => {
                                if (validateEmail(value)) {
                                    setInviteEmails([value, inviteEmails[1], inviteEmails[2]])
                                }
                                else {
                                    setInviteEmails(['', inviteEmails[1], inviteEmails[2]])
                                }
                                console.log(inviteEmails)
                            }}
                            className='text-sm font-normal leading-5 text-gray-400'
                        />
                    </div>

                    <div className='mx-2.5 py-1.5 w-full'>
                        <Input
                            placeholder="Email address"
                            type="text"
                            onChange={(value) => {
                                if (validateEmail(value)) {
                                    setInviteEmails([inviteEmails[0], value, inviteEmails[2]])
                                }
                                else {
                                    setInviteEmails([inviteEmails[0], '', inviteEmails[2]])
                                }
                                console.log(inviteEmails)
                            }}
                            className='text-sm font-normal leading-5 text-gray-400'
                        />
                    </div>

                    <div className='mx-2.5 py-1.5 w-full'>
                        <Input
                            placeholder="Email address"
                            type="text"
                            onChange={(value) => {
                                if (validateEmail(value)) {
                                    setInviteEmails([inviteEmails[0], inviteEmails[1], value])
                                }
                                else {
                                    setInviteEmails([inviteEmails[0], inviteEmails[1], ''])
                                }
                                console.log(inviteEmails)
                            }}
                            className='text-sm font-normal leading-5 text-gray-400'
                        />
                    </div>

                    <div className='mx-2.5 py-3 w-full'>
                        <div className='text-sm font-medium leading-5 text-gray-600 cursor-pointer hover:text-gray-400 dark:text-gray-500'
                            onClick={() => {
                                setUseBulkInvite(true)
                            }}
                        >
                            + Add more or invite in bulk
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <div className="flex flex-col items-center justify-center gap-2">
            <div className='flex flex-col items-center justify-center'>

                <div className='my-2.5'>
                    <div className='my-2 px-2 py-2.5 text-center'>
                        <div className='text-lg font-bold leading-6 text-gray-700 md:leading-8 md:text-2xl dark:text-gray-50'>
                            Who else is on the <span className='font-bold text-primary-rose'>{companyData.companyName}</span> team?
                        </div>
                    </div>
                </div>

                <div className='px-2 my-2 pb-2.5'>
                    <div className='text-lg font-light leading-7 text-gray-900 dark:text-gray-200'>
                        Invite teammates by email
                    </div>
                </div>

                <div className='my-2.5 py-4 w-96'>
                    <div className='flex flex-col my-2.5 gap-2 items-center'>

                        <div className='w-full mx-2.5'>
                            <div className='text-sm font-medium leading-5 text-gray-900 dark:text-gray-200'>
                                Send Invites
                            </div>
                        </div>

                        {emailInputField()}

                    </div>
                </div>
            </div>

            {/* Error Message */}
            <div className="text-red-500">{error}</div>

            <div className='my-2'>
                {submitButton}
            </div>

            <div className='mx-2.5'>
                <div className='text-sm font-medium leading-5 text-gray-600 align-middle cursor-pointer hover:text-gray-400 dark:text-gray-500'
                    onClick={() => {
                        prevStep()
                    }}
                >
                    Back
                </div>
            </div>

        </div>
    )
}