import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext.tsx';
import useS3URLs from 'src/hooks/data/files/useS3.ts';
import { LocationType } from 'src/hooks/data/locations/useLocations.ts';
import { AgreementStatus, Order, OrderStatus, OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import classNames from 'src/tools/classNames';
import { getStatusDisplayInfo } from 'src/tools/Orders/orderHelpers.tsx';
import StringHelper from 'src/utils/stringHelper.ts';
import Button from '../input/Button.js';
import IconDropdown from '../input/IconDropdown.js';
import StatusDisplay from './common/StatusDisplay.tsx';
import OrderAgreementIcons from './OrderAgreementIcons.tsx';
import { OrderAction } from './OrderList.tsx';
import UserManager from 'src/tools/UserManager.js';

type Props = {
  order: Order;
  market?: MarketType;
  location?: LocationType;
  connection: { name: string, id: string };
  view: 'rows' | 'list';
  isSelected?: boolean;
  onClick?: () => void;
  onAction?: (action: OrderAction) => void;
}

/**
 * This component displays a single order in a row or list view.
 * Used in `OrderList`.
 * Row view has more data and larger items. List view is more compact.
 *
 * The order actions and its related enums and maps make it easy to define actions
 * based on user company type and order status. Sending an `OrderAction` to the
 * `onAction` callback will prompt `OrderList` to do that action on the order
 * for this row item.
 *
 * `SpecialStatusLabels`, `SpecialStatusVariant`, and `StatusActions` make it 
 * super easy to customize the row item (in style and action) based on order input. 
 */
export default function OrderRowItem({ order,
  market,
  location,
  connection,
  view,
  isSelected,
  onClick = () => { },
  onAction = () => { }
}: Props) {
  const user = useCurrentUser();
  const companyType = user?.company?.type;
  const [company, setCompany] = useState({});

  const isInstaller = user?.company?.type === "installer";
  const isDistributor = user?.company?.type === "distributor";

  const deliveryDateString = getDeliveryDateString(order);
  const shortDateString = getDeliveryDateString(order, true);

  const installDateString = getInstallDateString(order);

  // on load, get users company to use the settings
  useEffect(() => {
    UserManager.makeAuthenticatedRequest(
      "/api/company/findmy",
      "GET",
    )
      .then(res => {
        if (res.data?.status === "ok") {
          setCompany(res.data.company);
        }
      })
      .catch(err => {
        console.error(err);
      });

  }, []);


  // ------------------------ //
  // --- Special Statuses --- //
  // ------------------------ //

  const waitingOnInstaller = order.quote?.agreements.deliveryDate.installer === AgreementStatus.PENDING
    || order.quote?.agreements.material.installer === AgreementStatus.PENDING
    || order.quote?.agreements.amount?.installer === AgreementStatus.PENDING;
  const waitingOnDistributor = order.quote?.agreements.deliveryDate.distributor === AgreementStatus.PENDING
    || order.quote?.agreements.material.distributor === AgreementStatus.PENDING
    || order.quote?.agreements.amount?.distributor === AgreementStatus.PENDING;

  /**
    * Actions specific to different user types
    * and specific statuses. For example, a distributor can
    * add a quote to an order that is WAITING_FOR_QUOTE.
    */
  const StatusActions = {
    "installer": {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]:
        !waitingOnInstaller ? null : <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
    },
    "distributor": {
      [OrderStatus.WAITING_FOR_QUOTE]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.ADD_QUOTE);
          }}
        >
          Add Quote
        </Button>,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]:
        !waitingOnDistributor ? null : <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.APPROVE_ORDER);
          }}
        >
          Review
        </Button>,
      [OrderStatus.CONFIRMED]:
        // if company.settings.orders.packagingVerificationEnabled, use ADD_PICK_TICKET, otherwise use ASSIGN_TRUCK
        company?.settings?.orders?.packagingVerificationEnabled ?
          (
            <Button
              variant="primary"
              className="!px-2 !py-1"
              onClick={(e) => {
                e.stopPropagation();
                onAction(OrderAction.ADD_PICK_TICKET);
              }}
            >
              Hand off to Packaging
            </Button>
          )
          : (
            <Button
              variant="primary"
              className="!px-2 !py-1"
              onClick={(e) => {
                e.stopPropagation();
                onAction(OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK);
              }}
            >
              Hand off to Delivery
            </Button>
          ),
      [OrderStatus.PACKAGED]:
        <Button
          variant="primary"
          className="!px-2 !py-1"
          onClick={(e) => {
            e.stopPropagation();
            onAction(OrderAction.ASSIGN_TRUCK);
          }}
        >
          Assign Truck
        </Button>,
    }
  }

  // -------------- //
  // --- Consts --- //
  // -------------- //

  let statusAction = StatusActions[user?.company?.type]?.[order.status];

  // --- Special cases for actions and variants --- //
  // Need to confirm reschedule
  if (
    // Before CONFIRMED
    ![
      OrderStatus.WAITING_FOR_QUOTE,
      OrderStatus.WAITING_FOR_QUOTE_APPROVAL,
      OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT,
    ].includes(order.status)
    &&
    // PENDING date
    order.quote?.agreements.deliveryDate[companyType] === AgreementStatus.PENDING
  ) {
    statusAction = <Button
      variant="primary"
      className="!px-2 !py-1"
      onClick={(e) => {
        e.stopPropagation();
        onAction(OrderAction.REVIEW_DATE);
      }}
    >
      Review Date
    </Button>
  }


  // TODO: improve this. This ends up doing lots of requests but combined it fails both instead of individually
  // so if no quote, then bom doesn't work
  const bomLinkArr = useS3URLs(order.files.billOfMaterials.at(-1)?.filePath ? [order.files.billOfMaterials[0]?.filePath] : []);
  const quoteLinkArr = useS3URLs(order.quote?.file?.filePath ? [order.quote?.file?.filePath] : []);
  const pickTicketLinkArr = useS3URLs(order.files.pickTickets.at(-1)?.filePath ? [order.files.pickTickets[0]?.filePath] : []);

  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length ? quoteLinkArr[0].getObjectSignedUrl : null;
  const pickTicketLink = pickTicketLinkArr?.length ? pickTicketLinkArr[0].getObjectSignedUrl : null;

  const rowActions = [
    {
      label: "View Order",
      href: `../details/${order._id}`
    },
    {
      label: "Add Note",
      value: OrderAction.ADD_NOTE
    },
    {
      label: "Reschedule",
      value: OrderAction.RESCHEDULE_ORDER
    },
    {
      label: "Cancel Order",
      value: OrderAction.CANCEL_ORDER
    },
  ];

  return view === "rows" ? (

    // ----------------- //
    // --- ROWS VIEW --- //
    // ----------------- //
    //
    // >=2xl Layout
    //   1fr          | 1fr           | auto     | auto   | auto  | auto | auto
    // +--------------+---------------+----------------------------------+---------------+
    // | Link/Name    | Badges        | Agreement Icons     <>      <>   | Actions       |
    // +--------------+---------------+----------+--------+-------+------+---------------+
    // | Address      | Install Date  | "BOM"    | BOM    | "PO#" | PO#  | <>            |
    // +--------------+---------------+----------+--------+-------+------+---------------+
    // | Contact Name | Delivery Date | "Quote"  | Quote  | "SO#" | SO#  | <>            |
    // +--------------+---------------+----------+--------+-------+------+---------------+
    // | Status       | Pick Ticket   | "Amount" | Amount | <>      <>   | Status Action |
    // +--------------+---------------+----------+--------+--------------+---------------+
    //
    // <2xl layout
    //   1fr          | 1fr           | auto     | auto   | auto
    // +--------------+---------------+-------------------+---------------+
    // | Link/Name    | Badges        | Agreement Icons   | Actions       |
    // +--------------+---------------+----------+--------+---------------+
    // | Address      | Install Date  | "BOM"    | BOM    | <>            |
    // +--------------+---------------+----------+--------+---------------+
    // | Contact Name | Delivery Date | "Quote"  | Quote  | <>            |
    // +--------------+---------------+----------+--------+---------------+
    // | Status       | Pick Ticket   | "Amount" | Amount | Status Action |
    // +--------------+---------------+----------+--------+---------------+
    <div
      data-disabled={isSelected}
      className={classNames(
        // Grid layout: Name/address/status, badges/dates, BOM/Quote/amount, PO#/SO# (>=2xl), actions
        "group/row grid grid-cols-subgrid col-span-full cursor-pointer gap-y-0.5",

        // Padding
        "p-4",

        // Coloring
        // TODO: dark mode
        "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border border-200",
      )}
      onClick={onClick}
    >
      {/* ----- Row 1 ----- */}

      {/* Link/Name */}
      <div className="flex items-center gap-1">
        <Link
          to={`../details/${order._id}`}
          className="relative bottom-0.5 text-primary-green cursor-pointer hover:text-primary-green-700"
        >
          <ArrowTopRightOnSquareIcon className="inline w-5 h-5 stroke-2" />
        </Link>
        <p className="text-sm font-semibold text-gray-600 line-clamp-1">
          {order.name ?? <span className="italic">No Name</span>}
        </p>
      </div>

      {/* Badges */}
      <div className="flex gap-2">
        {isInstaller && <MinorBadge>{market?.name ?? <span className="italic">No Market</span>}</MinorBadge>}
        {isDistributor && <MinorBadge>{location?.name ?? <span className="italic">No Location</span>}</MinorBadge>}
        <MinorBadge>{connection?.name}</MinorBadge>
      </div>

      {/* Agreement Icons */}
      <div className="col-span-2 2xl:col-span-4">
        <OrderAgreementIcons order={order} />
      </div>

      {/* Actions */}
      <div
        className="flex justify-end"
        onClick={e => e.stopPropagation()}
      >
        <IconDropdown
          options={rowActions}
          onSelected={(s: { label: string, value: any }) => {
            onAction(s.value as OrderAction);
          }}
        />
      </div>

      {/* ----- Row 2 ----- */}

      {/* Address */}
      <p className="text-sm font-normal text-gray-600 line-clamp-1">{addressToString(order.orderAddress)}</p>

      {/* Install Date */}
      <div className="text-sm font-normal text-gray-600">
        <span className="font-semibold">Install Date: </span>{installDateString}
      </div>

      {/* BOM Label */}
      <div className="text-sm font-semibold text-gray-600">BOM:</div>

      {/* BOM Value */}
      <p className="text-sm font-normal text-gray-600">
        {
          order.files.billOfMaterials ?
            <a
              data-disabled={bomLink == null}
              className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={bomLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.files.billOfMaterials.at(-1)?.filePath)}
            </a> :
            "--"
        }
      </p>

      {/* PO# Label */}
      <div className="hidden text-sm font-normal text-gray-600 2xl:block">PO#:</div>

      {/* PO# Value */}
      <div className="hidden text-sm font-normal text-gray-600 2xl:block whitespace-nowrap">{order.poNumber ?? "--"}</div>

      {/* Spacing */}
      <div></div>

      {/* ----- Row 3 ----- */}

      {/* Contact Name */}
      <p className="text-sm font-normal text-gray-600 line-clamp-1">
        {order.contact?.name ?? <span className="italic text-gray-400">No Contact Name</span>}
      </p>

      {/* Delivery Date */}
      <div className="text-sm font-normal text-gray-600">
        <span className="font-semibold">Delivery: </span>{deliveryDateString}
      </div>

      {/* Quote Label */}
      <div className="text-sm font-semibold text-gray-600">Quote:</div>

      {/* Quote Value */}
      <p className="text-sm font-normal text-gray-600">
        {
          order.quote?.file ?
            <a
              data-disabled={quoteLink == null}
              className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={quoteLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.quote.file.filePath)}
            </a> :
            "--"
        }
      </p>

      {/* SO# Label */}
      <div className="hidden text-sm font-normal text-gray-600 2xl:block">SO#:</div>

      {/* SO# Value */}
      <div className="hidden text-sm font-normal text-gray-600 2xl:block whitespace-nowrap">{order.soNumber ?? "--"}</div>

      {/* Spacing */}
      <div></div>

      {/* ----- Row 4 ----- */}

      {/* Status Badge */}
      <StatusDisplay
        order={order}
        companyType={user?.company?.type}
      />

      {/* Pick Ticket */}
      <div className="text-sm font-normal text-gray-600">
        <span className="font-semibold">Pick Ticket: </span>
        {
          order.files.pickTickets && order.files.pickTickets.length ?
            <a
              data-disabled={pickTicketLink == null}
              className="inline line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={pickTicketLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.files.pickTickets.at(-1)?.filePath)}
            </a> :
            "--"
        }
      </div>

      {/* Amount Label */}
      <div className="text-sm font-semibold text-gray-600">Amount:</div>

      {/* Amount Value */}
      <div className="text-sm font-normal text-gray-600">
        {order.quote?.value ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(order.quote.value) : "--"}
      </div>

      {/* Spacing */}
      <div className="hidden col-span-2 2xl:block"></div>

      {/* Action */}
      <div className="flex justify-end whitespace-nowrap">
        {statusAction}
      </div>

    </div >
  )

    // ----------------- //
    // --- LIST VIEW --- //
    // ----------------- //
    : (
      // TODO: select state
      <div
        data-disabled={isSelected}
        className={classNames(
          "group/row grid grid-cols-subgrid",
          "col-span-full bg-white items-center py-1 px-2",
          "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border border-200",
          "cursor-pointer",
          "text-sm",
        )}
        onClick={onClick}
      >
        {/* TODO: add TOA ID */}
        <div className="flex items-center gap-1 line-clamp-2">
          <Link to={`../details/${order._id}`} className="cursor-pointer text-primary-green hover:text-primary-green-700"><ArrowTopRightOnSquareIcon className="inline w-5 h-5 stroke-2" /></Link>
          {order.name ?? <span className="italic">No Name</span>}
        </div>
        <div className="flex flex-wrap gap-2">
          <StatusDisplay
            order={order}
            companyType={user?.company?.type}
          />
          {isInstaller && <MinorBadge>{market?.name ?? <span className="italic">No Market</span>}</MinorBadge>}
          {isDistributor && <MinorBadge>{location?.name ?? <span className="italic">No Location</span>}</MinorBadge>}
          <MinorBadge>{connection.name}</MinorBadge>
        </div>
        <div>{shortDateString}</div>
        <div className=""><OrderAgreementIcons order={order} small /></div>
        <div className="flex items-center justify-end">
          <p className="-my-1">{statusAction}</p>
          <div onClick={e => e.stopPropagation()} className="flex items-center justify-end">
            <IconDropdown
              options={rowActions}
              onSelected={(s: { label: string, value: any }) => {
                onAction(s.value as OrderAction);
              }}
            />
          </div>
        </div>
      </div>
    )
}

/**
 * Returns a formatted string for the delivery date and time range
 * of the given order object.
 * Format examples:
 * - if not short: "Monday 1/2 @ 1pm - 3pm"
* - if short: "1/2 1-3pm"
 */
export function getDeliveryDateString(order: Order, short: boolean = false): string {
  let date = moment(order.requestedDelivery.deliveryDate).utc(); // UTC to ignore time
  let startTime = moment(order.requestedDelivery.deliveryWindow.start);// TODO: we'll need to handle timezones better with these time ranges
  let endTime = moment(order.requestedDelivery.deliveryWindow.end);

  const dateFormat = !short ? 'ddd M/D' : "M/D";
  const timeFormat = !short ? 'h:mma' : 'ha';

  let dateStr = date.format(dateFormat);
  let startTimeStr = startTime.format(timeFormat);
  let endTimeStr = endTime.format(timeFormat);

  return !short
    ? `${dateStr} @ ${startTimeStr}-${endTimeStr}`
    : `${dateStr} ${startTimeStr}-${endTimeStr}`
}

/**
 * Returns a formatted string for the installation date of the given order object.
 */
function getInstallDateString(order: Order) {
  if (!order.installationDate) return "--";

  let date = moment(order.installationDate).utc() // UTC to ignore time
  return date.format("ddd M/D")
}

type MinorBadgeProps = {
  children: ReactNode;
  variant?: MinorBadgeVariant;
}
export enum MinorBadgeVariant {
  DEFAULT = "DEFAULT",
  WARNING = "WARNING",
}

/**
  * Minor badge for displaying things like tags.
  * Used for status, market/location, and connection.
  */
export function MinorBadge({
  children,
  variant = MinorBadgeVariant.DEFAULT,
}: MinorBadgeProps): JSX.Element {

  let color = "";
  switch (variant) {
    case MinorBadgeVariant.WARNING:
      color = "bg-orange-100 text-orange-700 border-orange-200";
      break;
    case MinorBadgeVariant.DEFAULT:
    default:
      color = "bg-gray-50 text-gray-700 border-gray-200";
      break;
  }

  return <div
    className={classNames(
      "border text-sm font-medium rounded w-max h-max px-1",
      color
    )}
  >{children}</div>
}

/**
  * Converts an address object to a string.
  * No new lines are added.
  */
export function addressToString(address: { line1: string, line2?: string, city: string, state: string, postalCode: number }): string {
  const { line1, line2, city, state, postalCode } = address
  return `${line1}, ${line2 ? line2 + ", " : ""}${city}, ${state} ${postalCode}`

}

/**
  * Handles getting a file name from a file path.
  * Truncates at 20 characters by default.
  */
function getFileName(filePath: string, truncate: boolean = true): string {
  let fileName = filePath.split("/").pop();
  if (truncate) {
    fileName = StringHelper.truncate(fileName, 20);
  }
  return fileName
}
