import React, { useState, useEffect } from 'react'
import Modal from '../../Modal'
import Input from '../../input/Input'
import Button from '../../input/Button'
import Switch from '../../input/Switch.tsx'

export default function EditStageModal({
  open: ex_open,
  setOpen: ex_setOpen,
  stage: ex_stage = null,
  handleEditStage = () => { },
}) {
  const [open, setOpen] = useState(ex_open);
  const [stage, setStage] = useState(ex_stage);

  useEffect(() => {
    if (ex_open !== open) {
      setOpen(ex_open);
    }
    setStage(ex_stage);
  }, [ex_open, ex_stage]);

  // Short circuit if no stage
  if (!stage) return null;

  function handleSave() {
    if (validate(stage, ex_stage)) {
      handleEditStage(stage);
    }
  }

  return (
    <Modal
      open={open}
      setOpen={ex_setOpen}
    >
      <div>
        {/* Content */}
        <div className="text-gray-900 font-medium text-sm flex flex-col gap-6">
          {/* Header */}
          <div>
            <div className="font-semibold text-base">Stage</div>
            <div className="text-gray-500 font-normal">
              Edit Stage to identify where a job is in the pipeline.
            </div>
          </div>

          {/* Name */}
          <Input
            value={stage.name}
            label="Stage Name"
            placeholder="Sold"
            type="text"
            highlightOnEmpty
            onChange={(value) => {
              setStage({ ...stage, name: value });
            }}
          />

          {/* Description */}
          <Input
            value={stage.description}
            label="Description"
            placeholder="Job has been sold"
            type="textarea"
            onChange={(value) => {
              setStage({ ...stage, description: value });
            }}
          />

          {/* Active */}
          <div className="flex gap-2 items-center">
            <div>Set as Active:</div>
            <Switch
              checked={stage.status === "active"}
              onChange={(checked) => {
                setStage({ ...stage, status: checked ? "active" : "inactive" });
              }}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end">
          <Button
            className="mt-6"
            variant="primary-green"
            onClick={handleSave}
            disabled={!validate(stage, ex_stage)}
          >
            Save Stage
          </Button>

          <Button
            className="mt-6 ml-2"
            variant="secondary"
            onClick={() => {
              ex_setOpen(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

function validate(stage, oldStage) {
  // Has name
  // Has changed
  return stage.name && stage.name.length > 0 && JSON.stringify(stage) !== JSON.stringify(oldStage);
}
