/**
 * Calculates the available and remaining jobs per week from
 * a user planned job input and the TOA estimated jobs.
 *
 * Available is the previous week's remainder and the newly
 * available jobs of the current week. Remainder is the current
 * week's available minus the input jobs.
 * Remainders are non-negative (clamped >= 0).
 */
export default function generateAvailableAndRemainder(inputs: number[], estimated: number[]): { available: number[], remainder: number[] } {
  if (inputs.length !== estimated.length) {
    throw new Error("Inputs and estimated must be same length");
  }

  let available = [];
  let remainder = [];

  // Go through weeks
  for (let i = 0; i < inputs.length; i++) {
    // Max of 0 and last week's remainder so it doesn't calculate off negative (don't accumulate missing)
    let lastRemainder: number = i === 0 ? 0 : Math.max(remainder[i - 1], 0);

    // Available is last week's remainder plus this week's estimated
    available.push(Math.ceil(lastRemainder + estimated[i]));

    // Remainder is available minus input
    remainder.push(Math.ceil(available[i] - inputs[i]));
  }

  return { available, remainder };
}
