import React, { useState, useEffect } from "react";
import Card from "src/components/Card";
import InputTable from "src/components/input/InputTable";
import { getSubscribeToEventsTableColumns } from "src/tools/dataConnectors";
import {
  ArrowsPointingOutIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Modal from "src/components/Modal";
import OrderUpdatesSampleJSON from "src/assets/sample_json/OrderUpdatesSampleJSON";
import UserManager from "src/tools/UserManager";


/**
 * Currently the only function of this page is to allow the user to subscribe to events via webhooks
 * 
 * @returns - the DataConnectorsPage component
*/
export default function DataConnectorsPage() {

  const [jsonSampleModalOpen, setJsonSampleModalOpen] = useState(false);

  const [jsonSampleFile, setJsonSampleFile] = useState(null);

  const [tableData, setTableData] = useState([]);

  // TODO: add more webhook documentation than just the sample JSON
  const webhookTypeMap = {
    "ORDER_UPDATES": {
      events: "Order Updates",
      "json-sample": OrderUpdatesSampleJSON,
    }
  }

  // on page load, get current webhooks and add them to the table
  useEffect(() => {
    UserManager.makeAuthenticatedRequest(
      "/api/company/findmy",
      "GET"
    )
      .then((response) => {
        if (response.data.status === "ok") {
          const webhooks = response.data.company.webhooks;
          setTableData(Object.keys(webhooks).map((webhook) => {
            return {
              id: webhook,
              events: { value: webhookTypeMap[webhook].events, className: "" },
              "custom-url": { value: webhooks[webhook].url, className: "" },
              "json-sample": {
                value:
                  <div
                    onClick={() => {
                      setJsonSampleFile(webhookTypeMap[webhook]["json-sample"]);
                      setJsonSampleModalOpen(true);
                    }}
                  >
                    <ArrowsPointingOutIcon
                      className="w-6 h-6 cursor-pointer text-primary-green hover:text-primary-green-400"
                    />
                  </div>,
                className: ""
              },
              rowStatus: webhooks[webhook].url ? "readonly" : "editing",
            }
          }
          ))
        }
      })
  }, []);

  /**
   * Save the webhook to the database
   */
  async function saveWebhook(webhookUrl, webhookType) {
    try {
      const response = await UserManager.makeAuthenticatedRequest(
        "/api/installers/add-webhook",
        "POST",
        {
          webhookUrl,
          webhookType
        }
      )

      // TODO: add toast for success or error
      if (response.data.status === "ok") {
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  }


  return (
    <>
      <Modal
        open={jsonSampleModalOpen}
        setOpen={setJsonSampleModalOpen}
        customMaxWidth={800}
      >
        <div
          className="flex flex-col items-center justify-start gap-4 max-h-[900px]"
        >
          <div
            className="flex flex-row items-center justify-between w-full"
          >
            <div className="text-lg font-semibold">JSON Sample</div>
            <XMarkIcon
              className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-300'
              onClick={() => setJsonSampleModalOpen(false)}
            />
          </div>
          <div
            // always show vertical scrollbar
            className="w-full p-2 overflow-y-scroll bg-gray-100 border-t border-b border-l border-gray-200"
          >
            <code>
              {/* convert the json file to a string, preserve newlines and whitespace */}
              <pre
                className="text-base"
              >
                {JSON.stringify(OrderUpdatesSampleJSON, null, 2)}
              </pre>
            </code>
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-center">
        <div className="relative grow">
          {/* TODO: redesign UI/UX to have link for API docs and distinguish it from webhooks here */}
          {/* <div className="absolute inset-0 flex justify-center items-center z-[-1] text-gray-500">Loading</div>
        <iframe title="TOA Integration Documentation" className="w-full h-[calc(100vh-200px)]" src="https://toa.readme.io" /> */}

          <div
            className="flex flex-col items-center justify-start mt-12"
          >
            <div
              className="text-xl font-semibold text-center align-middle"
            >
              Subscribe to Events
            </div>
            <div
              className="text-base font-medium text-center text-gray-500 align-middle"
            >
              Add in a custom URL to subscribe to different events within TOA
            </div>
          </div>

          <div
            className="mx-24 !min-w-[900px] mt-4"
          >
            <Card>
              <InputTable
                tableData={tableData}
                setTableData={setTableData}
                columns={getSubscribeToEventsTableColumns()}
                innerAddButton={false}
                outerAddButton={false}
                height={"calc(84vh - 300px)"}
                ellipsisDropdown={false}
                useOnBlur={false}
                onSaveRow={async (newRow) => {
                  return await saveWebhook(newRow["custom-url"].value, newRow.id)
                }}
                setError={() => { }}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
