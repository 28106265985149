import { useEffect, useState } from "react";
import S3, { S3File } from "src/tools/S3/s3.ts";
import UserManager from "src/tools/UserManager";

/**
 * Stateful wrapper for S3.get.
 * Returns an array of S3File objects.
 * Empty array if no filepaths are provided or if no files are found.
 */
export default function useS3URLs(
  filepaths: string[],
): S3File[] {
  const [files, setFiles] = useState<S3File[]>([]);

  // --------------- //
  // --- Effects --- //
  // --------------- //

  // Get presigned URLs for the given filepaths
  useEffect(() => {
    if (!filepaths || !filepaths.length) return;

    const abortController = new AbortController();

    S3.get(filepaths, abortController.signal)
      .then((res) => {
        setFiles(res)
      })
      .catch(err => {
        console.error(err)
      })

    return () => {
      abortController.abort();
    }
  }, [filepaths.join(",")]); // Depend on values of filepaths

  return files;
}
