import React, { useEffect } from "react";
import Breadcrumbs from "../../components/nav/Breadcrumbs";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/input/Button";
import Card from "../../components/Card";
import Dropdown from "../../components/input/Dropdown";
import Input from "../../components/input/Input";
import Switch from "../../components/input/Switch.tsx";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import UserManager from "../../tools/UserManager";
import moment from "moment";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import Modal from "../../components/Modal";

import pluralize from "pluralize";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleMaterial = {
  _id: 1,
  name: "V Sun 420",
  brand: "V Sun",
  watts: "420",
  model: "123-420-VSUN",
  jobs: [6, 5, 12, 4, 5, 6, 3, 5],
  createdAt: "2021-01-01",
  createdBy: {
    _id: 1,
    name: "John Doe",
  },
  modified: "2021-01-01",
  active: false,
  activeHistory: [
    {
      date: "2021-02-01",
      active: true,
    },
    {
      date: "2021-01-01",
      active: false,
    },
  ],
  group: {
    _id: 1,
    name: "Panel",
  },
};

export default function MaterialPage() {
  // Get material id from url
  const { id } = useParams();

  // Get edit mode from url query
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  var editMode = searchParams.get("edit") === "true";
  const [material, setMaterial] = React.useState(null);
  const [tempMaterial, setTempMaterial] = React.useState({});
  const [isEditMode, setEditMode] = React.useState(editMode);

  const [showMakeDefaultModal, setShowMakeDefaultModal] = React.useState(false);

  const [groups, setGroups] = React.useState(null);

  useEffect(() => {
    // Manage edit mode
    if (editMode !== isEditMode) {
      setEditMode(editMode);
      setTempMaterial(material);
    }

    // Get material
    if (!material) {
      UserManager.makeAuthenticatedRequest(
        "/api/operations-setup/materials/get-material/" + id,
        "GET"
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            var mat = res.data.material;
            mat.group = res.data.group;
            setMaterial(mat);
            setTempMaterial(mat);
          } else {
            console.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // Get material groups
    if (!groups) {
      UserManager.makeAuthenticatedRequest(
        "/api/operations-setup/materials/get-groups",
        "GET"
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            setGroups(res.data.materialGroups);
          } else {
            console.error(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location]);

  // Stats content
  var statsCardContent = {
    left: [
      // Total Jobs
      {
        label: "Total Jobs",
        value: formatNumber(material?.jobCount || 0),
        loadingSize: {
          width: 150,
        },
      },
    ],
    right: [],
  };

  function handleSave() {
    // Set material to null to force reload / loading state
    setMaterial(null);

    // Update material to temp material
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/materials/update-material/" + id,
      "POST",
      tempMaterial
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var mat = res.data.material;
          mat.group = res.data.group;
          setMaterial(mat);
          setTempMaterial(mat);

          // Navigate to view mode
          searchParams.set("edit", "false");
          navigate(`${location.pathname}?${searchParams.toString()}`);
        } else {
          console.error(res.data.error);
          alert("Error saving material. Please reload.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleMakeDefaultClick() {
    setShowMakeDefaultModal(true);
  }

  function handleMakeDefaultConfirm() {
    setShowMakeDefaultModal(false);

    UserManager.makeAuthenticatedRequest(
      "/api/materials/make-default",
      "POST",
      { materialId: id }
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var mat = res.data.material;
          mat.group = res.data.group;
          setMaterial(mat);
          setTempMaterial(mat);
        } else {
          console.error(res.data.error);
          alert("Error making material default. Please reload.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  var actions = !isEditMode ? (
    <>
      {/* <Button variant="secondary">
        <div className="text-gray-500">De-activate</div>
      </Button>
      <Button variant="secondary">
        <div className="text-gray-500">Clone</div>
      </Button> */}
      {!material?.isDefaultMaterial ? (
        <Button variant="primary-rose" onClick={handleMakeDefaultClick}>
          Make Default
        </Button>
      ) : (
        <div className="p-2 text-sm font-medium border rounded-lg border-primary-rose-200 text-primary-rose bg-primary-rose-50">
          Default
        </div>
      )}
      <Button
        variant="secondary"
        onClick={() => {
          searchParams.set("edit", "true");
          navigate(`${location.pathname}?${searchParams.toString()}`);
        }}
      >
        <div className="text-gray-500">Edit</div>
      </Button>
    </>
  ) : (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          searchParams.set("edit", "false");
          navigate(`${location.pathname}?${searchParams.toString()}`);
        }}
      >
        <div className="text-gray-500">Cancel</div>
      </Button>
      <Button variant="primary-green" onClick={handleSave}>
        <div className="text-white">Save</div>
      </Button>
    </>
  );

  // TODO: implement custom fields

  let materialGroup = groups?.find(
    (group) => group._id === material?.group._id
  );

  return (
    <div className="m-4">
      <Modal open={showMakeDefaultModal} setOpen={setShowMakeDefaultModal}>
        <div className="flex flex-col gap-6">
          <h2 className="text-lg font-semibold">
            Are you sure you want to set this as the default material?
          </h2>
          <p>
            By proceeding, this material will become the default for its group
            {materialGroup?.name &&
              `, ${pluralize(materialGroup.name, 2, false)}`}
            . This will replace the current default material.
          </p>
          <div className="flex justify-end gap-2 mt-4">
            <Button
              variant="secondary"
              onClick={() => {
                setShowMakeDefaultModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleMakeDefaultConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>

      {/* Breadcrumbs */}
      <Breadcrumbs
        pages={[
          {
            name: "Operations Setup",
            href: "/app/operations-setup",
            current: false,
          },
          {
            name: "Materials",
            href: "/app/operations-setup/material",
            current: false,
          },
          {
            name: material?.name,
            href: "/app/operations-setup/material/" + id,
            current: true,
            loading: !material,
          },
        ]}
      />

      <div className="flex flex-col gap-3 mt-2">
        {/* Header and Actions */}
        <div className="flex flex-row items-center justify-between">
          {/* Header */}
          <div className="text-2xl font-bold leading-8 text-gray-900">
            {material ? material.name : <LoadingBar height={32} width={200} />}
          </div>

          {/* Actions */}
          <div className="flex flex-row gap-2">{actions}</div>
        </div>

        {/* Content */}
        <Card className="flex flex-col mt-5">
          {/* Header */}
          <div className="flex flex-row items-center justify-between px-8 pb-4 -mx-4 border-b">
            {/* Title and Subtitle */}
            <div className="flex flex-col text-base grow">
              <div className="text-xl font-semibold leading-7 text-gray-900">
                Information
              </div>
              <div className="text-base font-normal leading-6 text-gray-500 ">
                System data used for pipeline management
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-col gap-3">
            {/* Default Fields */}
            <div className="flex flex-row justify-around gap-12 mx-12 my-6 text-sm font-normal leading-5">
              {/* Left Column */}
              <div className="flex flex-col divide-y grow">
                {/* Name */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Material Name</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <Input
                          placeholder={material.name}
                          type="text"
                          value={tempMaterial.name}
                          onChange={(value) => {
                            setTempMaterial({ ...tempMaterial, name: value });
                          }}
                        />
                      ) : (
                        material.name
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : 150}
                        height={isEditMode ? 30 : undefined}
                      />
                    )}
                  </div>
                </div>
                {/* Group */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Material Group</div>
                  <div className="text-gray-900 basis-1/2">
                    {material && (!editMode || groups) ? (
                      isEditMode ? (
                        <Dropdown
                          options={
                            groups && [
                              groups.map((group) => {
                                return {
                                  label: group.name,
                                  value: group._id,
                                };
                              }),
                            ]
                          }
                          wide
                          selectedValue={tempMaterial.materialGroup}
                          onSelected={(option) => {
                            setTempMaterial({
                              ...tempMaterial,
                              materialGroup: option.value,
                            });
                          }}
                          text={
                            <span className="font-normal text-gray-500">
                              {material.group.name}
                            </span>
                          }
                        />
                      ) : (
                        material.group.name
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : undefined}
                        height={isEditMode ? 30 : undefined}
                      />
                    )}
                  </div>
                </div>
                {/* Brand */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Brand</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <Input
                          placeholder={material.brand}
                          type="text"
                          value={tempMaterial.brand}
                          onChange={(value) => {
                            setTempMaterial({ ...tempMaterial, brand: value });
                          }}
                        />
                      ) : (
                        material.brand
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : 75}
                        height={isEditMode ? 30 : undefined}
                      />
                    )}
                  </div>
                </div>
                {/* Model */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Model</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <Input
                          placeholder={material.model}
                          type="text"
                          value={tempMaterial.model}
                          onChange={(value) => {
                            setTempMaterial({ ...tempMaterial, model: value });
                          }}
                        />
                      ) : (
                        material.model
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : 175}
                        height={isEditMode ? 30 : undefined}
                      />
                    )}
                  </div>
                </div>
                {/* Description */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Description</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <Input
                          placeholder={material.description}
                          type="textarea"
                          value={tempMaterial.description}
                          onChange={(value) => {
                            setTempMaterial({
                              ...tempMaterial,
                              description: value,
                            });
                          }}
                        />
                      ) : (
                        material.description
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : 200}
                        height={isEditMode ? 150 : undefined}
                        notFullRound={isEditMode}
                      />
                    )}
                  </div>
                </div>
                {/* Default Quantity */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">
                    Default Quantity
                  </div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <div className="flex flex-col gap-1">
                          <Dropdown
                            options={[
                              [
                                {
                                  label: "Constant",
                                  value: "constant",
                                },
                                {
                                  label: "Per kW", // TODO: adjust when perUnit is implemented fully
                                  value: "perUnit",
                                },
                              ],
                            ]}
                            wide
                            justifyLeft
                            selectedValue={
                              tempMaterial.defaultQuantityConfig?.configType
                            }
                            onSelected={(option) => {
                              let type = option.value;
                              let newConfig =
                                tempMaterial.defaultQuantityConfig;

                              if (!newConfig) {
                                newConfig = {
                                  value: 0,
                                };
                              }

                              newConfig.configType = type;

                              setTempMaterial({
                                ...tempMaterial,
                                defaultQuantityConfig: newConfig,
                              });
                            }}
                          />
                          <Input
                            placeholder={material.defaultQuantityConfig?.value}
                            type="number"
                            value={
                              tempMaterial.defaultQuantityConfig?.value ?? ""
                            }
                            onChange={(value) => {
                              var newConfig =
                                tempMaterial.defaultQuantityConfig;

                              if (!newConfig) {
                                newConfig = {
                                  configType: "constant",
                                  value: 0,
                                };
                              }

                              newConfig.value = value;
                              setTempMaterial({
                                ...tempMaterial,
                                defaultQuantityConfig: newConfig,
                              });
                            }}
                          />
                        </div>
                      ) : material.defaultQuantityConfig ? (
                        <div>
                          {material.defaultQuantityConfig.configType ===
                            "constant"
                            ? formatNumber(material.defaultQuantityConfig.value)
                            : `${formatNumber(
                              material.defaultQuantityConfig.value
                            )} / kW`}
                        </div>
                      ) : (
                        "-"
                      )
                    ) : (
                      <LoadingBar
                        width={isEditMode ? 300 : 200}
                        height={isEditMode ? 150 : undefined}
                        notFullRound={isEditMode}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Right Column */}
              <div className="flex flex-col divide-y grow">
                {/* Created Date */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Created</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      formatDate(material.createdAt)
                    ) : (
                      <LoadingBar width={150} />
                    )}
                  </div>
                </div>
                {/* Modified */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Modified</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      formatDate(material.updatedAt)
                    ) : (
                      <LoadingBar width={150} />
                    )}
                  </div>
                </div>
                {/* Created By */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Created By</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      material.createdBy &&
                      `${material.createdBy?.firstName || ""} ${material.createdBy?.lastName || ""
                      }`
                    ) : (
                      <LoadingBar width={100} />
                    )}
                  </div>
                </div>
                {/* Activated Date */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">
                    {material?.active ? "A" : "Dea"}ctivated Date
                  </div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      formatDate(
                        material.activeHistory?.reduce(
                          (a, b) => {
                            return a.date > b.date ? a : b;
                          },
                          { date: material.createdAt }
                        ).date
                      )
                    ) : (
                      <LoadingBar width={150} />
                    )}
                  </div>
                </div>
                {/* Active */}
                <div className="flex items-center justify-between py-3">
                  <div className="text-gray-500 basis-1/2">Active</div>
                  <div className="text-gray-900 basis-1/2">
                    {material ? (
                      isEditMode ? (
                        <Switch
                          checked={tempMaterial.active}
                          setChecked={(checked) => {
                            setTempMaterial({
                              ...tempMaterial,
                              active: checked,
                            });
                          }}
                        />
                      ) : material?.active ? (
                        <CheckIcon className="w-6 h-6 text-primary-green" />
                      ) : (
                        <XMarkIcon className="w-6 h-6 text-primary-rose" />
                      )
                    ) : (
                      <LoadingBar width={50} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Custom Fields */}
            {material?.customFields?.length > 0 && (
              <div className="flex flex-row justify-around gap-12 mx-12 my-6 text-sm font-normal leading-5">
                <div className="flex flex-col gap-3 grow">
                  <div className="text-lg font-semibold text-gray-900">
                    Custom Fields
                  </div>
                  <div className="grid grid-cols-2 gap-x-12">
                    {material ? (
                      material.customFields.map((f, i) => {
                        return (
                          <div
                            key={i}
                            className={classNames(
                              "flex justify-between items-center py-3",
                              i < material.customFields.length - 2
                                ? "border-b"
                                : ""
                            )}
                          >
                            <div className="text-gray-500 capitalize basis-1/2">
                              {f.name}
                            </div>
                            <div className="text-gray-900 basis-1/2">
                              {isEditMode ? (
                                <Input
                                  placeholder={f.value}
                                  type="text"
                                  value={tempMaterial.customFields[i].value}
                                  onChange={(value) => {
                                    var newCustomFields =
                                      tempMaterial.customFields;
                                    newCustomFields[i].value = value;
                                    setTempMaterial({
                                      ...tempMaterial,
                                      customFields: newCustomFields,
                                    });
                                  }}
                                />
                              ) : (
                                f.value
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        <div className="flex gap-3 py-3 border-b">
                          <div className="grow">
                            <LoadingBar width={150} notFullRound />
                          </div>
                          <LoadingBar width={300} notFullRound />
                        </div>
                        <div className="flex gap-3 py-3">
                          <div className="grow">
                            <LoadingBar width={150} notFullRound />
                          </div>
                          <LoadingBar width={300} notFullRound />
                        </div>
                        <div className="flex gap-3 py-3">
                          <div className="grow">
                            <LoadingBar width={150} notFullRound />
                          </div>
                          <LoadingBar width={300} notFullRound />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card>

        {/* Stats Content */}
        {!editMode && (
          <Card className="flex flex-col">
            {/* Header */}
            <div className="flex flex-row items-center justify-between px-8 pb-4 -mx-4 border-b">
              {/* Title and Subtitle */}
              <div className="flex flex-col text-base">
                <div className="text-xl font-semibold leading-7 text-gray-900">
                  Stats
                </div>
                <div className="text-base font-normal leading-6 text-gray-500 ">
                  Data from jobs using this material
                </div>
              </div>
            </div>
            {/* Content */}
            <div className="flex flex-row justify-around gap-12 mx-12 my-6 text-sm font-normal leading-5">
              {/* Left Column */}
              <div className="flex flex-col divide-y grow">
                {statsCardContent.left.map((item, index) => (
                  <LineItem
                    key={index}
                    material={material}
                    item={item}
                    editMode={editMode}
                  />
                ))}
              </div>

              {/* Right Column */}
              <div className="flex flex-col divide-y grow">
                {statsCardContent.right.map((item, index) => (
                  <LineItem
                    key={index}
                    material={material}
                    item={item}
                    editMode={editMode}
                  />
                ))}
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}

function LoadingBar({ height = 20, width = 100, notFullRound = false }) {
  return (
    <div
      className={classNames(
        "bg-gray-300 animate-pulse",
        notFullRound ? "rounded-2xl" : "rounded-full"
      )}
      style={{
        height: `${height}px`,
        width: `${width}px`,
      }}
    ></div>
  );
}

function formatDate(dateStr) {
  if (!dateStr) return null;

  return moment(dateStr).format("MM / DD / YY");
}

function formatNumber(number) {
  if (number === 0) return 0;

  if (!number) return null;

  return number.toLocaleString();
}

function LineItem({ material, item, editMode }) {
  var { label, value, edit, loadingSize, required } = item;

  var content = null;

  if (!material) {
    // Loading
    content = <LoadingBar {...loadingSize} />;
  } else {
    if (editMode && edit) {
      switch (edit.type) {
        // Switch
        case "switch":
          content = <Switch checked={edit.value} onChange={edit.onChange} />;
          break;

        // Array
        case "array":
          content = (
            <div className="flex flex-col gap-2">
              {edit.value.map((item, index) => (
                <div key={index} className="flex flex-row gap-2">
                  <Input
                    placeholder=""
                    type="text"
                    value={item}
                    highlightOnEmpty
                    onChange={(value) => {
                      var newArray = [...edit.value];
                      newArray[index] = value;
                      edit.onChange(newArray);
                    }}
                  />
                  {index > 0 && (
                    <MinusCircleIcon
                      className="w-6 h-6 text-primary-rose hover:cursor-pointer"
                      onClick={() => {
                        var newArray = [...edit.value];
                        newArray.splice(index, 1);
                        edit.onChange(newArray);
                      }}
                    />
                  )}
                </div>
              ))}
              <PlusCircleIcon
                className="w-6 h-6 text-primary-green hover:cursor-pointer"
                onClick={() => {
                  edit.onChange([...edit.value, ""]);
                }}
              />
            </div>
          );
          break;

        // Regular Inputs
        default:
          content = (
            <Input
              placeholder={edit.placeholder}
              type={edit.type}
              value={edit.value}
              onChange={edit.onChange}
              highlightOnEmpty={required}
            />
          );
          break;
      }
    } else {
      // View
      content = value;
    }
  }

  return (
    <div className="flex items-center justify-between py-3">
      <div className="text-gray-500 basis-1/2">{label}</div>
      <div className="text-gray-900 basis-1/2">{content}</div>
    </div>
  );
}
