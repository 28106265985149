import Modal from "../../Modal";
import InfoTooltip from "../../InfoTooltip";
import SearchBar from "../../SearchBar";
import Dropdown from "../../input/Dropdown";
import LineChart from "../../charts/LineChart";
import ToaBarChart from "../../BarChart";
import Pagination from "../../nav/Pagination";
import Table from "../../tables/Table";

import moment from "moment";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import JobPipelineContext from "../../../contexts/pipeline/jobs/JobPipelineContext";
import ConversionBreakdownGrid from "./ConversionBreakdownGrid";
import MultiSelectListbox from "src/components/input/MultiSelectListbox.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const GRAPH_HEIGHT = 350;
const narrowJobTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stageAndDays",
  },
  // {
  //   label: "Total",
  //   field: "daysSinceCreated",
  // },
];

/**
 * Modal for displaying stats and a graph within the pipeline page.
 * This is used for showing detailed information and a chart for a specific
 * pipeline metric. This is used for conversion rate, cycle time, installs/mo,
 * and sold/mo.
 *
 * // TODO: Update this description after the component is finalized
 */
export default function StatsModal({
  open,
  setOpen,
  title = "",
  tooltip,
  mainStat = "--",
  subtitle,
  subsubtitle,
  lineChart,
  barChart,
  hideStages = false,
  sideStats = {
    goal: "--",
    threeMo: "--",
    oneMo: "--",
    units: "",
  },
  bottomStats,
  positiveBad = false,
  handleClick,
  selectedMonthIndex = -1,
  selectedMonth = null,
  jobTableFields = narrowJobTableFields,
  jobList = null,
  pageInfo = null,
  large = false,
  showConversionGrid = false,
  handlePageChange = () => { },
  sideStatsInMonths = false,
}) {
  const {
    jobs,
    formattedJobs,

    markets,
    selectedMarketIds,
    setSelectedMarketId: handleMarketSelected,

    stages,
    selectedStageIndex: selectedStage,
    setSelectedStageIndex: setSelectedStage,

    search,
    setSearch: handleSearch,

    dateFilter,

    jobPagination: pagination,

    conversionStats,
  } = useContext(JobPipelineContext);

  let marketsOptions = markets?.map((m) => ({
    label: m.name,
    value: m._id,
  }))

  // Calculate +/- for side stats
  if (sideStats) {
    sideStats.plusMinus = sideStats.oneMo - sideStats.threeMo;
  }

  if (bottomStats) {
    bottomStats.sideStats.plusMinus =
      bottomStats.sideStats.oneMo - bottomStats.sideStats.threeMo;
  }

  return (
    <Modal
      wide
      open={open}
      setOpen={(o) => {
        setOpen(o);
        if (!o) handleClick(null, -1); // Reset selected month index
      }}
    >
      <div
        className={classNames(
          "flex",
          large ? "flex-row gap-6 overflow-auto" : "flex-col"
        )}
      >
        {/* Charting Half */}
        <div className="flex flex-col gap-3">
          {/* Header */}
          <div className="flex items-center gap-3 grow">
            {/* Title etc. */}
            <div className="flex flex-col">
              {/* Title */}
              <div className="flex items-center gap-2 text-lg font-medium">
                {title}
                {tooltip && <InfoTooltip text={tooltip} />}
              </div>
              {/* Stat */}
              <div className="text-2xl font-semibold leading-8">{mainStat}</div>
              {/* Subtitle */}
              <div className="flex flex-col text-gray-500 dark:text-gray-300">
                <div className="text-sm font-medium">{subtitle}</div>
                <div className="text-xs font-normal">{subsubtitle}</div>
              </div>
            </div>
            {/* Side stats */}
            {sideStats && (
              <div className="ml-auto mr-6">
                <table className="table-fixed">
                  <thead className="border-b">
                    {/* Header */}
                    <tr className="text-base text-gray-500 dark:text-gray-300">
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        Goal
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        {sideStatsInMonths ? "3 mo" : "90d"}
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        {sideStatsInMonths ? "1 mo" : "30d"}
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        + / -
                      </th>
                    </tr>
                  </thead>
                  {/* Stats */}
                  <tbody>
                    <tr className="text-base divide-x">
                      <th className="text-lg font-semibold text-center text-gray-500 dark:text-gray-300">
                        {round(sideStats.goal)}
                        {sideStats.units}
                      </th>
                      <th className="text-lg font-semibold text-center">
                        {round(sideStats.threeMo)}
                        {sideStats.units}
                      </th>
                      <th className="text-lg font-semibold text-center">
                        {round(sideStats.oneMo)}
                        {sideStats.units}
                      </th>
                      <th
                        className={classNames(
                          "text-lg font-semibold text-center",
                          (sideStats.plusMinus >= 0) ^ positiveBad // XOR for positiveBad to flip colors
                            ? "text-green-500"
                            : "text-red-600"
                        )}
                      >
                        {sideStats.plusMinus > 0 && "+"}
                        {round(sideStats.plusMinus)}
                        {sideStats.units}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {/* Filters */}
          <div className="flex items-center gap-3">
            {/* Markets */}
            <MultiSelectListbox
              options={marketsOptions}
              selectedOptionsValues={selectedMarketIds}
              onChange={(items) => {
                handleMarketSelected(items);
              }}
              itemType="Market"
            />
            {/* Stages */}
            {!hideStages && (
              <Dropdown
                options={[
                  [{ label: "Job Completion", value: -1 }],
                  [
                    ...(stages?.slice(1).map((s, i) => {
                      return {
                        label: s.name,
                        value: i + 1, // +1 because first stage is not selectable
                      };
                    }) || []),
                  ],
                ]}
                selectedValue={selectedStage}
                placeholder="Select a Stage"
                justifyLeft
                onSelected={(item) => {
                  setSelectedStage(item.value);
                }}
              />
            )}
            {/* Search */}
            <div className="shrink">
              <SearchBar value={search} onChange={handleSearch} />
            </div>
          </div>
          {/* Chart */}
          <div className="px-3 py-6 min-w-[800px]">
            {/* Line Chart */}
            {lineChart?.data?.length > 0 && (
              <div
                style={{
                  paddingLeft: large ? `calc(${(100 * 1) / 14}% - 5px)` : 0, // Line up with conversion breakdown grid
                }}
              >
                <LineChart
                  {...lineChart}
                  dots={false}
                  handleClick={handleClick}
                  selectedIndex={selectedMonthIndex}
                />
              </div>
            )}

            {/* Bar Chart */}
            {barChart?.data?.length > 0 && (
              <div
                style={{
                  paddingLeft: large ? `calc(${(100 * 1) / 14}%)` : 0, // Line up with conversion breakdown grid
                }}
              >
                <ToaBarChart
                  {...barChart}
                  layering="layered"
                  handleClick={handleClick}
                  selectedIndex={selectedMonthIndex}
                />
              </div>
            )}

            {/* No Data */}
            {!barChart?.data?.length && !lineChart?.data?.length && (
              <div
                className="flex flex-col items-center justify-center text-gray-500 dark:text-gray-400"
                style={{ height: GRAPH_HEIGHT }}
              >
                No Data
              </div>
            )}
          </div>

          {/* Stat Grid */}
          {showConversionGrid && (
            <div className="px-4">
              <ConversionBreakdownGrid
                sold={conversionStats?.byMonth.map((m) => m.jobCount)}
                installed={conversionStats?.byMonth.map((m) => m.completedJobs)}
                canceled={conversionStats?.byMonth.map((m) => m.canceledJobs)}
                active={conversionStats?.byMonth.map((m) => m.activeJobs)}
                conversion={conversionStats?.byMonth.map(
                  (m) => m.conversionRate
                )}
              />
            </div>
          )}

          {/* Bottom Stats */}
          {bottomStats && (
            <div className="flex items-center gap-3 px-6 py-6 -mx-6 border-t">
              {/* Title etc. */}
              <div className="flex flex-col">
                {/* Title */}
                <div className="flex items-center gap-2 text-base font-medium">
                  {bottomStats.title}
                </div>
                {/* Stat */}
                <div className="text-2xl font-semibold leading-8">
                  {bottomStats.mainStat}
                </div>
                {/* Subtitle */}
                <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                  {bottomStats.subtitle}
                </div>
              </div>
              {/* Side stats */}
              <div className="ml-auto mr-6">
                <table className="table-fixed">
                  <thead className="border-b">
                    {/* Header */}
                    <tr className="text-base text-gray-500 dark:text-gray-300">
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        Goal
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        {sideStatsInMonths ? "3 mo" : "90d"}
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        {sideStatsInMonths ? "1 mo" : "30d"}
                      </th>
                      <th className="px-4 py-1 font-medium text-center text-inherit">
                        + / -
                      </th>
                    </tr>
                  </thead>
                  {/* Stats */}
                  <tbody>
                    <tr className="text-base divide-x">
                      <th className="text-lg font-semibold text-center text-gray-500 dark:text-gray-300">
                        {round(bottomStats.sideStats.goal)}
                      </th>
                      <th className="text-lg font-semibold text-center">
                        {round(bottomStats.sideStats.threeMo)}
                      </th>
                      <th className="text-lg font-semibold text-center">
                        {round(bottomStats.sideStats.oneMo)}
                      </th>
                      <th
                        className={classNames(
                          "text-lg font-semibold text-center",
                          (bottomStats.sideStats.plusMinus >= 0) ^ positiveBad // XOR for positiveBad to flip colors
                            ? "text-green-500"
                            : "text-red-600"
                        )}
                      >
                        {bottomStats.sideStats.plusMinus > 0 && "+"}
                        {round(bottomStats.sideStats.plusMinus)}
                        {bottomStats.sideStats.units}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {/* List Half */}
        <div
          className={classNames(
            "flex flex-col gap-3 dark:border-gray-600",
            large ? "justify-center border-l px-6" : "px-6 pt-6 -mx-6 border-t"
          )}
        >
          {jobList ? (
            <div
              className={classNames(
                large && "py-6",
                "flex flex-col gap-2 grow"
              )}
            >
              {/* Header */}
              <div className="flex">
                <div>
                  <div className="text-lg font-semibold">
                    Showing{" "}
                    {(pageInfo.currentPage - 1) * pageInfo.itemsPerPage + 1}-
                    {Math.min(
                      pageInfo.currentPage * pageInfo.itemsPerPage,
                      pageInfo.totalItems
                    )}{" "}
                    of {pageInfo.totalItems} Jobs
                  </div>
                  <div className="text-gray-500 dark:text-gray-300 text-md">
                    {selectedMonth &&
                      moment
                        .utc({
                          ...selectedMonth,
                          month: selectedMonth.month - 1,
                        })
                        .format("MMMM YYYY")}
                  </div>
                </div>

                {pageInfo && (
                  <div className="ml-auto">
                    <Pagination
                      {...pageInfo}
                      onChange={handlePageChange}
                      hideTopBorder
                      hideShowingText
                    />
                  </div>
                )}
              </div>

              {/* Table */}
              <Table
                thinFirstColumn
                noSelect
                noEdit
                fields={jobTableFields}
                data={formatJobsForTable(jobList, stages, markets) || []}
                maxHeight={!large ? 400 : undefined}
              />
            </div>
          ) : (
            <div className="text-center text-gray-500 dark:text-gray-400">
              Select a month to see relevant jobs
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

function formatJobsForTable(jobs, stages, markets) {
  if (!stages) return null;

  return jobs?.map((j, i) => {
    // Build city state string
    var cityState = null;
    if (j.address?.city || j.address?.state) {
      cityState = `${j.address?.city}${j.address?.city ? ", " : ""}${j.address?.state
        }`;
    }

    // Order job stages based on stages order
    var jobStages = [];
    for (let k = 0; k < stages.length; k++) {
      let stage = stages[k];
      // var jobStage = j.stages.find((s) => s.stage === stage._id); // DEPRECATED
      // jobStage.name = stage.name;
      let stageDate = j.stageMap[stage._id];
      jobStages.push({
        stage: stage._id,
        date: stageDate,
        name: stage.name,
      });
    }

    // TODO: rework for stage map

    // Get the stage after the last stage with a date
    // If all stages have dates, TODO: figure out
    var stage = null;
    if (jobStages.length) {
      stage = jobStages[0]; // Default is first stage
      // TODO: switch this
      for (let k = jobStages.length - 1; k >= 0; k--) {
        // If stage has a date, set stage to successor (i.e. stage without a date)
        if (jobStages[k].date) {
          // If last stage has a date, set stage to completed
          if (k === jobStages.length - 1) {
            stage = {
              name: (
                <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
              ),
              completedDate: jobStages[k].date,
            };
            break;
          }
          stage = jobStages[k + 1];
          stage.started = jobStages[k].date;
          break;
        }
      }
    }

    // Get market
    var market = markets?.find((m) => m._id === j.market);
    // TODO: handle null markets

    // Get days since created
    // Get first stage date
    var firstStageDate = null;
    for (let _stage in stages) {
      if (j.stageMap[_stage._id]) {
        firstStageDate = j.stageMap[_stage._id];
        break;
      }
    }

    // Calculate days since sold (if complete use completed date instead of today)
    let soldDate = j.stageMap[stages[0]._id]
      ? moment.utc(j.stageMap[stages[0]._id])
      : null;
    var endDate = stage.hasOwnProperty("completedDate")
      ? moment.utc(stage.completedDate)
      : moment.utc();
    var daysSinceCreated = endDate.diff(firstStageDate || soldDate, "days");

    // Get days in stage
    if (stage && stage.started) {
      var stageDate = moment.utc(stage.started);
      var daysInStage = endDate.diff(stageDate, "days");
    }

    // Return formatted job
    var formattedJob = {
      cityState: cityState,
      name: j.name,
      systemSize: formatKW(j.systemSize),
      stage: stage?.name,
      stageAndDays:
        stage?.name && daysInStage
          ? `${stage.name} | ${daysInStage} / ${daysSinceCreated || "-"} Day${daysSinceCreated != 1 ? "s" : ""
          }`
          : stage?.name || "",
      market: market?.name,
      daysSinceCreated: daysSinceCreated || null,
      daysInStage: daysInStage || null,
      importedId: j.importedId,
      cycleTime: Math.round(j.cycleTime),
      stage1Date: j.date1 ? moment.utc(j.date1).format("M/D/YY") : "",
      closeDate: j.closeDate ? moment.utc(j.closeDate).format("M/D/YY") : "",
      completed: j.completed ? true : false,
      completedIcon: j.completed ? (
        <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
      ) : (
        <XMarkIcon className="w-4 h-4 text-red-500 stroke-2" />
      ),
      installDate: moment.utc(stage?.completedDate).format("M/D/YY"),
      soldDate: j.stageMap[stages[0]._id]
        ? moment.utc(j.stageMap[stages[0]._id]).format("M/D/YY")
        : "-",
      link: {
        href: `/app/jobs/${j._id}`,
        target: "_blank",
      },
    };

    // Add time in each stage (for each stage, time since previous stage)
    for (let k = 1; k < jobStages.length; k++) {
      let stage = jobStages[k];
      var prevStage = jobStages[k - 1];

      // If has previous stage, calculate time in stage
      if (prevStage.date) {
        var date = stage.date || moment.utc().startOf("day");

        stage.timeInStage = moment(date)
          .utc()
          .startOf("day")
          .diff(moment.utc(prevStage.date).startOf("day"), "days");
      } else {
        stage.timeInStage = null;
      }

      formattedJob[stage.stage] = stage.timeInStage;
    }

    return formattedJob;
  });
}
function formatKW(num) {
  return (
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(num) + "W"
  );
}

function round(num) {
  return num !== null ? Math.round(num * 10) / 10 : "--";
}
