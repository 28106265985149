import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import UserManager from "src/tools/UserManager";
import useMarkets from "./useMarkets.ts";


/**
 *  A hook that returns the markets of the user's installer.
 *  Also returns a state for selecting markets.
 *
 *  Before markets are loaded, `markets` and `selectedMarkets` are null.
 *
 *  If `defaultAll` is true, all markets are selected by default.
 *  Otherwise, once markets are loaded, `selectedMarkets` is set to none (i.e. [])
 *
*  If `includeNullId` is true, a "No Market" option is included in the markets.
*  This is meant to capture items where there is no market (e.g. orders w/o a market).
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [markets, selectedMarkets, setSelectedMarket] = useMultiMarketSelect);
 *  if (!markets) return <div>Loading...</div>;
 *  return <ul>{markets.map(market => <li>{market.name}</li>)}</ul>;
 *  ```
 *
 *  Other hooks: 
 *  - `useMarkets` - If you don't need to select markets, use `useMarkets`
 *  - `useMarketSelect` - If you're looking for one market selected at a time, use `useMarketSelect`
 */
export default function useMultiMarketSelect(defaultAll: boolean = false, includeNullId: boolean = false)
  : readonly [
    readonly MarketType[] | null,
    MarketType[] | null,
    Dispatch<SetStateAction<MarketType[]>>
  ] {
  const markets = useMarkets(includeNullId);
  const [selectedMarkets, setSelectedMarkets] = useState<MarketType[]>();

  // Set markets on load
  useEffect(() => {
    if (markets && !selectedMarkets) {
      setSelectedMarkets(defaultAll ? [...markets] : []); // [...markets] to clone as it's readonly
    }
  }, [markets]);

  return [markets, selectedMarkets, setSelectedMarkets] as const;
}
