/**
 * Get the columns for the SubscribeToEvents table
 * 
 * Columns:
 * - Events: the event types to subscribe to
 * - Custom URL: the URL to POST the webhook data to
 * - JSON Sample: a button to view a sample JSON payload for the webhook
 * 
 * @param {function} setError - function to set an error message
 * 
 * @returns - an array of columns for the SubscribeToEvents table
 */
export function getSubscribeToEventsTableColumns(setError = () => { }) {
    return [
        {
            Header: "Events",
            accessor: "events",
            placeholder: "",
            required: true,
            primary: false,
            columnWidth: 5,
            editable: false,
            editType: 'input',
            unique: true,
            validate: (value) => { return true },
            // TODO add ratios here to make columns different widths, manually implement table-fixed
        },
        {
            Header: "Custom URL",
            accessor: "custom-url",
            placeholder: "URL",
            required: true,
            primary: true,
            columnWidth: 5,
            editable: true,
            editType: 'input',
            validate: (value) => { return true },
        },
        {
            Header: "JSON Sample",
            accessor: "json-sample",
            placeholder: "",
            required: false,
            primary: true,
            columnWidth: 5,
            editable: false,
            // itemType: 'button',
            validate: (value) => { return true },
        }
    ]
}