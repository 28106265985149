import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import MultiSelectListbox from '../input/MultiSelectListbox.tsx';
import Button from '../input/Button';
import UserManager from 'src/tools/UserManager';

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void,
  marketIds: string[],
}

/**
 * Model for submitting a sales forecast.
 * Has a list of connected distributors to select from.
 */
export default function SubmitSalesForecastModal({
  open,
  setOpen,
  marketIds,
}: Props) {

  const [distributors, setDistributors] = useState([]);

  const [selectedDistributorIds, setSelectedDistributorIds] = useState([]);

  /**
   * on page load, get all connected distributors.
   */
  useEffect(() => {
    getDistributors()
  }, [])

  /**
  * Get all connected distributors.
  */
  function getDistributors() {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    ).then((res) => {
      setDistributors(
        res.data.connections.map((connection) => {
          if (connection.status === "active" || connection.status === "pending_request") {
            return connection;
          }
        }).filter((connection) => connection)
      );
    });
  }

  /**
   * Submit the forecast.
   * Sends POST to /api/sales/forecast/submit
   */
  function handleSubmit() {
    // TODO: spinner?
    UserManager.makeAuthenticatedRequest(
      "/api/sales/forecast/submit",
      "POST",
      {
        distributorIds: selectedDistributorIds,
        marketIds: marketIds,
      }
    ).then(() => {
      close();
    }).catch((err) => {
      console.error(err);
      // TODO: error handling
      // TODO: toast?
    });
  }

  /**
   * Closes the modal.
   */
  function close() {
    setSelectedDistributorIds([]);
    setOpen(false);
  }

  /**
  * Handle distributor selection.
  * @param {string[]} distributorIds
  */
  function handleDistributorSelected(distributorIds) {
    setSelectedDistributorIds(distributorIds)
  }

  // Generate distributors options
  var distributorsOptions = distributors.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  })

  return (
    <Modal
      open={open}
      setOpen={close}
      wide
    >
      <div className="flex flex-col">
        {/* Content */}
        <div className="flex flex-col divide-y">
          {/* Top Section */}
          <div className="flex flex-col gap-6 pb-6">
            <div className="flex flex-col">
              <div className="text-base font-semibold">Submit Forecast</div>
              <div className="text-sm text-gray-500">
                Save your current forecast and share it with your connected distributors.
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium">
                Select Distributors
              </div>
              <div className="flex items-center justify-start text-gray-500 dark:text-gray-400">
                <MultiSelectListbox
                  itemType="Distributor"
                  options={distributorsOptions}
                  selectedOptionsValues={selectedDistributorIds}
                  onChange={(values) => {
                    handleDistributorSelected(values)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="relative flex justify-end gap-3 px-4 py-3 -mx-4 bg-gray-100 rounded-b-lg dark:bg-gray-700 sm:-mx-6 -bottom-4 sm:-bottom-6">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary-green" onClick={handleSubmit}>
            Submit Forecast
          </Button>
        </div>
      </div>
    </Modal>
  )
}
