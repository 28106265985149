import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StageType } from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext";
import UserManager from "src/tools/UserManager";

/**
 *  A hook that returns the markets of the user's installer.
 *
 *  Before stages are loaded, `stages` is null.
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [stages] = useStages();
 *  if (!stages) return <div>Loading...</div>;
 *  return <ul>{stages.map(stage => <li>{stage.name}</li>)}</ul>;
 *  ```
 */
export default function useStages(): StageType[] | null {
  const [stages, setStages] = useState<StageType[]>();
  // TODO: use better stage type

  // Get markets on load
  useEffect(() => {
    UserManager.makeAuthenticatedRequest("/api/stages/installer/find").then(
      res => {
        if (res.data.status === "ok") {
          const stages = res.data.stages;
          setStages(stages);
        }
      }
    ).catch(err => {
      console.error(err);
    })
  }, []);

  return stages;
}
