import React from 'react'
import { Order } from 'src/hooks/data/orders/useOrders.ts';
import { getStatusDisplayInfo } from 'src/tools/Orders/orderHelpers.tsx';
import { MinorBadge } from '../OrderRowItem.tsx'

type Props = {
  order: Order;
  companyType: "installer" | "distributor";
}

/**
 * Displays the status of an order.
 * Uses the status of the order to determine the label and variant of the badge.
 */
export default function StatusDisplay({ order, companyType }: Props) {
  let { label, variant } = getStatusDisplayInfo(order, companyType);
  return (
    <MinorBadge variant={variant}>{label}</MinorBadge>
  )
}
