
import { ArrowTopRightOnSquareIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import ToaBarChart from "src/components/BarChart";
import MiniFunnel from "src/components/charts/MiniFunnel.tsx";
import MiniDiffStat from "src/components/stats/MiniDiffStats.tsx";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import classNames from "src/tools/classNames";

import moment from "moment";
import MiniGrid from "../../../../tables/MiniGrid.tsx";

import pluralize from "pluralize";
import CustomTooltip from "src/components/charts/helpers/CustomTooltip.tsx";

type RowProps = {
  market: MarketType;
  marketStats?: MarketStatType;
  compact?: boolean;
  extraPadding?: boolean;
  goToMarket: (marketId: string) => void;
  companyType: string;
};
type ForecastType = {
  _id: string;
  isCurrent: boolean;
  market: string;

  numActiveJobs: number;
  numEstimatedJobs: number;
  numInputJobs: number;

  toaForecast: number[];
  inputForecast: number[];

  toaForecastByMonth?: number[];
  inputForecastByMonth?: number[];
  // TODO: finish ForecastType
};
type Month = {
  month: number;
  year: number;
}
export type MarketStatType = {
  name: string;
  activePipelineCount?: number;
  estimatedInstallCount?: number;
  forecast?: ForecastType;
  soldJobs?: {
    last30: number;
    last90: number;
    avg: number;
    data: { _id: Month, count: number }[];
  };
  installedJobs?: {
    last30: number;
    last90: number;
    avg: number;
    data: { _id: Month, count: number }[];
  };
  conversionRate?: {
    last30Days: number;
    last90Days: number;
  };
  cycleTime?: {
    last30Days: number;
    last90Days: number;
  };
}

/**
 * Displays a single row of stats that represent the given market.
 * Shows sold by month, installs by month, forecast, conversion rate, and cycle time.
 * Can be compacted to show as a single row of data rather than a chart view.
 *
 * Takes the `market`, its `marketStats`, and a handler to `goToMarket` for more details.
 */
export default function Row({
  market,
  marketStats,
  goToMarket = (_: string) => { },
  compact: can_compact = false, 
  extraPadding = false,
  companyType,
}: RowProps,
): JSX.Element {
  const CHART_HEIGHT = 140;
  const BAR_WIDTH = 20;

  const [compact, setCompact] = useState(can_compact);

  /**
   * Expands the row from compact view to a full view.
   */
  function handleExpand() {
    setCompact(false);
  }

  /**
  * Collapses the row from full view to a compact view.
  * Only if the row is allowed to be compact.
  */
  function handleCollapse() {
    if (can_compact) {
      setCompact(true);
    }
  }

  // Format installs for mini grid
  let installsUnformattedArray = marketStats?.installedJobs?.data?.map(d => d.count) ?? Array(12).fill(0);
  if (installsUnformattedArray) {
    // Replace last with fraction of installs / this month's forecast
    let fraction = {
      numerator: installsUnformattedArray[installsUnformattedArray.length - 1],
      denominator: marketStats?.forecast?.inputForecastByMonth?.[0]
    };
    var installsFormattedArray = [...installsUnformattedArray.slice(0, -1), fraction];
  }

  // Format forecast data for chart
  let jpmForecastData =
    marketStats?.forecast?.toaForecastByMonth.slice(1).map((_, i) => ({
      // Slice to remove current month
      month: moment()
        .utc()
        .add(i + 1, "months")
        .format("MMM"),
      _id: moment().utc().startOf("month").add(i + 1, "months"),
      toa: Math.ceil(marketStats.forecast.toaForecastByMonth[i + 1]), // +1 to avoid current month
      input: marketStats.forecast.inputForecastByMonth?.[i + 1], // +1 to avoid current month
    })) || [];

  // Get max value for charts to use as domain
  // This keeps the scale consistent between charts

  let maxBarValue = marketStats?.installedJobs?.data && marketStats?.forecast?.inputForecastByMonth && marketStats?.forecast?.toaForecastByMonth ? Math.max(
    ...marketStats.installedJobs.data.map((m) => m.count),
    ...marketStats.forecast.inputForecastByMonth,
    ...marketStats.forecast.toaForecastByMonth
  ) : 0;

  const Skeleton = ({ width = "100%", height = "100%" }: { width?: number | string, height?: number | string }) => <div className="bg-gray-300 rounded-lg animate-pulse" style={{ height, width }} />

  // Skeleton load
  if (!marketStats) {
    return !compact ? (
      <div className="grid grid-cols-10 grid-rows-2 gap-1 mx-5">

        <div className="col-span-2 row-span-2" >
          <Skeleton height={160} />
        </div>

        <div className="col-span-3 row-span-2" >
          <Skeleton />
        </div>

        <div className="col-span-3 row-span-2" >
          <Skeleton />
        </div>

        <div className="col-span-1 row-span-2" >
          <Skeleton />
        </div>

        <div className="col-span-1 row-span-1" >
          <Skeleton />
        </div>
        <div className="col-span-1 row-span-1" >
          <Skeleton />
        </div>
      </div>
    ) : (
      <div className="p-1">
        <Skeleton height={20} />
      </div>
    )
  }

  // TODO: Dark mode
  return !compact ? (
    // --- FULL VIEW --- //
    <>
      <div className={classNames("grid grid-cols-10 grid-rows-2 divide-x", can_compact ? "border-y py-2 bg-white" : "")}>
        {/* Market */}
        <div className="relative flex flex-col items-stretch justify-between col-span-2 row-span-2 gap-2 pr-2">
          {/* Title */}
          <div className={classNames("flex items-center justify-between gap-3", can_compact ? "pl-2" : "pl-5")}>
            <h1 className="text-xl font-semibold leading-7">{market.name}</h1>
            {market._id && <PipelineLink market={market} goToMarket={goToMarket} />}
          </div>

          {/* Funnel */}
          <MiniFunnel
            startValue={marketStats?.activePipelineCount || 0}
            startLabel="Active Pipeline"
            endValue={marketStats?.estimatedInstallCount ? Math.ceil(marketStats.estimatedInstallCount) : 0}
            endLabel="Estimated to Install"
            funnelClassName="fill-primary-green"
          />

          {/* Empty for Spacing */}
          <div></div>

          {/* Collapse */}
          {can_compact && (
            <button onClick={handleCollapse} className="absolute bottom-0 right-2">
              <ArrowsPointingInIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" />
            </button>
          )}
        </div>

        {/* Sold */}
        <div className="flex flex-col col-span-3 row-span-2 px-5 pt-2">
          {/* Header */}
          <div className="flex justify-between">
            {/* Left Header */}
            <div>
              <div className="text-xl font-semibold leading-7">{marketStats?.soldJobs ? Math.round(marketStats.soldJobs.last90 || 0) : "--"} Sold Jobs <span className="text-base font-medium leading-6 text-gray-400">/ mo</span></div>
              <MiniDiffStat
                value={marketStats?.soldJobs?.last30 || 0}
                diff={
                  (marketStats?.soldJobs?.last30 || 0)
                  -
                  (marketStats?.soldJobs ? Math.round(marketStats.soldJobs.last90 || 0) : 0)}
              />
            </div>
            {/* Goal */}
            <div><span className="text-xs font-medium leading-4 text-gray-400">Goal</span> --</div>
          </div>

          {/* Chart */}
          <div className="-mx-2.5 grow">
            <ToaBarChart
              height={CHART_HEIGHT}
              barSize={BAR_WIDTH}
              data={marketStats?.soldJobs?.data?.map(d =>
              ({
                ...d,
                month: moment(`${d._id.year}/${d._id.month}/1`, "YYYY/M/D").format("MMM")
              })) || []
              }
              dataKeys={["count"]}
              yAxis={false}
              xAxisSettings={{
                dataKey: "month",
                interval: 0,
                axisLine: false,
                tickLine: false,
                tick: {
                  fontSize: 12,
                }
              }}
              customTooltip={<CustomTooltip
                fields={[{ field: "count", label: "Sold" }]}
                headerField={{ field: "_id", asDate: true, label: "Month" }}
                dateFormatter={(d: any) =>
                  moment.utc({ ...d, month: d.month - 1 }).format("MMMM YYYY")
                }
              />}
            />
          </div>
        </div>

        {/* Installs */}
        <div className="flex flex-col col-span-3 row-span-2 px-5 py-2">
          {/* Header */}
          <div className="flex items-start gap-12">
            {/* Left Header */}
            <div className="grow">
              <div className="text-xl font-semibold leading-7">{marketStats?.installedJobs ? Math.round(marketStats.installedJobs.last90 || 0) : "--"} Installs <span className="text-base font-medium leading-6 text-gray-400">/ mo</span></div>
              <MiniDiffStat value={marketStats?.installedJobs?.last30 || 0} diff={
                (marketStats?.installedJobs?.last30 || 0)
                -
                (marketStats?.installedJobs ? Math.round(marketStats.installedJobs.last90 || 0) : 0)
              } />
            </div>
            {/* Goal */}
            <div><span className="text-xs font-medium leading-4 text-gray-400">Goal</span> --</div>
          </div>

          {/* Chart */}
          <div className="-mr-5 grow">
            <ToaBarChart
              height={CHART_HEIGHT}
              barSize={BAR_WIDTH}
              data={marketStats?.installedJobs?.data?.map((d, i) =>
              ({
                ...d,
                month: moment(`${d._id.year}/${d._id.month}/1`, "YYYY/M/D").format("MMM"),
                ...(i === marketStats.installedJobs.data.length - 1 && {
                  toa: marketStats?.forecast?.toaForecastByMonth[0],
                  forecast: marketStats?.forecast?.inputForecastByMonth[0]
                })
              })) || []
              }
              dataKeys={["toa", "forecast", "count"]}
              labelLists={[false, true, true]}
              hideZeros={[true, true, false]}
              layering={"bullet"}
              colors={["fill-gray-300", "fill-primary-green-300", null]}
              xAxisSettings={{
                dataKey: "month",
                interval: 0,
                axisLine: false,
                tickLine: false,
                tick: {
                  fontSize: 12,
                }
              }}
              yAxisSettings={{
                hide: true,
                domain: [0, maxBarValue * 1.1],
              }}
              customTooltip={<CustomTooltip
                fields={[
                  { field: "count", label: "Installs" },
                  { field: "toa", label: "TOA Estimated", hideNulls: true, round: true },
                  { field: "forecast", label: "Forecast", hideNulls: true }
                ]}
                headerField={{ field: "_id", asDate: true, label: "Month" }}
                dateFormatter={(d: any) =>
                  moment.utc({ ...d, month: d.month - 1 }).format("MMMM YYYY")
                }
              />}
            />
          </div>
        </div>

        {/* Forecasts */}
        <div className="flex flex-col items-stretch justify-between col-span-1 row-span-2 px-1 py-2">
          {/* Forecast */}
          <div className="flex items-center justify-center gap-3">
            <h1 className="text-base font-semibold leading-7">Forecast</h1>
            {companyType === "installer" && (market._id && <ForecastLink market={market} />)}
          </div>

          {/* Chart */}
          <div className="">
            <ToaBarChart
              height={CHART_HEIGHT}
              barSize={BAR_WIDTH}
              data={jpmForecastData}
              dataKeys={["toa", "input"]}
              colors={["fill-gray-300", "fill-primary-green"]}
              labelLists={[false, true]}

              xAxisSettings={{
                dataKey: "month",
                interval: 0,
                axisLine: false,
                tickLine: false,
                tick: {
                  fontSize: 12,
                }
              }}

              yAxisSettings={{
                hide: true,
                domain: [0, maxBarValue * 1.1],
              }}

              layering={"bullet"}

              customTooltip={<CustomTooltip
                fields={[
                  { field: "toa", label: "TOA Estimated", round: true },
                  { field: "input", label: "Forecast", }
                ]}
                headerField={{ field: "_id", asDate: true, label: "Month" }}
                dateFormatter={(d: any) =>
                  moment.utc(d).format("MMMM YYYY")
                }
              />}
            />
          </div>
        </div>

        {/* Conversion Rate */}
        <div className="flex items-center justify-center col-span-1 row-span-1">
          <div className="pb-2 mx-2">
            <div className="text-sm font-medium leading-5 text-gray-500">Conversion</div>
            <div className="text-2xl font-semibold leading-8">{marketStats?.conversionRate?.last90Days != null ? Math.round(100 * marketStats.conversionRate.last90Days) : "--"}%</div>
            <MiniDiffStat value={
              marketStats?.conversionRate?.last30Days != null ? Math.round(100 * marketStats.conversionRate.last30Days) : null
            }
              diff={
                (marketStats?.conversionRate?.last30Days != null) &&
                (marketStats?.conversionRate?.last90Days != null) &&
                Math.round(
                  100 * (marketStats.conversionRate.last30Days - marketStats.conversionRate.last90Days)
                )
              }
              units="%" />
          </div>
        </div>

        {/* Cycle Time */}
        <div className="col-span-1 row-span-1">
          <div className="flex items-center justify-center h-full pt-2 mx-2 border-t">
            <div>
              <div className="text-sm font-medium leading-5 text-gray-500">Cycle Time</div>
              <div className="text-2xl font-semibold leading-8">{marketStats?.cycleTime?.last90Days ? pluralize("day", Math.round(marketStats.cycleTime.last90Days), true) : "--"}</div>
              <MiniDiffStat
                value={marketStats?.cycleTime?.last30Days && Math.round(marketStats.cycleTime.last30Days)}
                diff={
                  marketStats?.cycleTime?.last30Days && marketStats?.cycleTime?.last90Days &&
                  Math.round(
                    marketStats.cycleTime.last30Days - marketStats.cycleTime.last90Days
                  )
                }
                negativeIsGood />
            </div>
          </div>
        </div>
      </div>
    </>
  ) :
    // --- COMPACT VIEW --- //
    (
      <>
        <div className="grid grid-cols-10 grid-rows-1 divide-x divide-gray-400">
          <div className="flex items-center justify-between col-span-2 px-3 border-b">
            <div className="flex items-center justify-between pr-3 grow">
              <div>{market.name}</div>
              <div className="font-bold">{marketStats?.activePipelineCount}</div>
            </div>
            <button onClick={handleExpand}><ArrowsPointingOutIcon className="w-5 h-5 text-gray-400 stroke-2 hover:text-gray-500" /></button>
          </div>
          <div className="col-span-3 px-2.5">
            <MiniGrid
              data={marketStats?.soldJobs?.data.map(d => d.count)}
            />
          </div>
          <div className="col-span-3 pl-5">
            <MiniGrid
              data={installsFormattedArray}
              classes={{ denominator: "text-primary-green-600" }}
            />
          </div>
          <div className="col-span-1 pr-3">
            <MiniGrid
              data={marketStats?.forecast?.inputForecastByMonth?.slice(1, 5)}
              classes={{ base: "text-primary-green-600" }}
            />
          </div>
          <div className="grid items-center grid-cols-2 col-span-1 px-3 text-center">
            <div>{marketStats?.conversionRate?.last90Days != null ? Math.round(100 * marketStats.conversionRate.last90Days) + "%" : "--"}</div>
            <div>{marketStats?.cycleTime?.last90Days != null ? Math.round(marketStats?.cycleTime?.last90Days) + "d" : "--"}</div>
          </div>
        </div>
      </>
    );
}

type ForecastLinkProps = {
  market: MarketType;
};

type PipelineLinkProps = {
  market: MarketType;
  goToMarket: (marketId: string) => void;
}

/**
  * Displays a link to a market's forecast page.
  */
function ForecastLink({ market }: ForecastLinkProps) {
  return (
    <Link
      to={`/app/forecast/job?market=${market._id}`}
      className="text-primary-green hover:text-primary-green-600"
    >
      <PencilSquareIcon className="w-5 h-5 stroke-2" />
    </Link>
  );
}

/**
  * Displays a link to a market's pipeline page.
  * As markets don't technically have a market page,
  * this "link" actually just adjusts the pipeline 
  * state to "grid" (see GridView) with the relevant market.
  */
function PipelineLink({ market, goToMarket = (_: string) => { } }: PipelineLinkProps) {
  return (
    <button
      onClick={() => {
        goToMarket(market._id)
      }}
      className="text-primary-green hover:text-primary-green-600"
    >
      <ArrowTopRightOnSquareIcon className="w-5 h-5 stroke-2" />
    </button>
  )
}
