import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SubNav from "../../components/nav/SubNav";

/**
 * TrucksPage component, which allows admin users of distributor companies to create and manage their trucks
 */
export default function TrucksPage() {
  const [subNavTabs, setSubNavTabs] = useState([
    { name: "Trucks", href: "manage", current: true },
  ]);

  const location = useLocation();

  // Set the current tab based on the URL
  useEffect(() => {
    // Get the current tab from the URL
    const currentTab = location.pathname.split("/")[3];

    // Get name by href
    const currentTabName = subNavTabs.find((tab) => tab.href == currentTab)?.name ?? subNavTabs[0].name;

    // Set tab
    selectTab(currentTabName);
  }, [location]);

  /**
   * Selects a tab by name. This adjusts subNavTabs.
   * @param {string} tabName Name of tab to select
   */
  function selectTab(tabName) {
    var temp = [...subNavTabs];
    temp.forEach((tab) => {
      tab.current = tab.name == tabName ? true : false;
    });
    setSubNavTabs(temp);
  }

  return (
    <>
      <div className="flex-col dark:text-white">
        {/* Top Bar */}
        <div className="inset-0 items-center hidden p-2 border-b dark:border-gray-600 h-14 bg-gray-50 dark:bg-gray-800 lg:flex">
          <div className="pl-6 text-xl font-semibold leading-7">Trucks</div>
        </div>

        {/* Body */}
        <div className="inset-0 flex flex-col flex-1 p-6 pt-2 space-y-2">
          {/* Sub Nav */}
          {/* 
            keep this here, will uncomment when we need the subnav
            TODO: uncomment when we add Activity page or other subpages
          */}
          {/* <div className="p-2 pt-0">
            <SubNav
              tabs={subNavTabs}
              onSelected={(tabName) => {
                selectTab(tabName);
              }}
            />
          </div> */}

          <Outlet />
        </div>
      </div>
    </>
  );
}
