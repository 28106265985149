import React, { useState, useEffect } from "react";
import Modal from "../../Modal";
import Input from "../../input/Input";
import Button from "../../input/Button";
import Switch from "../../input/Switch.tsx";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import $ from "jquery";

export default function AddMarketModal({
  open: ex_open,
  setOpen: ex_setOpen,
  handleNewMarket,
}) {
  const [open, setOpen] = useState(ex_open);
  const [market, setMarket] = useState({
    name: "",
    description: "",
    status: "active",
    marketFormulaValues: [],
    // TODO: add primaryDistributor, mainCrews
  });

  useEffect(() => {
    if (ex_open !== open) {
      setOpen(ex_open);
      setMarket({
        name: "",
        description: "",
        status: "active",
        marketFormulaValues: [],
        // TODO: add primaryDistributor, mainCrews});
      });
    }
  }, [ex_open]);

  function handleAdd() {
    if (validate(market)) {
      // Dedupe market formula values
      var marketFormulaValues = [...new Set(market.marketFormulaValues)];
      var tempMarket = { ...market, marketFormulaValues: marketFormulaValues };
      handleNewMarket(tempMarket);
    }
  }

  return (
    <Modal open={open} setOpen={ex_setOpen}>
      {/* Content */}
      <div id="add-market-modal" className="text-gray-900 font-medium text-sm flex flex-col gap-6">
        {/* Header */}
        <div>
          <div className="font-semibold text-base">Market</div>
          <div className="text-gray-500 font-normal">
            Add Market to group jobs by location.
          </div>
        </div>

        {/* Name */}
        <Input
          value={market.name}
          label="Market Name"
          placeholder="California"
          type="text"
          highlightOnEmpty
          onChange={(value) => {
            setMarket({ ...market, name: value });
          }}
        />

        {/* Description */}
        <Input
          value={market.description}
          label="Description"
          placeholder="Market encompasses all jobs in California"
          type="textarea"
          onChange={(value) => {
            setMarket({ ...market, description: value });
          }}
        />

        {/* Active */}
        <div className="flex gap-2 items-center">
          <div>Set as Active:</div>
          <Switch
            checked={market.status === "active"}
            onChange={(checked) => {
              setMarket({ ...market, status: checked ? "active" : "inactive" });
            }}
          />
        </div>

        {/* Line */}
        <div className="border-b border-gray-200"></div>

        {/* Market Formula Values */}
        <div className="flex flex-col gap-2">
          {/* Title */}
          <div>
            <div className="font-semibold text-base">Market Values</div>
            <div className="text-gray-500 font-normal">
              Add Market Values to identify which market a job belongs
              to (e.g. CA, California, Cali). Must have at least one value.
            </div>
          </div>

          {/* List */}
          <ul className="flex flex-col gap-2">
            {market.marketFormulaValues.map((value, index) => (
              <li key={index} className="flex flex-row items-center gap-2">
                <Input
                  placeholder=""
                  type="text"
                  value={value}
                  highlightOnEmpty
                  onChange={(value) => {
                    var newMarketFormulaValues = [
                      ...market.marketFormulaValues,
                    ];
                    newMarketFormulaValues[index] = value;
                    setMarket({
                      ...market,
                      marketFormulaValues: newMarketFormulaValues,
                    });
                  }}
                />
                <MinusCircleIcon
                  className="h-5 w-5 text-primary-rose cursor-pointer"
                  onClick={() => {
                    var newMarketFormulaValues = [
                      ...market.marketFormulaValues,
                    ];
                    newMarketFormulaValues.splice(index, 1);
                    setMarket({
                      ...market,
                      marketFormulaValues: newMarketFormulaValues,
                    });
                  }}
                />
              </li>
            ))}
            <li>
              <Button
                variant="primary-green"
                onClick={() => {
                  setMarket({
                    ...market,
                    marketFormulaValues: [...market.marketFormulaValues, ""],
                  });

                  // Focus on last input. Need to wait for DOM to update. Might be a better way to do this.
                  setTimeout(() => {
                    $("#add-market-modal input:last-child").trigger('focus');
                  }, 0);
                }}
              >
                Add Market Value
              </Button>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-end">
        <Button
          className="mt-6"
          variant="primary-green"
          onClick={handleAdd}
          disabled={!validate(market)}
        >
          Add Market
        </Button>

        <Button
          className="mt-6 ml-2"
          variant="secondary"
          onClick={() => {
            ex_setOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

function validate(market) {
  // Require name
  if (!market.name || market.name.length === 0) {
    return false;
  }

  // Require at least one market formula value
  if (!market.marketFormulaValues || market.marketFormulaValues.length === 0) {
    return false;
  }

  // Require non-empty market formula values
  if (
    !market.marketFormulaValues.every(
      (marketFormulaValue) => marketFormulaValue.length > 0
    )
  ) {
    return false;
  }

  return true;
}
