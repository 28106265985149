import { PaperClipIcon, TrashIcon } from '@heroicons/react/24/outline';
import React from 'react'
import StringHelper from 'src/utils/stringHelper.ts';
import Button from './Button';

type Props = {
  uploadText?: string;
  uploadedText?: string;
  onFileChange?: (file: File | null) => void;
  buttonVariant?: string;
}

/**
 * File upload input component.
 * `uploadText` - Text to display on upload button.
 * `uploadedText` - Text to display next to file name after file is uploaded.
 * `onFileChange` - Callback function with file parameter to handle file change event. Called on file selection or removal.
 *
 * TODO: Implement drag and drop file upload.
 */
export default function FileUpload({
  uploadText,
  uploadedText,
  onFileChange = () => { },
  buttonVariant = "primary"
}: Props) {

  // TODO: handle multiple files?
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [file, setFile] = React.useState<File | null>(null);

  /**
    * Handles file change event by setting state
    * and calling `onFileChange` hook
    */
  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
      onFileChange(event.target.files[0]);
    }
  }

  /**
    * Clicks the hidden file input element
    * when the upload button is clicked
    */
  function handleUploadBtnClick() {
    hiddenFileInput.current.click();
  }

  /**
    * Clear values when file is removed.
    * - Set file state
    * - Set hidden file input value
    * - Call `onFileChange` hook
    */
  function handleFileRemove() {
    setFile(null);
    hiddenFileInput.current.value = null;
    onFileChange(null);
  }
  return <div className="flex items-center justify-center gap-4">
    <input
      type="file"
      onChange={handleFileChange}
      ref={hiddenFileInput}
      className="hidden"
    />
    {!file
      ? <div>
        <Button
          variant={buttonVariant}
          onClick={handleUploadBtnClick}
        >
          <div className="flex items-center justify-center">
            <PaperClipIcon className="w-6 h-6" />
            <div>{uploadText ?? "Upload File"}</div>
          </div>
        </Button>
      </div>
      : <>
        <div className="flex items-center gap-2">
          <div className="flex items-center justify-center w-8 h-8 bg-gray-700 rounded-full">
            <PaperClipIcon className="w-5 h-5 text-white" />
          </div>
          <p className="text-sm font-semibold text-gray-600">
            {uploadedText ?? "File"}
          </p>
        </div>
        <div className="text-sm font-medium text-gray-500">
          {StringHelper.truncate(file.name, 16)}
          {" "}
          <a
            className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700"
            onClick={() => window.open(URL.createObjectURL(file))}
          >
            View
          </a>
        </div>
        <div className="ml-auto">
          <TrashIcon
            className="w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-800"
            onClick={handleFileRemove}
          />
        </div>
      </>
    }
  </div>
}
