import React, { useContext, useEffect, useState } from "react";
import InfoCard from "../../../InfoCard";
import JobsInPipelineCard from "../JobsInPipelineCard";
import Card from "../../../Card";
import Table from "../../../tables/Table.js";
import Pagination from "../../../nav/Pagination";
import StatsModal from "../StatsModal";

import moment from "moment";
import JobPipelineContext from "../../../../contexts/pipeline/jobs/JobPipelineContext";
import UserManager from "../../../../tools/UserManager";
import MiniFunnel from "../../../charts/MiniFunnel.tsx";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import ToaBarChart from "../../../BarChart.js";
import { groupWeeksByMonths } from "src/tools/Forecast/WeekToMonth.ts";
import classNames from "src/tools/classNames.js";


const GRAPH_HEIGHT = 350;

const narrowJobTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stageAndDays",
  },
  // {
  //   label: "Total",
  //   field: "daysSinceCreated",
  // },
];

const conversionTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Completed",
    field: "completedIcon",
  },
  {
    label: "Sold",
    field: "soldDate",
  },
  {
    label: (
      <p
        title="Date the job was either completed or canceled. Completed being job completion or stage completion based on the stage filter."
        className="underline decoration-dashed"
      >
        Close
      </p>
    ),
    field: "closeDate",
  },
];

const cycleTimeTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stage",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Cycle Time",
    field: "cycleTime",
  },
];

const installTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stage",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Installed",
    field: "installDate",
  },
];

const soldTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stage",
  },
  {
    label: "Market",
    field: "market",
  },
  {
    label: "Sold",
    field: "soldDate",
  },
];

/**
 * Grid view of the pipeline jobs page.
 * Shows info cards for conversion, cycle time, installs per month, and sold per month.
 * Shows a table of jobs in the pipeline.
 * Shows some metric breakdowns by stage.
 *
 * // TODO: update this comment once finalized
 */
export default function GridView({
  cycleTimeStats,
  conversionStats,
  installStats,
  soldStats,
  companyType = "installer",
  installerId = null,
}) {
  const {
    jobs,
    formattedJobs,

    markets,
    selectedMarketIds,
    setSelectedMarketId: handleMarketSelected,

    stages,
    selectedStageIndex,
    setSelectedStageIndex,

    search,
    setSearch: handleSearch,

    dateFilter,

    jobPagination: pagination,
    setJobListPage: handlePageChange,

    combinedForecast,
  } = useContext(JobPipelineContext);

  const [conversionModalOpen, setConversionModalOpen] = useState(false);
  const [cycleTimeModalOpen, setCycleTimeModalOpen] = useState(false);
  const [jobsPerMoModalOpen, setJobsPerMoModalOpen] = useState(false);
  const [soldPerMoModalOpen, setSoldPerMoModalOpen] = useState(false);

  // Month selection in modals
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(-1);

  const [monthJobs, setMonthJobs] = useState(null);
  const [monthJobsPagination, setMonthJobsPagination] = useState({
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
  });
  const [monthJobsInfo, setMonthJobsInfo] = useState({
    month: null,
    year: null,
    from: null,
  });

  // On market change, reset selected month
  useEffect(() => {
    setSelectedMonthIndex(-1);
    setMonthJobsPagination({
      currentPage: 1,
      itemsPerPage: 15,
      totalItems: 0,
    });
    setMonthJobs(null);
    setMonthJobsInfo({
      month: null,
      year: null,
      from: null,
    });
  }, [selectedMarketIds]);

  // Skeleton loading
  let loading =
    !conversionStats || !cycleTimeStats || !installStats || !soldStats;
  if (loading) {
    return (
      <div className="grid grid-cols-1 gap-4 px-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-100">
        {/* Sold / Mo */}
        <div className="h-40 bg-gray-200 rounded-lg dark:bg-gray-500 animate-pulse sm:col-span-2"></div>

        {/* Installs / Mo */}
        <div className="h-40 bg-gray-200 rounded-lg dark:bg-gray-500 animate-pulse sm:col-span-2"></div>

        {/* Conversion info */}
        <div className="h-40 bg-gray-200 rounded-lg xl:row-start-1 xl:col-start-3 2xl:row-start-3 2xl:col-start-1 dark:bg-gray-500 animate-pulse"></div>
        {/* Cycle Time */}
        <div className="h-40 bg-gray-200 rounded-lg dark:bg-gray-500 animate-pulse"></div>

        {/* Stages & Job List */}
        <div className="bg-gray-200 min-h-[300px] rounded-lg dark:bg-gray-500 animate-pulse sm:col-span-2 xl:col-span-3 2xl:col-span-2 2xl:row-start-1 2xl:col-start-3 2xl:row-span-3"></div>
      </div>
    );
  }

  var allMarketsOption = { label: "All Markets", value: null };
  var marketsOptions = markets
    ? [
      [allMarketsOption],
      markets.map((m) => {
        return {
          label: m.name,
          value: m._id,
        };
      }),
    ]
    : [[allMarketsOption]];

  var { avgCycleTime, byMonth: cycleTimeByMonth } = cycleTimeStats || {};
  var { conversionRate, byMonth: conversionByMonth } = conversionStats || {};
  var { avgInstallsPerMo, installsByMo } = installStats || {};
  var { avgSoldPerMo, soldByMo } = soldStats || {};

  var conversion3MoAvg =
    (100 *
      conversionByMonth?.slice(-3).reduce((a, b) => a + b.completedJobs, 0)) /
    conversionByMonth?.slice(-3).reduce((a, b) => a + b.jobCount, 0);

  var conversionInfo = {
    title: "Conversion",
    value: `${Math.round(conversion3MoAvg) || "--"}%`,
  };

  var conversionSideInfo = [
    {
      title: "Goal",
      value: "--%",
    },
    {
      title: "Market",
      value: "--%",
    },
  ];

  var lastMoConv = (conversionByMonth?.slice(-1)[0]?.conversionRate || 0) * 100;
  var conversionSubInfo = {
    value: Math.round(lastMoConv || 0),
    diff: Math.round(lastMoConv || 0) - Math.round(conversion3MoAvg || 0),
    units: "%",
  };

  var conversionLineGraph = {
    height: GRAPH_HEIGHT,
    data:
      conversionByMonth?.map((m, i) => {
        return {
          value: m.conversionRate * 100 || 0,
          jobs: m.jobCount || 0,
          completed: m.completedJobs || 0,
          date: { month: m._id.month, year: m._id.year },
          xAxisLabel: moment
            .utc({
              month: m._id.month - 1,
              year: m._id.year,
            })
            .format("MMM"),
          rollingAvg:
            (conversionByMonth
              .slice(Math.max(i - 2, 0), i + 1)
              .reduce((a, b) => a + b.completedJobs, 0) *
              100) /
            conversionByMonth
              .slice(Math.max(i - 2, 0), i + 1)
              .reduce((a, b) => a + b.jobCount, 0),
        };
      }) || [],
    dataKeys: ["rollingAvg", "value"],
    colors: ["#ccc", null],
    dashPatterns: ["4 4", null],
    names: ["3 Mo. Rolling Avg", "Conversion Rate"],
    legendSettings: {},
    lines: [
      {
        label: `${Math.round(conversion3MoAvg)}%`,
        value: conversion3MoAvg,
        color: "#00000000",
      },
    ],
    xAxisSettings: {
      dataKey: "xAxisLabel",
    },
    yAxisSettings: {
      tickCount: 2,
      label: {
        value: "Conversion Rate (%)",
        angle: -90,
        strokeWidth: 0,
        fill: "",
      },
    },
    customTooltip: (
      <CustomTooltip
        fields={[
          {
            label: "Conversion Rate",
            field: "value",
            round: true,
            highlight: true,
            units: "%",
          },
          {
            label: "Completed Count",
            field: "completed",
          },
          {
            label: "Sold Count",
            field: "jobs",
          },
          {
            label: "Last 3 Months",
            field: "rollingAvg",
            round: true,
            units: "%",
          },
        ]}
      />
    ),
  };

  var conversionBarChart = {
    legendSettings: {},
    height: GRAPH_HEIGHT,
    data:
      conversionByMonth?.map((m) => {
        return {
          month: m._id.month,
          year: m._id.year,
          completed: m.completedJobs,
          date: { month: m._id.month, year: m._id.year },
          total: m.jobCount,
          xAxisLabel: moment
            .utc({
              month: m._id.month - 1,
              year: m._id.year,
            })
            .format("MMM"),
        };
      }) || [],
    dataKeys: ["total", "completed"],
    colors: ["fill-gray-300", "fill-gray-400 dark:fill-gray-600"],
    names: [
      selectedStageIndex === -1 ? "Sold" : "Entered Stage",
      selectedStageIndex === -1 ? "Completed" : "Exited Stage",
    ],
    xAxisSettings: {
      dataKey: "xAxisLabel",
    },
    yAxisSettings: {
      tickCount: 2,
      label: {
        value: "Jobs",
        angle: -90,
        strokeWidth: 0,
        fill: "",
      },
    },
    customTooltip: (
      <CustomTooltip
        fields={[
          {
            label: "Completed",
            field: "completed",
          },
          {
            label: "Sold",
            field: "total",
          },
        ]}
      />
    ),
  };

  var conversionSideStats = {
    goal: null, // TODO: implement goal
    threeMo: Math.round(conversion3MoAvg),
    oneMo: Math.round(
      (100 *
        conversionByMonth?.slice(-1).reduce((a, b) => a + b.completedJobs, 0)) /
      conversionByMonth?.slice(-1).reduce((a, b) => a + b.jobCount, 0)
    ),
    units: "%",
  };

  var cycleTimeInfo = {
    title: "Cycle Time",
    value: `${Math.round(cycleTimeStats.last90) || "--"} days`,
  };

  var cycleTimeSideInfo = [
    {
      title: "Goal",
      value: "--",
    },
    {
      title: "Market",
      value: "--",
    },
  ];

  var cycleTimeSubInfo = {
    value: Math.round(cycleTimeStats.last30 || 0),
    diff:
      Math.round(cycleTimeStats.last30 || 0) -
      Math.round(cycleTimeStats.last90 || 0),
    negativeIsGood: true,
  };

  var cycleTimeGraph = {
    height: GRAPH_HEIGHT,
    data:
      cycleTimeByMonth?.map((m, i) => {
        return {
          avg: m.avgCycleTime || 0,
          min: m.minCycleTime || 0,
          max: m.maxCycleTime || 0,
          jobs: m.jobCount || 0,
          date: { month: m._id.month, year: m._id.year },
          subStdev: Math.max(
            m.avgCycleTime && m.stdevCycleTime
              ? m.avgCycleTime - m.stdevCycleTime
              : 0,
            0
          ),
          addStdev:
            m.avgCycleTime && m.stdevCycleTime
              ? m.avgCycleTime + m.stdevCycleTime
              : 0,
          stdev: m.stdevCycleTime,
          xAxisLabel: moment
            .utc({
              month: m._id.month - 1,
              year: m._id.year,
            })
            .format("MMM"),
          rollingAvg:
            cycleTimeByMonth
              .slice(Math.max(i - 2, 0), i + 1)
              .reduce((a, b) => a + b.summedCycleTime, 0) /
            cycleTimeByMonth
              .slice(Math.max(i - 2, 0), i + 1)
              .reduce((a, b) => a + b.jobCount, 0),
        };
      }) || [],
    dataKeys: ["rollingAvg", "avg"],
    colors: ["#ccc", null, "#ccc", "#ccc"],
    dashPatterns: ["4 4", null, "4 4", "4 4"],
    names: ["3 Mo. Rolling Avg", "Cycle Time", "Min", "Max"],
    legendSettings: {},
    lines: [],
    xAxisSettings: {
      dataKey: "xAxisLabel",
    },
    yAxisSettings: {
      tickCount: 2,
      label: {
        value: "Cycle Time (days)",
        angle: -90,
        strokeWidth: 0,
        fill: "",
      },
    },
    customTooltip: (
      <CustomTooltip
        fields={[
          {
            label: "Avg",
            field: "avg",
            round: true,
            highlight: true,
            units: " days",
          },
          {
            label: "Jobs",
            field: "jobs",
          },
          {
            label: "Last 3 Months",
            field: "rollingAvg",
            round: true,
            units: " days",
          },
        ]}
      />
    ),
  };

  var cycleTimeSideStats = {
    goal: null, // TODO: implement goal
    threeMo: cycleTimeStats.last90,
    oneMo: cycleTimeStats.last30,
  };

  var jobsPerMoInfo = {
    title: "Installs / Mo.",
    value: `${Math.round(installStats.last90) || "--"}`,
  };

  var jobsPerMoSideInfo = [
    {
      title: "Goal",
      value: "--", // TODO: implement goal
    },
  ];

  var installsSubInfo = {
    value: Math.round(installStats.last30 || 0),
    diff:
      Math.round(installStats.last30 || 0) -
      Math.round(installStats.last90 || 0),
  };
  // TODO: pull into forecast graph component?
  // Forecast graph
  let forecastsByMonth = combinedForecast && {
    input: groupWeeksByMonths(combinedForecast.inputForecast),
    toa: groupWeeksByMonths(combinedForecast.toaForecast),
  };

  let maxInstallValue = installsByMo && forecastsByMonth ? Math.max(
    ...installsByMo.map((m) => m.count),
    ...forecastsByMonth.toa,
    ...forecastsByMonth.input
  ) : 0;
  var jobsPerMoGraph = {
    height: GRAPH_HEIGHT,
    data:
      installsByMo?.map((m, i) => ({
        ...m,
        date: { month: m._id.month, year: m._id.year },
        xAxisLabel: moment
          .utc({ year: m._id.year, month: m._id.month - 1 })
          .format("MMM"),
        rollingAvg:
          installsByMo
            .slice(Math.max(i - 2, 0), i + 1)
            .reduce((a, b) => a + b.count, 0) / Math.min(i + 1, 3),
      })) || [],
    dataKeys: ["toa", "input", "count"],
    colors: ["fill-gray-300", "fill-primary-green-300", null],
    dashPatterns: ["4 4", null],
    names: ["Installs"],
    // legendSettings: {},
    lines: [],
    xAxisSettings: {
      dataKey: "xAxisLabel",
    },
    yAxisSettings: {
      tickCount: 2,
      label: {
        value: "Installs",
        angle: -90,
        strokeWidth: 0,
        fill: "",
      },
    },
    customTooltip: (
      <CustomTooltip
        fields={[
          {
            label: "Jobs",
            field: "count",
            round: true,
            highlight: true,
          },
        ]}
      />
    ),
  };

  let jpmForecastData =
    forecastsByMonth?.toa.slice(1).map((_, i) => ({
      // Slice to remove current month
      month: moment()
        .utc()
        .add(i + 1, "months")
        .format("MMM"),
      toa: Math.ceil(forecastsByMonth.toa[i + 1]), // +1 to avoid current month
      input: forecastsByMonth.input[i + 1], // +1 to avoid current month
    })) || [];

  let jobsPerMoForecastGraph = {
    height: "100%",
    // minHeight: 170,
    data: jpmForecastData,
    dataKeys: ["toa", "input"],
    colors: ["fill-gray-300", "fill-primary-green"],
    labelLists: [false, true],

    xAxisSettings: {
      stroke: "#9cA3Af",
      axisLine: false,
      tickLine: false,
      dataKey: "month",
    },
    yAxisSettings: {
      hide: true,
    },

    layering: "bullet",
    hideTooltip: true,
  };

  var installSideStats = {
    goal: null, // TODO: implement goal
    threeMo: installStats.last90,
    oneMo: installStats.last30,
  };

  var installBottomStats = {
    title: "Installs / Week",

    // Convert last 90 to weekly
    mainStat: Math.round((10 * installStats.last90 * 3) / (90 / 7)) / 10, // 90/7 gives number of weeks
    subtitle: "Last 90 days",
    sideStats: {
      goal: null, // TODO: implement goal
      threeMo: (installStats.last90 * 3) / (90 / 7), // * 3 as it's 3 month avg not total
      oneMo: installStats.last30 / (30 / 7),
    },
  };

  var soldPerMoInfo = {
    title: "Sold / Mo.",
    value: Math.round(soldStats.last90) || "--",
  };

  var soldPerMoSideInfo = [
    {
      title: "Goal",
      value: "--", // TODO: implement goal
    },
  ];

  var soldSubInfo = {
    value: Math.round(soldStats.last30 || 0),
    diff: Math.round(soldStats.last30 || 0) - Math.round(soldStats.last90 || 0),
  };

  var soldPerMoGraph = {
    height: GRAPH_HEIGHT,
    data:
      soldByMo?.map((m) => ({
        ...m,
        monthName: moment
          .utc({ year: m._id.year, month: m._id.month - 1 })
          .format("MMM"),
        date: { month: m._id.month, year: m._id.year },
      })) || [],
    dataKeys: [null, "count"],
    xAxisSettings: {
      dataKey: "monthName",
    },
    yAxisSettings: {
      tickCount: 2,
      label: {
        value: "Sold",
        angle: -90,
        strokeWidth: 0,
        fill: "",
      },
    },
    customTooltip: (
      <CustomTooltip
        fields={[
          {
            label: "Jobs",
            field: "count",
            round: true,
            highlight: true,
          },
        ]}
      />
    ),
  };

  var soldSideStats = {
    goal: null, // TODO: implement goal
    threeMo: soldStats.last90,
    oneMo: soldStats.last30,
  };

  function handleMonthSelect(data, index, from) {
    setSelectedMonthIndex(index);

    // Reset month jobs and pagination
    setMonthJobsPagination({
      currentPage: 1,
      itemsPerPage: 15,
      totalItems: 0,
    });
    if (index === -1) {
      setMonthJobs(null);
      setMonthJobsInfo({
        month: null,
        year: null,
        from: null,
      });
    } else {
      // Get jobs for month
      var { month, year } = data.date;

      getMonthJobs(month, year, from, 1);
    }
  }

  function handleMonthPageChange(page) {
    setMonthJobsPagination({
      ...monthJobsPagination,
      currentPage: page,
    });

    getMonthJobs(
      monthJobsInfo.month,
      monthJobsInfo.year,
      monthJobsInfo.from,
      page
    );
  }

  function getMonthJobs(month, year, from, page, query = null) {
    // Update month jobs info
    setMonthJobsInfo({
      month,
      year,
      from,
    });

    var jobQuery = query
      ? query
      : `?page=${page}` +
      `${companyType === "distributor" ? `&installerId=${installerId}` : ""}` +
      `&pageSize=${monthJobsPagination.itemsPerPage}` +
      `&month=${month}` +
      `&year=${year}` +
      `${selectedStageIndex !== -1 && stages
        ? `&stage=${stages[selectedStageIndex]._id}`
        : ""
      }` +
      `${selectedMarketIds ? `&markets=${selectedMarketIds.join(",")}` : ""}` +
      `${search ? `&search=${search}` : ""}` +
      `${dateFilter ? `&date=${dateFilter}` : ""}`;

    var endpoint = `/api/jobs/${companyType}/`;

    switch (from) {
      case "conversionRate":
        endpoint += "conversion-rates-list";
        break;
      case "cycleTime":
        endpoint += "cycle-times-list";
        break;
      case "installs":
        endpoint += "installs-per-month-list";
        break;
      case "sold":
        endpoint += "sold-per-month-list";
        break;
      default:
        return;
    }

    UserManager.makeAuthenticatedRequest(`${endpoint}${jobQuery}`, "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { jobCount, page: currPage, jobs: jobList } = res.data;

          // Update jobs
          setMonthJobs(jobList);

          // Update pagination
          setMonthJobsPagination({
            ...monthJobsPagination,
            currentPage: currPage,
            totalItems: jobCount,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let activeJobCount =
    stages?.reduce((a, b) => {
      return a + b.jobCount;
    }, 0) ?? 0;
  let estimatedInstallCount = combinedForecast && Math.ceil(combinedForecast.currentEstimatedInstallCount ?? 0)

  return (
    <div className="grid grid-cols-1 gap-4 pt-0 m-4 mt-0 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grow">
      {/* Sold / Wk */}
      <div className="sm:col-span-2">
        <InfoCard
          wide
          mainInfo={soldPerMoInfo}
          sideInfo={soldPerMoSideInfo}
          subInfo={soldSubInfo}
          graph={{
            data: soldPerMoGraph.data,
            dataKeys: soldPerMoGraph.dataKeys,
            xAxisSettings: {
              dataKey: "monthName",
              axisLine: false,
              tickLine: false,
            },
            yAxis: false,

            layering: "bullet",
            margin: {
              top: 20, bottom: -7,
            },

          }}
          showExpand
          onExpand={() => setSoldPerMoModalOpen(true)}
        >
          {/* Funnel */}
          <div className="flex flex-col items-center justify-between h-full">
            <p className="text-base font-semibold text-center">
              Active Pipeline
            </p>
            <MiniFunnel
              funnelClassName="fill-primary-green"
              startValue={activeJobCount}
              endValue={estimatedInstallCount}
              startLabel="Total Active"
              endLabel="Estimated"
            />

            {/* Empty for justify-between behavior to center the funnel */}
            <div></div>
          </div>
        </InfoCard>
      </div>

      {/* Installs / Mo */}
      <div className="sm:col-span-2">
        <InfoCard
          wide
          mainInfo={jobsPerMoInfo}
          sideInfo={jobsPerMoSideInfo}
          subInfo={installsSubInfo}
          graph={{
            data: jobsPerMoGraph.data.map((d, i) => ({
              ...d,

              // TODO: see if we should add Toa to actual as forecast is from this week on
              toa: forecastsByMonth && i === jobsPerMoGraph.data.length - 1 ? Math.ceil(forecastsByMonth.toa[0]) + d.count : 0, // Show current month's forecast
              input: forecastsByMonth && i === jobsPerMoGraph.data.length - 1 ? Math.ceil(forecastsByMonth.input[0]) : 0, // Show current month's forecast
            })),
            dataKeys: jobsPerMoGraph.dataKeys,
            labelLists: [false, true, true],
            hideZeros: [true, true, false],
            xAxisSettings: {
              dataKey: "xAxisLabel",
              axisLine: false,
              tickLine: false,
            },
            yAxisSettings: {
              hide: true,
              domain: [0, maxInstallValue * 1.1],
            },

            colors: jobsPerMoGraph.colors,
            layering: "bullet",
            margin: {
              top: 20, bottom: -7,
            },
          }}
          showExpand
          onExpand={() => setJobsPerMoModalOpen(true)}
        >
          <div className="flex flex-col items-stretch justify-between h-full">
            <div className="flex items-center justify-center gap-3">
              <p className="text-base font-semibold">Forecast</p>
              {selectedMarketIds?.length == 1 && <Link
                to={
                  "../../forecast/job?" +
                  (selectedMarketIds ? `market=${selectedMarketIds[0]}` : "")
                }
              >
                <PencilSquareIcon className="w-5 h-5 stroke-2 text-primary-green hover:text-primary-green-600" />
              </Link>}
            </div>
            <div className="mt-10 -mb-3 grow">
              <ToaBarChart {...jobsPerMoForecastGraph} />
            </div>
          </div>
        </InfoCard>
      </div>

      {/* Conversion info */}
      <div className="xl:row-start-1 xl:col-start-3 2xl:row-start-3 2xl:col-start-1">
        <InfoCard
          mainInfo={conversionInfo}
          sideInfo={conversionSideInfo}
          subInfo={conversionSubInfo}
          graph={{
            data: conversionLineGraph.data,
            dataKey: conversionLineGraph.dataKeys[1],
          }}
          showExpand
          onExpand={() => setConversionModalOpen(true)}
        />
      </div>

      {/* Cycle Time */}
      <div className="">
        <InfoCard
          mainInfo={cycleTimeInfo}
          sideInfo={cycleTimeSideInfo}
          subInfo={cycleTimeSubInfo}
          graph={{
            data: cycleTimeGraph.data,
            dataKey: cycleTimeGraph.dataKeys[1],
          }}
          showExpand
          onExpand={() => setCycleTimeModalOpen(true)}
        />
      </div>

      {/* Stages & Job List */}
      <div className="sm:col-span-2 xl:col-span-3 2xl:col-span-2 2xl:row-start-1 2xl:col-start-3 2xl:row-span-4">
        <Card>
          <JobsInPipelineCard />
          {formattedJobs ? (
            <div className="flex flex-col">
              <Table
                noSelect
                noEdit
                thinFirstColumn
                fields={narrowJobTableFields}
                data={formattedJobs}
              />
              <Pagination {...pagination} onChange={handlePageChange} />
            </div>
          ) : (
            <div className="rounded-lg animate-pulse h-[736px] bg-gray-200 dark:bg-gray-500"></div>
          )}
        </Card>
      </div>

      {/* Modals */}

      <StatsModal
        large
        title="Conversion Rate"
        tooltip="Conversion rate is the percentage of jobs that are installed out of all jobs that are sold. If a stage is selected, it is the percentage of jobs that complete that stage out of all jobs that enter that stage."
        mainStat={Math.round(10 * conversion3MoAvg) / 10 + "%"}
        subtitle="3 Month Average"
        open={conversionModalOpen}
        setOpen={setConversionModalOpen}
        lineChart={conversionLineGraph}
        barChart={conversionBarChart}
        sideStats={null}
        // sideStats={conversionSideStats} // TODO: uncomment and finish when goals are implemented
        handleClick={(d, i) => {
          handleMonthSelect(d, i, "conversionRate");
        }}
        selectedMonthIndex={selectedMonthIndex}
        selectedMonth={conversionByMonth?.[selectedMonthIndex]?._id}
        jobTableFields={conversionTableFields}
        jobList={monthJobs}
        pageInfo={monthJobsPagination}
        handlePageChange={handleMonthPageChange}
        showConversionGrid
        sideStatsInMonths
      />

      <StatsModal
        title="Cycle Time"
        tooltip="Cycle time is the number of days from sell to install. If a stage is selected, it is the number of days from entering that stage to completing that stage."
        mainStat={Math.round(10 * cycleTimeStats.last90) / 10 + " days"}
        subtitle={
          selectedStageIndex === -1 ? "Full Cycle Time" : "Days in Stage"
        }
        subsubtitle="Last 90 Days"
        open={cycleTimeModalOpen}
        setOpen={setCycleTimeModalOpen}
        lineChart={cycleTimeGraph}
        sideStats={cycleTimeSideStats}
        positiveBad
        handleClick={(d, i) => {
          handleMonthSelect(d, i, "cycleTime");
        }}
        selectedMonthIndex={selectedMonthIndex}
        selectedMonth={cycleTimeByMonth?.[selectedMonthIndex]?._id}
        jobTableFields={cycleTimeTableFields}
        jobList={monthJobs}
        pageInfo={monthJobsPagination}
        handlePageChange={handleMonthPageChange}
      />

      <StatsModal
        title="Installs / Month"
        tooltip="Installs per month is the number of jobs installed in a month."
        mainStat={Math.round(10 * installStats.last90) / 10 || "--"}
        subtitle="Total installs by month"
        subsubtitle="Last 90 Days"
        open={jobsPerMoModalOpen}
        setOpen={setJobsPerMoModalOpen}
        hideStages
        barChart={jobsPerMoGraph}
        sideStats={installSideStats}
        bottomStats={installBottomStats}
        handleClick={(d, i) => {
          handleMonthSelect(d, i, "installs");
        }}
        selectedMonthIndex={selectedMonthIndex}
        selectedMonth={installsByMo?.[selectedMonthIndex]?._id}
        jobTableFields={installTableFields}
        jobList={monthJobs}
        pageInfo={monthJobsPagination}
        handlePageChange={handleMonthPageChange}
      />

      <StatsModal
        title="Sold / Month"
        tooltip="Sold per month is the number of jobs sold in a month."
        mainStat={Math.round(10 * soldStats.last90) / 10 || "--"}
        subtitle="Total sold jobs by month"
        subsubtitle="Last 90 Days"
        open={soldPerMoModalOpen}
        setOpen={setSoldPerMoModalOpen}
        barChart={soldPerMoGraph}
        hideStages
        stages={stages}
        sideStats={soldSideStats}
        handleClick={(d, i) => {
          handleMonthSelect(d, i, "sold");
        }}
        selectedMonthIndex={selectedMonthIndex}
        selectedMonth={soldByMo?.[selectedMonthIndex]?._id}
        jobTableFields={soldTableFields}
        jobList={monthJobs}
        pageInfo={monthJobsPagination}
        handlePageChange={handleMonthPageChange}
      />
    </div>
  );
}

/**
 *
 * Custom tooltip for the charts in the grid view.
 * Takes an array of fields to display in the tooltip.
 * Each field object should have a label, field, and optionally a highlight, round, and units.
 *
 * Label: label to display for the field
 * Field: field to get the value from the data object
 * Highlight: whether to highlight the value
 * Round: whether to round the value
 * Units: units to display after the value
 *
 * @returns
 */
function CustomTooltip({
  active,
  payload,
  label,
  fields = [],
  dateFormatter = (d) =>
    moment.utc({ ...d, month: d.month - 1 }).format("MMM YYYY"),
}) {
  if (active && payload && payload.length) {
    var { date } = payload[0].payload;
    return (
      <div className="flex flex-col gap-2 p-4 text-sm bg-white border border-gray-300 rounded-md dark:bg-gray-700">
        {/* Date */}
        <div className="text-sm font-medium">{dateFormatter(date)}</div>

        {/* Table */}
        <table className="table table-fixed">
          <tbody>
            {fields.map((f, i) => {
              var value = payload[0].payload[f.field];

              if (f.round) {
                value = Math.round(value);
              }

              return (
                <tr key={i}>
                  <td className="text-gray-500 dark:text-gray-300">
                    {f.label}:
                  </td>
                  <td
                    className={classNames(
                      "text-right",
                      f.highlight ? "text-primary-green" : ""
                    )}
                  >
                    {value}
                    {f.units}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

function formatNumber(n) {
  // Format number with commas
  return n?.toLocaleString("en-US");
}

