import React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Order } from 'src/hooks/data/orders/useOrders';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import StringHelper from 'src/utils/stringHelper.ts';
import useS3URLs from 'src/hooks/data/files/useS3.ts';

type Props = {
  order: Order;
}

/**
 * Displays a simple, collapsible, read-only list of additional attachments
 * for the given order. The list starts collapsed and displays nothing
 * if the order has no additional attachments.
 */
export default function AdditionalAttachsDsply({
  order
}: Props) {

  // Additional attachments
  const additionalFilePaths = order.files.additionalFiles.map(file => file.filePath);
  const additionalFileArr = useS3URLs(additionalFilePaths);
  const additionalFileLinks = additionalFileArr?.length ? additionalFileArr.map(file => file.getObjectSignedUrl) : [];

  return (
    order.files.additionalFiles.length > 0
    && <Disclosure as="div" className="py-3">
      <DisclosureButton className="group font-semibold text-gray-500 text-sm flex items-center justify-between w-full">
        <div>Additional Attachments ({order.files.additionalFiles.length})</div>
        <ChevronDownIcon className="group-data-[open]:rotate-180 w-5 h-5 stroke-2" />
      </DisclosureButton>
      <DisclosurePanel className="pt-3 px-8 divide-y">
        {order.files.additionalFiles.map((file, index) => (
          <div
            className="p-2 px-4 font-medium text-sm flex items-center gap-2"
          >
            {/* File Name */}
            <div
              className="text-gray-500 line-clamp-1"
            >
              {StringHelper.truncate(file.filePath.split("/").pop(), 80)}
            </div>
            {/* Link */}
            <a
              className="text-primary-green hover:text-primary-green-700"
              href={additionalFileLinks[index]}
              target="_blank"
            >
              View
            </a>
          </div>
        ))}
      </DisclosurePanel>
    </Disclosure>
  )
}
