import { SetStateAction, Dispatch, useEffect, useState } from "react";
import useConnections, { Connection } from "./useConnections.ts";

// TODO: comment
export default function useMultiConnectionSelect(defaultAll: boolean = false)
  : [Connection[], Connection[], Dispatch<SetStateAction<Connection[]>>] {

  const connections = useConnections();
  const [selected, setSelected] = useState<Connection[]>();

  useEffect(() => {
    if (connections && defaultAll) {
      setSelected(connections);
    }
  }, [connections, defaultAll]);

  return [connections, selected, setSelected];
}
