import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import IconDropdown from "../input/IconDropdown";
import classNames from "../../tools/classNames";
import Button from "../input/Button";

// const exampleFields = [
//   {
//     label: "Name",
//     field: "name",
//   },
// ];

// const exampleData = [
//   {
//     name: "Lindsay Walton",
//     title: "Front-end Developer",
//     email: "lindsay.walton@example.com",
//     role: "Member",
//     href: "#",
//   },
//   // More people...
// ];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function Table({
  data = [],
  fields = [],
  noSelect = false,
  noEdit = false,
  ellipsisOptions = [],
  maxHeight = null,
  thinFirstColumn = false,
  noPadding = false,
}) {
  const checkbox = useRef();
  const [lines, setLines] = useState(data);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);


  useLayoutEffect(() => {
    const isIndeterminate =
      selectedLines.length > 0 && selectedLines.length < lines?.length;
    setChecked(selectedLines.length === lines?.length);
    setIndeterminate(isIndeterminate);
    if (checkbox.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedLines, selectedLines.length, lines?.length]);

  useEffect(() => {
    setLines(data);
  }, [data]);

  if (!lines) {
    return null;
  }

  function toggleAll() {
    setSelectedLines(checked || indeterminate ? [] : lines);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <div className={!noPadding ? "px-4 sm:px-6 lg:px-8" : ""}>
      <div className="flow-root">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div
              className="relative"
              style={{
                maxHeight: maxHeight,
              }}
            >
              {selectedLines.length > 0 && (
                <div className="absolute top-0 flex items-center h-12 space-x-3 bg-white dark:bg-gray-800 left-14 sm:left-12">
                  <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 text-sm font-semibold bg-white rounded shadow-sm dark:bg-gray-800 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    Bulk edit
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-900 bg-white rounded shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                  >
                    Delete all
                  </button>
                </div>
              )}
              <table className="min-w-full divide-y divide-gray-300 table-fixed">
                <thead className="sticky top-0 bg-white border-b dark:bg-gray-800">
                  <tr className="">
                    {!noSelect && (
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-primary-green focus:ring-primary-green"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                    )}
                    {fields.map((f, i) => {
                      if (!f) return null;
                      return i === 0 ? (
                        <th
                          key={i}
                          scope="col"
                          className={classNames(
                            thinFirstColumn ? "" : "min-w-[12rem]",
                            "py-3.5 pr-3 text-left text-sm font-semibold"
                          )}
                        >
                          {f.label}
                        </th>
                      ) : (
                        <th
                          key={i}
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          {f.label}
                        </th>
                      );
                    })}
                    {!noEdit && (
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-600 dark:bg-gray-800">
                  {lines.map((line, lineIndex) => (
                    <tr
                      key={lineIndex}
                      className={classNames(
                        selectedLines.includes(line) ? "bg-gray-50" : undefined,
                        line?.link?.href
                          ? "cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
                          : undefined
                      )}
                      onClick={() => {
                        if (line?.link?.href) {
                          if (line?.link?.target === "_blank") {
                            window.open(line.link.href);
                          } else {
                            window.location.href = line.link.href;
                          }
                        }
                      }}
                    >
                      {!noSelect && (
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedLines.includes(line) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-green" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-primary-green focus:ring-primary-green"
                            value={line.email}
                            checked={selectedLines.includes(line)}
                            onChange={(e) =>
                              setSelectedLines(
                                e.target.checked
                                  ? [...selectedLines, line]
                                  : selectedLines.filter((p) => p !== line)
                              )
                            }
                          />
                        </td>
                      )}
                      {fields.map((f, i) => {

                        if (!f) return null;

                        var value = line[f.field];

                        if (value === null || value === undefined) {
                          value = "-";
                        }

                        return i === 0 ? (
                          <td
                            key={i}
                            className={classNames(
                              "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                              selectedLines.includes(line)
                                ? "text-primary-green"
                                : ""
                            )}
                          >
                            <div
                              className={classNames(
                                f.className
                              )}
                            >
                              {value}
                            </div>
                          </td>
                        ) : (
                          <td
                            key={i}
                            className="px-3 py-4 text-sm text-gray-500 dark:text-gray-400 whitespace-nowrap"
                          >
                            {f.type === "button"
                              ? (
                                <Button
                                  onClick={(e) => {
                                    // Prevent the row from being selected
                                    e.stopPropagation();
                                    f.onClick(line);
                                  }}
                                  variant="secondary"
                                  className="!py-1 !px-3 my-[-5px]"
                                >
                                  {f.buttonLabel}
                                </Button>
                              )
                              : (
                                <div
                                  className={classNames(
                                    f.className
                                  )}
                                >
                                  {value}
                                </div>
                              )}
                          </td>
                        );
                      })}
                      {!noEdit && (
                        <td className={classNames(
                          "pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-3",
                          ellipsisOptions.length > 0 ? "py-3" : "py-4"
                        )}
                        >
                          {ellipsisOptions.length > 0 ? (
                            <IconDropdown
                              options={ellipsisOptions}
                              onSelected={(item) => item.onClick(line)}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          ) : (
                            <Link
                              to={line.href || "#"}
                              className="text-primary-green hover:text-primary-green-700"
                            >
                              Edit<span className="sr-only">, {line.name}</span>
                            </Link>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
