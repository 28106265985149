/**
  * Provides helper methods for math operations.
  * For example, rounding a number to a specified number of decimal places.
  */
export default class MathHelper {

  /**
    * Rounds a number to a specified number of decimal places.
    */
  static round(value: number, decimals = 0): number {
    // TODO: allow for rounding to places above tenths? e.g. 10's place: 123 -> 120
    let factor = Math.pow(10, decimals);

    // Add epsilot to handle some floating point issues
    return Math.round((value + Number.EPSILON) * factor) / factor;
  }

}
