import { PaperClipIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react'
import Button from 'src/components/input/Button';
import Modal from 'src/components/Modal'
import Spinner from 'src/components/Spinner';
import { Order, OrderStatus } from 'src/hooks/data/orders/useOrders.ts';
import UserManager from 'src/tools/UserManager';

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * Simple confirmation modal for cancelling an order.
 */
export default function CancelOrderModal({ order, open, setOpen }: Props) {

  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Cancels the order by hitting the API.
   */
  async function onCancel() {
    setLoading(true);
    try {
      var r = await UserManager.makeAuthenticatedRequest(`/api/orders/update-status`, "POST", {
        orderId: order._id,
        newStatus: OrderStatus.CANCELED,
      })
    } catch (e) {
      //  TODO: show an error message
      console.error(e);
    }

    //  TODO: show a success message
    setOpen(false);
    setLoading(false);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex items-center flex-col gap-3">
        <div className="font-semibold">Are you sure you want to cancel this order?</div>

        {/* Order Details */}
        <div className="flex gap-3">
          <div className="flex flex-col">
            <div className="text-sm font-medium">Order</div>
            <div className="text-sm font-normal text-gray-500">Name: {order.name ?? <span className="italic">No Name</span>}</div>
            <div className="text-sm font-normal text-gray-500">PO#: {order.poNumber ?? "--"}</div>
            <div className="text-sm font-normal text-gray-500">SO#: {order.soNumber ?? "--"}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-medium">Address</div>
            <div className="text-sm font-normal text-gray-500">
              <div>{order.orderAddress.line1}</div>
              {order.orderAddress.line2 && <div>{order.orderAddress.line2}</div>}
              <div>{order.orderAddress.city}, {order.orderAddress.state} {order.orderAddress.postalCode}</div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-medium">Contact Info</div>
            <div className="text-sm font-normal text-gray-500">
              <div>{order.contact?.name}</div>
              <div>{order.contact?.phone}</div>
              <div>{order.contact?.email}</div>
            </div>
          </div>
        </div>

        <div className="flex gap-2">
          {
            loading ? <div className="flex items-center pr-7">
              <Spinner size={20} />
            </div> :
              <Button variant="primary-rose" onClick={onCancel}>Cancel Order</Button>
          }
          <Button variant="secondary" onClick={() => setOpen(false)}>Go Back</Button>
        </div>
      </div>
    </Modal>
  )
}
