import React, { useState, useEffect } from 'react'
import TopBar from 'src/components/nav/TopBar'
import Card from 'src/components/Card'
import Table from 'src/components/tables/Table'
import UserManager from 'src/tools/UserManager'
import MultiSelectListbox from 'src/components/input/MultiSelectListbox.tsx'
import {
    formatOrdersForTable
} from 'src/tools/Orders/orderHelpers.tsx'
import {
    OrderStatus,
    TimestampTypes
} from 'src/hooks/data/orders/useOrders.ts'
import moment from 'moment'
import SimpleProgressDonut from 'src/components/charts/SimpleProgressDonut'
import classNames from 'src/tools/classNames'
import ToaLineChart from 'src/components/charts/LineChart'
import useConnections from 'src/hooks/data/connections/useConnections.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'

const GRAPH_HEIGHT = 90;

const orderTableFields = [
    {
        label: "Order Name",
        field: "orderName",
        className: "max-w-40 overflow-hidden text-ellipsis",
    },
    {
        label: "Address",
        field: "address",
        className: "max-w-44 overflow-hidden text-ellipsis",
    },
    {
        label: "Delivery Window",
        field: "deliveryWindow",
    },
    {
        label: "Delivery Time",
        field: "deliveryTime",
    },
    {
        label: "On Time",
        field: "timeStatus",
    },
    {
        label: "Material Accuracy",
        field: "materialStatus",
    },
    {
        label: "",
        field: "statusIcons",
    }
]

/**
 * A page that displays delivery performance stats/metrics
 * Shows the overall score, on time and material accuracy percentages for the last 7 days and last 30 days
 * Also shows historical performance for the last 8 weeks
 */
export default function PerformanceScorecard() {
    const user = useCurrentUser()

    const connections = useConnections()
    const [selectedConnections, setSelectedConnections] = useState([])

    const [markets, setMarkets] = useState([])
    const [selectedMarkets, setSelectedMarkets] = useState([])

    const [locations, setLocations] = useState([])
    const [selectedLocations, setSelectedLocations] = useState([])

    const [orders, setOrders] = useState([])

    const [sevenDaysAgo, setSevenDaysAgo] = useState(null)
    const [today, setToday] = useState(null)

    const [orderCountLast7Days, setOrderCountLast7Days] = useState(0)
    const [onTimeCountLast7Days, setOnTimeCountLast7Days] = useState(0)
    const [accurateMaterialCountLast7Days, setAccurateMaterialCountLast7Days] = useState(0)

    const [orderCountLast30Days, setOrderCountLast30Days] = useState(0)
    const [onTimeCountLast30Days, setOnTimeCountLast30Days] = useState(0)
    const [accurateMaterialCountLast30Days, setAccurateMaterialCountLast30Days] = useState(0)

    const [last8WeeksStats, setLast8WeeksStats] = useState([])

    // when the page first loads, select all connections
    useEffect(() => {
        if (connections?.length > 0) {
            setSelectedConnections(connections)
        }
    }, [connections])

    // when the connections are loaded, get the markets and locations
    useEffect(() => {
        var installerIds;
        var distributorIds;
        if (user?.company?.type === 'distributor') {
            if (selectedConnections.length === 0) {
                installerIds = connections?.map(i => i.id).join(",")
            } else {
                installerIds = selectedConnections?.map(i => i.id).join(",")
            }
        } else if (user?.company?.type === 'installer') {
            if (selectedConnections.length === 0) {
                distributorIds = connections?.map(d => d.id).join(",")
            } else {
                distributorIds = selectedConnections?.map(d => d.id).join(",")
            }
        }

        UserManager.makeAuthenticatedRequest(
            `/api/markets/${user?.company?.type}/find${user?.company?.type === 'distributor' ? '?installerIds=' + installerIds : ""}`,
            'GET'
        )
            .then((response) => {
                if (response.data.status === "ok") {
                    setMarkets(response.data.markets)
                    // initially select all markets
                    setSelectedMarkets(response.data.markets)
                }
            })
            .catch((err) => {
                console.error(err)
            })

        UserManager.makeAuthenticatedRequest(
            `/api/location/${user?.company?.type}/list${user?.company?.type === 'installer' ? '?distributorIds=' + distributorIds : ""}`,
            'GET'
        )
            .then((response) => {
                if (response.data.status === "ok") {
                    setLocations(response.data.locations)
                    // initially select all locations
                    setSelectedLocations(response.data.locations)
                }
            })
            .catch((err) => {
                console.error(err)
            })

    }, [selectedConnections])

    // when the connections, markets, and locations are loaded, get the orders with the selected filters
    useEffect(() => {
        if (user) {
            const _7DaysAgo = moment().subtract(7, "days").startOf("day");
            const today = moment()

            setSevenDaysAgo(_7DaysAgo.format("MMMM D"))
            setToday(today.format("MMMM D"))

            const queryFilters = {
                ...(user?.company?.type === 'installer'
                    ? { distributorIds: selectedConnections?.map(i => i.id) }
                    : { installerIds: selectedConnections?.map(d => d.id) }
                ),
                marketIds: selectedMarkets?.map(m => m._id),
                locationIds: selectedLocations?.map(l => l._id),
                orderStatus: [OrderStatus.DELIVERED],
                requestedDeliveryLowerBound: _7DaysAgo.toDate(),
            }

            UserManager.makeAuthenticatedRequest(
                `/api/orders/${user.company.type}/list?sort=deliveryDate&stats=true&queryFilters=${JSON.stringify(queryFilters)}`,
                'GET'
            )
                .then((response: any) => {
                    // orders are formatted like {2024-09-23: [...orders], 2024-09-24: [...orders], ...}
                    // so we need to flatten them into a single array
                    const newOrders: any = Object.values(response.data.orders).reduce((acc: any, val) => acc.concat(val), [])
                    setOrders(
                        formatOrdersForTable(newOrders, markets)
                            // if requestedDeliveryStart, sort by that, otherwise sort by requestedDeliveryEnd
                            .sort((a, b) => {
                                const aStart = a.requestedDeliveryStart || a.requestedDeliveryEnd
                                const bStart = b.requestedDeliveryStart || b.requestedDeliveryEnd
                                return aStart.localeCompare(bStart)
                            })
                    )

                    // set order counts for last 7 days
                    setOrderCountLast7Days(response.data.orderAccuracyStats.orderCountLast7Days)
                    setOnTimeCountLast7Days(response.data.orderAccuracyStats.onTimeCountLast7Days)
                    setAccurateMaterialCountLast7Days(response.data.orderAccuracyStats.accurateMaterialCountLast7Days)

                    // set order counts for last 30 days
                    setOrderCountLast30Days(response.data.orderAccuracyStats.orderCountLast30Days)
                    setOnTimeCountLast30Days(response.data.orderAccuracyStats.onTimeCountLast30Days)
                    setAccurateMaterialCountLast30Days(response.data.orderAccuracyStats.accurateMaterialCountLast30Days)

                    // set last 8 weeks stats
                    setLast8WeeksStats(response.data.orderAccuracyStats.last8WeeksStats)
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    }, [selectedConnections, selectedMarkets, selectedLocations])

    const onTimePercentageLast7Days = orderCountLast7Days ? (onTimeCountLast7Days / orderCountLast7Days) * 100 : 0
    const materialAccuracyPercentageLast7Days = orderCountLast7Days ? (accurateMaterialCountLast7Days / orderCountLast7Days) * 100 : 0

    const onTimePercentageLast30Days = orderCountLast30Days ? (onTimeCountLast30Days / orderCountLast30Days) * 100 : 0
    const materialAccuracyPercentageLast30Days = orderCountLast30Days ? (accurateMaterialCountLast30Days / orderCountLast30Days) * 100 : 0

    /**
     * Handles setting the selected distributor based on the new distributor IDs.
     */
    function handleSelectedConnectionsChange(newDistributorIds: string[]) {
        setSelectedConnections(connections?.filter(d => newDistributorIds.includes(d.id)))
    }

    /**
     * Handles setting the selected markets based on the new market IDs.
     */
    function handleSelectedMarketsChange(newMarketIds: string[]) {
        setSelectedMarkets(markets?.filter(m => newMarketIds.includes(m._id)))
    }

    /**
     * Handles setting the selected locations based on the new location IDs.
     */
    function handleSelectedLocationsChange(newLocationIds: string[]) {
        setSelectedLocations(locations?.filter(m => newLocationIds.includes(m._id)))
    }

    /**
     * Returns a table with the displaying the on time and material accuracy for the 
     * last 30 days, last 7 days, and the difference between them.
     * 
     * @param last30Days The on time or material accuracy percentage for the last 30 days
     * @param last7Days The on time or material accuracy percentage for the last 7 days
     * @returns The table
     */
    function historicalTable(last30Days, last7Days) {
        return (
            <div
                className='grid w-48 grid-cols-3 grid-rows-2 mx-2'
            >
                <div
                    className='self-end col-span-1 row-span-1 pb-0.5 pl-2 text-lg font-medium text-left text-gray-500 align-bottom border-b border-gray-300'
                >
                    L30
                </div>
                <div
                    className='self-end col-span-1 row-span-1 pb-0.5 pl-2 text-lg font-medium text-left text-gray-500 align-middle border-b border-gray-300'
                >
                    L7
                </div>
                <div
                    className='self-end col-span-1 row-span-1 pb-0.5 pl-2 text-lg font-medium text-left text-gray-500 align-middle border-b border-gray-300'
                >
                    + / -
                </div>
                <div
                    className='col-span-1 row-span-1 pt-1 pl-2 mt-1.5 text-lg font-semibold text-left align-middle border-r border-gray-300'
                >
                    {last30Days.toFixed(0) + "%"}
                </div>
                <div
                    className='col-span-1 row-span-1 pt-1 pl-2 mt-1.5 text-lg font-semibold text-left align-middle border-r border-gray-300'
                >
                    {last7Days.toFixed(0) + "%"}
                </div>
                <div
                    className={classNames(
                        'col-span-1 row-span-1 text-lg font-semibold text-left align-middle pl-2 mt-1.5 pt-1',
                        last7Days > last30Days
                            ? 'text-primary-green'
                            : last7Days < last30Days
                                ? 'text-primary-rose'
                                : 'text-gray-400'
                    )}
                >
                    {(last7Days - last30Days).toFixed(0) + "%"}
                </div>
            </div>
        )
    }

    /**
     * Custom tooltip for the on time and material accuracy graphs.
     */
    function CustomTooltip({
        active,
        payload,
        label,
        fields = [],
    }) {
        if (active && payload && payload.length) {
            return (
                <div className="flex flex-col gap-2 p-2 text-sm bg-white border border-gray-300 rounded-md dark:bg-gray-700">
                    {/* Table */}
                    <table className="table table-fixed">
                        <tbody>
                            {fields?.map((f, i) => {
                                var value = payload[0].payload[f.field];

                                if (f.field === "dateRange") {
                                    return (
                                        <tr key={i}>
                                            <td
                                                className={classNames(
                                                    "text-left text-xs"
                                                )}
                                            >
                                                {value}
                                                {f.units}
                                            </td>
                                        </tr>
                                    );
                                }
                                if (["onTimePercentage", "materialAccuracyPercentage"].includes(f.field)) {
                                    return (
                                        <tr key={i}>
                                            <td className="flex flex-row gap-1 text-xs text-gray-500 dark:text-gray-300">
                                                {f.label}:
                                                <div
                                                    className={classNames(
                                                        "text-left text-xs",
                                                        f.highlight ? "text-primary-green" : ""
                                                    )}
                                                >
                                                    {value.toFixed(0) + f.units}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    // data for the on time graph
    var onTimeGraph = {
        height: GRAPH_HEIGHT,
        data:
            last8WeeksStats?.map((week, i) => {
                return {
                    // use week.timeRangeStart and week.timeRangeEnd to get the date range, format like Oct 13 - Oct 20
                    dateRange: moment(week.timeRangeStart).format("MMM D") + " - " + moment(week.timeRangeEnd).format("MMM D"),
                    onTimePercentage: week.orderCount ? (week.onTimeCount / week.orderCount) * 100 : 0,
                };
            }) || [],
        dataKeys: ["onTimePercentage"],
        dots: false,
        lines: [],
        xAxisSettings: {
            hide: true
        },
        yAxisSettings: {
            hide: true
        },
        customTooltip: (
            <CustomTooltip
                fields={[
                    {
                        label: "Date Range",
                        field: "dateRange",
                    },
                    {
                        label: "On Time",
                        field: "onTimePercentage",
                        round: true,
                        highlight: true,
                        units: " %",
                    }
                ]}
                active={undefined}
                payload={undefined}
                label={undefined}
            />
        ),
    };

    // data for the material accuracy graph
    var materialAccuracyGraph = {
        height: GRAPH_HEIGHT,
        data:
            last8WeeksStats?.map((week, i) => {
                return {
                    // use week.timeRangeStart and week.timeRangeEnd to get the date range, format like Oct 13 - Oct 20
                    dateRange: moment(week.timeRangeStart).format("MMM D") + " - " + moment(week.timeRangeEnd).format("MMM D"),
                    materialAccuracyPercentage: week.orderCount ? (week.accurateMaterialCount / week.orderCount) * 100 : 0,
                };
            }) || [],
        dataKeys: ["materialAccuracyPercentage"],
        dots: false,
        lines: [],
        xAxisSettings: {
            hide: true
        },
        yAxisSettings: {
            hide: true
        },
        customTooltip: (
            <CustomTooltip
                fields={[
                    {
                        label: "Date Range",
                        field: "dateRange",
                    },
                    {
                        label: "Materials",
                        field: "materialAccuracyPercentage",
                        round: true,
                        highlight: true,
                        units: " %",
                    }
                ]}
                active={undefined}
                payload={undefined}
                label={undefined}
            />
        ),
    };

    return (
        <div className="flex flex-col max-h-screen dark:text-white grow">
            {/* Top Bar */}
            <TopBar>Performance Scorecard</TopBar>

            <div
                className='flex flex-col items-center justify-start h-full gap-4 px-6 mt-4 overflow-x-scroll'
            >
                <div
                    className='flex flex-row items-center justify-between w-full'
                >
                    <div
                        className='text-3xl font-semibold text-center align-middle'
                    >
                        Delivery Scorecard
                    </div>
                    {/* TODO: figure out what we want to show in grid view
                    <GridListToggle
                        onChange={() => {
                            console.log('changed')
                        }}
                    /> */}
                </div>

                <Card
                    className='w-[1500px]'
                >
                    <div
                        className='flex flex-col w-full gap-3'
                    >
                        <div
                            className='flex flex-row items-center justify-between w-full'
                        >
                            <div
                                className='flex flex-row items-center justify-center gap-2'
                            >
                                <div
                                    className=''
                                >
                                    <MultiSelectListbox
                                        itemType={user?.company?.type === 'installer' ? "Distributor" : "Installer"}
                                        options={connections?.map(d => ({ label: d.name, value: d.id })) || []}
                                        selectedOptionsValues={selectedConnections?.map(d => d.id) || []}
                                        onChange={handleSelectedConnectionsChange}
                                    />
                                </div>
                                <div
                                    className=''
                                >
                                    <MultiSelectListbox
                                        itemType="Market"
                                        options={markets?.map(m => ({ label: m.name, value: m._id })) || []}
                                        selectedOptionsValues={selectedMarkets?.map(m => m._id) || []}
                                        onChange={handleSelectedMarketsChange}
                                    />
                                </div>
                                <div
                                    className=''
                                >
                                    <MultiSelectListbox
                                        itemType="Location"
                                        options={locations?.map(l => ({ label: l.name, value: l._id })) || []}
                                        selectedOptionsValues={selectedLocations?.map(l => l._id) || []}
                                        onChange={handleSelectedLocationsChange}
                                    />
                                </div>
                            </div>

                            <div
                                className='hidden'
                            >
                                {"<users and chat stuff>"}
                            </div>
                        </div>

                        {/* scorecard stats */}
                        <div
                            className='grid grid-cols-12 grid-rows-3 border border-gray-300 rounded-md h-60'
                        >
                            <div
                                className='flex flex-col items-center justify-center col-span-1 row-span-1 border-b border-r border-gray-300'
                            >
                                <div
                                    className='text-base font-semibold text-center align-middle'
                                >
                                    Overall Score
                                </div>
                                <div
                                    className='text-xs font-semibold text-center align-middle'
                                >
                                    {orderCountLast7Days + " Orders"}
                                </div>
                            </div>

                            <div
                                className='flex flex-col items-center justify-center col-span-3 row-span-1 border-b border-r border-gray-300'
                            >
                                <div
                                    className='text-base font-semibold text-center align-middle'
                                >
                                    Delivery Accuracy
                                </div>
                                <div
                                    className='text-xs font-semibold text-center align-middle'
                                >
                                    {"Last 7 Days (" + sevenDaysAgo + " - " + today + ")"}
                                </div>
                            </div>

                            <div
                                className='flex flex-col items-center justify-center col-span-8 row-span-1 border-b border-gray-300'
                            >
                                <div
                                    className='text-base font-semibold text-center align-middle'
                                >
                                    Historical Performance
                                </div>
                            </div>

                            <div
                                className='col-span-1 row-span-2 py-5'
                            >
                                <div
                                    className='flex flex-col items-center justify-center h-full border-r border-gray-300'
                                >
                                    {/* circle chart */}
                                    <SimpleProgressDonut
                                        numerator={(onTimePercentageLast7Days + materialAccuracyPercentageLast7Days)}
                                        denominator={200}
                                        usePercentage={true}
                                        size={"sm"}
                                    />
                                </div>
                            </div>

                            <div
                                className='col-span-3 row-span-2 py-5'
                            >
                                <div
                                    className='flex flex-row items-center justify-center h-full border-r border-gray-300'
                                >
                                    {/* on time and material ratios */}
                                    <div
                                        className='flex flex-col items-center justify-center w-1/2'
                                    >
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            {onTimePercentageLast7Days.toFixed(0) + "%"}
                                        </div>
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            On Time
                                        </div>
                                        <div
                                            className='text-base font-normal text-center text-gray-500 align-middle'
                                        >
                                            {onTimeCountLast7Days + " / " + orderCountLast7Days}
                                        </div>
                                        <div
                                            className='relative w-32 h-4 mt-2 bg-gray-200 rounded-full'
                                        >
                                            <div
                                                className='absolute h-full rounded-full bg-primary-green'
                                                style={{ width: onTimePercentageLast7Days + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div
                                        className='flex flex-col items-center justify-center w-1/2'
                                    >
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            {materialAccuracyPercentageLast7Days.toFixed(0) + "%"}
                                        </div>
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            Material Accuracy
                                        </div>
                                        <div
                                            className='text-base font-normal text-center text-gray-500 align-middle'
                                        >
                                            {accurateMaterialCountLast7Days + " / " + orderCountLast7Days}
                                        </div>
                                        <div
                                            className='relative w-32 h-4 mt-2 bg-gray-200 rounded-full'
                                        >
                                            <div
                                                className='absolute h-full rounded-full bg-primary-green'
                                                style={{ width: materialAccuracyPercentageLast7Days + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className='col-span-8 row-span-2 py-5'
                            >
                                <div
                                    className='flex flex-row items-center justify-center w-full h-full'
                                >
                                    {/* historical performance charts */}
                                    <div
                                        className='flex flex-col items-center justify-center w-1/2 h-full gap-2'
                                    >
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            Deliveries On Time
                                        </div>
                                        <div
                                            className='flex flex-row items-center justify-center w-full px-2'
                                        >
                                            <div
                                                className='w-1/2'
                                            >
                                                {historicalTable(onTimePercentageLast30Days, onTimePercentageLast7Days)}
                                            </div>
                                            <div
                                                className='w-1/2'
                                            >
                                                <ToaLineChart
                                                    {...onTimeGraph}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className='flex flex-col items-center justify-center w-1/2 h-full gap-2'
                                    >
                                        <div
                                            className='text-base font-bold text-center align-middle'
                                        >
                                            Material Accuracy
                                        </div>
                                        <div
                                            className='flex flex-row items-center justify-center w-full px-2'
                                        >
                                            <div
                                                className='w-1/2'
                                            >
                                                {historicalTable(materialAccuracyPercentageLast30Days, materialAccuracyPercentageLast7Days)}
                                            </div>
                                            <div
                                                className='w-1/2'
                                            >
                                                <ToaLineChart
                                                    {...materialAccuracyGraph}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Table
                                noSelect
                                noEdit
                                thinFirstColumn
                                fields={orderTableFields}
                                data={orders}
                            />
                        </div>

                    </div>
                </Card>

            </div>
        </div>
    )
}
