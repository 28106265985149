import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import UserManager from "src/tools/UserManager";
import useMarkets from "./useMarkets.ts";

/**
 *  A hook that returns the markets of the user's installer.
 *  Also returns a state for selecting a market.
 *
 *  Before markets are loaded, `markets` and `selectedMarket` are null.
 *
 *  Simple example usage: 
 *  ```tsx
 *  const [markets, selectedMarket, setSelectedMarket] = useMarketSelect();
 *  if (!markets) return <div>Loading...</div>;
 *  return <ul>{markets.map(market => <li>{market.name}</li>)}</ul>;
 *  ```
 *
 *  Other hooks: 
 *  - `useMarkets` - If you don't need to select markets, use `useMarkets`
 *  - `useMultiMarketSelect` - If you're looking for more than one market selected at a time, use `useMultiMarketSelect`
 */
export default function useMarketSelect(defaultId?: string, includeNullId: boolean = false): readonly [readonly MarketType[] | null, MarketType | null, Dispatch<SetStateAction<MarketType>>] {
  const markets = useMarkets(includeNullId);
  const [selectedMarket, setSelectedMarket] = useState<MarketType>();

  // Set selected market on load
  useEffect(() => {
    if (markets && !selectedMarket) {
      setSelectedMarket(defaultId ?
        markets.find((market: MarketType) => market._id === defaultId)
        : markets[0]);
    }
  }, [markets]);

  return [markets, selectedMarket, setSelectedMarket] as const;
}
