import React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

/**
 * Crew planner page within Labor page.
 * Currently just padding and outlet for nested routes.
 */
export default function CrewPlannerPage({ }: Props) {
  return (
    <div>


      {/* Content */}

      <div className="py-2 px-6">
        <Outlet />
      </div>

    </div>
  )
}
