import moment from 'moment'
import React, { useState } from 'react'
import InfoTooltip from 'src/components/InfoTooltip'
import MinMaxBarChart, { Field } from 'src/components/charts/MinMaxBarChart.tsx'
import NumberGrid, { Colors, States } from 'src/components/tables/NumberGrid.tsx'
import ArrayHelpers from 'src/utils/arrayHelpers.ts'
import { H2, Label } from 'src/pages/Labor/CrewPlanner/MainView.tsx'
import MathHelper from 'src/utils/mathHelper.ts'

type Props = {
  jobsAvailable: number[]
  jobCapacity: number[]
  desiredRatioRange: {
    min: number;
    max: number;
  }
  onRangeChange: (range: { min: number, max: number }) => void;
}

/**
  * Displays the ratio of jobs available to job capacity for each week.
  * Shows both a bar chart and a grid.
  */
export default function WorkCapacityRatioChart({ jobsAvailable, jobCapacity, desiredRatioRange, onRangeChange }: Props) {
  const minMax = desiredRatioRange ?? { min: 0, max: 1 }

  let workCapacityRatios = jobCapacity.map((c, i) => {
    // Work capacity ratio = available / job capacity
    if (c === 0) return 0;
    return jobsAvailable[i] / c;
  });
  workCapacityRatios = workCapacityRatios.map(r => MathHelper.round(r, 1));

  let issueIndexes = workCapacityRatios.reduce((acc, r, i) => {
    if (r < minMax.min || r > minMax.max) {
      acc.push(i)
    }
    return acc;
  }, [])

  let rows = [
    {
      values: workCapacityRatios,
      isEditable: false,
      state: States.DEFAULT,
      issueIndexes: issueIndexes,
    },
  ]


  /**
    * Info tooltips to help users understand the data.
    */
  let tooltips = {
    workCapacityRatio: "Ratio of availble jobs to job capacity (i.e. available / capacity). Represents how much work is available compared to how much work can be done."
  }

  let tooltipFields: Field[] = [
    {
      field: "value",
      label: "Work / Capacity",
      highlight: true,
    },
    {
      field: "available",
      label: "Available",
    },
    {
      field: "capacity",
      label: "Capacity",
    }

  ];

  let data = ArrayHelpers.mergeArrays(
    ["label", "value", "available", "capacity"],
    // Generate labels for each week (M/D format)
    ArrayHelpers.generateNDatesFormatted(workCapacityRatios.length, "weeks", moment().utc().startOf("week").toDate(), "M/D", { useUTC: true }),
    workCapacityRatios,
    jobsAvailable,
    jobCapacity
  ) as { label: string, value: number }[];

  /**
    * Updates the max value for the chart.
    */
  function handleMaxChange(value: number) {
    if (value > minMax.min) {
      // setMinMax({ ...minMax, max: value })
      onRangeChange({ min: minMax.min, max: value });
    }
  }

  /**
    * Updates the min value for the chart.
    */
  function handleMinChange(value: number) {
    if (value < minMax.max) {
      // setMinMax({ ...minMax, min: value })
      onRangeChange({ min: value, max: minMax.max });
    }
  }

  return (
    <div>
      <div className="flex items-stretch gap-2">
        <Label />
        <div className="grow">
          <MinMaxBarChart
            data={data}
            min={minMax.min}
            max={minMax.max}
            showInputs
            onMaxChange={handleMaxChange}
            onMinChange={handleMinChange}
            tooltipFields={tooltipFields}
          />
        </div>
      </div>
      <div className="flex items-stretch gap-2">
        <Label>
          <H2><p>Work / Capacity</p><InfoTooltip text={tooltips.workCapacityRatio} /></H2>
        </Label>
        <NumberGrid rows={rows} />
      </div>
    </div>
  )
}

