import {
  Bars4Icon,
  Squares2X2Icon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import IconToggle, { IconType } from "./IconToggle.tsx";


export type Id = "grid" | "list" | "columns" | "rows";

type Prop = {
  onChange: (id: Id) => void;
  options?: Id[];
  selectedOption?: Id;
};

/**
 * A toggle switch for switching between grid and list views, and optionally column views.
 *
 * By default, the options are "grid" and "list". You can define additional options by passing them in the `options` prop.
 * `options` has type `("grid"|"list"|"columns"|"rows")[]`.
 *
 * `onChange` is a callback function that is called when the user changes the view. It receives the new ID as an argument.
 * 
 * @example
 * ```tsx
 * <GridListToggle onChange={(id) => console.log(id)} />
 * <GridListToggle onChange={(id) => console.log(id)} options={["rows", "columns", "grid"]} />
 * ```
 */
export default function GridListToggle({
  onChange,
  options = ["grid", "list"],
  selectedOption: selected_prop = "grid"
}: Prop): JSX.Element {
  const [selectedOption, setSelectedOption] = useState(selected_prop);

  let onClass = "text-primary-green stroke-2 h-5 w-5 stroke-2";
  let offClass = "text-gray-500 stroke-2 h-5 w-5 stroke-2";

  useEffect(() => {
    setSelectedOption(selected_prop);
  }, [selected_prop]);

  let gridIcon: IconType = {
    id: "grid",
    name: "Grid",
    content: {
      on: <Squares2X2Icon className={onClass} aria-hidden="true" />,
      off: <Squares2X2Icon className={offClass} aria-hidden="true" />,
    },
  };
  let listIcon: IconType = {
    id: "list",
    name: "List",
    content: {
      on: <Bars4Icon className={onClass} aria-hidden="true" />,
      off: <Bars4Icon className={offClass} aria-hidden="true" />,
    },
  };
  let columnsIcon: IconType = {
    id: "columns",
    name: "Columns",
    content: {
      on: <ViewColumnsIcon className={onClass} aria-hidden="true" />,
      off: <ViewColumnsIcon className={offClass} aria-hidden="true" />,
    },
  };
  let rowsIcon: IconType = {
    id: "rows",
    name: "Rows",
    content: {
      on: (
        <div className="rotate-90">
          <ViewColumnsIcon className={onClass} aria-hidden="true" />
        </div>
      ),
      off: (
        <div className="rotate-90">
          <ViewColumnsIcon className={offClass} aria-hidden="true" />
        </div>
      ),
    },
  };

  let iconMap = {
    grid: gridIcon,
    list: listIcon,
    columns: columnsIcon,
    rows: rowsIcon,
  };

  let icons = options.map((id) => iconMap[id]);

  return <IconToggle selectedIconId={selectedOption} icons={icons} onChange={onChange} />;
}
