import React, { Fragment } from 'react'
import SwitchCase from 'src/components/utils/SwitchCase.tsx'
import useConnections from 'src/hooks/data/connections/useConnections.ts'
import useLocations from 'src/hooks/data/locations/useLocations.ts'
import useMarkets from 'src/hooks/data/markets/useMarkets.ts'
import { Order } from 'src/hooks/data/orders/useOrders.ts'
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts'
import useUsersByIds from 'src/hooks/data/users/useUsersByIds.ts'
import { MinorBadge } from '../OrderRowItem.tsx'

type Props = {
  order: Order;
  title: string;
  subtitle?: string;
}

/**
 * Component to display major order metadata.
 * Useful to put at the top of an order action modal.
 * Takes `title` and `subtitle` to display info about the modal's purpose.
 */
export default function OrderModalHeader({
  order, title, subtitle
}: Props) {

  // --- Hooks --- //

  const user = useCurrentUser();
  const companyType = user?.company?.type;

  const connections = useConnections(); // TODO: only get specific one? pass from parent?
  let connectionId = null;
  switch (companyType) {
    case "installer":
      connectionId = order.distributorId;
      break;
    case "distributor":
      connectionId = order.installerId;
      break;
  }
  const connectionName = connections
    ?.find(c => c.id === connectionId)
    ?.name;

  const markets = useMarkets()
  const marketName = markets?.find(m => m._id === order.marketId)?.name;

  const locations = useLocations();
  const locationName = locations?.find(l => l._id === order.locationId)?.name;

  const usersMap = useUsersByIds([order.primaryContactId, order.installerOnSiteLeadId, ...order.sharedWithIds]);
  const users = usersMap ? Object.values(usersMap) : [];


  return (<Fragment>
    {/* Header */}
    <div className="flex items-center justify-between pb-3">
      <div className="flex flex-col">
        <div className="text-base font-semibold">{title}</div>
        {subtitle && <div className="text-sm font-normal text-gray-500">{subtitle}</div>}
      </div>
      <div className="grid grid-cols-2 gap-2 whitespace-nowrap">
        <div className="text-sm font-medium">PO #:</div>
        <div className="text-sm font-normal text-gray-400">{order.poNumber ?? "--"}</div>
        <div className="text-sm font-medium">SO #:</div>
        <div className="text-sm font-normal text-gray-400">{order.soNumber ?? "--"}</div>
        {/* TODO: TOA ID when we make it */}
      </div>
    </div >

    <div>
      {/* Sharing */}
      <div className="flex items-start gap-6 py-4">
        <div className="grid grid-cols-[auto,1fr] gap-2 grow">
          <div className="text-sm font-medium whitespace-nowrap">Distributor:</div>
          {/* Connection Badge */}
          <div>
            <MinorBadge>
              {connections ? (connectionName ?? <span className="italic">No Connection</span>) : <Loading />}
            </MinorBadge>
          </div>
          {/* Market or Location Badge */}
          <SwitchCase test={companyType}>
            {/* Market */}
            <div data-case="installer" className="contents">
              <div className="text-sm font-medium whitespace-nowrap">Market:</div>
              <div>
                <MinorBadge>
                  {markets ? (marketName ?? <span className="italic">No Market</span>) : <Loading />}
                </MinorBadge>
              </div>
            </div>
            {/* Location */}
            <div data-case="distributor" className="contents">
              <div className="text-sm font-medium whitespace-nowrap">Location:</div>
              <div>
                <MinorBadge>
                  {locations ? (locationName ?? <span className="italic">No location</span>) : <Loading />}
                </MinorBadge>
              </div>
            </div>
          </SwitchCase>
        </div>
        {/* Shared With Section */}
        <div className="flex flex-col gap-2 grow">
          <div className="text-sm font-medium">Shared With</div>
          <div className="flex flex-wrap gap-2">
            {users?.map(user => (
              <MinorBadge key={user._id}>
                {user.email}
              </MinorBadge>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* Order Details */}
    <div className="grid grid-cols-3 py-4">
      {/* Name */}
      <div className="flex flex-col">
        <div className="text-sm font-medium">Order Name</div>
        <div className="text-sm font-normal text-gray-500">{order.name ?? <span className="italic">No Name</span>}</div>
      </div>
      {/* Address */}
      <div className="flex flex-col">
        <div className="text-sm font-medium">Address</div>
        <div className="text-sm font-normal text-gray-500">
          <div>{order.orderAddress.line1}</div>
          {order.orderAddress.line2 && <div>{order.orderAddress.line2}</div>}
          <div>{order.orderAddress.city}, {order.orderAddress.state} {order.orderAddress.postalCode}</div>
        </div>
      </div>
      {/* Contact */}
      <div className="flex flex-col">
        <div className="text-sm font-medium">Contact Info</div>
        <div className="text-sm font-normal text-gray-500">
          <div>{order.contact?.name}</div>
          <div>{order.contact?.phone}</div>
          <div>{order.contact?.email}</div>
        </div>
      </div>
    </div>
  </Fragment>)
}

/**
  * Super simple loading component.
  * Pulses "Loading"
  */
function Loading({ }) {
  return <span className="animate-pulse">Loading</span>
}
