import React, { ChangeEvent, useEffect, useState } from 'react'
import classNames from 'src/tools/classNames';

// TODO: handle dark mode
// TODO: handle min width

type Props = {
  rows: Row[],
  numColumns?: number,
  onChange?: (rowIndex: number, colIndex: number, value: number, event: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (rowIndex: number, colIndex: number, value: number, event: React.FocusEvent<HTMLInputElement, Element>) => void,
}

export enum States {
  DEFAULT = "default",
  FADED = "faded",
}

export enum Colors {
  GREEN = "border-primary-green",
  PURPLE = "border-purple-500",
  ROSE = "border-primary-rose",
}

export type Row = {
  values: number[],
  state?: States,
  isEditable?: boolean,
  height?: number,
  border?: Colors | string,
  issueIndexes?: number[], // Highlights cell rose
  name?: string,
  numberOptions?: NumberOptions,
}
export const DEFAULT_ROW_HEIGHT = 40;

/**
 * Allows for a grid of numbers to be displayed.
 * Rows can be editable, colored, and disabled.
 *
 * numColumns is optional and will default to the number of values in the first row.
 * Missing values will be displayed as empty cells.
 */
export default function NumberGrid({
  rows: rows_prop,
  numColumns,
  onChange = () => { },
  onBlur = () => { },
}: Props): JSX.Element {
  const [rows, setRows] = useState<Row[]>(rows_prop);

  useEffect(() => {
    setRows(rows_prop);
  }, [rows_prop])

  // No Data
  if (rows.length === 0) {
    return <></>;
  }

  // Assume first row length is number of columns
  if (numColumns === undefined) {
    numColumns = rows[0].values.length
  }

  return (
    <table className="table w-full table-fixed">
      <tbody>
        {/* Display Each Row */}
        {rows.map((row, i) => (
          <tr key={i} className={classNames()} style={{ height: row.height ?? DEFAULT_ROW_HEIGHT }}>
            {/* Display numColumns Cells */}
            {
              Array(numColumns)
                .fill(null)
                .map((_, j) => {

                  let value = null;
                  if (j < row.values.length) {
                    value = row.values[j];
                  }

                  // Unique key for location and value
                  // This ensures that if the value changes, it will rerender
                  return <Cell
                    // key={`${i}-${j}-${value}`}
                    key={j}
                    rows={rows}
                    rowIndex={i}
                    colIndex={j}
                    isIssue={row.issueIndexes?.includes(j) ?? false}
                    onChange={(rowIndex, colIndex, value, event) => {
                      // TODO: enforce step/min/max
                      let parsedValue = +value;
                      if (isNaN(parsedValue)) parsedValue = 0;

                      onChange(rowIndex, colIndex, parsedValue, event)

                      setRows((prev) => {
                        let newRows = [...prev];
                        let newValues = [...newRows[rowIndex].values];
                        newValues[colIndex] = parsedValue;
                        newRows[rowIndex].values = newValues;
                        return newRows;
                      })
                    }}
                    onBlur={(rowIndex, colIndex, value, event) => {
                      let parsedValue = +value;
                      if (isNaN(parsedValue)) parsedValue = 0;

                      onBlur(rowIndex, colIndex, parsedValue, event)
                    }}
                    numberOptions={row.numberOptions}
                  />
                })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const DEFAULT_NUMBER_OPTIONS = {
  step: 1,
  min: 0,
}

type NumberOptions = {
  step?: number,
  min?: number,
  max?: number,
}

type CellProps = {
  rows: Row[],
  rowIndex: number,
  colIndex: number,
  isIssue?: boolean,
  onChange?: (rowIndex: number, colIndex: number, value: string, event: ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (rowIndex: number, colIndex: number, value: string, event: React.FocusEvent<HTMLInputElement, Element>) => void,
  numberOptions?: NumberOptions
}

function Cell({
  rows, rowIndex,
  colIndex,
  isIssue,
  onChange = () => { },
  onBlur = () => { },
  numberOptions = DEFAULT_NUMBER_OPTIONS
}: CellProps): JSX.Element {

  // Get values from rows
  let row = rows[rowIndex];
  let prev = rowIndex > 0 ? rows[rowIndex - 1] : undefined;
  let next = rowIndex < rows.length - 1 ? rows[rowIndex + 1] : undefined;
  let value = colIndex < row.values.length ? row.values[colIndex] : null;

  // --- Borders --- //

  // We want to override default borders with colored borders
  // so we check prev and next for color if this row has no color

  // Border maps from color to class
  let topBorders = {
    [Colors.GREEN]: "border-t-primary-green",
    [Colors.PURPLE]: "border-t-purple-500",
    [Colors.ROSE]: "border-t-primary-rose",
    default: "border-t-gray-400",
  }
  let bottomBorders = {
    [Colors.GREEN]: "border-b-primary-green",
    [Colors.PURPLE]: "border-b-purple-500",
    [Colors.ROSE]: "border-b-primary-rose",
    default: "border-b-gray-400",
  }

  // Default + top border and bottom border if applicable
  let defaultBorderClass = "border border-gray-400";
  if (prev?.border) {
    defaultBorderClass += ` ${topBorders[prev.border]}`;
  }
  if (next?.border) {
    defaultBorderClass += ` ${bottomBorders[next.border]}`;
  }

  let border = "";
  switch (row.state) {
    default:
    case States.DEFAULT:
      border = row.border ? `border ${row.border}` : defaultBorderClass;
      break;
    case States.FADED:
      border = "border border-gray-200";

      // Handle top and bottom borders if next/prev have colors or are not faded
      if (prev && (prev?.border || prev?.state !== States.FADED)) {
        border += ` ${prev?.border ? topBorders[prev.border] : topBorders["default"]}`;
      }
      if (next && (next?.border || next?.state !== States.FADED)) {
        border += ` ${next?.border ? bottomBorders[next.border] : bottomBorders["default"]}`;
      }
      break;
  }

  // --- Text --- //

  // Choose text color based on state
  // Override with white if issue
  let text = "";
  switch (row.state) {
    default:
    case States.DEFAULT:
      text = "text-gray-700";
      break;
    case States.FADED:
      text = "text-gray-400";
      break;
  }
  if (isIssue) {
    text = "text-white";
  }

  return <td className={classNames(
    // Border color 
    border,

    // Text alignment
    "text-center",

    // Text color
    text,

    // BG color
    !isIssue ? "" : "bg-primary-rose",
  )}>
    {/* TODO: make row option for 0s vs "-" */}

    {
      row.isEditable && (!row.state || row.state === States.DEFAULT)
        ?
        <input type="number"
          value={value || ""}
          className={classNames(
            // Border
            "border-none",

            // Padding/Margin
            "px-0 mx-0",

            // Sizing
            "w-full",

            // Text
            "text-center",

            // Hide Arrows
            "hide-arrows",

            // Invalid
            "invalid:ring-red-500 focus:invalid:ring-red-500 focus:ring-2 invalid:ring-1 invalid:ring-offset-1",
          )}
          placeholder='-'
          onChange={(e) => {
            onChange(rowIndex, colIndex, e.target.value, e)
          }}
          onBlur={(e) => {
            onBlur(rowIndex, colIndex, e.target.value, e)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
              onBlur(rowIndex, colIndex, e.currentTarget.value, null)
            }
          }}
          {...numberOptions}
        />
        :
        (value || "-")}
  </td>
}
